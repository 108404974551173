import {
  t
} from 'i18next';

export enum Steps {
  CreateSet = 'createSet',
  SelectEntity = 'selectEntity',
}

export const getStepsMap = () => [
  {
    id: Steps.CreateSet,
    label: t('question.createOrExpandSet'),
  },
  {
    id: Steps.SelectEntity,
    label: t('management.selectGroupsAndOrganisations'),
  },
];
