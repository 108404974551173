import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const SectionContainer = styled.div`
  ${tw`border-r border-gray90 flex flex-col`}
`;

export const SectionHeader = styled.div`
  ${tw`flex flex-col gap-4 pt-4 pb-4`}
`;

export const TitleWrapper = styled.div`
  ${tw`flex gap-4 items-center px-4`}
`;

export const EmptyMessageContainer = styled.div`
  ${tw`
    flex flex-col gap-2 items-center text-center px-8 py-6 my-auto mx-6
    bg-alice-blue text-dark-gray text-17-26 font-medium rounded-md
  `}
`;

export const EmptyMessageTitle = styled.div`
  ${tw`text-gray7 font-bold`}
`;

export const TabsContainer = styled.div`
  ${tw`flex gap-6 overflow-x-auto items-center`}
`;
