import React from 'react';
import {
  useSearchParams
} from 'react-router-dom';

import {
  DiscussionSection, PageLayout
} from 'src/widgets';
import {
  communityTab
} from 'src/shared/utils';
import {
  CommunityFollowerSection
} from 'src/widgets/Sections/CommunityCommentsSection';
import {
  CommunityTabId
} from 'src/redux/community';

import * as Style from './Community.styles';
import {
  ActiveQuestionCommunity
} from './ActiveQuestionCommunity';

export const Community = () => {
  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get(communityTab) || CommunityTabId.Active;

  return (
    <Style.PageContainer>
      <PageLayout>
        <Style.MainContainer>
          <DiscussionSection />

          {(activeTab === CommunityTabId.Active
            || activeTab === CommunityTabId.Scored) && (
            <ActiveQuestionCommunity tab={activeTab} />
          )}

          {activeTab === CommunityTabId.Followings && (
            <CommunityFollowerSection />
          )}
        </Style.MainContainer>
      </PageLayout>
    </Style.PageContainer>
  );
};
