import {
  emptySplitApi as api
} from '../../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiQuestions: build.query<
    GetApiQuestionsApiResponse,
    GetApiQuestionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiQuestionsById: build.query<
    GetApiQuestionsByIdApiResponse,
    GetApiQuestionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}`,
      }),
    }),
    patchApiQuestionsById: build.mutation<
    PatchApiQuestionsByIdApiResponse,
    PatchApiQuestionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateQuestionBodySchema,
      }),
    }),
    getApiQuestionsByIdGroups: build.query<
    GetApiQuestionsByIdGroupsApiResponse,
    GetApiQuestionsByIdGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/groups`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    postApiQuestionsByIdAnswers: build.mutation<
    PostApiQuestionsByIdAnswersApiResponse,
    PostApiQuestionsByIdAnswersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/answers`,
        method: 'POST',
        body: queryArg.updateQuestionAnswerBodySchema,
      }),
      invalidatesTags: ['Scoring'],
    }),
    getApiQuestionsFeed: build.query<
    GetApiQuestionsFeedApiResponse,
    GetApiQuestionsFeedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/feed`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiQuestionsByIdFeed: build.query<
    GetApiQuestionsByIdFeedApiResponse,
    GetApiQuestionsByIdFeedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/feed`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    postApiQuestionsByQuestionIdForecasts: build.mutation<
    PostApiQuestionsByQuestionIdForecastsApiResponse,
    PostApiQuestionsByQuestionIdForecastsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.questionId}/forecasts`,
        method: 'POST',
        body: queryArg.createForecastBodySchema,
      }),
      invalidatesTags: ['Forecast'],
    }),
    deleteApiQuestionsByQuestionIdForecastsAndForecastId: build.mutation<
    DeleteApiQuestionsByQuestionIdForecastsAndForecastIdApiResponse,
    DeleteApiQuestionsByQuestionIdForecastsAndForecastIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.questionId}/forecasts/${queryArg.forecastId}`,
        method: 'DELETE',
      }),
    }),
    getApiQuestionsStatistics: build.query<
    GetApiQuestionsStatisticsApiResponse,
    GetApiQuestionsStatisticsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/statistics`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiQuestionsStatisticsActive: build.query<
    GetApiQuestionsStatisticsActiveApiResponse,
    GetApiQuestionsStatisticsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/statistics/active`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
      providesTags: ['Favorites'],
    }),
    getApiQuestionsByIdStatisticsActive: build.mutation<
    GetApiQuestionsByIdStatisticsActiveApiResponse,
    GetApiQuestionsByIdStatisticsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/statistics/active`,
        method: 'GET',
        params: {
          filters: queryArg.filters,
        },
      }),
    }),
    getApiQuestionsStatisticsAvailable: build.query<
    GetApiQuestionsStatisticsAvailableApiResponse,
    GetApiQuestionsStatisticsAvailableApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/statistics/available`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiQuestionsCommunityDeactivated: build.query<
    GetApiQuestionsCommunityDeactivatedApiResponse,
    GetApiQuestionsCommunityDeactivatedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/community/deactivated`,
        params: {
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          questionSetIds: queryArg.questionSetIds,
          fromDate: queryArg.fromDate,
          withQuestionSets: queryArg.withQuestionSets,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
      providesTags: ['Favorites'],
    }),
    getApiQuestionsCommunity: build.query<
    GetApiQuestionsCommunityApiResponse,
    GetApiQuestionsCommunityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/community`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiQuestionsByIdStatisticsCharts: build.query<
    GetApiQuestionsByIdStatisticsChartsApiResponse,
    GetApiQuestionsByIdStatisticsChartsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/statistics/charts`,
        params: {
          filters: queryArg.filters,
        },
      }),
      providesTags: ['Forecast'],
    }),
    postApiQuestionsByIdFavorites: build.mutation<
    PostApiQuestionsByIdFavoritesApiResponse,
    PostApiQuestionsByIdFavoritesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/favorites`,
        method: 'POST',
      }),
      invalidatesTags: ['Favorites'],
    }),
    deleteApiQuestionsByIdFavorites: build.mutation<
    DeleteApiQuestionsByIdFavoritesApiResponse,
    DeleteApiQuestionsByIdFavoritesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/favorites`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Favorites'],
    }),
    getApiQuestionsPostEstimationsRedirect: build.query<
    GetApiQuestionsPostEstimationsRedirectApiResponse,
    GetApiQuestionsPostEstimationsRedirectApiArg
    >({
      query: () => ({
        url: `/api/questions/post-estimations/redirect`,
      }),
    }),
    getApiQuestionsByIdPostEstimations: build.query<
    GetApiQuestionsByIdPostEstimationsApiResponse,
    GetApiQuestionsByIdPostEstimationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/post-estimations`,
      }),
    }),
    postApiQuestionsByIdPostEstimations: build.mutation<
    PostApiQuestionsByIdPostEstimationsApiResponse,
    PostApiQuestionsByIdPostEstimationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/post-estimations`,
        method: 'POST',
        body: queryArg.createQuestionPostEstimationFeedbackBodySchema,
      }),
    }),
    postApiQuestionsByIdDelegations: build.mutation<
    PostApiQuestionsByIdDelegationsApiResponse,
    PostApiQuestionsByIdDelegationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/delegations`,
        method: 'POST',
        body: queryArg.createQuestionDelegateBodySchema,
      }),
    }),
    deleteApiQuestionsByIdDelegations: build.mutation<
    DeleteApiQuestionsByIdDelegationsApiResponse,
    DeleteApiQuestionsByIdDelegationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/delegations`,
        method: 'DELETE',
      }),
    }),
    getApiQuestionsByIdDelegations: build.query<
    GetApiQuestionsByIdDelegationsApiResponse,
    GetApiQuestionsByIdDelegationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/delegations`,
      }),
    }),
    getApiQuestionsByIdDelegationsGroups: build.query<
    GetApiQuestionsByIdDelegationsGroupsApiResponse,
    GetApiQuestionsByIdDelegationsGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/delegations/groups`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiQuestionsByIdDelegationsUsers: build.query<
    GetApiQuestionsByIdDelegationsUsersApiResponse,
    GetApiQuestionsByIdDelegationsUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/delegations/users`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          groupId: queryArg.groupId,
          query: queryArg.query,
        },
      }),
    }),
    getApiQuestionsByIdSurveysAndSurveyQuestionIdOptions: build.query<
    GetApiQuestionsByIdSurveysAndSurveyQuestionIdOptionsApiResponse,
    GetApiQuestionsByIdSurveysAndSurveyQuestionIdOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/surveys/${queryArg.surveyQuestionId}/options`,
      }),
      providesTags: ['Survey'],
    }),
    postApiQuestionsSurveys: build.mutation<
    PostApiQuestionsSurveysApiResponse,
    PostApiQuestionsSurveysApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/surveys`,
        method: 'POST',
        body: queryArg.createQuestionSurveyBodySchema,
      }),
    }),
    postApiQuestionsByIdSurveysAndSurveyQuestionIdOptionsOptionId:
      build.mutation<
      PostApiQuestionsByIdSurveysAndSurveyQuestionIdOptionsOptionIdApiResponse,
      PostApiQuestionsByIdSurveysAndSurveyQuestionIdOptionsOptionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/questions/${queryArg.id}/surveys/${queryArg.surveyQuestionId}/options/${queryArg.optionId}`,
          method: 'POST',
        }),
        invalidatesTags: ['Survey'],
      }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiQuestions
};
export type GetApiQuestionsApiResponse =
  /** status 200 Default Response */ QuestionsResponseSchema;
export type GetApiQuestionsApiArg = {
  filters?: {
    title?: string;
    questionSetId?: string;
    active?: boolean;
    updatesPer?: string;
    status?: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
    notStatus?: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
    sortBy?: 'createdAt' | 'startDate';
    sortOrder?: 'asc' | 'desc';
  };
  page?: number;
  limit?: number;
};
export type GetApiQuestionsByIdApiResponse =
  /** status 200 Default Response */ QuestionResponseSchema;
export type GetApiQuestionsByIdApiArg = {
  id: string;
};
export type PatchApiQuestionsByIdApiResponse =
  /** status 200 Default Response */ QuestionBasicResponseSchema;
export type PatchApiQuestionsByIdApiArg = {
  id: string;
  updateQuestionBodySchema: UpdateQuestionBodySchema;
};
export type GetApiQuestionsByIdGroupsApiResponse =
  /** status 200 Default Response */ QuestionGroupsResponseSchema;
export type GetApiQuestionsByIdGroupsApiArg = {
  page?: Page;
  limit?: Limit;
  id: string;
};
export type PostApiQuestionsByIdAnswersApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiQuestionsByIdAnswersApiArg = {
  id: string;
  updateQuestionAnswerBodySchema: UpdateQuestionAnswerBodySchema;
};
export type GetApiQuestionsFeedApiResponse =
  /** status 200 Default Response */ QuestionsFeedResponseSchema;
export type GetApiQuestionsFeedApiArg = {
  filters?: {
    sortBy?: 'createdAt' | 'commentsCount' | 'commendationsCount';
    sortOrder?: SortOrder;
    userOnly?: boolean;
    groupIds?: string[];
    dateStart?: string;
    dateEnd?: DateStart;
  };
  page?: Page;
  limit?: Limit;
};
export type GetApiQuestionsByIdFeedApiResponse =
  /** status 200 Default Response */ QuestionsFeedResponseSchema;
export type GetApiQuestionsByIdFeedApiArg = {
  filters?: {
    sortBy?: 'createdAt' | 'commentsCount' | 'commendationsCount';
    sortOrder?: SortOrder;
    userOnly?: boolean;
    dateStart?: DateStart;
    dateEnd?: DateStart;
    groupIds?: string[];
    commentId?: string;
    withForecast?: boolean;
  };
  page?: Page;
  limit?: Limit;
  id: string;
};
export type PostApiQuestionsByQuestionIdForecastsApiResponse =
  /** status 201 Default Response */ ForecastResponseSchema;
export type PostApiQuestionsByQuestionIdForecastsApiArg = {
  questionId: string;
  createForecastBodySchema: CreateForecastBodySchema;
};
export type DeleteApiQuestionsByQuestionIdForecastsAndForecastIdApiResponse =
  /** status 200 Default Response */ ForecastResponseSchema;
export type DeleteApiQuestionsByQuestionIdForecastsAndForecastIdApiArg = {
  questionId: string;
  forecastId: string;
};
export type GetApiQuestionsStatisticsApiResponse =
  /** status 200 Default Response */ QuestionsStatisticsResponseSchema;
export type GetApiQuestionsStatisticsApiArg = {
  filters?: QuestionsAllStatisticsFiltersSchema;
  page?: Page;
  limit?: Limit;
};
export type GetApiQuestionsStatisticsActiveApiResponse =
  /** status 200 Default Response */ QuestionsActiveStatisticsResponseSchema;
export type GetApiQuestionsStatisticsActiveApiArg = {
  filters?: {
    favorite?: boolean;
    title?: string;
    questionSetId?: string;
    updatesPer?: '1d' | '7d' | '14d' | '30d';
    sortBy?:
    | 'createdAt'
    | 'commentsCount'
    | 'participantsCount'
    | 'updatesCount';
    sortOrder?: SortOrder;
  };
  page?: Page;
  limit?: Limit;
};
export type GetApiQuestionsByIdStatisticsActiveApiResponse =
  /** status 200 Default Response */ QuestionActiveStatisticsResponseSchema;
export type GetApiQuestionsByIdStatisticsActiveApiArg = {
  filters?: {
    updatesPer?: UpdatesPer;
  };
  id: string;
};
export type GetApiQuestionsStatisticsAvailableApiResponse =
  /** status 200 Default Response */ QuestionsAvailableStatisticsResponseSchema;
export type GetApiQuestionsStatisticsAvailableApiArg = {
  filters?: Filters;
  page?: Page;
  limit?: Limit;
};
export type GetApiQuestionsCommunityDeactivatedApiResponse =
  /** status 200 Default Response */ QuestionsDeactivatedCommunityResponseSchema;
export type GetApiQuestionsCommunityDeactivatedApiArg = {
  sortBy?: 'deactivatedAt' | 'score';
  sortOrder?: SortOrder;
  questionSetIds?: string[] | null;
  fromDate?: UpdatesPer2;
  withQuestionSets?: boolean;
  page?: Page;
  limit?: Limit;
};
export type GetApiQuestionsCommunityApiResponse =
  /** status 200 Default Response */ CommunityQuestionsResponseSchema;
export type GetApiQuestionsCommunityApiArg = {
  filters: {
    groupId?: string;
    sortBy?: 'commentsCreatedAt' | 'commentsCount' | 'participantsCount';
    sortOrder?: SortOrder;
    questionSetId?: string;
  };
  page?: Page;
  limit?: Limit;
};
export type GetApiQuestionsByIdStatisticsChartsApiResponse =
  /** status 200 Default Response */ QuestionStatisticsChartResponseSchema;
export type GetApiQuestionsByIdStatisticsChartsApiArg = {
  filters?: {
    timeRange?: UpdatesPer2;
    groupIds?: string[];
    estimates?: string[];
    myForecasts?: boolean;
    groupsAverages?: boolean;
  };
  id: string;
};
export type PostApiQuestionsByIdFavoritesApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiQuestionsByIdFavoritesApiArg = {
  id: string;
};
export type DeleteApiQuestionsByIdFavoritesApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiQuestionsByIdFavoritesApiArg = {
  id: string;
};
export type GetApiQuestionsPostEstimationsRedirectApiResponse = unknown;
export type GetApiQuestionsPostEstimationsRedirectApiArg = void;
export type GetApiQuestionsByIdPostEstimationsApiResponse =
  /** status 200 Default Response */ PostEstimationFeedbackQuestionResponseSchema;
export type GetApiQuestionsByIdPostEstimationsApiArg = {
  id: string;
};
export type PostApiQuestionsByIdPostEstimationsApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiQuestionsByIdPostEstimationsApiArg = {
  id: string;
  createQuestionPostEstimationFeedbackBodySchema: CreateQuestionPostEstimationFeedbackBodySchema;
};
export type PostApiQuestionsByIdDelegationsApiResponse =
  /** status 200 Default Response */ CreateQuestionDelegateResponseSchema;
export type PostApiQuestionsByIdDelegationsApiArg = {
  id: string;
  createQuestionDelegateBodySchema: CreateQuestionDelegateBodySchema;
};
export type DeleteApiQuestionsByIdDelegationsApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiQuestionsByIdDelegationsApiArg = {
  id: string;
};
export type GetApiQuestionsByIdDelegationsApiResponse =
  /** status 200 Default Response */ GetQuestionDelegationUserResponseSchema;
export type GetApiQuestionsByIdDelegationsApiArg = {
  id: string;
};
export type GetApiQuestionsByIdDelegationsGroupsApiResponse =
  /** status 200 Default Response */ GetQuestionDelegationGroupsResponseSchema;
export type GetApiQuestionsByIdDelegationsGroupsApiArg = {
  page?: Page;
  limit?: Limit;
  id: string;
};
export type GetApiQuestionsByIdDelegationsUsersApiResponse =
  /** status 200 Default Response */ GetQuestionDelegationUsersResponseSchema;
export type GetApiQuestionsByIdDelegationsUsersApiArg = {
  page?: Page;
  limit?: Limit;
  groupId?: string;
  query?: string;
  id: string;
};
export type GetApiQuestionsByIdSurveysAndSurveyQuestionIdOptionsApiResponse =
  /** status 200 Default Response */ GetSurveyQuestionOptionsResponseSchema;
export type GetApiQuestionsByIdSurveysAndSurveyQuestionIdOptionsApiArg = {
  id: string;
  surveyQuestionId: string;
};
export type PostApiQuestionsSurveysApiResponse =
  /** status 200 Default Response */ CreateQuestionSurveyResponseSchema;
export type PostApiQuestionsSurveysApiArg = {
  createQuestionSurveyBodySchema: CreateQuestionSurveyBodySchema;
};
export type PostApiQuestionsByIdSurveysAndSurveyQuestionIdOptionsOptionIdApiResponse =
  /** status 200 Default Response */ SubmitSurveyQuestionOptionResponseSchema;
export type PostApiQuestionsByIdSurveysAndSurveyQuestionIdOptionsOptionIdApiArg =
  {
    surveyQuestionId: string;
    id: string;
    optionId: string;
  };
export type Items = {
  id: string;
  createdAt: string;
  updatedAt: string;
  estimates: {
    title: string;
    value: number;
  }[];
  question: {
    id: string;
  };
  comments: {
    id: string;
    createdAt: string;
    updatedAt: string;
    text: string | null;
    isPrivate: boolean;
    author: {
      id: string;
    };
    comments: {
      id: string;
      createdAt: string;
      updatedAt: string;
      text: string;
      isPrivate: boolean;
      author: {
        id: string;
      };
      rootComment: {
        id: string;
      } | null;
      question: {
        id: string;
      } | null;
      forecast: {
        id: string;
      } | null;
    }[];
    rootComment: {
      id: string;
    } | null;
    question: {
      id: string;
    } | null;
    forecast: {
      id: string;
    } | null;
  }[];
  author: {
    id: string;
  };
};
export type QuestionsResponseSchema = {
  data: {
    questions: {
      id: string;
      isMandatory: boolean;
      createdAt: string;
      updatedAt: string;
      title: string;
      startDate: string;
      endDate: string;
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      estimatesTitles: string[] | null;
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      questionSet: {
        id: string;
        title: string;
      } | null;
      forecasts: Items[];
    }[];
    total: number;
  };
  message: string;
};
export type QuestionResponseSchema = {
  data: {
    question: {
      id: string;
      createdAt: string;
      updatedAt: string;
      isMandatory: boolean;
      title: string;
      startDate: string;
      endDate: string;
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      estimatesTitles: string[] | null;
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      groupStatus: ('ACTIVATED' | 'DEACTIVATED') | null;
      surveyQuestions: {
        id: string;
        title: string;
        startDate: string;
        endDate: string;
        createdAt: string;
        updatedAt: string;
        status: 'ACTIVE' | 'UPCOMING' | 'CLOSED';
      }[];
      questionSet: {
        id: string;
        title: string;
      } | null;
      forecasts: {
        id: string;
        createdAt: string;
        updatedAt: string;
        estimates: {
          title: string;
          value: number;
        }[];
        question: {
          id: string;
        };
        comments: {
          id: string;
          createdAt: string;
          updatedAt: string;
          text: string | null;
          isPrivate: boolean;
          author: {
            id: string;
          };
          comments: {
            id: string;
            createdAt: string;
            updatedAt: string;
            text: string;
            isPrivate: boolean;
            author: {
              id: string;
            };
            rootComment: {
              id: string;
            } | null;
            question: {
              id: string;
            } | null;
            forecast: {
              id: string;
            } | null;
          }[];
          rootComment: {
            id: string;
          } | null;
          question: {
            id: string;
          } | null;
          forecast: {
            id: string;
          } | null;
        }[];
        author: {
          id: string;
        };
      }[];
      isActive: boolean;
      isFavorite: boolean;
      hasDelegate: boolean;
      links: {
        url: string;
        title: string;
      }[];
      information: string | null;
      onboardingText: string | null;
      baseRate: number | null;
    };
  };
  message: string;
};
export type QuestionBasicResponseSchema = {
  message: string;
  data: {
    question: {
      id: string;
      isMandatory: boolean;
      information: string | null;
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      startDate: string;
      endDate: string;
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      title: string;
      questionSet: {
        id: string;
        title: string;
      };
      estimatesTitles: string[] | null;
      links: {
        url: string;
        title: string;
      }[];
    };
  };
};
export type Items2 = {
  url: string;
  title: string;
};
export type UpdateQuestionBodySchema = {
  title?: string;
  questionSetId?: string;
  information?: string;
  links?: Items2[];
  isMandatory?: boolean;
};
export type QuestionGroupsResponseSchema = {
  message: string;
  data: {
    groups: {
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    }[];
  };
};
export type Page = number;
export type Limit = number;
export type MessageResponseSchema = {
  message: string;
};
export type UpdateQuestionAnswerBodySchema = {
  result: boolean;
  endDate: string | null;
  postDebiasing: {
    message: string;
  } | null;
};
export type Author = {
  id: string;
  name: string;
  avatarUrl: string | null;
  displayName: string | null;
  isFollowed: boolean;
};
export type QuestionsFeedResponseSchema = {
  message: string;
  data: {
    feed: {
      id: string;
      createdAt: string;
      updatedAt: string;
      text: string | null;
      isPrivate: boolean;
      commendationsCount: number;
      userCommendation:
      | ('LIKE' | 'RELEVANT' | 'INSIGHTFUL' | 'INSPIRING')
      | null;
      commendationTypes:
      | ('LIKE' | 'RELEVANT' | 'INSIGHTFUL' | 'INSPIRING')[]
      | null;
      groupRemoveAccess?: boolean;
      author: {
        id: string;
        name: string;
        avatarUrl: string | null;
        displayName: string | null;
        isFollowed: boolean;
      };
      forecast: {
        id: string;
        estimates: {
          title: string;
          value: number;
        }[];
      } | null;
      question: {
        id: string;
        title: string;
        hasUserOnboarded?: boolean;
      };
      commentsCount: number;
      comments: {
        id: string;
        createdAt: string;
        updatedAt: string;
        text: string;
        author: Author;
        likes: number;
        isLiked: boolean;
      }[];
      estimatesDiff: number | null;
    }[];
    total: number;
    hasOnboarded: boolean;
  };
};
export type SortOrder = 'asc' | 'desc';
export type DateStart = string;
export type ForecastResponseSchema = {
  data: {
    forecast: Items;
  };
  message: string;
};
export type CreateForecastBodySchema = {
  estimates: {
    title: string;
    value: number;
  }[];
  comment: {
    text: string | null;
    isPrivate: boolean;
  } | null;
};
export type QuestionStatisticsSchema = {
  id: string;
  createdAt: string;
  updatedAt: string;
  isMandatory: boolean;
  status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
  groupStatus: ('ACTIVATED' | 'DEACTIVATED') | null;
  type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
  estimatesTitles: string[] | null;
  isActive: boolean;
  startDate: string;
  endDate: string;
  title: string;
  questionSet: {
    id: string;
    title: string;
  };
};
export type QuestionsStatisticsResponseSchema = {
  data: {
    total: number;
    statistics: QuestionStatisticsSchema[];
  };
  message: string;
};
export type QuestionsAllStatisticsFiltersSchema = {
  questionSetId?: string;
};
export type QuestionsActiveStatisticsResponseSchema = {
  data: {
    total: number;
    statistics: {
      id: string;
      createdAt: string;
      updatedAt: string;
      isMandatory: boolean;
      isPreferred: boolean;
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      groupStatus: ('ACTIVATED' | 'DEACTIVATED') | null;
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      estimatesTitles: string[] | null;
      isActive: boolean;
      isFavorite: boolean;
      hasDelegate: boolean;
      startDate: string;
      endDate: string;
      title: string;
      participantsCount: number;
      updatesCount: number;
      commentsCount: number;
      questionSet: {
        id: string;
        title: string;
      };
      activeSurveyQuestion: {
        id: string;
        title: string;
        startDate: string;
        endDate: string;
      } | null;
      userEstimatesAvg: number | null;
      globalEstimatesAvg: number | null;
      globalEstimatesChange: number | null;
    }[];
  };
  message: string;
};
export type Items3 = {
  id: string;
  createdAt: string;
  updatedAt: string;
  isMandatory: boolean;
  isPreferred: boolean;
  status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
  groupStatus: ('ACTIVATED' | 'DEACTIVATED') | null;
  type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
  estimatesTitles: string[] | null;
  isActive: boolean;
  isFavorite: boolean;
  hasDelegate: boolean;
  startDate: string;
  endDate: string;
  title: string;
  participantsCount: number;
  updatesCount: number;
  commentsCount: number;
  questionSet: {
    id: string;
    title: string;
  };
  activeSurveyQuestion: {
    id: string;
    title: string;
    startDate: string;
    endDate: string;
  } | null;
  userEstimatesAvg: number | null;
  globalEstimatesAvg: number | null;
  globalEstimatesChange: number | null;
};
export type QuestionActiveStatisticsResponseSchema = {
  message: string;
  data: {
    statistics: Items3;
  };
};
export type UpdatesPer = '1d' | '7d' | '14d' | '30d';
export type QuestionsAvailableStatisticsResponseSchema = {
  message: string;
  data: {
    total: number;
    statistics: {
      id: string;
      createdAt: string;
      updatedAt: string;
      isMandatory: boolean;
      isPreferred: boolean;
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      groupStatus: ('ACTIVATED' | 'DEACTIVATED') | null;
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      estimatesTitles: string[] | null;
      startDate: string;
      endDate: string;
      title: string;
      activeSurveyQuestion: {
        id: string;
        title: string;
        startDate: string;
        endDate: string;
      } | null;
      questionSet: {
        id: string;
        title: string;
      };
      participantsCount: number;
      updatesCount: number;
      commentsCount: number;
      onboardingText: string | null;
    }[];
  };
};
export type Filters = {
  favorite?: boolean;
  title?: string;
  questionSetId?: string;
  updatesPer?: '1d' | '7d' | '14d' | '30d';
  sortBy?: 'createdAt' | 'commentsCount' | 'participantsCount' | 'updatesCount';
  sortOrder?: SortOrder;
};
export type QuestionsDeactivatedCommunityResponseSchema = {
  data: {
    total: number;
    statistics: {
      id: string;
      title: string;
      createdAt: string;
      updatedAt: string;
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      startDate: string;
      endDate: string;
      result: boolean | string;
      deactivatedAt: null | string;
      userScore: number;
      questionSet: {
        id: string;
        title: string;
      };
      userEstimatesAvg: null | number;
      globalEstimatesAvg: null | number;
      forecastsCount: number;
      commentsCount: number;
    }[];
    questionSets:
    | {
      id: string;
      title: string;
    }[]
    | null;
  };
  message: string;
};
export type UpdatesPer2 = string;
export type CommunityQuestionsResponseSchema = {
  message: string;
  data: {
    questions: {
      id: string;
      title: string;
      createdAt: string;
      updatedAt: string;
      startDate: string;
      endDate: string;
      participantsCount: number;
      commentsCount: number;
      updatesCount: number;
      globalEstimatesAvg: number | null;
      globalEstimatesChange: number | null;
    }[];
    total: number;
  };
};
export type Total =
  | (
    | number
    | {
      [key: string]: number;
    }
  )
  | null;
export type QuestionStatisticsChartResponseSchema = {
  message: string;
  data: {
    chart: ({
      date: string;
      total:
      | (
        | number
        | {
          [key: string]: number;
        }
      )
      | null;
      votes: number;
      groupsTotal: Total;
      groupsVotes: number;
      own: Total;
    } & {
      [key: string]: Total;
    })[];
  };
};
export type PostEstimationFeedbackQuestionResponseSchema = {
  message: string;
  data: {
    question: {
      title: string;
      setTitle: string;
      questionId: string;
      avg: number;
      postDebiasingMessage: string | null;
    };
  };
};
export type CreateQuestionPostEstimationFeedbackBodySchema = {
  feedback: string;
};
export type CreateQuestionDelegateResponseSchema = {
  message: string;
  data: {
    delegate: {
      id: string;
      displayName: string | null;
      name: string;
      avatarUrl: string | null;
    };
  };
};
export type CreateQuestionDelegateBodySchema = {
  delegateId: string;
};
export type GetQuestionDelegationUserResponseSchema = {
  message: string;
  data: {
    delegate: {
      id: string;
      displayName: string | null;
      name: string;
      avatarUrl: string | null;
    } | null;
  };
};
export type GetQuestionDelegationGroupsResponseSchema = {
  message: string;
  data: {
    groups: {
      id: string;
      name: string;
    }[];
    total: number;
  };
};
export type GetQuestionDelegationUsersResponseSchema = {
  message: string;
  data: {
    users: {
      id: string;
      displayName: string | null;
      name: string;
      avatarUrl: string | null;
    }[];
    total: number;
  };
};
export type GetSurveyQuestionOptionsResponseSchema = {
  message: string;
  data: {
    options: {
      id: string;
      votes: number;
      createdAt: string;
      updatedAt: string;
      votesPercent: number;
      title: string;
      order: number;
      isChosen: boolean;
    }[];
  };
};
export type CreateQuestionSurveyResponseSchema = {
  message: string;
  data: {
    question: {
      id: string;
      createdAt: string;
      updatedAt: string;
      title: string;
      startDate: string;
      endDate: string;
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      isMandatory: boolean;
      information: string | null;
      links: {
        url: string;
        title: string;
      }[];
      questionSet: {
        title: string;
      };
      surveyQuestions: {
        id: string;
        title: string;
        startDate: string;
        endDate: string;
        createdAt: string;
        updatedAt: string;
        options: {
          id: string;
          title: string;
          votes: number;
          votesPercent: number;
          order: number;
          updatedAt: string;
          createdAt: string;
        }[];
      }[];
    };
  };
};
export type CreateQuestionSurveyBodySchema = {
  questionSetId: string;
  isMandatory: boolean;
  title: string;
  information: string | null;
  links:
  | {
    url: string;
    title: string;
  }[]
  | null;
  surveyQuestions: {
    title: string;
    startDate: string;
    endDate: string;
    options: {
      title: string;
      order: number;
    }[];
  }[];
};
export type Items4 = {
  id: string;
  votes: number;
  createdAt: string;
  updatedAt: string;
  votesPercent: number;
  title: string;
  order: number;
  isChosen: boolean;
};
export type SubmitSurveyQuestionOptionResponseSchema = {
  message: string;
  data: {
    options: Items4[];
  };
};
export const {
  useGetApiQuestionsQuery,
  useGetApiQuestionsByIdQuery,
  usePatchApiQuestionsByIdMutation,
  useGetApiQuestionsByIdGroupsQuery,
  usePostApiQuestionsByIdAnswersMutation,
  useGetApiQuestionsFeedQuery,
  useGetApiQuestionsByIdFeedQuery,
  usePostApiQuestionsByQuestionIdForecastsMutation,
  useDeleteApiQuestionsByQuestionIdForecastsAndForecastIdMutation,
  useGetApiQuestionsStatisticsQuery,
  useGetApiQuestionsStatisticsActiveQuery,
  useGetApiQuestionsByIdStatisticsActiveMutation,
  useGetApiQuestionsStatisticsAvailableQuery,
  useGetApiQuestionsCommunityDeactivatedQuery,
  useGetApiQuestionsCommunityQuery,
  useGetApiQuestionsByIdStatisticsChartsQuery,
  usePostApiQuestionsByIdFavoritesMutation,
  useDeleteApiQuestionsByIdFavoritesMutation,
  useGetApiQuestionsPostEstimationsRedirectQuery,
  useGetApiQuestionsByIdPostEstimationsQuery,
  usePostApiQuestionsByIdPostEstimationsMutation,
  usePostApiQuestionsByIdDelegationsMutation,
  useDeleteApiQuestionsByIdDelegationsMutation,
  useGetApiQuestionsByIdDelegationsQuery,
  useGetApiQuestionsByIdDelegationsGroupsQuery,
  useGetApiQuestionsByIdDelegationsUsersQuery,
  useGetApiQuestionsByIdSurveysAndSurveyQuestionIdOptionsQuery,
  usePostApiQuestionsSurveysMutation,
  usePostApiQuestionsByIdSurveysAndSurveyQuestionIdOptionsOptionIdMutation,
} = injectedRtkApi;
