import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  SortBy,
  SortByMenu,
  FilterByMenu,
  TimeRangeMenu,
  FilterBy,
} from 'src/entities/Questions';
import {
  TimeSpansValues
} from 'src/redux/timeSpans';
import {
  FilterPopoverMenu
} from 'src/shared/components';
import {
  useMediaQuery
} from 'src/shared/hooks';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  homePreferencesSelectors,
  setHomePreferencesFilterBy,
  setHomePreferencesSortBy,
  setHomePreferencesTimeRange,
} from 'src/redux/homePreferences';

import * as Style from './OnboardingQuestionsFilter.styles';

interface OnboardingQuestionsFilterProps {
  isTableView: boolean;
}

export const OnboardingQuestionsFilter: FC<OnboardingQuestionsFilterProps> = ({
  isTableView,
}) => {
  const {
    t
  } = useTranslation();

  const {
    filterBy, sortBy, timeRange, isFavorites
  } = useTypedSelector(
    homePreferencesSelectors.selectAllPreferences,
  );

  const dispatch = useTypedDispatch();

  const onSortClick = (filter: SortBy | null) => dispatch(setHomePreferencesSortBy(filter));

  const onFilterClick = (id: FilterBy | null) => dispatch(setHomePreferencesFilterBy(id));

  const onTimeRangeSelect = (range: TimeSpansValues | null) => {
    return dispatch(setHomePreferencesTimeRange(range));
  };

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  return (
    <FilterPopoverMenu
      tooltipMessage={isMobile ? '' : t('filters.filterQuestions')}
      placement="bottom-start"
      width={308}
    >
      <Style.MainContainer>
        {!isTableView && !isFavorites && (
          <>
            <FilterByMenu
              currentValue={filterBy}
              onSelect={onFilterClick}
            />

            <Style.Divider />
          </>
        )}

        <TimeRangeMenu
          currentValue={timeRange}
          onSelect={onTimeRangeSelect}
        />

        <Style.Divider />

        <SortByMenu
          withExtendedOptions
          onSelect={onSortClick}
          currentValue={sortBy || SortBy.Newest}
          timeRange={timeRange}
        />
      </Style.MainContainer>
    </FilterPopoverMenu>
  );
};
