import {
  t
} from 'i18next';
import React, {
  FunctionComponent, ReactNode, SVGProps
} from 'react';

import {
  TrashDeleteIcon, EditPencilIcon, ReloadIcon
} from 'src/shared/icons';
import {
  ToggleAutoApproval
} from 'src/features/ToggleAutoApproval';

import {
  GroupActions,
  GroupRequestsActions,
  ManagementEntity,
  OrganisationActions,
} from '../../types';

interface MenuItem<T> {
  action: T;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
  isDelete?: boolean;
  renderCustomAction?: (id: string) => ReactNode;
}

export type EditMenuConfigMap = {
  organization: MenuItem<OrganisationActions>[];
  groupRequest: MenuItem<GroupRequestsActions>[];
  group: MenuItem<GroupActions>[];
};

export const getEditMenuConfig = <T extends ManagementEntity>(
  type: T,
): EditMenuConfigMap[T] | null => {
  const config = {
    organization: [
      {
        action: OrganisationActions.EditOrganisation,
        icon: EditPencilIcon,
        label: t('organisation.editOrganisation'),
      },
      {
        action: OrganisationActions.ToggleAutoApproval,
        icon: ReloadIcon,
        label: t('management.newSetAutoApproval'),
        renderCustomAction: (id: string) => (
          <ToggleAutoApproval organizationId={id} />
        ),
      },
      {
        action: OrganisationActions.DeleteOrg,
        icon: TrashDeleteIcon,
        label: t('organisation.Delete'),
        isDelete: true,
      },
    ],
    groupRequest: [
      {
        action: GroupRequestsActions.DeleteRequest,
        icon: TrashDeleteIcon,
        label: t('organisation.removeRequest'),
        isDelete: true,
      },
    ],
    group: [
      {
        action: GroupActions.EditGroup,
        icon: EditPencilIcon,
        label: t('group.editGroup'),
      },
      {
        action: GroupActions.DeleteGroup,
        icon: TrashDeleteIcon,
        label: t('group.deleteGroup'),
        isDelete: true,
      },
    ],
  };

  return config[type] || null;
};
