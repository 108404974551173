import React, {
  FC
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  СheckmarkСircleStrokeIcon, ArrowRightIcon
} from 'src/shared/icons';
import {
  Button
} from 'src/shared/components';
import {
  ROUTE
} from 'src/shared/utils';

import {
  Title
} from './Login.styles';

export const CreationSuccessPage: FC = () => {
  const navigate = useNavigate();

  const {
    t
  } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center flex-1 h-full gap-8 max-w-450px m-auto">
      <div className="flex flex-col gap-4">
        <СheckmarkСircleStrokeIcon className="w-16 h-16 text-button-blue mx-auto" />

        <Title>{t('login.successfullyCreated')}</Title>

        <div className="flex flex-row gap-2 items-center justify-start w-full">
          <p>{t('login.nextYouNeedTo')}</p>
        </div>
      </div>

      <Button
        variant="big-blue"
        onClick={() => navigate(`/${ROUTE.CREATE_GROUP}`)}
      >
        <span className="text-17-26 md:text-xl font-bold">
          {t('group.personalGroup')}
        </span>

        <ArrowRightIcon className="text-white" />
      </Button>
    </div>
  );
};
