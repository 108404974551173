import React, {
  FC, FunctionComponent
} from 'react';
import clsx from 'clsx';

import {
  CloseIcon, СheckmarkСircleStrokeIcon
} from 'src/shared/icons';
import {
  Button, ModalContainer
} from 'src/shared/components';

import * as Style from './ModalSuccess.styles';

interface ModalSuccessProps {
  closeModal: () => void;
  isOpen: boolean;
  title: string;
  textContent?: string;
  buttonTitle?: string;
  buttonIcon?: FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
  onButtonClick?: () => void;
  titleClassName?: string;
  textClassName?: string;
  buttonClassName?: string;
  modalWidth?: number;
  withCloseButton?: boolean;
}

export const ModalSuccess: FC<ModalSuccessProps> = ({
  closeModal,
  isOpen,
  title,
  textContent,
  buttonTitle,
  buttonIcon: Icon = CloseIcon,
  onButtonClick,
  titleClassName,
  textClassName,
  buttonClassName,
  modalWidth = 586,
  withCloseButton,
}) => {
  return (
    <ModalContainer
      modalWidth={modalWidth}
      isDarkBackground
      isHeader={!!withCloseButton}
      closeModalHandler={closeModal}
      isOpen={isOpen}
    >
      <Style.ContentContainer>
        <СheckmarkСircleStrokeIcon className="w-16 h-16 text-button-blue mx-auto" />

        <Style.ModalTitle className={titleClassName}>{title}</Style.ModalTitle>

        {textContent && (
          <Style.ModalText className={textClassName}>
            {textContent}
          </Style.ModalText>
        )}

        {(buttonTitle || Icon) && (
          <Button
            variant="big-blue"
            onClick={onButtonClick || closeModal}
            className={clsx(
              'w-max ml-auto',
              buttonClassName
            )}
          >
            <Style.ModalSpan>{buttonTitle}</Style.ModalSpan>

            {Icon && <Icon className="w-6 h-6 text-white" />}
          </Button>
        )}
      </Style.ContentContainer>
    </ModalContainer>
  );
};
