import {
  t
} from 'i18next';

import {
  ClockIcon, MessageIcon, ThumbUpIcon
} from 'src/shared/icons';
import {
  commentsFilterSelectors,
  setFeedShowMyOnly,
  setFeedShowForecasts,
} from 'src/redux/commentsFilters';

import {
  FilterDateType, SortBy
} from './types';

export const sortMethods = [
  {
    title: SortBy.MOST_DISCUSSION,
    icon: MessageIcon,
  },
  {
    title: SortBy.MOST_LIKED,
    icon: ThumbUpIcon,
  },
  {
    title: SortBy.CHRONOLOGICAL,
    icon: ClockIcon,
  },
];

export const filterDates = [
  FilterDateType.IN_24_HOURS,
  FilterDateType.IN_48_HOURS,
  FilterDateType.WEEK,
  FilterDateType.ALL_TIME,
];

export const getVisibilityOptions = () => [
  {
    selector: commentsFilterSelectors.selectFeedShowMyOnly,
    label: t('filters.myCommentsOnly'),
    onToggleDispatch: setFeedShowMyOnly,
  },
  {
    selector: commentsFilterSelectors.selectFeedShowForecasts,
    label: t('filters.withForecasts'),
    onToggleDispatch: setFeedShowForecasts,
  },
];
