import React from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Button
} from 'src/shared/components';
import {
  PlusRoundedIcon
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';

export const QuestionSetControls = () => {
  const {
    t
  } = useTranslation();

  return (
    <Button
      variant="big-blue-outlined"
      className="!py-2.5 !px-4 !bg-transparent !gap-2 w-max h-max"
      component="link"
      to={`${ROUTE.ADD_SET}`}
    >
      <PlusRoundedIcon className="w-6 h-6" />

      <p>{t('question.addQuestionsOrSets')}</p>
    </Button>
  );
};
