import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  useMediaQuery
} from 'src/shared/hooks';
import {
  CardsLayout
} from 'src/widgets';
import {
  ActiveCard, SimpleCard
} from 'src/entities/Questions';
import {
  ModalUpdateForecast, Spinner
} from 'src/shared/components';
import {
  QuestionsActiveStatistics
} from 'src/redux/openapi';

import {
  EmptyResultsMessage
} from '../EmptyResultsMessage';
import {
  useUpdateModal, useGetQuestions
} from '../../hooks';

import * as Style from './CardsView.styles';

interface CardsViewProps {}

export const CardsView: FC<CardsViewProps> = () => {
  const {
    t
  } = useTranslation();

  const isTablet = useMediaQuery(
    'xl',
    'down'
  );

  const {
    activeConfig, availableConfig, isLoading, onActiveQuestionUpdate
  } = useGetQuestions();

  const {
    isUpdateModalOpen, updatedCard, toggleUpdateModal
  } = useUpdateModal();

  if (isLoading) {
    return <Spinner />;
  }

  if (
    ![activeConfig, availableConfig].some(({
      questions
    }) => questions.length)
  ) {
    return <EmptyResultsMessage />;
  }

  const onActiveButtonClick = (card: QuestionsActiveStatistics) => {
    toggleUpdateModal(card);
  };

  return (
    <Style.ContentContainer>
      {!!activeConfig.questions.length && (
        <section>
          <Style.Title>{t('common.active')}</Style.Title>

          <CardsLayout
            cardsConfig={activeConfig}
            renderElement={(card) => (
              <ActiveCard
                card={card}
                buttonProps={{
                  onClick:
                    card.type !== 'SURVEY'
                      ? () => onActiveButtonClick(card)
                      : undefined,
                }}
              />
            )}
            keyExtractor={(card) => card.id}
            maxPerRow={2}
          />
        </section>
      )}

      {!!availableConfig.questions.length && (
        <section>
          <Style.Title>{t('common.available')}</Style.Title>

          <CardsLayout
            cardsConfig={availableConfig}
            renderElement={(card) => <SimpleCard card={card} />}
            keyExtractor={(card) => card.id}
            maxPerRow={isTablet ? 2 : 3}
          />
        </section>
      )}

      {isUpdateModalOpen && updatedCard && (
        <ModalUpdateForecast
          updatedCard={updatedCard}
          closeModalHandler={() => toggleUpdateModal()}
          isModalOpen={isUpdateModalOpen}
          refetchQuestions={() => onActiveQuestionUpdate(updatedCard.id)}
        />
      )}
    </Style.ContentContainer>
  );
};
