export enum ROUTE {
  ROOT = '/',
  ABOUT_US = 'about-us',
  HELP_CENTER = 'help-center',
  LOGIN = 'login',
  SOCIAL_LOGIN = 'social-login',
  ENTER_PASSWORD = 'enter-password',
  SOCIAL_ENTER_PASSWORD = 'social-enter-password',
  CREATE_ACCOUNT = 'create-account',
  SOCIAL_CREATE_ACCOUNT = 'social-create-account',
  CREATE_PASSWORD = 'create-password',
  SOCIAL_SIGNUP = 'social-signup',
  RESET_PASSWORD = 'reset-password',
  RECOVERY_CODE = 'recovery-code',
  CREATION_SUCCESS = 'creation-success',
  ACCOUNT_BANNED = 'account-banned',
  QUESTION = 'question',
  ONBOARDING_QUESTION = 'onboarding-question',
  COMMUNITY = 'community',
  GROUPS = 'groups',
  GROUPS_OVERVIEW = 'overview',
  GROUP_ID = 'groupId',
  CREATE_GROUP = 'create-group',
  ORGANISATION = 'organisation',
  ORGANIZATION_ID = 'organizationId',
  NOTIFICATIONS = 'notifications',
  GROUP_PROMOTION_STATUS = 'group-promotion-status',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_REGULATIONS = 'terms-and-conditions',
  FAQ = 'faq',
  ARQ_MANAGEMENT = 'arq-management',
  SETUP_ORGANISATION = 'setup-organisation',
  MANAGE_INVITATIONS = 'manage-invitations',
  CALIBRATION_TOOL = 'calibration-tool',
  CALIBRATION_RESULTS = 'calibration-results',
  COUNT_DOWN = 'count-down',
  EDIT_QUESTIONS = 'edit-questions',
  ADD_IN_BULK = 'add-in-bulk',
  REMOVE_IN_BULK = 'remove-in-bulk',
  SET_ONBOARDING_TEXT = 'set-onboarding-text',
  CALIBRATION_ID = 'calibrationId',
  LEARN = 'learn',
  EMAIL_VERIFICATION = 'email-verification',
  POST_ESTIMATION_FEEDBACK = 'post-estimation-feedback',
  QUESTION_ID = 'questionId',
  PROFILE = 'profile',
  ADD_SET = 'add-set',
}
