import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  formatDateRange
} from 'src/shared/utils';

import {
  GroupCardQuestionBlock
} from '../GroupCardQuestionBlock';

import * as Style from './GroupSets.styles';

interface GroupSetsProps {
  questionSets:
  | {
    id: string;
    title: string;
    startDate: string | null;
    endDate: string | null;
    activeQuestionsCount?: number | null;
    questionsCount?: number | null;
  }[]
  | null;
  setContainerClassName?: string;
}

export const GroupSets: FC<GroupSetsProps> = ({
  questionSets,
  setContainerClassName,
}) => {
  const visibleSets = (questionSets?.length ?? 0) < 2 ? questionSets : questionSets?.slice(
    0,
    2
  );

  const {
    i18n
  } = useTranslation();

  return (
    <Style.VisibleSetContainer>
      {visibleSets?.map(
        ({
          id: questionId,
          title: questionTitle,
          startDate,
          endDate,
          activeQuestionsCount,
          questionsCount,
        }) => {
          const dates = formatDateRange({
            start: startDate,
            end: endDate,
            language: i18n.language,
          });

          return (
            <GroupCardQuestionBlock
              key={questionId}
              data={{
                dates,
                questionTitle,
                activeQuestionsCount,
                questionsCount,
              }}
              setContainerClassName={setContainerClassName}
            />
          );
        },
      )}
    </Style.VisibleSetContainer>
  );
};
