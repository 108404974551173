import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useSearchParams
} from 'react-router-dom';

import {
  FilterPopoverMenu, MenuItem
} from 'src/shared/components';
import {
  questionManagementFilter, questionTab
} from 'src/shared/utils';

import {
  QuestionScoringTabs, Filters, getFiltersMap
} from '../../utils';

interface ScoringFiltersProps {
  isAdmin?: boolean;
}

export const ScoringFilters: FC<ScoringFiltersProps> = ({
  isAdmin
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    t
  } = useTranslation();

  const currentTab = searchParams.get(questionTab) as QuestionScoringTabs;

  const activeFilter = searchParams.get(questionManagementFilter);

  const onFilterChange = (value: Filters) => {
    if (value === activeFilter) {
      searchParams.delete(questionManagementFilter);
    } else {
      searchParams.set(
        questionManagementFilter,
        value
      );
    }

    setSearchParams(searchParams);
  };

  const filters = getFiltersMap(
    !!isAdmin,
    currentTab
  );

  if (!filters) {
    return null;
  }

  return (
    <FilterPopoverMenu
      tooltipMessage={t('filters.filterQuestions')}
      width={265}
      withBorder={false}
    >
      <div className="flex flex-col p-2">
        {filters.map(({
          id, label
        }) => (
          <MenuItem
            key={id}
            $isActive={activeFilter === id}
            onClick={() => onFilterChange(id)}
          >
            {label}
          </MenuItem>
        ))}
      </div>
    </FilterPopoverMenu>
  );
};
