import React, {
  FC, useEffect, useState
} from 'react';
import {
  useInView
} from 'react-intersection-observer';

import {
  usePatchNotificationStatusMutation,
  Notification,
} from 'src/redux/openapi';
import {
  NotificationType,
  NotificationStatus,
  Context,
  AnswerStatus,
} from 'src/shared/api/notifications/types';
import {
  useHighlightedText
} from 'src/shared/hooks';

import {
  CommentNotification,
  OrgNotifications,
  GroupNotification,
  UserNotifications,
  OrgSetupNotification,
  OrgAdminMsgNotifications,
  AdminCharacteristicsNotification,
  UserCharacteristicsNotification,
  QuestionRequestNotification,
} from '../Notifications';

interface NotificationItemProps {
  notification: Notification;
  query: string;
}

export const NotificationItem: FC<NotificationItemProps> = ({
  notification,
  query,
}) => {
  const [wasUpdated, setWasUpdated] = useState(false);

  const {
    getHighlightedText
  } = useHighlightedText({
    searchQuery: query,
    spanClassName: 'bg-input-border-blue',
  });

  const {
    ref, inView
  } = useInView();

  const context = (notification.context as Context) || {};

  const {
    id,
    status,
    type,
    replyComment,
    createdAt,
    group,
    organization,
    inviteFromUser,
    suggestUser,
    invitation,
  } = notification;

  const {
    groupName = '',
    organizationName = '',
    status: answerStatus = AnswerStatus.PENDING,
    fromUserName = '',
    groupId = '',
    reason = '',
    message = '',
    userName,
    characteristics = [],
    organizationId = '',
  } = context;

  const isNew = status === NotificationStatus.UNREAD;

  const [updateStatus] = usePatchNotificationStatusMutation();

  const handleStatusUpdate = async () => {
    await updateStatus({
      id,
      updateNotificationStatusBodySchema: {
        status: NotificationStatus.READ,
      },
    });

    setWasUpdated(true);
  };

  useEffect(
    () => {
      if (inView && isNew && !wasUpdated) {
        handleStatusUpdate();
      }
    },
    [inView, isNew, wasUpdated]
  );

  return (
    <div ref={ref}>
      {type === NotificationType.COMMENT_REPLY && replyComment && (
        <CommentNotification
          authorAvatarUrl={replyComment?.authorAvatarUrl}
          authorName={replyComment?.authorName || ''}
          createdAt={replyComment?.createdAt || createdAt}
          text={replyComment?.text || ''}
          isNew={isNew}
          getHighlightedText={getHighlightedText}
          commentId={replyComment.rootCommentId}
          questionId={replyComment.questionId}
        />
      )}

      {type === NotificationType.GROUP_ORGANIZATION_REQUEST
        && (organization
        && !organization.isSetUp
        && answerStatus === AnswerStatus.ACCEPTED ? (
          <OrgSetupNotification
            groupName={group?.name || groupName}
            organizationId={organization?.id}
            createdAt={createdAt}
            isNew={isNew}
            getHighlightedText={getHighlightedText}
            notificationId={id}
          />
          ) : (
            <OrgNotifications
              groupName={group?.name || groupName}
              organizationName={organization?.name || organizationName}
              createdAt={createdAt}
              isNew={isNew}
              answerStatus={answerStatus}
              getHighlightedText={getHighlightedText}
            />
          ))}

      {type === NotificationType.GROUP_INVITATION && (
        <GroupNotification
          createdAt={createdAt}
          userName={
            inviteFromUser?.displayName || inviteFromUser?.name || fromUserName
          }
          userAvatarUrl={inviteFromUser?.avatarUrl || null}
          groupName={group?.name || groupName}
          groupId={group?.id || groupId}
          isNew={isNew}
          answerStatus={answerStatus}
          notificationId={id}
          invitation={invitation}
          getHighlightedText={getHighlightedText}
        />
      )}

      {[
        NotificationType.GROUP_USER_REMOVAL,
        NotificationType.GROUP_USER_PROMOTION,
      ].includes(type as NotificationType) && (
        <UserNotifications
          authorAvatarUrl={suggestUser?.avatarUrl}
          authorName={suggestUser?.displayName || suggestUser?.name || ''}
          createdAt={createdAt}
          isNew={isNew}
          groupName={group?.name || groupName}
          type={type as NotificationType}
          reason={reason}
          getHighlightedText={getHighlightedText}
        />
      )}

      {type === NotificationType.ORGANIZATION_ADMIN_MESSAGE && (
        <OrgAdminMsgNotifications
          isNew={isNew}
          createdAt={createdAt}
          organizationName={organizationName}
          getHighlightedText={getHighlightedText}
          message={message}
        />
      )}

      {type === NotificationType.IMMUTABLE_CHARACTERISTIC_UPDATE && (
        <AdminCharacteristicsNotification
          isNew={isNew}
          createdAt={createdAt}
          authorName={
            suggestUser?.displayName || suggestUser?.name || userName || ''
          }
          authorAvatarUrl={suggestUser?.avatarUrl || null}
          characteristics={characteristics}
          getHighlightedText={getHighlightedText}
          notificationId={id}
          answerStatus={answerStatus}
        />
      )}

      {[
        NotificationType.CHARACTERISTIC_CHANGE_ACCEPTED,
        NotificationType.CHARACTERISTIC_CHANGE_REJECTED,
      ].includes(type as NotificationType) && (
        <UserCharacteristicsNotification
          isNew={isNew}
          createdAt={createdAt}
          getHighlightedText={getHighlightedText}
          status={
            type === NotificationType.CHARACTERISTIC_CHANGE_ACCEPTED
              ? AnswerStatus.ACCEPTED
              : AnswerStatus.REJECTED
          }
        />
      )}

      {[
        NotificationType.QUESTION_CREATE_REQUEST_APPROVE,
        NotificationType.QUESTION_CREATE_REQUEST_DECLINE,
      ].includes(type as NotificationType) && (
        <QuestionRequestNotification
          isNew={isNew}
          createdAt={createdAt}
          answerStatus={
            type === NotificationType.QUESTION_CREATE_REQUEST_APPROVE
              ? AnswerStatus.ACCEPTED
              : AnswerStatus.REJECTED
          }
          message={message}
          organizationId={organizationId}
        />
      )}
    </div>
  );
};
