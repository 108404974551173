import {
  useSearchParams
} from 'react-router-dom';

import {
  view
} from '../utils';

enum ListView {
  Table = 'table',
  Cards = 'cards',
}

export const useListView = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isTableView = searchParams.get(view) === ListView.Table;

  const onSwitch = () => {
    searchParams.set(
      view,
      isTableView ? ListView.Cards : ListView.Table
    );

    setSearchParams(searchParams);
  };

  return {
    onSwitch,
    isTableView,
  };
};
