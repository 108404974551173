import {
  t
} from 'i18next';

export enum CommunityFilters {
  Members = 'Members',
  Invited = 'Invited',
}

export const getCommunityFilters = () => [
  {
    id: CommunityFilters.Members,
    label: t(`group.${CommunityFilters.Members}`),
  },
  {
    id: CommunityFilters.Invited,
    label: t(`group.invited`),
  },
];
