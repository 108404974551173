import React, {
  FC, useState
} from 'react';
import clsx from 'clsx';
import {
  Trans, useTranslation
} from 'react-i18next';

import {
  Avatar,
  PopoverMenu,
  ReportUserModal,
  Tooltip,
  successfulToast,
  DeleteUserFromGroupModal,
  ModalLeave,
  DeleteModalType,
} from 'src/shared/components';
import {
  MoreIcon,
  KeyCrossIcon,
  FlagIcon,
  PersonDeleteIcon,
  LogOutIcon,
} from 'src/shared/icons';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  usePatchGroupUsersDemoteMutation,
  usePatchGroupUsersPromoteMutation,
  usePatchOrganizationUserPromoteMutation,
  usePatchOrganizationUserDemoteMutation,
  Group,
  Organization,
  GroupMember,
  OrganizationMember,
  GroupAdmin,
  OrganizationAdmin,
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';

import * as Style from './MemberRow.styles';

interface MemberRowProps {
  member: OrganizationMember | GroupMember | GroupAdmin | OrganizationAdmin;
  isAdminView?: boolean;
  isSuperAdminView?: boolean;
  totalAdminsNumber?: number;
  organization?: Organization;
  group?: Group;
  refetchMembers: () => void;
  isMemberAdmin?: boolean;
  className?: string;
}

export const MemberRow: FC<MemberRowProps> = ({
  member,
  isAdminView = false,
  isSuperAdminView = false,
  totalAdminsNumber = 0,
  organization,
  group,
  refetchMembers,
  isMemberAdmin,
  className,
}) => {
  const {
    t
  } = useTranslation();

  const isGroupInOrganization = !!group && group.organizationStatus === 'ACCEPTED';

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isModalDeleteFromGroupOpen, setIsModalDeleteFromGroupOpen] = useState(false);

  const [isModalLeaveFromGroupOpen, setIsModalLeaveFromGroupOpen] = useState(false);

  const toggleReportModal = () => setIsModalOpen((prev) => !prev);

  const {
    avatarUrl: avatarSrc, name, displayName, id: memberId
  } = member;

  const {
    id: currentUserId
  } = useTypedSelector(userSelectors.user);

  const isCurrentUser = currentUserId === memberId;

  const canBePromoted = !isMemberAdmin && totalAdminsNumber < 2;

  const canAdminRemove = isMemberAdmin && totalAdminsNumber > 0 && currentUserId !== memberId;

  const [promoteGroupUserRole, {
    isLoading: isPromoteGroupLoading
  }] = usePatchGroupUsersPromoteMutation();

  const [demoteGroupUserRole, {
    isLoading: isDemoteGroupLoading
  }] = usePatchGroupUsersDemoteMutation();

  const [promoteOrgUserRole, {
    isLoading: isPromoteOrgLoading
  }] = usePatchOrganizationUserPromoteMutation();

  const [demoteOrgUserRole, {
    isLoading: isDemoteOrgLoading
  }] = usePatchOrganizationUserDemoteMutation();

  const isLoading = isPromoteGroupLoading
    || isDemoteGroupLoading
    || isPromoteOrgLoading
    || isDemoteOrgLoading;

  const handlePromoteUser = async () => {
    if (!canBePromoted) {
      return;
    }

    try {
      let response;

      if (group) {
        response = await promoteGroupUserRole({
          id: group.id,
          promoteGroupUserBodySchema: {
            userId: memberId,
          },
        }).unwrap();
      }

      if (organization) {
        response = await promoteOrgUserRole({
          id: organization.id,
          promoteGroupUserBodySchema: {
            userId: memberId,
          },
        }).unwrap();
      }

      if (response) {
        successfulToast(response.message);
        refetchMembers();
      }
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const handleRemoveAdmin = async () => {
    if (!canAdminRemove) {
      return;
    }

    try {
      let response;

      if (group) {
        response = await demoteGroupUserRole({
          id: group.id,
          demoteGroupUserBodySchema: {
            userId: memberId,
          },
        }).unwrap();
      }

      if (organization) {
        response = await demoteOrgUserRole({
          id: organization.id,
          demoteGroupUserBodySchema: {
            userId: memberId,
          },
        }).unwrap();
      }

      if (response) {
        successfulToast(response.message);
        refetchMembers();
      }
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const memberName = displayName || name;

  return (
    <Style.MainContainer className={className}>
      <Style.InfoContainer>
        <Avatar
          size={32}
          src={avatarSrc}
          userName={memberName}
        />

        <Style.MemberName>{memberName}</Style.MemberName>
      </Style.InfoContainer>

      <ReportUserModal
        isOpen={isModalOpen}
        onClose={toggleReportModal}
        memberName={memberName}
        userId={memberId}
      />

      <DeleteUserFromGroupModal
        isOpen={isModalDeleteFromGroupOpen}
        member={member}
        onClose={() => setIsModalDeleteFromGroupOpen(false)}
        id={group ? group.id : organization?.id}
        type={group ? DeleteModalType.Group : DeleteModalType.Organization}
        refetch={refetchMembers}
      />

      <ModalLeave
        isOpen={isModalLeaveFromGroupOpen}
        closeModal={() => setIsModalLeaveFromGroupOpen(false)}
        group={group}
        organization={organization}
      />

      <PopoverMenu
        shouldForcedClose={
          isModalOpen || isModalDeleteFromGroupOpen || isLoading
        }
        buttonContent={<MoreIcon className="w-6 text-dim-gray" />}
        zIndex={10}
        applyMaxHeight={false}
      >
        <Style.MenuContainer>
          {!isCurrentUser
            && isMemberAdmin
            && isSuperAdminView
            && !isGroupInOrganization && (
              <Tooltip
                disabled={canAdminRemove}
                variant="black"
                placement="bottom-end"
                customShift={-8}
                opacity={0.8}
                message={(
                  <Style.TooltipMessage>
                    <Trans
                      i18nKey={
                        currentUserId === memberId
                          ? 'group.youCantRemoveYourselfAdmin'
                          : 'group.mustHaveAtLeast1admin'
                      }
                      values={{
                        type: group
                          ? t('common.group')
                          : t('common.organisation'),
                      }}
                    />
                  </Style.TooltipMessage>
                )}
              >
                <Style.MenuButtonContainer
                  onClick={handleRemoveAdmin}
                  $disabled={!canAdminRemove}
                >
                  <KeyCrossIcon
                    className={clsx(
                      'w-6 h-6 text-dim-gray',
                      {
                        'opacity-80': !canAdminRemove,
                      }
                    )}
                  />

                  <Style.MenuButtonTitle $disabled={!canAdminRemove}>
                    {t('common.removeAdminRights')}
                  </Style.MenuButtonTitle>
                </Style.MenuButtonContainer>
              </Tooltip>
          )}

          {!isCurrentUser
            && !isMemberAdmin
            && !isGroupInOrganization
            && isSuperAdminView && (
              <Tooltip
                disabled={canBePromoted}
                variant="black"
                placement="bottom-end"
                customShift={-8}
                opacity={0.8}
                message={(
                  <p className="max-w-194px text-center text-xs text-white leading-5 font-medium">
                    {t('group.youHave2Admins')}
                  </p>
                )}
              >
                <Style.MenuButtonContainer
                  onClick={handlePromoteUser}
                  $disabled={!canBePromoted}
                >
                  <KeyCrossIcon
                    className={clsx(
                      'w-6 h-6 text-beige',
                      {
                        'text-brownish-yellow': canBePromoted,
                      }
                    )}
                  />

                  <Style.MenuButtonTitle $disabled={!canBePromoted}>
                    {t('common.promoteToAdmin')}
                  </Style.MenuButtonTitle>
                </Style.MenuButtonContainer>
              </Tooltip>
          )}

          {!isCurrentUser && (
            <Style.MenuButtonContainer onClick={toggleReportModal}>
              <FlagIcon className="w-6 h-6 text-dim-gray" />

              <Style.MenuButtonTitle>
                {t('modal.reportUser')}
              </Style.MenuButtonTitle>
            </Style.MenuButtonContainer>
          )}

          {!isCurrentUser
            && (isSuperAdminView || (isAdminView && !isMemberAdmin)) && (
              <Style.MenuButtonContainer
                onClick={() => setIsModalDeleteFromGroupOpen(true)}
              >
                <PersonDeleteIcon className="w-6 h-6 text-red-text-error" />

                <Style.MenuButtonTitle>
                  {`${t('modal.removeFrom')} ${
                    group ? t('modal.group') : t('modal.organisation')
                  }`}
                </Style.MenuButtonTitle>
              </Style.MenuButtonContainer>
          )}

          {isCurrentUser && (
            <Style.MenuButtonContainer
              onClick={() => setIsModalLeaveFromGroupOpen(true)}
            >
              <LogOutIcon className="w-6 h-6 text-red-text-error" />

              <Style.MenuButtonTitle>
                {`${t('modal.leaveThe')} ${
                  group ? t('modal.group') : t('modal.organisation')
                }`}
              </Style.MenuButtonTitle>
            </Style.MenuButtonContainer>
          )}
        </Style.MenuContainer>
      </PopoverMenu>
    </Style.MainContainer>
  );
};
