import React, {
  FC
} from 'react';

import {
  Group
} from 'src/redux/openapi';
import {
  Table, TableContent, TableRow
} from 'src/shared/components';

import {
  getGroupCols
} from './utils';

interface GroupTableProps {
  groups: Group[];
}

export const GroupTable: FC<GroupTableProps> = ({
  groups
}) => {
  const groupColumns = getGroupCols();

  return (
    <Table>
      <TableContent
        data={groups}
        columns={groupColumns}
        renderRow={(row, index) => (
          <TableRow
            row={row}
            key={row.original.id}
            index={index}
            variant="white"
          />
        )}
      />
    </Table>
  );
};
