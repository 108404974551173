import {
  ScoredTabSortBy
} from 'src/redux/community';
import {
  SortOrder
} from 'src/shared/api/types';

enum FilterSortBy {
  DeactivatedAt = 'deactivatedAt',
  Score = 'score',
}

export const getScoresFilters = (sortBy: ScoredTabSortBy) => {
  switch (sortBy) {
    case ScoredTabSortBy.Lower:
      return {
        sortBy: FilterSortBy.Score,
        sortOrder: SortOrder.Asc,
      };

    case ScoredTabSortBy.Higher:
      return {
        sortBy: FilterSortBy.Score,
        sortOrder: SortOrder.Desc,
      };

    case ScoredTabSortBy.Oldest:
      return {
        sortBy: FilterSortBy.DeactivatedAt,
        sortOrder: SortOrder.Asc,
      };

    case ScoredTabSortBy.Newest:
    default:
      return {
        sortBy: FilterSortBy.DeactivatedAt,
        sortOrder: SortOrder.Desc,
      };
  }
};

export const getScoreResult = (result: boolean | string) => {
  if (typeof result === 'string') {
    return result;
  }

  return result ? 'Yes' : 'No';
};
