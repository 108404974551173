import tw from 'twin.macro';
import {
  styled
} from 'styled-components';
import {
  Link
} from 'react-router-dom';

export const DetailsLink = styled(Link)`
  ${tw`flex flex-col gap-1 px-2 py-1`}
`;

export const QuestionTitle = styled.p`
  ${tw`font-bold text-17-26 text-gray7 group-hover:text-dim-gray transition-all`}
`;

export const Result = styled.p`
  ${tw`font-medium text-xs text-dim-gray`}
`;

export const DownloadButtonWrapper = styled.p`
  ${tw`flex justify-end pr-3`}
`;
