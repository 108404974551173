import {
  emptySplitApi as api
} from '../../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCharacteristicsKeysByIdValues: build.query<
    GetApiCharacteristicsKeysByIdValuesApiResponse,
    GetApiCharacteristicsKeysByIdValuesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/characteristics/keys/${queryArg.id}/values`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    postApiCharacteristicsNotificationsByIdImmutableAccept: build.mutation<
    PostApiCharacteristicsNotificationsByIdImmutableAcceptApiResponse,
    PostApiCharacteristicsNotificationsByIdImmutableAcceptApiArg
    >({
      query: (queryArg) => ({
        url: `/api/characteristics/notifications/${queryArg.id}/immutable/accept`,
        method: 'POST',
      }),
    }),
    postApiCharacteristicsNotificationsByIdImmutableReject: build.mutation<
    PostApiCharacteristicsNotificationsByIdImmutableRejectApiResponse,
    PostApiCharacteristicsNotificationsByIdImmutableRejectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/characteristics/notifications/${queryArg.id}/immutable/reject`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiCharacteristics
};
export type GetApiCharacteristicsKeysByIdValuesApiResponse =
  /** status 200 Default Response */ GetKeyValuesResponseSchema;
export type GetApiCharacteristicsKeysByIdValuesApiArg = {
  page?: number;
  limit?: number;
  id: string;
};
export type PostApiCharacteristicsNotificationsByIdImmutableAcceptApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiCharacteristicsNotificationsByIdImmutableAcceptApiArg = {
  id: string;
};
export type PostApiCharacteristicsNotificationsByIdImmutableRejectApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiCharacteristicsNotificationsByIdImmutableRejectApiArg = {
  id: string;
};
export type GetKeyValuesResponseSchema = {
  message: string;
  data: {
    total: number;
    values: {
      id: string;
      value: string;
      users: {
        userId: string;
      }[];
    }[];
  };
};
export type MessageResponseSchema = {
  message: string;
};
export const {
  useGetApiCharacteristicsKeysByIdValuesQuery,
  usePostApiCharacteristicsNotificationsByIdImmutableAcceptMutation,
  usePostApiCharacteristicsNotificationsByIdImmutableRejectMutation,
} = injectedRtkApi;
