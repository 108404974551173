import React, {
  FC
} from 'react';

import {
  ScrollContainer,
  ScrollContainerVariant,
  Spinner,
} from 'src/shared/components';
import {
  Filters, FilterAmountTile
} from 'src/features';
import {
  UseNestedSelect
} from 'src/shared/hooks/useNestedSelect';
import {
  ROUTE
} from 'src/shared/utils';

import * as Style from '../EditQuestions.styles';
import {
  EditQuestionType, GroupFilters, PreparedQuestionSets
} from '../types';

import {
  QuestionSetCard
} from './QuestionSetCard';

export interface EditQuestionsListProps {
  groupsFiltersConfig?: {
    filters: GroupFilters;
    onLoadGroupsMore: () => void;
    hasGroupsNextPage: boolean;
    isGroupsNextLoading: boolean;
  };
  isQuestionsLoading: boolean;
  preparedSets: PreparedQuestionSets[];
  activeGroupId?: string | null;
  onFilterChange?: (id: string | null) => void;
  selectConfig: UseNestedSelect;
  isRemoving?: boolean;
  onLoadQuestionsBySetId?: (id: string) => Promise<EditQuestionType[]>;
  togglePreferred?: (id: string, isPreferred: boolean) => void;
  preferredOverrides?: { id: string; isPreferred: boolean }[];
}

export const EditQuestionsList: FC<EditQuestionsListProps> = ({
  groupsFiltersConfig,
  isQuestionsLoading,
  preparedSets,
  activeGroupId = null,
  onFilterChange,
  selectConfig,
  isRemoving,
  onLoadQuestionsBySetId,
  togglePreferred,
  preferredOverrides,
}) => {
  const {
    selectedItems, onParentCheck, onChildCheck
  } = selectConfig;

  const isOrganizationSetUp = window.location.pathname.includes(
    `${ROUTE.SETUP_ORGANISATION}`,
  );

  const {
    filters: groupFilters = null,
    onLoadGroupsMore,
    hasGroupsNextPage,
    isGroupsNextLoading,
  } = groupsFiltersConfig || {};

  return (
    <Style.EditListContent $isGrow={isOrganizationSetUp}>
      {groupFilters && onFilterChange && (
        <Filters
          items={groupFilters}
          onFilterClick={onFilterChange}
          activeFilterId={activeGroupId}
          onLoadMore={onLoadGroupsMore}
          hasNextPage={hasGroupsNextPage}
          loading={isGroupsNextLoading}
          renderRightElement={(item) => {
            const isActive = item.id === activeGroupId;

            const countToDisplay = isActive && !isQuestionsLoading
              ? selectConfig.selectedItems.flatMap(
                (selectedItem) => selectedItem.selectedChildrenId,
              ).length
              : item.activeQuestionCount;

            return (
              <FilterAmountTile isActive={isActive}>
                {countToDisplay}
              </FilterAmountTile>
            );
          }}
        />
      )}

      {isQuestionsLoading ? (
        <Spinner
          size={40}
          withLoadingText
        />
      ) : (
        <ScrollContainer
          variant={ScrollContainerVariant.light}
          edgeHeight={56}
        >
          {preparedSets.map((question) => (
            <QuestionSetCard
              key={question.id}
              questionSet={question}
              onTopicCheck={onParentCheck}
              onQuestionCheck={onChildCheck}
              selectedItems={selectedItems}
              isRemoving={isRemoving}
              onLoadQuestionsBySetId={onLoadQuestionsBySetId}
              togglePreferred={togglePreferred}
              preferredOverrides={preferredOverrides}
            />
          ))}
        </ScrollContainer>
      )}
    </Style.EditListContent>
  );
};
