import React from 'react';
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';

import {
  GroupInvited
} from 'src/redux/openapi';
import {
  GrayText
} from 'src/shared/components';

import * as Style from './InvitedTabContent.styles';

const invitedColumnsHelper = createColumnHelper<GroupInvited>();

export const getInvitedCols = () => [
  invitedColumnsHelper.accessor(
    'name',
    {
      header: t('modal.groupName'),
      footer: (props) => props.column.id,
      size: 380,
      minSize: 380,
      cell: (info) => <Style.Title>{info.getValue()}</Style.Title>,
    }
  ),
  invitedColumnsHelper.accessor(
    'invitedCount',
    {
      header: t('group.invited'),
      footer: (props) => props.column.id,
      size: 200,
      minSize: 128,
      cell: (info) => <GrayText>{info.getValue()}</GrayText>,
    }
  ),
  invitedColumnsHelper.accessor(
    'id',
    {
      header: '',
      footer: (props) => props.column.id,
      size: 300,
      minSize: 200,
      cell: () => null,
    }
  ),
];
