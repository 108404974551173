import {
  clsx
} from 'clsx';
import React, {
  ReactNode
} from 'react';

import {
  Pagination
} from 'src/shared/components';
import {
  useMediaQuery
} from 'src/shared/hooks';

const columnsVariants = {
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  6: 'col-span-6',
};

interface Props<TCard> {
  cardsConfig: {
    questions: TCard[];
    total: number;
    limit: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
  };
  renderElement: (card: TCard) => ReactNode;
  keyExtractor: (card: TCard) => string | number;
  maxPerRow?: number;
}

export const CardsLayout = <TCard,>({
  cardsConfig,
  renderElement,
  maxPerRow = 3,
  keyExtractor,
}: Props<TCard>): JSX.Element => {
  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const {
    questions, total, limit, currentPage, setCurrentPage
  } = cardsConfig;

  const rows = Math.ceil(questions.length / maxPerRow);
  const remainder = questions.length % maxPerRow;
  const defaultCardSpan = maxPerRow >= 3 ? 2 : 3;

  const isOneLastCard = remainder === 1;

  const getCardsSpan = () => {
    if (!remainder) {
      return defaultCardSpan;
    }

    return isOneLastCard ? 6 : 3;
  };

  const lastRowCardsSpan = getCardsSpan();
  const maxIndex = rows * maxPerRow - 1;
  const lastFullIndex = remainder ? maxIndex - maxPerRow : maxIndex;

  const getColSpan = (index: number) => {
    const colSpan = index <= lastFullIndex ? defaultCardSpan : lastRowCardsSpan;

    return columnsVariants[colSpan];
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-6 md:gap-3 gap-y-4 mb-6">
        {questions.map((card, index) => {
          return (
            <div
              key={keyExtractor(card)}
              className={clsx(
                !isMobile && getColSpan(index),
                {
                  'max-w-55%':
                  isOneLastCard && index > lastFullIndex && !isMobile,
                }
              )}
            >
              {renderElement(card)}
            </div>
          );
        })}
      </div>

      <div className="mx-auto w-max">
        <Pagination
          totalCount={total}
          pageSize={limit}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </>
  );
};
