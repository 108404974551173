import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CardQuestionContainer = styled.div<{ $isInvited?: boolean }>`
  ${tw`rounded flex flex-col justify-between`}

  ${({
    $isInvited
  }) => ($isInvited ? tw`bg-alice-blue p-2` : tw`bg-pale-blue px-4 py-3`)}
`;
export const CardQuestionDates = styled.div`
  ${tw`text-xs leading-5 font-medium text-dim-gray flex justify-between items-baseline`}
`;

export const CardQuestionTitle = styled.div<{ $isInvited?: boolean }>`
  ${tw`font-bold text-15-20 text-dark-gray`}

  ${({
    $isInvited
  }) => $isInvited && tw`mt-1`}
`;

export const QuestionStatistic = styled.span`
  ${tw`text-center px-1 bg-input-blue rounded-100px`}
`;
