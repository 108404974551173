import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

import {
  TableVariant
} from '../../types';

export const TableRow = styled.tr<{
  $index?: number;
  $shouldHover?: boolean;
  $variant?: TableVariant;
}>`
  ${tw`border-b border-b-gray94`}

  ${({
    $shouldHover
  }) => $shouldHover && tw`cursor-pointer hover:bg-alice-blue`}

  ${({
    $index, $variant
  }) => $variant === 'stripped' && $index && $index % 2 && tw`bg-gray98`}
`;

export const TableCell = styled.td`
  ${tw`text-base text-dim-gray px-3 py-2`}
`;
