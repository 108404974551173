import clsx from 'clsx';
import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  SetForCreation
} from 'src/redux/openapi';
import {
  CircleButton,
  ScrollContainer,
  ScrollContainerVariant,
} from 'src/shared/components';
import {
  ArrowUpWideIcon
} from 'src/shared/icons';
import {
  formatDateRange
} from 'src/shared/utils';

import * as Style from './QuestionSetItem.styles';

interface QuestionSetItemProps {
  set: SetForCreation;
}

export const QuestionSetItem: FC<QuestionSetItemProps> = ({
  set
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    title, questions, startDate, endDate
  } = set;

  const onExpand = () => setIsExpanded((prev) => !prev);

  const {
    i18n: {
      language
    },
    t,
  } = useTranslation();

  return (
    <Style.MainContainer>
      <Style.SetWrapper>
        <Style.SetData>
          <Style.SetDate>
            {formatDateRange({
              start: startDate,
              end: endDate,
              language,
            })}
          </Style.SetDate>

          <Style.SetTitle>{title}</Style.SetTitle>
        </Style.SetData>

        <CircleButton
          onClick={onExpand}
          className="p-2.5"
        >
          <ArrowUpWideIcon
            className={clsx({
              'rotate-180': !isExpanded,
            })}
          />
        </CircleButton>
      </Style.SetWrapper>

      {isExpanded && (
        <ScrollContainer
          className="max-h-414px"
          variant={ScrollContainerVariant.dark}
          edgeHeight={32}
          visibleScroll
        >
          {questions.length ? (
            questions.map((question, index) => (
              <Style.QuestionContainer key={`${question.title}_${index}`}>
                <Style.QuestionNumber>{`${index + 1}.`}</Style.QuestionNumber>

                <Style.QuestionTitle>{question.title}</Style.QuestionTitle>

                {question.isMandatory && (
                  <Style.BlueTile>{t('question.mandatory')}</Style.BlueTile>
                )}

                {question.isPreferred && !question.isMandatory && (
                  <Style.BlueTile>{t('question.preferred')}</Style.BlueTile>
                )}
              </Style.QuestionContainer>
            ))
          ) : (
            <Style.QuestionTitle>
              {t('empty.thereAreNoQuestions')}
            </Style.QuestionTitle>
          )}
        </ScrollContainer>
      )}
    </Style.MainContainer>
  );
};
