import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const TileContainer = styled.div`
  ${tw`
    flex gap-2 items-center
    text-15-26 md:text-17-26 font-bold text-dark-gray
  `}
`;

export const TileContent = styled.div`
  ${tw`flex gap-1`}
`;

export const BoldText = styled.p`
  ${tw`font-bold text-dark-gray`}
`;

export const MediumText = styled.p`
  ${tw`font-medium text-dim-gray`}
`;

export const RowContainer = styled.div`
  ${tw`flex gap-3 md:gap-18px items-center p-1 grow flex-wrap`}
`;
