import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const GroupInfo = styled.div`
  ${tw`flex gap-3`}
`;

export const GroupIsAdminContainer = styled.div`
  ${tw`flex items-center`}
`;

export const GroupTile = styled.div`
  ${tw`text-xl font-bold text-gray7`}
`;

export const GroupLocation = styled.div`
  ${tw`text-13-21 font-medium text-dim-gray`}
`;
