import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const TableContentWrapper = styled.div`
  ${tw`bg-white overflow-auto grow flex flex-col`}
`;

export const TableHeader = styled.div`
  ${tw`flex justify-between px-3 items-center border-b border-b-gray94`}
`;

export const NameCellSpan = styled.span<{ $isUnread?: boolean }>`
  ${tw`font-semibold text-gray7`}

  ${({
    $isUnread
  }) => $isUnread
    && tw`relative before:(w-2.5 h-2.5 rounded-full block bg-button-blue absolute -top-2.5 -left-2.5)`}
`;

export const RightAlignedCellText = styled.p`
  ${tw`text-end`}
`;

export const CenteredCellText = styled.p`
  ${tw`text-center`}
`;

export const GrayText = styled.p`
  ${tw`text-dim-gray font-medium text-17-26`}
`;
