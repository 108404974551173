import {
  emptySplitApi as api
} from '../../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiGroups: build.mutation<
    PostApiGroupsApiResponse,
    PostApiGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/`,
        method: 'POST',
        body: queryArg.createGroupBodySchema,
      }),
    }),
    getApiGroups: build.query<GetApiGroupsApiResponse, GetApiGroupsApiArg>({
      query: (queryArg) => ({
        url: `/api/groups/`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiGroupsCommunity: build.query<
    GetApiGroupsCommunityApiResponse,
    GetApiGroupsCommunityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/community`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    patchApiGroupsById: build.mutation<
    PatchApiGroupsByIdApiResponse,
    PatchApiGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateGroupBodySchema,
      }),
    }),
    getApiGroupsById: build.query<
    GetApiGroupsByIdApiResponse,
    GetApiGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}`,
      }),
    }),
    deleteApiGroupsById: build.mutation<
    DeleteApiGroupsByIdApiResponse,
    DeleteApiGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiGroupsNamesExists: build.mutation<
    GetApiGroupsNamesExistsApiResponse,
    GetApiGroupsNamesExistsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/names/exists`,
        method: 'GET',
        params: {
          name: queryArg.name,
        },
      }),
    }),
    getApiGroupsByIdFeed: build.query<
    GetApiGroupsByIdFeedApiResponse,
    GetApiGroupsByIdFeedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/feed`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filters: queryArg.filters,
        },
      }),
    }),
    getApiGroupsByIdQuestionSets: build.query<
    GetApiGroupsByIdQuestionSetsApiResponse,
    GetApiGroupsByIdQuestionSetsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/question-sets`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiGroupsByIdQuestionSetsAll: build.query<
    GetApiGroupsByIdQuestionSetsAllApiResponse,
    GetApiGroupsByIdQuestionSetsAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/question-sets/all`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiGroupsByIdQuestionSetsAndQuestionSetIdQuestions: build.query<
    GetApiGroupsByIdQuestionSetsAndQuestionSetIdQuestionsApiResponse,
    GetApiGroupsByIdQuestionSetsAndQuestionSetIdQuestionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/question-sets/${queryArg.questionSetId}/questions`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filters: queryArg.filters,
        },
      }),
    }),
    getApiGroupsByIdQuestions: build.query<
    GetApiGroupsByIdQuestionsApiResponse,
    GetApiGroupsByIdQuestionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/questions`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    postApiGroupsByIdQuestions: build.mutation<
    PostApiGroupsByIdQuestionsApiResponse,
    PostApiGroupsByIdQuestionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/questions`,
        method: 'POST',
        body: queryArg.updateGroupQuestionsBodySchema,
      }),
    }),
    getApiGroupsByIdQuestionSetsAndQuestionSetIdQuestionsAll: build.query<
    GetApiGroupsByIdQuestionSetsAndQuestionSetIdQuestionsAllApiResponse,
    GetApiGroupsByIdQuestionSetsAndQuestionSetIdQuestionsAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/question-sets/${queryArg.questionSetId}/questions/all`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filters: queryArg.filters,
        },
      }),
    }),
    postApiGroupsByIdUpgrade: build.mutation<
    PostApiGroupsByIdUpgradeApiResponse,
    PostApiGroupsByIdUpgradeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/upgrade`,
        method: 'POST',
        body: queryArg.upgradeGroupToOrganizationBodySchema,
      }),
    }),
    getApiGroupsUsersInviteAccept: build.query<
    GetApiGroupsUsersInviteAcceptApiResponse,
    GetApiGroupsUsersInviteAcceptApiArg
    >({
      query: () => ({
        url: `/api/groups/users/invite/accept`,
      }),
    }),
    getApiGroupsUsersInviteAcceptSession: build.query<
    GetApiGroupsUsersInviteAcceptSessionApiResponse,
    GetApiGroupsUsersInviteAcceptSessionApiArg
    >({
      query: () => ({
        url: `/api/groups/users/invite/accept/session`,
      }),
    }),
    postApiGroupsUsersInviteAcceptSession: build.mutation<
    PostApiGroupsUsersInviteAcceptSessionApiResponse,
    PostApiGroupsUsersInviteAcceptSessionApiArg
    >({
      query: () => ({
        url: `/api/groups/users/invite/accept/session`,
        method: 'POST',
      }),
    }),
    getApiGroupsByIdUsers: build.query<
    GetApiGroupsByIdUsersApiResponse,
    GetApiGroupsByIdUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users`,
        params: {
          query: queryArg.query,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    deleteApiGroupsByIdUsers: build.mutation<
    DeleteApiGroupsByIdUsersApiResponse,
    DeleteApiGroupsByIdUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users`,
        method: 'DELETE',
        params: {
          userId: queryArg.userId,
          reason: queryArg.reason,
        },
      }),
    }),
    getApiGroupsByIdUsersFromGroup: build.query<
    GetApiGroupsByIdUsersFromGroupApiResponse,
    GetApiGroupsByIdUsersFromGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/from-group`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiGroupsByIdUsersInvites: build.query<
    GetApiGroupsByIdUsersInvitesApiResponse,
    GetApiGroupsByIdUsersInvitesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/invites`,
        params: {
          query: queryArg.query,
          lastInvitationSentAt: queryArg.lastInvitationSentAt,
          lastUserId: queryArg.lastUserId,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    deleteApiGroupsByIdUsersInvites: build.mutation<
    DeleteApiGroupsByIdUsersInvitesApiResponse,
    DeleteApiGroupsByIdUsersInvitesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/invites`,
        method: 'DELETE',
        params: {
          userId: queryArg.userId,
        },
      }),
      invalidatesTags: ['Invites'],
    }),
    getApiGroupsByIdUsersInvitesCount: build.query<
    GetApiGroupsByIdUsersInvitesCountApiResponse,
    GetApiGroupsByIdUsersInvitesCountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/invites/count`,
      }),
    }),
    patchApiGroupsByIdUsersInvitesAccept: build.mutation<
    PatchApiGroupsByIdUsersInvitesAcceptApiResponse,
    PatchApiGroupsByIdUsersInvitesAcceptApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/invites/accept`,
        method: 'PATCH',
        body: queryArg.acceptLoggedInUserInviteBodySchema,
      }),
    }),
    patchApiGroupsByIdUsersInvitesDecline: build.mutation<
    PatchApiGroupsByIdUsersInvitesDeclineApiResponse,
    PatchApiGroupsByIdUsersInvitesDeclineApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/invites/decline`,
        method: 'PATCH',
        body: queryArg.declineLoggedInUserInviteBodySchema,
      }),
    }),
    postApiGroupsByIdUsersInvitesEmails: build.mutation<
    PostApiGroupsByIdUsersInvitesEmailsApiResponse,
    PostApiGroupsByIdUsersInvitesEmailsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/invites/emails`,
        method: 'POST',
        body: queryArg.inviteGroupUsersEmailBodySchema,
      }),
      invalidatesTags: ['Invites'],
    }),
    getApiGroupsByIdUsersInvitesDownload: build.query<
    GetApiGroupsByIdUsersInvitesDownloadApiResponse,
    GetApiGroupsByIdUsersInvitesDownloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/invites/download`,
        params: {
          type: queryArg.type,
        },
      }),
    }),
    postApiGroupsByIdUsersInvitesResend: build.mutation<
    PostApiGroupsByIdUsersInvitesResendApiResponse,
    PostApiGroupsByIdUsersInvitesResendApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/invites/resend`,
        method: 'POST',
      }),
    }),
    postApiGroupsByIdUsersInvitesLinks: build.mutation<
    PostApiGroupsByIdUsersInvitesLinksApiResponse,
    PostApiGroupsByIdUsersInvitesLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/invites/links`,
        method: 'POST',
      }),
    }),
    patchApiGroupsByIdUsersRolesPromote: build.mutation<
    PatchApiGroupsByIdUsersRolesPromoteApiResponse,
    PatchApiGroupsByIdUsersRolesPromoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/roles/promote`,
        method: 'PATCH',
        body: queryArg.promoteGroupUserBodySchema,
      }),
    }),
    patchApiGroupsByIdUsersRolesDemote: build.mutation<
    PatchApiGroupsByIdUsersRolesDemoteApiResponse,
    PatchApiGroupsByIdUsersRolesDemoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/roles/demote`,
        method: 'PATCH',
        body: queryArg.demoteGroupUserBodySchema,
      }),
    }),
    patchApiGroupsByIdUsersRolesSuperadmin: build.mutation<
    PatchApiGroupsByIdUsersRolesSuperadminApiResponse,
    PatchApiGroupsByIdUsersRolesSuperadminApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/roles/superadmin`,
        method: 'PATCH',
        body: queryArg.assignSuperadminBodySchema,
      }),
    }),
    deleteApiGroupsByIdUsersCurrent: build.mutation<
    DeleteApiGroupsByIdUsersCurrentApiResponse,
    DeleteApiGroupsByIdUsersCurrentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/users/current`,
        method: 'DELETE',
        params: {
          newSuperadminId: queryArg.newSuperadminId,
        },
      }),
    }),
    patchApiGroupsByIdQuestionsDeactivation: build.mutation<
    PatchApiGroupsByIdQuestionsDeactivationApiResponse,
    PatchApiGroupsByIdQuestionsDeactivationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/questions/deactivation`,
        method: 'PATCH',
        body: queryArg.updateGroupQuestionsBodySchema,
      }),
    }),
    getApiGroupsByIdQuestionsAndQuestionIdOnboardingText: build.query<
    GetApiGroupsByIdQuestionsAndQuestionIdOnboardingTextApiResponse,
    GetApiGroupsByIdQuestionsAndQuestionIdOnboardingTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/questions/${queryArg.questionId}/onboarding-text`,
      }),
    }),
    putApiGroupsByIdQuestionsAndQuestionIdOnboardingText: build.mutation<
    PutApiGroupsByIdQuestionsAndQuestionIdOnboardingTextApiResponse,
    PutApiGroupsByIdQuestionsAndQuestionIdOnboardingTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/questions/${queryArg.questionId}/onboarding-text`,
        method: 'PUT',
        body: queryArg.updateGroupQuestionOnboardingTextBodySchema,
      }),
    }),
    getApiGroupsByIdQuestionsOnboardingText: build.query<
    GetApiGroupsByIdQuestionsOnboardingTextApiResponse,
    GetApiGroupsByIdQuestionsOnboardingTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/questions/onboarding-text`,
        params: {
          questionIds: queryArg.questionIds,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    putApiGroupsByIdQuestionsOnboardingText: build.mutation<
    PutApiGroupsByIdQuestionsOnboardingTextApiResponse,
    PutApiGroupsByIdQuestionsOnboardingTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/questions/onboarding-text`,
        method: 'PUT',
        body: queryArg.updateGroupQuestionsOnboardingTextBodySchema,
      }),
    }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiGroup
};
export type PostApiGroupsApiResponse =
  /** status 201 Default Response */ CreateGroupResponseSchema;
export type PostApiGroupsApiArg = {
  createGroupBodySchema: CreateGroupBodySchema;
};
export type GetApiGroupsApiResponse =
  /** status 200 Default Response */ GroupsResponseSchema;
export type GetApiGroupsApiArg = {
  page?: number;
  limit?: number;
};
export type GetApiGroupsCommunityApiResponse =
  /** status 200 Default Response */ CommunityGroupsResponseSchema;
export type GetApiGroupsCommunityApiArg = {
  page?: Page;
  limit?: Limit;
};
export type PatchApiGroupsByIdApiResponse =
  /** status 200 Default Response */ CreateGroupResponseSchema;
export type PatchApiGroupsByIdApiArg = {
  id: string;
  updateGroupBodySchema: UpdateGroupBodySchema;
};
export type GetApiGroupsByIdApiResponse =
  /** status 200 Default Response */ SingleGroupResponseSchema;
export type GetApiGroupsByIdApiArg = {
  id: string;
};
export type DeleteApiGroupsByIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiGroupsByIdApiArg = {
  id: string;
};
export type GetApiGroupsNamesExistsApiResponse =
  /** status 200 Default Response */ CheckGroupNameExistsResponseSchema;
export type GetApiGroupsNamesExistsApiArg = {
  name: string;
};
export type GetApiGroupsByIdFeedApiResponse =
  /** status 200 Default Response */ GroupFeedResponseSchema;
export type GetApiGroupsByIdFeedApiArg = {
  page?: Page;
  limit?: Limit;
  filters?: {
    sortBy?: 'createdAt' | 'commentsCount' | 'commendationsCount';
    sortOrder?: SortOrder;
    userOnly?: boolean;
    dateEnd?: string;
    dateStart?: DateEnd;
    questionId?: string;
  };
  id: string;
};
export type GetApiGroupsByIdQuestionSetsApiResponse =
  /** status 200 Default Response */ GroupQuestionSetsResponseSchema;
export type GetApiGroupsByIdQuestionSetsApiArg = {
  filters?: {
    query?: string;
    sortBy?:
    | 'createdAt'
    | 'commentCount'
    | 'forecastCount'
    | 'participantsCount'
    | 'startDate';
    sortOrder?: 'asc' | 'desc';
    status?: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
    groupStatus?: 'ACTIVATED' | 'DEACTIVATED';
    activeOnly?: boolean;
  };
  page?: Page;
  limit?: Limit;
  id: string;
};
export type GetApiGroupsByIdQuestionSetsAllApiResponse =
  /** status 200 Default Response */ GroupAllQuestionSetsResponseSchema;
export type GetApiGroupsByIdQuestionSetsAllApiArg = {
  filters?: Filters;
  page?: Page;
  limit?: Limit;
  id: string;
};
export type GetApiGroupsByIdQuestionSetsAndQuestionSetIdQuestionsApiResponse =
  /** status 200 Default Response */ QuestionSetQuestionsResponseSchema;
export type GetApiGroupsByIdQuestionSetsAndQuestionSetIdQuestionsApiArg = {
  page?: Page;
  limit?: Limit;
  filters?: Filters2;
  id: string;
  questionSetId: string;
};
export type GetApiGroupsByIdQuestionsApiResponse =
  /** status 200 Default Response */ QuestionsResponseSchema;
export type GetApiGroupsByIdQuestionsApiArg = {
  page?: Page;
  limit?: Limit;
  id: string;
};
export type PostApiGroupsByIdQuestionsApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiGroupsByIdQuestionsApiArg = {
  id: string;
  updateGroupQuestionsBodySchema: UpdateGroupQuestionsBodySchema;
};
export type GetApiGroupsByIdQuestionSetsAndQuestionSetIdQuestionsAllApiResponse =
  /** status 200 Default Response */ QuestionSetQuestionsAllResponseSchema;
export type GetApiGroupsByIdQuestionSetsAndQuestionSetIdQuestionsAllApiArg = {
  filters?: {
    query?: string;
    sortBy?: 'createdAt' | 'startDate';
    sortOrder?: SortOrder;
    status?: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
    groupStatus?: 'ACTIVATED' | 'DEACTIVATED';
    activeOnly?: boolean;
  };
  page?: Page;
  limit?: Limit;
  id: string;
  questionSetId: string;
};
export type PostApiGroupsByIdUpgradeApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiGroupsByIdUpgradeApiArg = {
  id: string;
  upgradeGroupToOrganizationBodySchema: UpgradeGroupToOrganizationBodySchema;
};
export type GetApiGroupsUsersInviteAcceptApiResponse = unknown;
export type GetApiGroupsUsersInviteAcceptApiArg = void;
export type GetApiGroupsUsersInviteAcceptSessionApiResponse =
  /** status 200 Default Response */ GroupUserInviteSessionResponseSchema;
export type GetApiGroupsUsersInviteAcceptSessionApiArg = void;
export type PostApiGroupsUsersInviteAcceptSessionApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiGroupsUsersInviteAcceptSessionApiArg = void;
export type GetApiGroupsByIdUsersApiResponse =
  /** status 200 Default Response */ GroupUsersResponseSchema;
export type GetApiGroupsByIdUsersApiArg = {
  query?: string;
  page?: Page;
  limit?: Limit;
  id: string;
};
export type DeleteApiGroupsByIdUsersApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiGroupsByIdUsersApiArg = {
  userId: string;
  reason?: string;
  id: string;
};
export type GetApiGroupsByIdUsersFromGroupApiResponse =
  /** status 200 Default Response */ GetGroupUsersForCreateFromGroupResponseSchema;
export type GetApiGroupsByIdUsersFromGroupApiArg = {
  page?: Page;
  limit?: Limit;
  id: string;
};
export type GetApiGroupsByIdUsersInvitesApiResponse =
  /** status 200 Default Response */ GroupUsersInvitedResponseSchema;
export type GetApiGroupsByIdUsersInvitesApiArg = {
  query?: string;
  lastInvitationSentAt?: string;
  lastUserId?: string;
  page?: Page;
  limit?: Limit;
  id: string;
};
export type DeleteApiGroupsByIdUsersInvitesApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiGroupsByIdUsersInvitesApiArg = {
  userId: string;
  id: string;
};
export type GetApiGroupsByIdUsersInvitesCountApiResponse =
  /** status 200 Default Response */ GroupUsersInvitedCountResponseSchema;
export type GetApiGroupsByIdUsersInvitesCountApiArg = {
  id: string;
};
export type PatchApiGroupsByIdUsersInvitesAcceptApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiGroupsByIdUsersInvitesAcceptApiArg = {
  id: string;
  acceptLoggedInUserInviteBodySchema: AcceptLoggedInUserInviteBodySchema;
};
export type PatchApiGroupsByIdUsersInvitesDeclineApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiGroupsByIdUsersInvitesDeclineApiArg = {
  id: string;
  declineLoggedInUserInviteBodySchema: DeclineLoggedInUserInviteBodySchema;
};
export type PostApiGroupsByIdUsersInvitesEmailsApiResponse =
  /** status 201 Default Response */ MessageResponseSchema;
export type PostApiGroupsByIdUsersInvitesEmailsApiArg = {
  id: string;
  inviteGroupUsersEmailBodySchema: InviteGroupUsersEmailBodySchema;
};
export type GetApiGroupsByIdUsersInvitesDownloadApiResponse = unknown;
export type GetApiGroupsByIdUsersInvitesDownloadApiArg = {
  type?: 'csv' | 'xlsx';
  id: string;
};
export type PostApiGroupsByIdUsersInvitesResendApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiGroupsByIdUsersInvitesResendApiArg = {
  id: string;
};
export type PostApiGroupsByIdUsersInvitesLinksApiResponse =
  /** status 201 Default Response */ InviteGroupUserLinkResponseSchema;
export type PostApiGroupsByIdUsersInvitesLinksApiArg = {
  id: string;
};
export type PatchApiGroupsByIdUsersRolesPromoteApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiGroupsByIdUsersRolesPromoteApiArg = {
  id: string;
  promoteGroupUserBodySchema: PromoteGroupUserBodySchema;
};
export type PatchApiGroupsByIdUsersRolesDemoteApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiGroupsByIdUsersRolesDemoteApiArg = {
  id: string;
  demoteGroupUserBodySchema: DemoteGroupUserBodySchema;
};
export type PatchApiGroupsByIdUsersRolesSuperadminApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiGroupsByIdUsersRolesSuperadminApiArg = {
  id: string;
  assignSuperadminBodySchema: AssignSuperadminBodySchema;
};
export type DeleteApiGroupsByIdUsersCurrentApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiGroupsByIdUsersCurrentApiArg = {
  newSuperadminId?: string;
  id: string;
};
export type PatchApiGroupsByIdQuestionsDeactivationApiResponse =
  /** status 200 Default Response */ ListGroupDeactivationQuestionsResponseSchema;
export type PatchApiGroupsByIdQuestionsDeactivationApiArg = {
  id: string;
  updateGroupQuestionsBodySchema: UpdateGroupQuestionsBodySchema;
};
export type GetApiGroupsByIdQuestionsAndQuestionIdOnboardingTextApiResponse =
  /** status 200 Default Response */ GroupQuestionOnboardingTextResponseSchema;
export type GetApiGroupsByIdQuestionsAndQuestionIdOnboardingTextApiArg = {
  id: string;
  questionId: string;
};
export type PutApiGroupsByIdQuestionsAndQuestionIdOnboardingTextApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiGroupsByIdQuestionsAndQuestionIdOnboardingTextApiArg = {
  id: string;
  questionId: string;
  updateGroupQuestionOnboardingTextBodySchema: UpdateGroupQuestionOnboardingTextBodySchema;
};
export type GetApiGroupsByIdQuestionsOnboardingTextApiResponse =
  /** status 200 Default Response */ GroupQuestionsOnboardingTextResponseSchema;
export type GetApiGroupsByIdQuestionsOnboardingTextApiArg = {
  questionIds?: string[];
  sortBy?: 'createdAt' | 'startDate';
  sortOrder?: SortOrder;
  page?: Page;
  limit?: Limit;
  id: string;
};
export type PutApiGroupsByIdQuestionsOnboardingTextApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiGroupsByIdQuestionsOnboardingTextApiArg = {
  id: string;
  updateGroupQuestionsOnboardingTextBodySchema: UpdateGroupQuestionsOnboardingTextBodySchema;
};
export type CreateGroupResponseSchema = {
  message: string;
  data: {
    group: {
      name: string;
      location: string | null;
      id: string;
    };
  };
};
export type CreateGroupBodySchema = {
  name: string;
  location: string | null;
  questionIds?: string[];
};
export type GroupsResponseSchema = {
  message: string;
  data: {
    total: number;
    groups: {
      id: string;
      name: string;
      location: string | null;
      updatedAt: string;
      createdAt: string;
      isPublic: boolean | null;
      status: 'ACTIVATED' | 'DEACTIVATED';
      groupRole: ('SUPERADMIN' | 'ADMIN' | 'USER') | null;
      organizationRole: ('SUPERADMIN' | 'ADMIN') | null;
      organizationId: string | null;
      organizationStatus: ('PENDING' | 'ACCEPTED' | 'REJECTED') | null;
      userRole: 'ADMIN' | 'USER';
      memberCount: number;
      forecastCount: number;
      commentCount: number;
      questionSets:
      | {
        id: string;
        title: string;
        startDate: string | null;
        endDate: string | null;
      }[]
      | null;
    }[];
  };
};
export type CommunityGroupsResponseSchema = {
  message: string;
  data: {
    groups: {
      id: string;
      name: string;
    }[];
    total: number;
  };
};
export type Page = number;
export type Limit = number;
export type UpdateGroupBodySchema = {
  name?: string;
  location?: string;
};
export type Items = {
  id: string;
  name: string;
  location: string | null;
  updatedAt: string;
  createdAt: string;
  isPublic: boolean | null;
  status: 'ACTIVATED' | 'DEACTIVATED';
  groupRole: ('SUPERADMIN' | 'ADMIN' | 'USER') | null;
  organizationRole: ('SUPERADMIN' | 'ADMIN') | null;
  organizationId: string | null;
  organizationStatus: ('PENDING' | 'ACCEPTED' | 'REJECTED') | null;
  userRole: 'ADMIN' | 'USER';
  memberCount: number;
  forecastCount: number;
  commentCount: number;
  questionSets:
  | {
    id: string;
    title: string;
    startDate: string | null;
    endDate: string | null;
  }[]
  | null;
};
export type SingleGroupResponseSchema = {
  message: string;
  data: {
    group: Items;
  };
};
export type MessageResponseSchema = {
  message: string;
};
export type CheckGroupNameExistsResponseSchema = {
  data: {
    exists: boolean;
  };
};
export type Author = {
  id: string;
  name: string;
  avatarUrl: string | null;
  displayName: string | null;
  isFollowed: boolean;
};
export type GroupFeedResponseSchema = {
  message: string;
  data: {
    feed: {
      id: string;
      createdAt: string;
      updatedAt: string;
      text: string;
      isPrivate: boolean;
      commendationsCount: number;
      userCommendation:
      | ('LIKE' | 'RELEVANT' | 'INSIGHTFUL' | 'INSPIRING')
      | null;
      commendationTypes:
      | ('LIKE' | 'RELEVANT' | 'INSIGHTFUL' | 'INSPIRING')[]
      | null;
      groupRemoveAccess?: boolean;
      author: {
        id: string;
        name: string;
        avatarUrl: string | null;
        displayName: string | null;
        isFollowed: boolean;
      };
      forecast: {
        id: string;
        estimates: {
          title: string;
          value: number;
        }[];
      } | null;
      question: {
        id: string;
        title: string;
        hasUserOnboarded?: boolean;
      };
      commentsCount: number;
      comments: {
        id: string;
        createdAt: string;
        updatedAt: string;
        text: string;
        author: Author;
        likes: number;
        isLiked: boolean;
      }[];
      estimatesDiff: number | null;
    }[];
    total: number;
    hasAvailable: boolean;
  };
};
export type SortOrder = 'asc' | 'desc';
export type DateEnd = string;
export type GroupQuestionSetsResponseSchema = {
  message: string;
  data: {
    questionSets: {
      id: string;
      title: string;
      createdAt: string;
      updatedAt: string;
      groupStatus: ('ACTIVATED' | 'DEACTIVATED') | null;
      status: ('AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED') | null;
      startDate: string | null;
      endDate: string | null;
      questionCount: number;
      participantsCount: number;
      forecastCount: number;
      commentCount: number;
    }[];
    total: number;
  };
};
export type GroupAllQuestionSetsResponseSchema = {
  message: string;
  data: {
    activatedQuestions: {
      id: string;
      questions: {
        id: string;
      }[];
    }[];
    questionSets: {
      id: string;
      title: string;
      createdAt: string;
      updatedAt: string;
      groupStatus: ('ACTIVATED' | 'DEACTIVATED') | null;
      status: ('AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED') | null;
      startDate: string | null;
      endDate: string | null;
      activatedQuestionCount?: number;
      questionCount?: number;
    }[];
    total: number;
  };
};
export type Filters = {
  query?: string;
  sortBy?: 'createdAt' | 'startDate';
  sortOrder?: SortOrder;
  status?: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
  groupStatus?: 'ACTIVATED' | 'DEACTIVATED';
  activeOnly?: boolean;
};
export type QuestionSetQuestionsResponseSchema = {
  message: string;
  data: {
    questions: {
      id: string;
      title: string;
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      isMandatory: boolean;
      groupStatus: ('ACTIVATED' | 'DEACTIVATED') | null;
      onboardingText: string | null;
      startDate: string;
      endDate: string;
      createdAt: string;
      updatedAt: string;
      isActive: boolean;
      userEstimatesAvg: number | null;
      globalEstimatesAvg: number | null;
      globalEstimatesChange: number | null;
      groupEstimatesAvg: number | null;
      groupEstimatesChange: number | null;
      participantsCount: number;
      commentsCount: number;
      updatesCount: number;
    }[];
    total: number;
  };
};
export type Filters2 = {
  query?: string;
  sortBy?:
  | 'createdAt'
  | 'commentCount'
  | 'forecastCount'
  | 'participantsCount'
  | 'startDate';
  sortOrder?: 'asc' | 'desc';
  status?: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
  groupStatus?: 'ACTIVATED' | 'DEACTIVATED';
  activeOnly?: boolean;
};
export type QuestionsResponseSchema = {
  message: string;
  data: {
    questions: {
      id: string;
      title: string;
      isMandatory: boolean;
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      groupStatus: ('ACTIVATED' | 'DEACTIVATED') | null;
      startDate: string;
      endDate: string;
      createdAt: string;
      updatedAt: string;
      userEstimatesAvg: number;
      globalEstimatesAvg: number;
      globalEstimatesChange: number;
      groupEstimatesAvg: number;
      groupEstimatesChange: number;
    }[];
    total: number;
  };
};
export type UpdateGroupQuestionsBodySchema = {
  questionIds: string[];
};
export type QuestionSetQuestionsAllResponseSchema = {
  message: string;
  data: {
    questions: {
      id: string;
      title: string;
      isMandatory: boolean;
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      groupStatus: ('ACTIVATED' | 'DEACTIVATED') | null;
      startDate: string;
      endDate: string;
      createdAt: string;
      updatedAt: string;
      isActive: boolean;
    }[];
    total: number;
  };
};
export type UpgradeGroupToOrganizationBodySchema = {
  organizationName: string;
  description: string;
};
export type GroupUserInviteSessionResponseSchema = {
  data: {
    group: {
      id: string;
      name: string;
    };
    userEmail: string | null;
  };
  invitationSession: boolean;
  message: string;
};
export type GroupUsersResponseSchema = {
  message: string;
  data: {
    total: number;
    members: {
      id: string;
      name: string;
      role: 'SUPERADMIN' | 'ADMIN' | 'USER';
      createdAt: string;
      displayName: string | null;
      email: string | null;
      avatarUrl: string | null;
      commentCount?: number;
      forecastCount?: number;
    }[];
    admins: {
      id: string;
      name: string;
      role: 'SUPERADMIN' | 'ADMIN' | 'USER';
      createdAt: string;
      displayName: string | null;
      email: string | null;
      avatarUrl: string | null;
      commentCount: number;
      forecastCount: number;
    }[];
  };
};
export type GetGroupUsersForCreateFromGroupResponseSchema = {
  message: string;
  data: {
    users: {
      userId: string;
      email: string;
    }[];
    total: number;
  };
};
export type GroupUsersInvitedResponseSchema = {
  message: string;
  data: {
    users: {
      sentAt: string;
      email: string;
      id?: string;
    }[];
    total: number;
  };
};
export type GroupUsersInvitedCountResponseSchema = {
  data: {
    total: number;
  };
};
export type AcceptLoggedInUserInviteBodySchema = {
  notificationId: string;
};
export type DeclineLoggedInUserInviteBodySchema = {
  notificationId: string;
};
export type InviteGroupUsersEmailBodySchema = {
  emails: string[];
  inviteMessage?: string;
};
export type InviteGroupUserLinkResponseSchema = {
  message: string;
  data: {
    link: string;
  };
};
export type PromoteGroupUserBodySchema = {
  userId: string;
};
export type DemoteGroupUserBodySchema = {
  userId: string;
  reason?: string;
};
export type AssignSuperadminBodySchema = {
  userId: string;
};
export type ListGroupDeactivationQuestionsResponseSchema = {
  message: string;
  data: {
    sets: {
      title: string;
      startDate: string;
      endDate: string;
      questionsCount: number;
    }[];
    questions: string[];
  };
};
export type GroupQuestionOnboardingTextResponseSchema = {
  message: string;
  data: {
    onboardingText: string | null;
  };
};
export type UpdateGroupQuestionOnboardingTextBodySchema = {
  onboardingText: string | null;
};
export type GroupQuestionsOnboardingTextResponseSchema = {
  message: string;
  data: {
    total: number;
    questions: {
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      id: string;
      title: string;
      startDate: string;
      endDate: string;
      onboardingText: string | null;
    }[];
  };
};
export type UpdateGroupQuestionsOnboardingTextBodySchema = {
  questions: {
    id: string;
    onboardingText: string | null;
  }[];
};
export const {
  usePostApiGroupsMutation,
  useGetApiGroupsQuery,
  useGetApiGroupsCommunityQuery,
  usePatchApiGroupsByIdMutation,
  useGetApiGroupsByIdQuery,
  useDeleteApiGroupsByIdMutation,
  useGetApiGroupsNamesExistsMutation,
  useGetApiGroupsByIdFeedQuery,
  useGetApiGroupsByIdQuestionSetsQuery,
  useGetApiGroupsByIdQuestionSetsAllQuery,
  useGetApiGroupsByIdQuestionSetsAndQuestionSetIdQuestionsQuery,
  useGetApiGroupsByIdQuestionsQuery,
  usePostApiGroupsByIdQuestionsMutation,
  useGetApiGroupsByIdQuestionSetsAndQuestionSetIdQuestionsAllQuery,
  usePostApiGroupsByIdUpgradeMutation,
  useGetApiGroupsUsersInviteAcceptQuery,
  useGetApiGroupsUsersInviteAcceptSessionQuery,
  usePostApiGroupsUsersInviteAcceptSessionMutation,
  useGetApiGroupsByIdUsersQuery,
  useDeleteApiGroupsByIdUsersMutation,
  useGetApiGroupsByIdUsersFromGroupQuery,
  useGetApiGroupsByIdUsersInvitesQuery,
  useDeleteApiGroupsByIdUsersInvitesMutation,
  useGetApiGroupsByIdUsersInvitesCountQuery,
  usePatchApiGroupsByIdUsersInvitesAcceptMutation,
  usePatchApiGroupsByIdUsersInvitesDeclineMutation,
  usePostApiGroupsByIdUsersInvitesEmailsMutation,
  useGetApiGroupsByIdUsersInvitesDownloadQuery,
  usePostApiGroupsByIdUsersInvitesResendMutation,
  usePostApiGroupsByIdUsersInvitesLinksMutation,
  usePatchApiGroupsByIdUsersRolesPromoteMutation,
  usePatchApiGroupsByIdUsersRolesDemoteMutation,
  usePatchApiGroupsByIdUsersRolesSuperadminMutation,
  useDeleteApiGroupsByIdUsersCurrentMutation,
  usePatchApiGroupsByIdQuestionsDeactivationMutation,
  useGetApiGroupsByIdQuestionsAndQuestionIdOnboardingTextQuery,
  usePutApiGroupsByIdQuestionsAndQuestionIdOnboardingTextMutation,
  useGetApiGroupsByIdQuestionsOnboardingTextQuery,
  usePutApiGroupsByIdQuestionsOnboardingTextMutation,
} = injectedRtkApi;
