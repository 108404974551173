import React, {
  FC, useMemo
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Spinner,
  TableContent,
  TableRow,
  usePaginationSettings,
  TableFooter,
  EmptyResultsMessage,
} from 'src/shared/components';
import {
  useGetAdminOrganizationsQuery
} from 'src/redux/openapi';
import {
  UseSelectionSet
} from 'src/shared/hooks';

import {
  getColumns, getIsAllSelected, getIsSomeSelected
} from './utils';

interface OrganisationTabContentProps {
  searchQuery: string;
  selectConfig: UseSelectionSet<string>;
  isGlobal: boolean;
  toggleGlobal: () => void;
}

export const OrganisationTabContent: FC<OrganisationTabContentProps> = ({
  searchQuery,
  selectConfig,
  isGlobal,
  toggleGlobal,
}) => {
  const {
    currentPage, setCurrentPage, pageSize, onPageSizeChange
  } = usePaginationSettings(searchQuery);

  const {
    t
  } = useTranslation();

  const {
    data: organizationsData, isLoading
  } = useGetAdminOrganizationsQuery(
    {
      filters: {
        query: searchQuery,
        status: 'ACCEPTED',
      },
      limit: pageSize,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total, organizations
  } = useMemo(
    () => organizationsData?.data || {
      organizations: [],
      total: 0,
    },
    [organizationsData],
  );

  const {
    selectedItems: selectedIds,
    toggleSelection,
    addMultiple,
    removeMultiple,
  } = selectConfig;

  const isAllSelected = getIsAllSelected(
    organizations,
    selectedIds
  );

  const isSomeSelected = getIsSomeSelected(
    organizations,
    selectedIds
  );

  const toggleAllOnPage = () => {
    const idsToEdit = organizations.map(({
      id
    }) => id);

    if (!isSomeSelected) {
      addMultiple(idsToEdit);

      return;
    }

    removeMultiple(idsToEdit);
  };

  const columns = getColumns({
    selectedIds,
    nameHeaderTitle: t('organisation.organisationName'),
    toggleAllOnPage,
    isAllSelected,
    isSomeSelected,
    isGlobal,
    toggleGlobal,
  });

  const label = t('organisation.organisations');

  const onRowClick = (id: string) => {
    if (isGlobal) {
      toggleGlobal();
    }

    toggleSelection(id);
  };

  if (isLoading) {
    return <Spinner withLoadingText />;
  }

  if (!organizations?.length) {
    return (
      <EmptyResultsMessage
        results={label}
        withSearchQuery={!!searchQuery}
      />
    );
  }

  return (
    <>
      <TableContent
        columns={columns}
        data={organizations}
        fixedLayout
        renderRow={(row, index) => (
          <TableRow
            row={row}
            index={index}
            onRowClick={onRowClick}
            canClick
          />
        )}
      />

      <TableFooter
        totalCount={total}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={label}
      />
    </>
  );
};
