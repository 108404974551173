import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  UserGroupRole,
  Organization,
  useGetOrganizationUsersQuery,
  useGetOrganizationUsersAdminsQuery,
} from 'src/redux/openapi';
import {
  EmptyResultsMessage,
  Spinner,
  TableFooter,
  usePaginationSettings,
} from 'src/shared/components';
import {
  MemberRow,
  MembersContainer,
  MembersListContainer,
  MembersRole,
  StatusCount,
} from 'src/widgets';

interface MembersTabContentProps {
  role: UserGroupRole;
  organization: Organization;
  searchQuery: string;
}

export const MembersTabContent: FC<MembersTabContentProps> = ({
  role,
  organization,
  searchQuery,
}) => {
  const {
    currentPage, setCurrentPage, pageSize, onPageSizeChange
  } = usePaginationSettings(searchQuery);

  const isAdmin = role !== UserGroupRole.USER;
  const isSuperAdmin = role === UserGroupRole.SUPERADMIN;

  const {
    t
  } = useTranslation();

  const {
    data: membersData,
    refetch: refetchMembers,
    isLoading: isMembersLoading,
  } = useGetOrganizationUsersQuery(
    {
      id: organization.id,
      query: searchQuery,
      limit: pageSize,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: adminsData,
    refetch: refetchAdmins,
    isLoading: isAdminsLoading,
  } = useGetOrganizationUsersAdminsQuery({
    id: organization.id,
    query: searchQuery,
  });

  const {
    members = [], total: totalMembers = 0
  } = membersData?.data || {};

  const {
    status = null, admins = []
  } = adminsData?.data || {};

  const totalAdminsNumber = admins?.length;

  const refetchAllMembers = () => {
    refetchAdmins();
    refetchMembers();
  };

  if (isMembersLoading || isAdminsLoading) {
    return <Spinner size={24} />;
  }

  return (
    <>
      <MembersContainer className="grow">
        {status && organization && <StatusCount membersStatusCount={status} />}

        <MembersListContainer className="scroll-hidden">
          {!!admins.length && (
            <div>
              <MembersRole
                usersRole="admins"
                variant="vertical"
              />

              {admins.map((member) => (
                <MemberRow
                  key={member.id}
                  member={member}
                  isAdminView={isAdmin}
                  isSuperAdminView={isSuperAdmin}
                  totalAdminsNumber={totalAdminsNumber}
                  organization={organization}
                  refetchMembers={refetchAllMembers}
                  isMemberAdmin
                  className="px-3"
                />
              ))}
            </div>
          )}

          {!!members.length && (
            <div>
              <MembersRole
                usersRole="users"
                variant="vertical"
              />

              {members?.map((member) => (
                <MemberRow
                  key={member.id}
                  member={member}
                  isAdminView={isAdmin}
                  isSuperAdminView={isSuperAdmin}
                  totalAdminsNumber={totalAdminsNumber}
                  organization={organization}
                  refetchMembers={refetchAllMembers}
                  className="px-3"
                />
              ))}
            </div>
          )}
        </MembersListContainer>

        {!members.length && !admins.length && (
          <EmptyResultsMessage results={t('group.members').toLowerCase()} />
        )}
      </MembersContainer>

      <TableFooter
        totalCount={totalMembers}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={t('group.Members')}
      />
    </>
  );
};
