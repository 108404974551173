import {
  AdminNotificationChar
} from 'src/redux/openapi';

export enum NotificationType {
  GROUP_INVITATION = 'GROUP_INVITATION',
  COMMENT_REPLY = 'COMMENT_REPLY',
  GROUP_USER_REMOVAL = 'GROUP_USER_REMOVAL',
  GROUP_ORGANIZATION_REQUEST = 'GROUP_ORGANIZATION_REQUEST',
  GROUP_USER_DEMOTION = 'GROUP_USER_DEMOTION',
  GROUP_USER_PROMOTION = 'GROUP_USER_PROMOTION',
  IMMUTABLE_CHARACTERISTIC_UPDATE = 'IMMUTABLE_CHARACTERISTIC_UPDATE',
  CHARACTERISTIC_CHANGE_ACCEPTED = 'CHARACTERISTIC_CHANGE_ACCEPTED',
  CHARACTERISTIC_CHANGE_REJECTED = 'CHARACTERISTIC_CHANGE_REJECTED',
  ORGANIZATION_ADMIN_MESSAGE = 'ORGANIZATION_ADMIN_MESSAGE',
  QUESTION_CREATE_REQUEST_DECLINE = 'QUESTION_CREATE_REQUEST_DECLINE',
  QUESTION_CREATE_REQUEST_APPROVE = 'QUESTION_CREATE_REQUEST_APPROVE',
}

export type NotificationTypeKeys = keyof typeof NotificationType;

export enum NotificationStatus {
  UNREAD = 'UNREAD',
  READ = 'READ',
}

export enum AnswerStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface Context {
  groupId?: string;
  groupName?: string;
  status?: AnswerStatus;
  invitationMessage?: string;
  organizationId?: string;
  organizationName?: string;
  requestDescription?: string;
  fromUserName?: string;
  reason?: string;
  message?: string;
  userName?: string;
  userId?: string;
  characteristics?: AdminNotificationChar[];
}

export type NotificationTab = 'replies' | 'groups' | 'actions';
