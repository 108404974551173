import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  PayloadAction
} from '@reduxjs/toolkit';

import {
  SortBy, FilterBy
} from 'src/entities/Questions';
import {
  TimeSpansValues
} from 'src/redux/timeSpans';
import {
  FAVORITE
} from 'src/shared/utils';

export interface PreferencesState {
  filterBy: FilterBy | null;
  sortBy: SortBy | null;
  timeRange: TimeSpansValues | null;
  isTableView: boolean;
  activeFilterId: string | null;
  activeQuestionsPage: number;
  availableQuestionsPage: number;
  isFavorites: boolean;
}

export interface HomePreferencesReducer {
  homePreferences: PreferencesState;
}

export const initialState: PreferencesState = {
  filterBy: null,
  sortBy: null,
  timeRange: null,
  isTableView: false,
  activeFilterId: null,
  activeQuestionsPage: 1,
  availableQuestionsPage: 1,
  isFavorites: false,
};

export const homePreferencesSlice = createSlice({
  name: 'homePreferences',
  initialState,
  reducers: {
    resetHomePreferences: (state) => {
      state.filterBy = state.isTableView
        ? initialState.filterBy
        : state.filterBy;

      state.sortBy = initialState.sortBy;
    },
    resetPages(state) {
      state.activeQuestionsPage = 1;
      state.availableQuestionsPage = 1;
    },
    setHomePreferencesFilterBy: (
      state,
      action: PayloadAction<FilterBy | null>,
    ) => {
      state.filterBy = action.payload;
      homePreferencesSlice.caseReducers.resetPages(state);
    },
    setHomePreferencesSortBy: (state, action: PayloadAction<SortBy | null>) => {
      state.sortBy = action.payload;
      homePreferencesSlice.caseReducers.resetPages(state);
    },
    setHomePreferencesTimeRange: (
      state,
      action: PayloadAction<TimeSpansValues | null>,
    ) => {
      state.timeRange = action.payload;
    },
    setHomePreferencesTableView: (state, action: PayloadAction<boolean>) => {
      state.isTableView = action.payload;
      state.filterBy = null;
      homePreferencesSlice.caseReducers.resetPages(state);
    },
    setHomePreferencesActiveFilterId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.activeFilterId = action.payload;

      if (action.payload === FAVORITE) {
        if (state.filterBy && state.filterBy !== FilterBy.Active) {
          state.filterBy = FilterBy.Active;
        }

        state.isFavorites = true;
      } else {
        state.isFavorites = false;
      }

      homePreferencesSlice.caseReducers.resetPages(state);
    },
    setHomeActiveQuestionsPage: (state, action: PayloadAction<number>) => {
      state.activeQuestionsPage = action.payload;
    },
    setHomeAvailableQuestionsPage: (state, action: PayloadAction<number>) => {
      state.availableQuestionsPage = action.payload;
    },
  },
});

export const {
  setHomePreferencesFilterBy,
  setHomePreferencesSortBy,
  setHomePreferencesTimeRange,
  setHomePreferencesTableView,
  setHomePreferencesActiveFilterId,
  resetHomePreferences,
  setHomeActiveQuestionsPage,
  setHomeAvailableQuestionsPage,
} = homePreferencesSlice.actions;

export const homePreferencesReducer = homePreferencesSlice.reducer;
