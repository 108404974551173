import {
  useMemo
} from 'react';
import {
  useNavigate, useParams
} from 'react-router-dom';

import {
  useGetGroupQuestionsOnboardingTextQuery,
  usePutGroupQuestionsOnboardingTextMutation,
} from 'src/redux/openapi';
import {
  successfulToast, useStepper
} from 'src/shared/components';
import {
  useSetOnboardingTextList
} from 'src/widgets';
import {
  useEditQuestions
} from 'src/features';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';

import {
  getStepsMap, Steps, getNextStepButtonConfig
} from '../utils';

export const useEditQuestionsInGroup = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    groupId = ''
  } = params;

  const steps = getStepsMap(
    false,
    true
  );

  const {
    completeCurrentStep,
    activeStepId,
    isLastStep,
    handleNextStep,
    completedStatus,
  } = useStepper(steps);

  const nextStepButtonConfig = useMemo(
    () => getNextStepButtonConfig({
      isLastStep,
      isAdding: true,
    }),
    [isLastStep],
  );

  const {
    isQuestionsLoading,
    preparedSets,
    onSave,
    selectConfig,
    handleProceed,
    deactivationList,
    isSavingInProcess,
    onFilterChange,
    activeGroupId,
    clearDeactivationList,
    groupsFiltersConfig,
    onLoadQuestionsBySetId,
  } = useEditQuestions({
    groupId,
  });

  const selectedQuestionsIds = useMemo(
    () => selectConfig.selectedItems.flatMap((items) => items.selectedChildrenId),
    [selectConfig],
  );

  const {
    questionsWithOnboardingText, isQuestionsWithTextLoading
  } = useGetGroupQuestionsOnboardingTextQuery(
    {
      id: groupId,
      questionIds: selectedQuestionsIds,
      limit: 5000,
      sortBy: 'startDate',
      sortOrder: 'asc',
    },
    {
      skip: activeStepId !== Steps.SetOnboardingText || !groupId,
      selectFromResult: ({
        data, isLoading, isFetching
      }) => ({
        questionsWithOnboardingText: data?.data.questions || [],
        isQuestionsWithTextLoading: isLoading || isFetching,
      }),
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    questionsOnboardingText, onTextChange
  } = useSetOnboardingTextList(
    questionsWithOnboardingText,
  );

  const completeWithChangeStep = () => {
    completeCurrentStep();
    handleNextStep();
  };

  const [putGroupQuestionsOnboardingText] = usePutGroupQuestionsOnboardingTextMutation();

  const handleSaveOnboardText = async () => {
    try {
      const resp = await putGroupQuestionsOnboardingText({
        id: groupId,
        updateGroupQuestionsOnboardingTextBodySchema: {
          questions: questionsOnboardingText,
        },
      }).unwrap();

      successfulToast(resp.message);
      navigate(`/${ROUTE.GROUPS}/${groupId}`);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onNextStepClick = async () => {
    switch (activeStepId) {
      case Steps.ChooseQuestions:
        await onSave();

        return completeWithChangeStep();

      case Steps.SetOnboardingText:
        await handleSaveOnboardText();

        return completeWithChangeStep();

      default:
        return handleNextStep();
    }
  };

  const isDataLoading = isQuestionsWithTextLoading || isQuestionsLoading;

  return {
    isDataLoading,
    nextStepButtonConfig,
    onNextStepClick,
    questionsOnboardingText,
    onTextChange,
    steps,
    activeStepId,
    completedStatus,
    preparedSets,
    selectConfig,
    handleProceed,
    deactivationList,
    isSavingInProcess,
    onFilterChange,
    activeGroupId,
    clearDeactivationList,
    groupsFiltersConfig,
    onLoadQuestionsBySetId,
  };
};
