import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CardWrapper = styled.div<{ $isComplete?: boolean }>`
  ${tw`grid gap-6 mb-6`}

  ${({
    $isComplete
  }) => ($isComplete ? tw`grid-cols-group-completed` : tw`grid-cols-2`)}
`;

export const GroupContainer = styled.div`
  ${tw`bg-white px-6 py-4 rounded-md border border-gray94 shadow-card-shadow-default`}
`;

export const GroupHeaderContainer = styled.div`
  ${tw`flex justify-between gap-3 mb-4`}
`;

export const CardStatisticsRow = styled.div`
  ${tw`flex justify-between mb-4`}
`;

export const CardStatisticsRowRight = styled.div`
  ${tw`flex gap-2.5 items-center`}
`;

export const CardStatistics = styled.div`
  ${tw`p-1`}
`;
