import React, {
  FC
} from 'react';
import ReactSelect, {
  DropdownIndicatorProps,
  MultiValueProps,
  OptionProps,
  StylesConfig,
  components,
} from 'react-select';
import {
  clsx
} from 'clsx';

import {
  ArrowDownIcon,
  CheckBoxFalseIcon,
  CheckBoxTrueIcon,
} from 'src/shared/icons';
import {
  UseInfiniteScroll
} from 'src/shared/hooks';

import {
  SelectPaginationMenu
} from '../SelectPaginationMenu';

export interface Option<T> {
  label: string;
  value: T;
}

interface SelectProps<T> {
  options: Option<T>[];
  defaultValue?: Option<T>;
  placeholder?: string;
  isMulti?: boolean;
  onChange: (newValue: Option<T> | Option<T>[]) => void;
  selectedValue: Option<T> | Option<T>[] | null;
  closeMenuOnSelect?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  selectConfig?: Pick<UseInfiniteScroll<T>, 'sentryRef' | 'displayLoader'>;
  styles?: StylesConfig;
}

const ALL_VALUE = 'all';

const DropdownIndicator: FC<DropdownIndicatorProps> = ({
  selectProps
}) => {
  return (
    <ArrowDownIcon
      className={clsx(
        'h-6 w-6 translate-all',
        {
          'rotate-180': selectProps.menuIsOpen,
        }
      )}
    />
  );
};

const SelectOption: FC<OptionProps> = ({
  isSelected,
  label,
  selectOption,
  data,
}) => {
  return (
    <button
      type="button"
      className="flex gap-2 w-full justify-between text-17-26 font-medium mb-2 last:mb-0 p-2 rounded cursor-pointer"
      onClick={() => selectOption(data)}
    >
      <div className="truncate">{label}</div>

      {isSelected ? <CheckBoxTrueIcon /> : <CheckBoxFalseIcon />}
    </button>
  );
};

const MultiValue: FC<MultiValueProps> = ({
  index, selectProps, children
}) => {
  const {
    value, placeholder
  } = selectProps as {
    value: Option<string>[];
    placeholder: string;
  };

  if (value.length === 1) {
    return <div className="truncate flex-1">{children}</div>;
  }

  if (index) {
    return null;
  }

  return <div>{placeholder}</div>;
};

export const Select = <T,>({
  options,
  defaultValue,
  placeholder,
  isMulti,
  onChange,
  selectedValue,
  closeMenuOnSelect = false,
  onMenuOpen,
  onMenuClose,
  selectConfig,
  styles,
}: SelectProps<T>) => {
  const handleChange = (
    value: Option<T> | Option<T>[],
    action: string,
    option: Option<T>,
  ) => {
    if (!Array.isArray(value)) {
      return onChange(value);
    }

    if (option?.value === ALL_VALUE) {
      return onChange([option]);
    }

    if (!value.length && defaultValue) {
      return onChange([defaultValue]);
    }

    if (value.some((val) => val.value === ALL_VALUE)) {
      return onChange(value.filter((val) => val.value !== ALL_VALUE));
    }

    return onChange(value);
  };

  return (
    <ReactSelect
      options={options}
      menuPlacement="auto"
      isMulti={isMulti}
      hideSelectedOptions={false}
      menuPortalTarget={document.body}
      isClearable={false}
      defaultValue={isMulti ? [defaultValue] : defaultValue}
      value={selectedValue}
      placeholder={placeholder}
      isSearchable={false}
      closeMenuOnSelect={closeMenuOnSelect}
      closeMenuOnScroll={false}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      onChange={(newValue, {
        action, option
      }) => handleChange(
        newValue as Option<T> | Option<T>[],
        action,
        option as Option<T>,
      )}
      components={{
        DropdownIndicator,
        Option: isMulti ? SelectOption : components.Option,
        MultiValue: isMulti ? MultiValue : components.MultiValue,
        ...(selectConfig && {
          MenuList: SelectPaginationMenu,
        }),
      }}
      sentryRef={selectConfig?.sentryRef}
      displayLoader={selectConfig?.displayLoader}
      styles={{
        ...styles,
        menuPortal: (base) => ({
          ...base,
          ...styles?.menuPortal,
          zIndex: 9999,
        }),
      }}
      classNames={{
        control: () => clsx(
          `
            px-4 py-3px border outline-0 !rounded-md
            text-15-20 md:text-17-26 text-dark-gray font-medium
            !shadow-none !border-gray90 !h-11
          `,
          {
            'text-gray7':
                !Array.isArray(selectedValue)
                || !selectedValue.some((value) => value.value === ALL_VALUE),
            'text-dim-gray':
                Array.isArray(selectedValue)
                && selectedValue.some((value) => value.value === ALL_VALUE),
          },
        ),
        valueContainer: () => `!px-0 truncate`,
        indicatorsContainer: () => `!h-9`,
        container: () => `flex-1 mb-4`,
        indicatorSeparator: () => `hidden`,
        option: ({
          isSelected
        }) => clsx(
          `
            text-17-26 font-medium mb-2 last:mb-0 p-2 rounded hover:!bg-cultured cursor-pointer
          `,
          {
            '!bg-alice-blue !text-button-blue': isSelected,
            '!text-dark-gray !bg-white': !isSelected,
          },
        ),
        menu: () => `p-2 !shadow-menu-shadow !border-0 rounded !mt-1 !z-10 overflow-auto scroll-hidden`,
        menuList: () => 'overflow-auto',
      }}
    />
  );
};
