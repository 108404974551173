import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Title = styled.h1`
  ${tw`font-bold text-28-36 text-gray7 mb-5`}
`;

export const Recommendation = styled.div`
  ${tw`border-y border-y-gray94 py-4 px-3 -mt-px`}
`;

export const SuccessResult = styled.div`
  ${tw`flex gap-2 items-center flex-wrap`}
`;

export const ResultButtons = styled.div`
  ${tw`flex gap-4 p-6 justify-end mt-auto`}
`;
