import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col overflow-auto border-t border-t-gray94`}
`;

export const Title = styled.p`
  ${tw`text-17-26 font-bold text-gray7`}
`;

export const IconPlaceholder = styled.div`
  ${tw`h-6 w-6`}
`;
