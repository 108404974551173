import {
  useEffect, useState
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  warningToast
} from 'src/shared/components';
import {
  useGetUserFollowingsQuery
} from 'src/redux/openapi';

const PAGE_LIMIT = 10;

export const useGetFollowingsUsers = ({
  skip = false
} = {}) => {
  const [searchParams] = useSearchParams();

  const {
    t
  } = useTranslation();

  const [page, setPage] = useState(1);

  useEffect(
    () => {
      setPage(1);
    },
    [searchParams]
  );

  const {
    data: followingsData,
    isLoading,
    isFetching: isLoadingMore,
    refetch,
  } = useGetUserFollowingsQuery(
    {
      limit: PAGE_LIMIT * page,
      page: 1,
    },
    {
      skip,
      refetchOnMountOrArgChange: true,
    },
  );

  const loadNextPage = async () => {
    try {
      if (
        followingsData
        && followingsData.data.total > followingsData.data.followings.length
        && !isLoadingMore
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      warningToast(t('errors.errorLoadFollowings'));
    }
  };

  return {
    followingsList: followingsData?.data.followings || [],
    isLoading,
    loadNextPage,
    isLoadingMore,
    refetch,
    total: followingsData?.data.total || 0,
  };
};
