import React, {
  FC
} from 'react';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import i18next from 'i18next';
import {
  useTranslation
} from 'react-i18next';

import {
  ArrowUpIcon
} from 'src/shared/icons';
import {
  CircleButton, Spinner
} from 'src/shared/components';
import {
  CommentsFilterContext, SendCommentBlock
} from 'src/widgets';
import {
  commentID, questionId, ROUTE
} from 'src/shared/utils';
import {
  useGetComments,
  useGetPollingComments,
  useMediaQuery,
  useSendComment,
} from 'src/shared/hooks';

import {
  CommentList
} from './components';
import * as Style from './YourFeed.styles';

interface YourFeedProps {
  isExpanded: boolean;
  onExpand?: () => void;
  headerTitle?: string;
  canAddComments?: boolean;
}

const ICON_SIZE = 24;

export const YourFeed: FC<YourFeedProps> = ({
  isExpanded,
  onExpand,
  headerTitle = i18next.t('feed.yourFeed'),
  canAddComments,
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const params = useParams();

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const {
    t
  } = useTranslation();

  const {
    addReplyHandler, addNewCommentHandler
  } = useSendComment();

  const activeQuestionId = searchParams.get(questionId) || null;
  const questionIdToSearch = params.id || activeQuestionId;

  const poolingCommentsData = useGetPollingComments({
    skip: !!questionIdToSearch,
  });

  const commentsData = useGetComments({
    skip: !questionIdToSearch,
  });

  const commentListProps = questionIdToSearch
    ? commentsData
    : poolingCommentsData;

  const {
    hasOnboarded, data: commentsList, isLoading
  } = commentListProps;

  const commentIdToReply = searchParams.get(commentID);

  const isOnboardQuestion = !!location.state?.isOnboarding;
  const isDeactivated = !!location.state?.isDeactivated;

  const isQuestionPage = location.pathname.startsWith(`/${ROUTE.QUESTION}/`)
    && !isOnboardQuestion
    && !isDeactivated;

  const hasCommunityLink = !isMobile && isExpanded && isQuestionPage && hasOnboarded;

  return (
    <Style.MainContainer $isExpanded={isExpanded}>
      <Style.Header>
        <div>
          <Style.HeaderTitle>{headerTitle}</Style.HeaderTitle>

          {hasCommunityLink && (
            <Link
              to={`/${ROUTE.COMMUNITY}`}
              state={
                params.id
                  ? {
                    questionId: params.id,
                  }
                  : null
              }
            >
              <Style.Question>
                <Style.QuestionTitle>
                  {t('feed.goToCommunity')}
                </Style.QuestionTitle>
              </Style.Question>
            </Link>
          )}
        </div>

        <Style.HeaderButtons>
          {isExpanded && hasOnboarded && !isOnboardQuestion && (
            <CommentsFilterContext />
          )}

          {onExpand && !isMobile && (
            <CircleButton onClick={onExpand}>
              <ArrowUpIcon
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            </CircleButton>
          )}
        </Style.HeaderButtons>
      </Style.Header>

      {isExpanded && (
        <>
          {!!commentsList.length && !isOnboardQuestion && (
            <>
              {isLoading ? (
                <Spinner />
              ) : (
                <CommentList
                  comments={commentListProps.data}
                  isLoadingComments={commentListProps.isLoading}
                  isLoadingMoreComments={commentListProps.isLoadingMoreComments}
                  loadNextPage={commentListProps.loadNextPage}
                  refetch={commentListProps.refetch}
                  total={commentListProps.total}
                />
              )}

              {(commentIdToReply || isQuestionPage) && !isDeactivated && (
                <SendCommentBlock
                  addNewCommentHandler={addNewCommentHandler}
                  addReplyHandler={addReplyHandler}
                />
              )}
            </>
          )}

          {(!commentsList.length || isOnboardQuestion) && (
            <>
              {isLoading ? (
                <Spinner />
              ) : (
                <Style.EmptyContentContainer>
                  <Style.OnboardText>
                    {hasOnboarded
                      ? t('empty.noResultsForYourFilters')
                      : t('feed.onboardFirstQuestionToOpenFeed')}
                  </Style.OnboardText>
                </Style.EmptyContentContainer>
              )}

              {isQuestionPage && canAddComments && (
                <SendCommentBlock
                  addNewCommentHandler={addNewCommentHandler}
                  addReplyHandler={addReplyHandler}
                />
              )}
            </>
          )}
        </>
      )}
    </Style.MainContainer>
  );
};
