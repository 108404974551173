import React, {
  FC, PropsWithChildren
} from 'react';

import {
  DragAndDropInput
} from 'src/shared/components';
import {
  SET_TEMPLATE_URL
} from 'src/shared/utils';

import {
  HeaderContainer
} from '../AddQuestionSets.styles';

interface HeaderProps extends PropsWithChildren {
  loadFile?: (file: FormData) => Promise<void>;
}

export const Header: FC<HeaderProps> = ({
  loadFile, children
}) => {
  return (
    <HeaderContainer>
      {children}

      {loadFile && (
        <DragAndDropInput
          handleFileLoading={loadFile}
          templateLink={SET_TEMPLATE_URL}
        />
      )}
    </HeaderContainer>
  );
};
