import React, {
  FC, useEffect, useRef, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useSearchParams
} from 'react-router-dom';

import {
  useGetOrgRequestsUnreadCountQuery
} from 'src/redux/openapi';
import {
  TableSearch,
  TableTabs,
  TableContentWrapper,
  TableHeader,
  TabInfo,
} from 'src/shared/components';
import {
  useDebouncedValue
} from 'src/shared/hooks';
import {
  managementTab
} from 'src/shared/utils';
import {
  ScoringFilters
} from 'src/widgets/QuestionScoring';

import {
  getTabsMap
} from './utils';
import {
  Tabs
} from './types';
import {
  GroupRequestsContent,
  GroupsContent,
  OrganisationsContent,
  QuestionScoringContent,
  SetManagementContent,
} from './ui';

export const OrgManagementTable: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [maxTableHeight, setMaxTableHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const currentTab = (searchParams.get(managementTab) as Tabs) || Tabs.AllOrganizations;

  const setCurrentTab = (tab: Tabs) => {
    searchParams.set(
      managementTab,
      tab
    );

    setSearchParams(searchParams);
  };

  const isOrganizations = currentTab === Tabs.AllOrganizations;
  const isGroupsRequests = currentTab === Tabs.GroupsRequests;
  const isGroups = currentTab === Tabs.UnaffiliatedGroups;
  const isQuestionsScoring = currentTab === Tabs.QuestionScoring;
  const isSetManagement = currentTab === Tabs.SetManagement;

  const {
    t
  } = useTranslation();

  const debouncedQuery = useDebouncedValue(
    searchQuery,
    500
  );

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const {
    data: requestsCountData, refetch: refetchRequestsCount
  } = useGetOrgRequestsUnreadCountQuery(
    undefined,
    {
      pollingInterval: 30000,
    }
  );

  const requestsCount = requestsCountData?.data.total;

  const onTabChange = (tabId: Tabs) => {
    setCurrentTab(tabId);
    refetchRequestsCount();
  };

  const calculateMaxHeight = () => {
    if (contentRef?.current && window) {
      const {
        top
      } = contentRef.current.getBoundingClientRect();

      const distance = window.innerHeight - top - 24;
      setMaxTableHeight(distance);
    }
  };

  useEffect(
    () => {
      calculateMaxHeight();

      window.addEventListener(
        'resize',
        calculateMaxHeight
      );

      return () => {
        window.removeEventListener(
          'resize',
          calculateMaxHeight
        );
      };
    },
    []
  );

  const tabs = getTabsMap();

  return (
    <TableContentWrapper
      ref={contentRef}
      style={{
        maxHeight: `${maxTableHeight}px`,
      }}
    >
      <TableHeader>
        <TableTabs
          tabs={tabs}
          onTabChange={onTabChange}
          renderInfo={(tabId) => (tabId === Tabs.GroupsRequests && !!requestsCount ? (
            <TabInfo>{requestsCount}</TabInfo>
          ) : null)}
          currentTab={currentTab}
        />

        <div className="flex gap-3 items-center justify-end">
          <TableSearch
            searchQuery={searchQuery}
            placeholder={t('common.startTyping')}
            handleSearch={handleSearch}
          />

          {isQuestionsScoring && <ScoringFilters isAdmin />}
        </div>
      </TableHeader>

      {isOrganizations && <OrganisationsContent searchQuery={debouncedQuery} />}

      {isGroupsRequests && (
        <GroupRequestsContent
          searchQuery={debouncedQuery}
          requestsCount={requestsCount}
        />
      )}

      {isGroups && <GroupsContent searchQuery={debouncedQuery} />}

      {isQuestionsScoring && (
        <QuestionScoringContent searchQuery={searchQuery} />
      )}

      {isSetManagement && <SetManagementContent searchQuery={debouncedQuery} />}
    </TableContentWrapper>
  );
};
