import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  Link
} from 'react-router-dom';

import {
  useTypedSelector
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';
import {
  Avatar, PopoverMenu
} from 'src/shared/components';
import {
  communityTab, ROUTE
} from 'src/shared/utils';
import {
  CommunityTabId
} from 'src/redux/community';

import * as Style from './MyProfileMenu.styles';
import {
  ActivityTabContent,
  BadgesTabContent,
  EditProfileMenu,
  MenuHeader,
} from './components';

export enum Tabs {
  Activity = 'Activity',
  Badges = 'Badges',
  // TODO: uncomment tabs after BE implementation
  // HistoryLog = 'History log',
}

export const TABS_MAP = [
  Tabs.Activity,
  Tabs.Badges,
  // Tabs.HistoryLog,
];

export const MyProfileMenu: FC = () => {
  const {
    t
  } = useTranslation();

  const [activeTab, setActiveTab] = useState(Tabs.Activity);
  const [isCloseButtonClicked, setIsCloseButtonClicked] = useState(false);
  const [isEditingActive, setIsEditingActive] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);

  const [summary, setSummary] = useState({
    total: 0,
  });

  const {
    avatarUrl: avatarSrc,
    name,
    displayName,
  } = useTypedSelector(userSelectors.user);

  return (
    <>
      <PopoverMenu
        placement="bottom-end"
        customShift={16}
        borderRadius={6}
        hideScrollBar
        applyFullHeight
        maxHeight={920}
        auto={false}
        buttonContent={(
          <Style.ButtonContent>
            <Avatar
              src={avatarSrc}
              userName={displayName || name}
              isClickable
            />
          </Style.ButtonContent>
        )}
        shouldForcedClose={isCloseButtonClicked}
        onClose={() => setIsCloseButtonClicked(false)}
        shouldCloseOnOutsideClick={!isActiveModal}
        buttonProps={{
          className: 'hidden md:block',
        }}
      >
        <Style.MainContainer
          $isEditingActive={isEditingActive}
          className="scroll-hidden"
        >
          {isEditingActive ? (
            <EditProfileMenu
              onEditClose={() => setIsEditingActive(false)}
              onMenuClose={() => setIsCloseButtonClicked(true)}
              onModalToggle={setIsActiveModal}
            />
          ) : (
            <>
              <Style.StickyContent>
                <MenuHeader
                  onClose={() => setIsCloseButtonClicked(true)}
                  onEdit={() => setIsEditingActive(true)}
                  onModalToggle={() => setIsActiveModal((prev) => !prev)}
                />

                <Style.SummaryContainer
                  to={`/${ROUTE.COMMUNITY}?${communityTab}=${CommunityTabId.Scored}`}
                >
                  <Style.SummaryTitle>
                    {t('profile.summaryPerformance')}
                  </Style.SummaryTitle>

                  <Style.SummaryNumber>{summary.total}</Style.SummaryNumber>
                </Style.SummaryContainer>

                <Style.Tabs>
                  {TABS_MAP.map((tab) => (
                    <Style.Tab
                      key={tab}
                      type="button"
                      onClick={() => setActiveTab(tab)}
                      $isActive={tab === activeTab}
                    >
                      {t(`profile.${tab}`)}
                    </Style.Tab>
                  ))}
                </Style.Tabs>
              </Style.StickyContent>

              <Style.TabContentWrapper className="scroll-hidden">
                {activeTab === Tabs.Activity && (
                  <ActivityTabContent
                    onSummaryChange={setSummary}
                    onModalToggle={setIsActiveModal}
                  />
                )}

                {activeTab === Tabs.Badges && <BadgesTabContent />}
              </Style.TabContentWrapper>
            </>
          )}
        </Style.MainContainer>
      </PopoverMenu>

      <Link
        to={`/${ROUTE.PROFILE}`}
        className="md:hidden"
      >
        <Avatar
          src={avatarSrc}
          userName={displayName || name}
          isClickable
        />
      </Link>
    </>
  );
};
