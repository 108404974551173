import {
  emptySplitApi as api
} from '../../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiUsersCurrent: build.query<
    GetApiUsersCurrentApiResponse,
    GetApiUsersCurrentApiArg
    >({
      query: () => ({
        url: `/api/users/current`,
      }),
    }),
    deleteApiUsersCurrent: build.mutation<
    DeleteApiUsersCurrentApiResponse,
    DeleteApiUsersCurrentApiArg
    >({
      query: () => ({
        url: `/api/users/current`,
        method: 'DELETE',
      }),
    }),
    patchApiUsersCurrent: build.mutation<
    PatchApiUsersCurrentApiResponse,
    PatchApiUsersCurrentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current`,
        method: 'PATCH',
        body: queryArg.updateCurrentUserBodySchema,
      }),
    }),
    getApiUsersCurrentTheme: build.query<
    GetApiUsersCurrentThemeApiResponse,
    GetApiUsersCurrentThemeApiArg
    >({
      query: () => ({
        url: `/api/users/current/theme`,
      }),
      providesTags: ['Theme'],
    }),
    putApiUsersCurrentTheme: build.mutation<
    PutApiUsersCurrentThemeApiResponse,
    PutApiUsersCurrentThemeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/theme`,
        method: 'PUT',
        body: queryArg.setCurrentUserThemeBodySchema,
      }),
      invalidatesTags: ['Theme'],
    }),
    getApiUsersCurrentAchievements: build.query<
    GetApiUsersCurrentAchievementsApiResponse,
    GetApiUsersCurrentAchievementsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/achievements`,
        params: {
          locked: queryArg.locked,
        },
      }),
    }),
    getApiUsersCurrentThemes: build.query<
    GetApiUsersCurrentThemesApiResponse,
    GetApiUsersCurrentThemesApiArg
    >({
      query: () => ({
        url: `/api/users/current/themes`,
      }),
    }),
    getApiUsersCurrentEmails: build.query<
    GetApiUsersCurrentEmailsApiResponse,
    GetApiUsersCurrentEmailsApiArg
    >({
      query: () => ({
        url: `/api/users/current/emails`,
      }),
    }),
    getApiUsersCurrentCharacteristics: build.query<
    GetApiUsersCurrentCharacteristicsApiResponse,
    GetApiUsersCurrentCharacteristicsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/characteristics`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    patchApiUsersCurrentCharacteristics: build.mutation<
    PatchApiUsersCurrentCharacteristicsApiResponse,
    PatchApiUsersCurrentCharacteristicsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/characteristics`,
        method: 'PATCH',
        body: queryArg.updateCurrentUserCharacteristicsBodySchema,
      }),
    }),
    getApiUsersCurrentActivity: build.query<
    GetApiUsersCurrentActivityApiResponse,
    GetApiUsersCurrentActivityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/activity`,
        params: {
          dateFrom: queryArg.dateFrom,
        },
      }),
    }),
    patchApiUsersCurrentLanguage: build.mutation<
    PatchApiUsersCurrentLanguageApiResponse,
    PatchApiUsersCurrentLanguageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/language`,
        method: 'PATCH',
        body: queryArg.updateCurrentUserLanguageCodeBodySchema,
      }),
    }),
    getApiUsersCurrentLanguage: build.query<
    GetApiUsersCurrentLanguageApiResponse,
    GetApiUsersCurrentLanguageApiArg
    >({
      query: () => ({
        url: `/api/users/current/language`,
      }),
    }),
    postApiUsersCurrentAvatar: build.mutation<
    PostApiUsersCurrentAvatarApiResponse,
    PostApiUsersCurrentAvatarApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/avatar`,
        method: 'POST',
        headers: {
          'content-length': queryArg['content-length'].toString(),
          'content-type': queryArg['content-type'],
        },
      }),
    }),
    getApiUsersEmailsUnsubscription: build.query<
    GetApiUsersEmailsUnsubscriptionApiResponse,
    GetApiUsersEmailsUnsubscriptionApiArg
    >({
      query: () => ({
        url: `/api/users/emails/unsubscription`,
      }),
    }),
    postApiUsersEmailsUnsubscription: build.mutation<
    PostApiUsersEmailsUnsubscriptionApiResponse,
    PostApiUsersEmailsUnsubscriptionApiArg
    >({
      query: () => ({
        url: `/api/users/emails/unsubscription`,
        method: 'POST',
      }),
    }),
    getApiUsersEmailsVerifications: build.query<
    GetApiUsersEmailsVerificationsApiResponse,
    GetApiUsersEmailsVerificationsApiArg
    >({
      query: () => ({
        url: `/api/users/emails/verifications`,
      }),
    }),
    postApiUsersEmailsVerifications: build.mutation<
    PostApiUsersEmailsVerificationsApiResponse,
    PostApiUsersEmailsVerificationsApiArg
    >({
      query: () => ({
        url: `/api/users/emails/verifications`,
        method: 'POST',
      }),
    }),
    postApiUsersByIdBans: build.mutation<
    PostApiUsersByIdBansApiResponse,
    PostApiUsersByIdBansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg.id}/bans`,
        method: 'POST',
        body: queryArg.userUpdateBanBodySchema,
      }),
    }),
    getApiUsersGlobalSearch: build.query<
    GetApiUsersGlobalSearchApiResponse,
    GetApiUsersGlobalSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/global-search`,
        params: {
          query: queryArg.query,
        },
      }),
    }),
    postApiUsersCurrentPassword: build.mutation<
    PostApiUsersCurrentPasswordApiResponse,
    PostApiUsersCurrentPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/password`,
        method: 'POST',
        body: queryArg.changePasswordBodySchema,
      }),
    }),
    postApiUsersCurrentForgotPasswordRequest: build.mutation<
    PostApiUsersCurrentForgotPasswordRequestApiResponse,
    PostApiUsersCurrentForgotPasswordRequestApiArg
    >({
      query: () => ({
        url: `/api/users/current/forgot-password/request`,
        method: 'POST',
      }),
    }),
    postApiUsersCurrentForgotPasswordVerify: build.mutation<
    PostApiUsersCurrentForgotPasswordVerifyApiResponse,
    PostApiUsersCurrentForgotPasswordVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/forgot-password/verify`,
        method: 'POST',
        body: queryArg.verifyForgotPasswordBodySchema,
      }),
    }),
    postApiUsersCurrentForgotPasswordResend: build.mutation<
    PostApiUsersCurrentForgotPasswordResendApiResponse,
    PostApiUsersCurrentForgotPasswordResendApiArg
    >({
      query: () => ({
        url: `/api/users/current/forgot-password/resend`,
        method: 'POST',
      }),
    }),
    postApiUsersCurrentReverificationsResend: build.mutation<
    PostApiUsersCurrentReverificationsResendApiResponse,
    PostApiUsersCurrentReverificationsResendApiArg
    >({
      query: () => ({
        url: `/api/users/current/reverifications/resend`,
        method: 'POST',
      }),
    }),
    postApiUsersCurrentVerificationsResend: build.mutation<
    PostApiUsersCurrentVerificationsResendApiResponse,
    PostApiUsersCurrentVerificationsResendApiArg
    >({
      query: () => ({
        url: `/api/users/current/verifications/resend`,
        method: 'POST',
      }),
    }),
    getApiUsersCurrentVerificationsResendCountdown: build.query<
    GetApiUsersCurrentVerificationsResendCountdownApiResponse,
    GetApiUsersCurrentVerificationsResendCountdownApiArg
    >({
      query: () => ({
        url: `/api/users/current/verifications/resend/countdown`,
      }),
    }),
    postApiUsersCurrentEmailCommunicationUnsubscription: build.mutation<
    PostApiUsersCurrentEmailCommunicationUnsubscriptionApiResponse,
    PostApiUsersCurrentEmailCommunicationUnsubscriptionApiArg
    >({
      query: () => ({
        url: `/api/users/current/email-communication/unsubscription`,
        method: 'POST',
      }),
    }),
    putApiUsersCurrentEmailCommunicationFrequency: build.mutation<
    PutApiUsersCurrentEmailCommunicationFrequencyApiResponse,
    PutApiUsersCurrentEmailCommunicationFrequencyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/email-communication/frequency`,
        method: 'PUT',
        body: queryArg.updateCurrentUserEmailCommunicationFrequencyBodySchema,
      }),
    }),
    postApiUsersReports: build.mutation<
    PostApiUsersReportsApiResponse,
    PostApiUsersReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/reports`,
        method: 'POST',
        body: queryArg.reportUserBodySchema,
      }),
    }),
    getApiUsersSessionsRedirect: build.query<
    GetApiUsersSessionsRedirectApiResponse,
    GetApiUsersSessionsRedirectApiArg
    >({
      query: () => ({
        url: `/api/users/sessions/redirect`,
      }),
    }),
    postApiUsersCurrentFollowingsByFolloweeId: build.mutation<
    PostApiUsersCurrentFollowingsByFolloweeIdApiResponse,
    PostApiUsersCurrentFollowingsByFolloweeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/followings/${queryArg.followeeId}`,
        method: 'POST',
      }),
    }),
    deleteApiUsersCurrentFollowingsByFolloweeId: build.mutation<
    DeleteApiUsersCurrentFollowingsByFolloweeIdApiResponse,
    DeleteApiUsersCurrentFollowingsByFolloweeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/followings/${queryArg.followeeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Followings'],
    }),
    getApiUsersCurrentFollowings: build.query<
    GetApiUsersCurrentFollowingsApiResponse,
    GetApiUsersCurrentFollowingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/followings`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          sortOrder: queryArg.sortOrder,
        },
      }),
      providesTags: ['Followings'],
    }),
    getApiUsersCurrentFollowingsByFolloweeIdComments: build.query<
    GetApiUsersCurrentFollowingsByFolloweeIdCommentsApiResponse,
    GetApiUsersCurrentFollowingsByFolloweeIdCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/followings/${queryArg.followeeId}/comments`,
        params: {
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiUsers
};
export type GetApiUsersCurrentApiResponse =
  /** status 200 Default Response */ CurrentUserResponseSchema;
export type GetApiUsersCurrentApiArg = void;
export type DeleteApiUsersCurrentApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiUsersCurrentApiArg = void;
export type PatchApiUsersCurrentApiResponse =
  /** status 200 Default Response */ UpdateCurrentUserResponseSchema;
export type PatchApiUsersCurrentApiArg = {
  updateCurrentUserBodySchema: UpdateCurrentUserBodySchema;
};
export type GetApiUsersCurrentThemeApiResponse =
  /** status 200 Default Response */ CurrentUserThemeResponseSchema;
export type GetApiUsersCurrentThemeApiArg = void;
export type PutApiUsersCurrentThemeApiResponse =
  /** status 200 Default Response */ SetCurrentUserThemeResponseSchema;
export type PutApiUsersCurrentThemeApiArg = {
  setCurrentUserThemeBodySchema: SetCurrentUserThemeBodySchema;
};
export type GetApiUsersCurrentAchievementsApiResponse =
  /** status 200 Default Response */ CurrentUserAchievementsResponseSchema;
export type GetApiUsersCurrentAchievementsApiArg = {
  locked?: boolean;
};
export type GetApiUsersCurrentThemesApiResponse =
  /** status 200 Default Response */ CurrentUserThemesResponseSchema;
export type GetApiUsersCurrentThemesApiArg = void;
export type GetApiUsersCurrentEmailsApiResponse =
  /** status 200 Default Response */ CurrentUserEmailsResponseSchema;
export type GetApiUsersCurrentEmailsApiArg = void;
export type GetApiUsersCurrentCharacteristicsApiResponse =
  /** status 200 Default Response */ GetCurrentUserCharacteristicsResponseSchema;
export type GetApiUsersCurrentCharacteristicsApiArg = {
  page?: number;
  limit?: number;
};
export type PatchApiUsersCurrentCharacteristicsApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiUsersCurrentCharacteristicsApiArg = {
  updateCurrentUserCharacteristicsBodySchema: UpdateCurrentUserCharacteristicsBodySchema;
};
export type GetApiUsersCurrentActivityApiResponse =
  /** status 200 Default Response */ UserActivityResponseSchema;
export type GetApiUsersCurrentActivityApiArg = {
  dateFrom?: string;
};
export type PatchApiUsersCurrentLanguageApiResponse =
  /** status 200 Default Response */ UpdateCurrentUserLanguageCodeResponseSchema;
export type PatchApiUsersCurrentLanguageApiArg = {
  updateCurrentUserLanguageCodeBodySchema: UpdateCurrentUserLanguageCodeBodySchema;
};
export type GetApiUsersCurrentLanguageApiResponse =
  /** status 200 Default Response */ GetCurrentUserLanguageCodeResponseSchema;
export type GetApiUsersCurrentLanguageApiArg = void;
export type PostApiUsersCurrentAvatarApiResponse =
  /** status 200 Default Response */ AvatarUploadResponseSchema;
export type PostApiUsersCurrentAvatarApiArg = {
  'content-length': number;
  'content-type': string;
};
export type GetApiUsersEmailsUnsubscriptionApiResponse = unknown;
export type GetApiUsersEmailsUnsubscriptionApiArg = void;
export type PostApiUsersEmailsUnsubscriptionApiResponse =
  /** status 200 Default Response */ UnsubscribeUserEmailCommunicationResponseSchema;
export type PostApiUsersEmailsUnsubscriptionApiArg = void;
export type GetApiUsersEmailsVerificationsApiResponse = unknown;
export type GetApiUsersEmailsVerificationsApiArg = void;
export type PostApiUsersEmailsVerificationsApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiUsersEmailsVerificationsApiArg = void;
export type PostApiUsersByIdBansApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiUsersByIdBansApiArg = {
  id: string;
  userUpdateBanBodySchema: UserUpdateBanBodySchema;
};
export type GetApiUsersGlobalSearchApiResponse =
  /** status 200 Default Response */ UserGlobalSearchResponseSchema;
export type GetApiUsersGlobalSearchApiArg = {
  query?: string;
};
export type PostApiUsersCurrentPasswordApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiUsersCurrentPasswordApiArg = {
  changePasswordBodySchema: ChangePasswordBodySchema;
};
export type PostApiUsersCurrentForgotPasswordRequestApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiUsersCurrentForgotPasswordRequestApiArg = void;
export type PostApiUsersCurrentForgotPasswordVerifyApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiUsersCurrentForgotPasswordVerifyApiArg = {
  verifyForgotPasswordBodySchema: VerifyForgotPasswordBodySchema;
};
export type PostApiUsersCurrentForgotPasswordResendApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiUsersCurrentForgotPasswordResendApiArg = void;
export type PostApiUsersCurrentReverificationsResendApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiUsersCurrentReverificationsResendApiArg = void;
export type PostApiUsersCurrentVerificationsResendApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiUsersCurrentVerificationsResendApiArg = void;
export type GetApiUsersCurrentVerificationsResendCountdownApiResponse =
  /** status 200 Default Response */ FindVerificationEmailResendCountdownResponseSchema;
export type GetApiUsersCurrentVerificationsResendCountdownApiArg = void;
export type PostApiUsersCurrentEmailCommunicationUnsubscriptionApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiUsersCurrentEmailCommunicationUnsubscriptionApiArg = void;
export type PutApiUsersCurrentEmailCommunicationFrequencyApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiUsersCurrentEmailCommunicationFrequencyApiArg = {
  updateCurrentUserEmailCommunicationFrequencyBodySchema: UpdateCurrentUserEmailCommunicationFrequencyBodySchema;
};
export type PostApiUsersReportsApiResponse =
  /** status 201 Default Response */ MessageResponseSchema;
export type PostApiUsersReportsApiArg = {
  reportUserBodySchema: ReportUserBodySchema;
};
export type GetApiUsersSessionsRedirectApiResponse = unknown;
export type GetApiUsersSessionsRedirectApiArg = void;
export type PostApiUsersCurrentFollowingsByFolloweeIdApiResponse =
  /** status 201 Default Response */ MessageResponseSchema;
export type PostApiUsersCurrentFollowingsByFolloweeIdApiArg = {
  followeeId: string;
};
export type DeleteApiUsersCurrentFollowingsByFolloweeIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiUsersCurrentFollowingsByFolloweeIdApiArg = {
  followeeId: string;
};
export type GetApiUsersCurrentFollowingsApiResponse =
  /** status 200 Default Response */ GetCurrentUserFollowingsResponseSchema;
export type GetApiUsersCurrentFollowingsApiArg = {
  page?: Page;
  limit?: Limit;
  sortOrder?: 'asc' | 'desc';
};
export type GetApiUsersCurrentFollowingsByFolloweeIdCommentsApiResponse =
  /** status 200 Default Response */ GetCurrentUserFolloweeCommentsResponseSchema;
export type GetApiUsersCurrentFollowingsByFolloweeIdCommentsApiArg = {
  sortBy?: 'createdAt' | 'commendationsCount' | 'commentsCount';
  sortOrder?: SortOrder;
  startDate?: string;
  endDate?: string;
  page?: number;
  limit?: number;
  followeeId: string;
};
export type CurrentUserResponseSchema = {
  data: {
    user: {
      id: string;
      name: string;
      email: string;
      avatarUrl: string | null;
      displayName: string | null;
      description: string | null;
      role: 'ADMIN' | 'USER';
      fullName: string | null;
    };
  };
};
export type MessageResponseSchema = {
  message: string;
};
export type User = {
  id: string;
  name: string;
  email: string;
  avatarUrl: string | null;
  displayName: string | null;
  description: string | null;
  role: 'ADMIN' | 'USER';
  fullName: string | null;
};
export type UpdateCurrentUserResponseSchema = {
  message: string;
  data: {
    user: User;
  };
};
export type UpdateCurrentUserBodySchema = {
  name?: string;
  email?: string;
  displayName?: string;
  description?: string;
  fullName?: string;
};
export type CurrentUserThemeResponseSchema = {
  message: string;
  data: {
    theme: {
      colorPrimary: string;
      logoUrl: string | null;
    } | null;
  };
};
export type SetCurrentUserThemeResponseSchema = {
  message: string;
  data: {
    theme: {
      name: string;
      colorPrimary: string;
      organization: {
        logoUrl: string | null;
      } | null;
    };
  };
};
export type SetCurrentUserThemeBodySchema = {
  themeId: string;
};
export type CurrentUserAchievementsResponseSchema = {
  message: string;
  data: {
    achievements: {
      userId: string;
      id: string;
      type: 'CALIBRATED';
      createdAt: string;
      updatedAt: string;
      achievement: {
        description: string;
        descriptionDe: string;
      };
    }[];
    lockedAchievements:
    | {
      type: 'CALIBRATED';
      createdAt: string;
      updatedAt: string;
      description: string;
      descriptionDe: string;
    }[]
    | null;
  };
};
export type CurrentUserThemesResponseSchema = {
  message: string;
  data: {
    themes: {
      id: string;
      isDefault: null | true;
      isActive: boolean;
      colorPrimary: string;
      name: string;
    }[];
  };
};
export type CurrentUserEmailsResponseSchema = {
  message: string;
  data: {
    emails: {
      email: string;
    }[];
    emailCommunication: {
      status: ('SENT' | 'FAILED') | null;
      emailFrequency: 'DAILY' | 'WEEKLY' | 'ONCE_TWO_WEEKS';
      enabled: boolean;
      lastSentAt: string | null;
    } | null;
  };
};
export type GetCurrentUserCharacteristicsResponseSchema = {
  message: string;
  data: {
    characteristics: {
      lastModifiedAt: string | null;
      isPending: boolean;
      key: {
        id: string;
        name: string;
        isMutable: boolean;
      };
      value: {
        id: string;
        value: string;
      };
    }[];
    total: number;
  };
};
export type UpdateCurrentUserCharacteristicsBodySchema = {
  characteristics: {
    keyId: string;
    valueId: string | null;
  }[];
};
export type UserActivityResponseSchema = {
  message: string;
  data: {
    summary: {
      total: number;
    };
    active: {
      total: number;
      user: number;
    };
    scored: {
      total: number;
      user: number;
    };
  };
};
export type UpdateCurrentUserLanguageCodeResponseSchema = {
  message: string;
  data: {
    languageCode: string;
  };
};
export type UpdateCurrentUserLanguageCodeBodySchema = {
  languageCode: 'en' | 'de';
};
export type GetCurrentUserLanguageCodeResponseSchema = {
  data: {
    languageCode: string;
  };
};
export type AvatarUploadResponseSchema = {
  message: string;
  data: {
    user: User;
  };
};
export type UnsubscribeUserEmailCommunicationResponseSchema = {
  message: string;
  data: {
    user: {
      id: string;
      email: string | null;
    };
  };
};
export type UserUpdateBanBodySchema = {
  banned: boolean;
};
export type UserGlobalSearchResponseSchema = {
  message: string;
  data: {
    groups: {
      name: string;
      id: string;
      status: 'ACTIVATED' | 'DEACTIVATED';
      location: string | null;
      _count: {
        users: number;
      };
    }[];
    questions: {
      questionSet: {
        id: string;
        title: string;
      };
      title: string;
      id: string;
      startDate: string;
      endDate: string;
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
    }[];
  };
};
export type ChangePasswordBodySchema = {
  oldPassword: string;
  newPassword: string;
};
export type VerifyForgotPasswordBodySchema = {
  code: string;
};
export type FindVerificationEmailResendCountdownResponseSchema = {
  data: {
    seconds: number;
    nextAvailableDate: null | string;
  };
};
export type UpdateCurrentUserEmailCommunicationFrequencyBodySchema = {
  emailFrequency: 'DAILY' | 'WEEKLY' | 'ONCE_TWO_WEEKS';
};
export type ReportUserBodySchema = {
  userId: string;
  option: 'LEAKAGE' | 'INAPPROPRIATE' | 'SPAM' | 'OTHER';
  comment?: string;
};
export type GetCurrentUserFollowingsResponseSchema = {
  message: string;
  data: {
    total: number;
    followings: {
      id: string;
      name: string;
      displayName: string | null;
      avatarUrl: string | null;
      commentsCount: number;
      forecastsCount: number;
    }[];
  };
};
export type Page = number;
export type Limit = number;
export type Author = {
  id: string;
  name: string;
  avatarUrl: string | null;
  displayName: string | null;
  isFollowed: boolean;
};
export type GetCurrentUserFolloweeCommentsResponseSchema = {
  message: string;
  data: {
    total: number;
    comments: {
      id: string;
      createdAt: string;
      updatedAt: string;
      text: string;
      isPrivate: boolean;
      commendationsCount: number;
      userCommendation:
      | ('LIKE' | 'RELEVANT' | 'INSIGHTFUL' | 'INSPIRING')
      | null;
      commendationTypes:
      | ('LIKE' | 'RELEVANT' | 'INSIGHTFUL' | 'INSPIRING')[]
      | null;
      author: {
        id: string;
        name: string;
        avatarUrl: string | null;
        displayName: string | null;
        isFollowed: boolean;
      };
      forecast: {
        id: string;
        estimates: {
          title: string;
          value: number;
        }[];
      } | null;
      question: {
        id: string;
        title: string;
        hasUserOnboarded?: boolean;
      };
      commentsCount: number;
      comments: {
        id: string;
        createdAt: string;
        updatedAt: string;
        text: string;
        author: Author;
        likes: number;
        isLiked: boolean;
      }[];
      estimatesDiff: number | null;
    }[];
  };
};
export type SortOrder = 'asc' | 'desc';
export const {
  useGetApiUsersCurrentQuery,
  useDeleteApiUsersCurrentMutation,
  usePatchApiUsersCurrentMutation,
  useGetApiUsersCurrentThemeQuery,
  usePutApiUsersCurrentThemeMutation,
  useGetApiUsersCurrentAchievementsQuery,
  useGetApiUsersCurrentThemesQuery,
  useGetApiUsersCurrentEmailsQuery,
  useGetApiUsersCurrentCharacteristicsQuery,
  usePatchApiUsersCurrentCharacteristicsMutation,
  useGetApiUsersCurrentActivityQuery,
  usePatchApiUsersCurrentLanguageMutation,
  useGetApiUsersCurrentLanguageQuery,
  usePostApiUsersCurrentAvatarMutation,
  useGetApiUsersEmailsUnsubscriptionQuery,
  usePostApiUsersEmailsUnsubscriptionMutation,
  useGetApiUsersEmailsVerificationsQuery,
  usePostApiUsersEmailsVerificationsMutation,
  usePostApiUsersByIdBansMutation,
  useGetApiUsersGlobalSearchQuery,
  usePostApiUsersCurrentPasswordMutation,
  usePostApiUsersCurrentForgotPasswordRequestMutation,
  usePostApiUsersCurrentForgotPasswordVerifyMutation,
  usePostApiUsersCurrentForgotPasswordResendMutation,
  usePostApiUsersCurrentReverificationsResendMutation,
  usePostApiUsersCurrentVerificationsResendMutation,
  useGetApiUsersCurrentVerificationsResendCountdownQuery,
  usePostApiUsersCurrentEmailCommunicationUnsubscriptionMutation,
  usePutApiUsersCurrentEmailCommunicationFrequencyMutation,
  usePostApiUsersReportsMutation,
  useGetApiUsersSessionsRedirectQuery,
  usePostApiUsersCurrentFollowingsByFolloweeIdMutation,
  useDeleteApiUsersCurrentFollowingsByFolloweeIdMutation,
  useGetApiUsersCurrentFollowingsQuery,
  useGetApiUsersCurrentFollowingsByFolloweeIdCommentsQuery,
} = injectedRtkApi;
