import React, {
  FC, useMemo
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  getColumnWidths,
  getColumnHeaders,
  FilterBy,
} from 'src/entities/Questions';
import {
  ModalUpdateForecast,
  ScrollContainer,
  Spinner,
  Table,
  TableFooter,
  TableHeader,
} from 'src/shared/components';
import {
  useTypedSelector
} from 'src/redux';
import {
  homePreferencesSelectors
} from 'src/redux/homePreferences';

import {
  EmptyResultsMessage
} from '../EmptyResultsMessage';
import {
  useGetQuestions, useUpdateModal
} from '../../hooks';

import * as Style from './TableView.styles';
import {
  TableHeaderTabs
} from './TableHeaderTabs';
import {
  TableRow
} from './TableRow';

interface TableViewProps {
  isExpanded: boolean;
}

export const TableView: FC<TableViewProps> = ({
  isExpanded
}) => {
  const {
    t
  } = useTranslation();

  const {
    activeConfig, availableConfig, isLoading, onActiveQuestionUpdate
  } = useGetQuestions();

  const {
    filterBy, timeRange
  } = useTypedSelector(
    homePreferencesSelectors.selectAllPreferences,
  );

  const {
    isUpdateModalOpen, updatedCard, toggleUpdateModal
  } = useUpdateModal();

  const activeTab = filterBy || FilterBy.Active;

  const isFiltersApplied = !!timeRange;

  const isExtendedTable = activeTab !== FilterBy.Available;

  const visibleQuestions = useMemo(
    () => {
      switch (activeTab) {
        case FilterBy.Active:
          return activeConfig;
        case FilterBy.Available:
        default:
          return availableConfig;
      }
    },
    [activeTab, availableConfig, activeConfig]
  );

  const {
    questions = [],
    total = 0,
    limit = 0,
    currentPage = 1,
    setCurrentPage,
  } = visibleQuestions || {};

  const columnWidths = getColumnWidths(
    isExpanded,
    isExtendedTable
  );

  const columnHeaders = getColumnHeaders(isExtendedTable);

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderTabs />
        </TableHeader>

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {!questions.length && (
              <>
                {isFiltersApplied && <EmptyResultsMessage />}

                {!isFiltersApplied && (
                  <Style.EmptyQuestionsMessage>
                    {t('empty.thereAreNoQuestions')}
                  </Style.EmptyQuestionsMessage>
                )}
              </>
            )}

            {!!questions.length && (
              <ScrollContainer className="scroll-hidden">
                <Style.TableContainer $applyMinWidth={isExtendedTable}>
                  <Style.TableHead>
                    <tr>
                      {columnHeaders.map(({
                        id, label
                      }, index) => (
                        <Style.ColumnHeader
                          key={id}
                          $width={columnWidths[index]}
                        >
                          {label}
                        </Style.ColumnHeader>
                      ))}
                    </tr>
                  </Style.TableHead>

                  <tbody className="overflow-auto">
                    {questions.map((row) => (
                      <TableRow
                        key={row.id}
                        onUpdate={toggleUpdateModal}
                        row={row}
                        isExtendedTable={isExtendedTable}
                      />
                    ))}
                  </tbody>
                </Style.TableContainer>
              </ScrollContainer>
            )}

            {total > limit && (
              <TableFooter
                totalCount={total}
                pageSize={limit}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            )}
          </>
        )}
      </Table>

      {isUpdateModalOpen && updatedCard && (
        <ModalUpdateForecast
          updatedCard={updatedCard}
          closeModalHandler={() => toggleUpdateModal()}
          isModalOpen={isUpdateModalOpen}
          refetchQuestions={() => onActiveQuestionUpdate(updatedCard.id)}
        />
      )}
    </>
  );
};
