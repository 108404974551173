import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  RadialСheckFalseIcon, RadialСheckTrueIcon
} from 'src/shared/icons';
import {
  FilterBy
} from 'src/entities/Questions';

import * as Style from './Menus.styles';

const FILTER_BY_MAP = [
  {
    id: null,
  },
  {
    id: FilterBy.Available,
  },
  {
    id: FilterBy.Active,
  },
];

interface FilterByMenuProps {
  onSelect: (value: FilterBy | null) => void;
  currentValue: FilterBy | null;
  withAvailable?: boolean;
}

export const FilterByMenu: FC<FilterByMenuProps> = ({
  onSelect,
  currentValue,
  withAvailable = true,
}) => {
  const {
    t
  } = useTranslation();

  const visibleFilters = withAvailable
    ? FILTER_BY_MAP
    : FILTER_BY_MAP.filter((option) => option.id !== FilterBy.Available);

  return (
    <Style.MenuContainer>
      <Style.MenuTitle>{t('filters.showMe')}</Style.MenuTitle>

      {visibleFilters.map(({
        id
      }) => (
        <Style.MenuCheckboxItem
          key={id}
          type="button"
          onClick={() => onSelect(id)}
        >
          {id ? t(`filters.${id}`) : t('filters.all')}

          {currentValue === id ? (
            <RadialСheckTrueIcon />
          ) : (
            <RadialСheckFalseIcon />
          )}
        </Style.MenuCheckboxItem>
      ))}
    </Style.MenuContainer>
  );
};
