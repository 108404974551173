import {
  useEffect, useMemo
} from 'react';

import {
  getFilteredTimeRange, showWarningFromServer
} from 'src/shared/utils';
import {
  getQuestionSortBy
} from 'src/widgets/QuestionsFilter/utils';
import {
  HomeQuestionSortBy
} from 'src/shared/api/types';
import {
  useGetQuestionStatisticsActiveMutation,
  useGetActiveStatisticsActiveQuery,
  useGetAvailableStatisticsQuery,
  QuestionsUpdatesPer,
} from 'src/redux/openapi';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  homePreferencesSelectors,
  setHomeActiveQuestionsPage,
  setHomeAvailableQuestionsPage,
} from 'src/redux/homePreferences';

import {
  FilterBy, SortBy
} from '../types';

const TABLE_VIEW_LIMIT = 8;
const AVAILABLE_LIMIT = 6;
const ACTIVE_LIMIT = 4;

export const useGetQuestions = () => {
  const {
    filterBy,
    sortBy: sortParam,
    timeRange: timeRangeParam,
    isTableView,
    activeFilterId,
    activeQuestionsPage: currentActivePage,
    availableQuestionsPage: currentAvailablePage,
    isFavorites: isFavoriteFilter,
  } = useTypedSelector(homePreferencesSelectors.selectAllPreferences);

  const filterParam = filterBy || (isTableView ? FilterBy.Active : null);

  const dispatch = useTypedDispatch();

  const setCurrentActivePage = (value: number) => dispatch(setHomeActiveQuestionsPage(value));

  const setCurrentAvailablePage = (value: number) => dispatch(setHomeAvailableQuestionsPage(value));

  const questionFilters = useMemo(
    () => {
      const sortByResult = getQuestionSortBy<HomeQuestionSortBy>(
        sortParam || SortBy.Newest,
        true,
      );

      const updatesPer = timeRangeParam
        ? (getFilteredTimeRange(timeRangeParam) as QuestionsUpdatesPer)
        : undefined;

      return {
        ...sortByResult,
        updatesPer,
      };
    },
    [sortParam, timeRangeParam]
  );

  const activeLimit = isTableView ? TABLE_VIEW_LIMIT : ACTIVE_LIMIT;
  const skipActive = !!filterParam && filterParam !== FilterBy.Active;

  const {
    data: activeQuestionsData,
    isLoading: isActiveQuestionsLoading,
    isFetching: isActiveDataFetching,
    refetch: refetchActive,
    isUninitialized: isActiveUninitialized,
  } = useGetActiveStatisticsActiveQuery(
    {
      filters: {
        questionSetId:
          activeFilterId && !isFavoriteFilter ? activeFilterId : undefined,
        favorite: isFavoriteFilter || undefined,
        ...questionFilters,
      },
      page: currentActivePage,
      limit: activeLimit,
    },
    {
      skip: skipActive,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(
    () => {
      const shouldResetPage = !isActiveUninitialized
      && !isActiveQuestionsLoading
      && !isActiveDataFetching
      && currentActivePage !== 1
      && !activeQuestionsData?.data.statistics.length;

      if (shouldResetPage) {
        setCurrentActivePage(1);
      }
    },
    [
      isActiveUninitialized,
      isActiveQuestionsLoading,
      activeQuestionsData,
      currentActivePage,
      isActiveDataFetching,
    ]
  );

  const [updateQuestionData] = useGetQuestionStatisticsActiveMutation();

  const onActiveQuestionUpdate = async (questionId: string) => {
    try {
      await updateQuestionData({
        id: questionId,
        filters: {
          updatesPer: questionFilters.updatesPer,
        },
      }).unwrap();

      refetchActive();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const activeConfig = useMemo(
    () => {
      const {
        total, statistics
      } = activeQuestionsData?.data || {
        total: 0,
        statistics: [],
      };

      return {
        total: skipActive ? 0 : total,
        questions: skipActive ? [] : statistics,
        limit: activeLimit,
        currentPage: currentActivePage,
        setCurrentPage: setCurrentActivePage,
      };
    },
    [activeQuestionsData, skipActive, currentActivePage]
  );

  const availableLimit = isTableView ? TABLE_VIEW_LIMIT : AVAILABLE_LIMIT;

  const skipAvailable = (filterParam && filterParam !== FilterBy.Available) || isFavoriteFilter;

  const {
    data: availableQuestionsData,
    isLoading: isAvailableQuestionsLoading,
    isFetching: isAvailableDataFetching,
    isUninitialized: isAvailableUninitialized,
  } = useGetAvailableStatisticsQuery(
    {
      filters: {
        questionSetId: activeFilterId || undefined,
        ...questionFilters,
      },
      page: currentAvailablePage,
      limit: availableLimit,
    },
    {
      skip: skipAvailable,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(
    () => {
      const shouldResetPage = !isAvailableUninitialized
      && !isAvailableQuestionsLoading
      && !isAvailableDataFetching
      && currentAvailablePage !== 1
      && !availableQuestionsData?.data.statistics.length;

      if (shouldResetPage) {
        setCurrentAvailablePage(1);
      }
    },
    [
      isAvailableUninitialized,
      isAvailableQuestionsLoading,
      isAvailableDataFetching,
      availableQuestionsData,
      currentAvailablePage,
    ]
  );

  const availableConfig = useMemo(
    () => {
      const {
        total, statistics
      } = availableQuestionsData?.data || {
        total: 0,
        statistics: [],
      };

      return {
        total: skipAvailable ? 0 : total,
        questions: skipAvailable ? [] : statistics,
        limit: availableLimit,
        currentPage: currentAvailablePage,
        setCurrentPage: setCurrentAvailablePage,
      };
    },
    [availableQuestionsData, currentAvailablePage, skipAvailable]
  );

  const isLoading = isAvailableQuestionsLoading || isActiveQuestionsLoading;

  return {
    availableConfig,
    activeConfig,
    isLoading,
    onActiveQuestionUpdate,
  };
};

export type UseGetQuestions = ReturnType<typeof useGetQuestions>;
