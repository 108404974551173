import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

import {
  Variant
} from './types';

export const MemberRoleWrapper = styled.div<{ $variant: Variant }>`
  ${tw`flex gap-2 mb-3`}

  ${({
    $variant
  }) => ($variant === 'vertical' ? tw`flex-col` : tw`items-center`)}
`;

export const MemberRole = styled.p<{ $variant: Variant }>`
  ${tw`min-w-14 font-medium text-xs text-dim-gray`}

  ${({
    $variant
  }) => $variant === 'vertical' && tw`px-3`}
`;
