import React, {
  useEffect, useState
} from 'react';
import {
  flexRender,
  TableOptions,
  useReactTable,
  getCoreRowModel,
  Row,
  ExpandedState,
  getExpandedRowModel,
} from '@tanstack/react-table';

import * as Style from './TableContent.styles';

interface TableContentProps<T>
  extends Pick<TableOptions<T>, 'data' | 'columns'> {
  renderRow: (row: Row<T>, index: number) => React.ReactNode;
  renderExpandedContent?: (row: Row<T>) => React.ReactNode;
  resetExpandedKeys?: unknown[];
  fixedLayout?: boolean;
}

export const TableContent = <T,>({
  data,
  columns,
  renderRow,
  renderExpandedContent,
  resetExpandedKeys = [],
  fixedLayout,
}: TableContentProps<T>) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    getCoreRowModel: getCoreRowModel(),
    columns,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      expanded,
    },
  });

  useEffect(
    () => {
      setExpanded({});
    },
    [...resetExpandedKeys]
  );

  return (
    <Style.TableWrapper className="scroll-hidden">
      <Style.TableContainer $fixedLayout={fixedLayout}>
        <Style.TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Style.HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Style.HeaderCell
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: `${header.getSize()}px`,
                  }}
                >
                  {!header.isPlaceholder
                    && flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                </Style.HeaderCell>
              ))}
            </Style.HeaderRow>
          ))}
        </Style.TableHead>

        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <React.Fragment key={row.id}>
              {renderRow(
                row,
                index
              )}

              {!!renderExpandedContent
                && row.getIsExpanded()
                && renderExpandedContent(row)}
            </React.Fragment>
          ))}
        </tbody>
      </Style.TableContainer>
    </Style.TableWrapper>
  );
};
