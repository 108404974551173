import React, {
  FC, useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  useGetOrgGroupsInvitationsQuery
} from 'src/redux/openapi';
import {
  EmptyResultsMessage,
  Spinner,
  Table,
  TableContentStyles,
  TableFooter,
} from 'src/shared/components';
import {
  usePaginationSettings
} from 'src/shared/components/Pagination';

import {
  GroupWithUsersCard
} from '../GroupWithUsersCard';

import {
  getInvitedCols
} from './utils';

interface InvitedTabContentProps {
  searchQuery: string;
  organizationId: string;
  onInvitedUpdate: () => void;
}

export const InvitedTabContent: FC<InvitedTabContentProps> = ({
  searchQuery,
  organizationId,
  onInvitedUpdate,
}) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const {
    t
  } = useTranslation();

  const {
    currentPage, setCurrentPage, pageSize, onPageSizeChange
  } = usePaginationSettings(searchQuery);

  const {
    data: groupsData,
    isLoading,
    refetch,
  } = useGetOrgGroupsInvitationsQuery(
    {
      id: organizationId,
      query: searchQuery,
      limit: pageSize,
      page: currentPage,
    },
    {
      skip: !organizationId,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalGroups, groups: currentGroups
  } = groupsData?.data || {
    total: 0,
    groups: [],
  };

  useEffect(
    () => {
      if (!currentGroups.length && currentPage > 1) {
        setCurrentPage((prev) => prev - 1);
      }
    },
    [currentGroups]
  );

  const onGroupDelete = () => {
    refetch();
  };

  const columns = getInvitedCols();

  const table = useReactTable({
    data: currentGroups,
    getCoreRowModel: getCoreRowModel(),
    columns,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      expanded,
    },
  });

  const tableRows = table.getRowModel().rows;

  if (isLoading) {
    return <Spinner />;
  }

  if (!tableRows.length) {
    return (
      <EmptyResultsMessage
        results={t('group.Groups').toLowerCase()}
        withSearchQuery={!!searchQuery}
      />
    );
  }

  return (
    <>
      <Table>
        <TableContentStyles.TableWrapper className="scroll-hidden">
          <TableContentStyles.TableContainer>
            <TableContentStyles.TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableContentStyles.HeaderRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableContentStyles.HeaderCell
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        width: `${header.getSize()}px`,
                      }}
                    >
                      {!header.isPlaceholder
                        && flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    </TableContentStyles.HeaderCell>
                  ))}
                </TableContentStyles.HeaderRow>
              ))}
            </TableContentStyles.TableHead>

            <tbody>
              {tableRows.map((row) => (
                <GroupWithUsersCard
                  row={row}
                  key={row.id}
                  onInvitedUpdate={onInvitedUpdate}
                  onGroupDelete={onGroupDelete}
                  refetchGroups={refetch}
                />
              ))}
            </tbody>
          </TableContentStyles.TableContainer>
        </TableContentStyles.TableWrapper>
      </Table>

      <TableFooter
        totalCount={totalGroups}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={t('group.Groups')}
      />
    </>
  );
};
