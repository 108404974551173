import React, {
  FC, useEffect, useMemo
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  EmptyResultsMessage,
  Spinner,
  TableContent,
  TableFooter,
  usePaginationSettings,
} from 'src/shared/components';
import {
  useGetAdminRequestsQuery
} from 'src/redux/openapi';
import {
  DeleteOrgRequestModal
} from 'src/features/Modals';

import {
  ManagementTableRow
} from '../ManagementTableRow';
import {
  GroupRequestsActions
} from '../../types';
import {
  RequestModal
} from '../RequestModal';
import {
  useManagementAction
} from '../../hooks';

import {
  getGroupRequestsCols
} from './utils';

interface GroupRequestsContentProps {
  searchQuery: string;
  requestsCount?: number;
}

export const GroupRequestsContent: FC<GroupRequestsContentProps> = ({
  searchQuery,
  requestsCount,
}) => {
  const {
    i18n: {
      language
    },
    t,
  } = useTranslation();

  const {
    onAction,
    currentAction,
    idToModify,
    removeAction,
    setIdToModify,
    setCurrentAction,
  } = useManagementAction<GroupRequestsActions>();

  const onRowClick = (id: string) => {
    setIdToModify(id);
    setCurrentAction(GroupRequestsActions.Request);
  };

  const onAccept = () => setCurrentAction(null);

  const groupRequestsColumns = useMemo(
    () => getGroupRequestsCols(
      onAction,
      language
    ),
    [onAction, language],
  );

  const {
    currentPage, setCurrentPage, pageSize, onPageSizeChange
  } = usePaginationSettings(searchQuery);

  const {
    data: groupsRequestsData,
    refetch: refetchGroupsRequestsData,
    isLoading,
  } = useGetAdminRequestsQuery(
    {
      filters: {
        query: searchQuery,
      },
      limit: pageSize,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalRequests, groupRequests: requests
  } = useMemo(
    () => groupsRequestsData?.data || {
      groupRequests: [],
      total: 0,
    },
    [groupsRequestsData],
  );

  useEffect(
    () => {
      if (!requests.length && currentPage !== 1) {
        setCurrentPage((prev) => prev - 1);
      }
    },
    [requests.length]
  );

  useEffect(
    () => {
      refetchGroupsRequestsData();
    },
    [requestsCount]
  );

  const label = t('common.requests');

  if (isLoading) {
    return <Spinner withLoadingText />;
  }

  if (!requests.length) {
    return (
      <EmptyResultsMessage
        results={label.toLowerCase()}
        withSearchQuery={!!searchQuery}
      />
    );
  }

  return (
    <>
      <TableContent
        data={requests}
        columns={groupRequestsColumns}
        renderRow={(row, index) => (
          <ManagementTableRow
            row={row}
            key={row.original.id}
            index={index}
            onRowClick={onRowClick}
          />
        )}
      />

      <TableFooter
        totalCount={totalRequests}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={label}
      />

      <DeleteOrgRequestModal
        isOpen={currentAction === GroupRequestsActions.DeleteRequest}
        closeModal={removeAction}
        id={idToModify}
        onSuccessDelete={refetchGroupsRequestsData}
      />

      <RequestModal
        isOpen={currentAction === GroupRequestsActions.Request}
        closeModal={removeAction}
        id={idToModify || ''}
        refetch={refetchGroupsRequestsData}
        onAcceptClick={onAccept}
      />
    </>
  );
};
