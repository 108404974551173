import {
  arqApiAdmin,
  PostAdminQuestionsUploadsXlsxApiResponse,
} from 'src/redux/openapi';

const adminApi = arqApiAdmin.injectEndpoints({
  endpoints: (build) => ({
    postApiAdminQuestionsUploadsXlsx: build.mutation<
    PostAdminQuestionsUploadsXlsxApiResponse,
    PostAdminQuestionsUploadsXlsxApiArg
    >({
      query: (args) => ({
        url: `/api/admin/questions/uploads/xlsx`,
        method: 'POST',
        body: args.file,
      }),
    }),
  }),
  overrideExisting: true,
});

type PostAdminQuestionsUploadsXlsxApiArg = {
  file: FormData;
};

export const {
  usePostApiAdminQuestionsUploadsXlsxMutation:
    usePostAdminQuestionsUploadsXlsxMutation,
} = adminApi;
