import i18next from 'i18next';
import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

interface EmptyResultsMessageProps {
  results?: string;
  withSearchQuery?: boolean;
}

export const EmptyResultsMessage: FC<EmptyResultsMessageProps> = ({
  results = i18next.t('common.results'),
  withSearchQuery = true,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <p className="text-grey-dark-40 flex items-center justify-center font-medium text-17-26 text-center h-full">
      {`${t('empty.unfortunatelyThereAreNo')} ${results} ${
        withSearchQuery ? t('empty.thatMatchSearchRequest') : ''
      }`}
    </p>
  );
};
