import React, {
  useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  AddQuestionSets, ModalSuccess
} from 'src/features';
import {
  SetForCreation,
  usePostAdminQuestionsMutation,
} from 'src/redux/openapi';
import {
  usePostAdminQuestionsUploadsXlsxMutation
} from 'src/shared/api';
import {
  Stepper, successfulToast, useStepper
} from 'src/shared/components';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  PageLayout
} from 'src/widgets';
import {
  useSelectionSet
} from 'src/shared/hooks';

import {
  getStepsMap, Steps
} from './utils';

export const AddAdminQuestionSet = () => {
  const [questionSets, setQuestionSets] = useState<SetForCreation[]>([]);
  const [isGlobal, setGlobal] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const {
    t
  } = useTranslation();

  const groupsSelectConfig = useSelectionSet<string>();
  const orgSelectConfig = useSelectionSet<string>();

  const {
    selectedItems: selectedGroups, clearSelection: clearGroups
  } = groupsSelectConfig;

  const {
    selectedItems: selectedOrgs, clearSelection: clearOrgs
  } = orgSelectConfig;

  const toggleGlobal = () => {
    setGlobal((prev) => !prev);
    clearGroups();
    clearOrgs();
  };

  const [parseQuestions] = usePostAdminQuestionsUploadsXlsxMutation();

  const [postQuestions, {
    isLoading: isCreationInProcess
  }] = usePostAdminQuestionsMutation();

  const handleFileLoading = async (file: FormData) => {
    try {
      const response = await parseQuestions({
        file,
      }).unwrap();

      successfulToast(response.message);
      setQuestionSets(response.data.sets);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onCreate = async () => {
    const relations = isGlobal
      ? {
        isGlobal,
        organizationIds: null,
        groupIds: null,
      }
      : {
        isGlobal,
        organizationIds: Array.from(selectedOrgs),
        groupIds: Array.from(selectedGroups),
      };

    try {
      await postQuestions({
        createQuestionsBodySchema: {
          relations,
          sets: questionSets,
        },
      }).unwrap();

      setIsSuccessModalOpen(true);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const steps = getStepsMap();

  const {
    completeCurrentStep,
    activeStepId,
    handleNextStep,
    completedStatus,
    handlePreviousStep,
    onStepClick,
    isLastStep,
    currentStep,
    resetSteps,
  } = useStepper(steps);

  useEffect(
    () => {
      if (activeStepId === Steps.CreateSet) {
        completeCurrentStep(!!questionSets.length);
      }

      if (activeStepId === Steps.SelectEntity) {
        completeCurrentStep(
          !!selectedGroups.size || !!selectedOrgs.size || isGlobal,
        );
      }
    },
    [
      activeStepId,
      selectedGroups.size,
      selectedOrgs.size,
      questionSets.length,
    ]
  );

  const onModalClose = () => {
    setIsSuccessModalOpen(false);
    setQuestionSets([]);
    clearGroups();
    clearOrgs();
    resetSteps();
  };

  const canCreateSets = (isGlobal || !!selectedGroups.size || !!selectedOrgs.size)
    && !!questionSets.length;

  return (
    <PageLayout maxHeightScreen>
      <AddQuestionSets.ContentWrapper>
        <AddQuestionSets.Header
          loadFile={
            activeStepId === Steps.CreateSet ? handleFileLoading : undefined
          }
        >
          <Stepper
            steps={steps}
            activeStepId={activeStepId}
            completedStatus={completedStatus}
            onStepClick={onStepClick}
          />
        </AddQuestionSets.Header>

        {activeStepId === Steps.CreateSet && (
          <AddQuestionSets.QuestionSetList questionSets={questionSets} />
        )}

        {activeStepId === Steps.SelectEntity && (
          <AddQuestionSets.SelectEntityTable
            groupSelectConfig={groupsSelectConfig}
            orgSelectConfig={orgSelectConfig}
            isGlobal={isGlobal}
            toggleGlobal={toggleGlobal}
          />
        )}

        <AddQuestionSets.Footer
          canCreate={canCreateSets}
          isCreationInProcess={isCreationInProcess}
          handleCreation={onCreate}
          onPreviousClick={handlePreviousStep}
          onNextClick={questionSets.length ? handleNextStep : undefined}
          isLastStep={isLastStep}
          isFirstStep={currentStep === 0}
        />
      </AddQuestionSets.ContentWrapper>

      <ModalSuccess
        closeModal={onModalClose}
        isOpen={isSuccessModalOpen}
        title={t('modal.changesSaved')}
        titleClassName="capitalize"
        withCloseButton
        buttonTitle={`${t('buttons.gotIt')}!`}
        onButtonClick={onModalClose}
        buttonIcon={null}
        buttonClassName="mx-auto"
        modalWidth={509}
      />
    </PageLayout>
  );
};
