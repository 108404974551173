import React, {
  FC, useState
} from 'react';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  Checkbox,
  CircleButton,
  ScrollContainer,
  ScrollContainerVariant,
  Spinner,
  Tooltip,
} from 'src/shared/components';
import {
  ArrowUpWideIcon
} from 'src/shared/icons';
import {
  formatDateRange, showWarningFromServer
} from 'src/shared/utils';
import {
  SelectedItem, TParentCheckbox
} from 'src/shared/hooks';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';

import * as Style from '../EditQuestions.styles';
import {
  PreparedQuestionSets, EditQuestionType
} from '../types';
import {
  checkDeactivated
} from '../utils';

import {
  QuestionCard
} from './QuestionCard';

interface QuestionSetCardProps {
  questionSet: PreparedQuestionSets;
  onTopicCheck: (parentItem: TParentCheckbox) => void;
  onQuestionCheck: (parentItem: TParentCheckbox, childId: string) => void;
  selectedItems: SelectedItem[];
  isRemoving?: boolean;
  onLoadQuestionsBySetId?: (id: string) => Promise<EditQuestionType[]>;
  togglePreferred?: (id: string, isPreferred: boolean) => void;
  preferredOverrides?: { id: string; isPreferred: boolean }[];
}

export const QuestionSetCard: FC<QuestionSetCardProps> = ({
  questionSet,
  selectedItems,
  onTopicCheck,
  onQuestionCheck,
  isRemoving,
  onLoadQuestionsBySetId,
  togglePreferred,
  preferredOverrides,
}) => {
  const {
    title: questionSetTitle,
    id: questionSetId,
    startDate,
    endDate,
    questionCount,
    questions = [],
  } = questionSet;

  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandLoading, setIsExpandLoading] = useState(false);
  const [isCheckLoading, setIsCheckLoading] = useState(false);

  const {
    t
  } = useTranslation();

  const toggleExpand = async () => {
    if (onLoadQuestionsBySetId) {
      try {
        setIsExpandLoading(true);
        await onLoadQuestionsBySetId(questionSetId);
      } catch (error) {
        showWarningFromServer(questionSetId);
      } finally {
        setIsExpandLoading(false);
      }
    }

    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  const onExpand = () => {
    toggleExpand();
  };

  const isSetSelected = selectedItems.some(
    (item) => item.parentId === questionSetId,
  );

  const selectedQuestions = selectedItems.find((item) => item.parentId === questionSetId)
    ?.selectedChildrenId || [];

  const isAllQuestionsSelected = questionCount === selectedQuestions.length;

  const handleQuestionSetCheck = async () => {
    let questionsToCheck = questions;

    if (!questions.length && onLoadQuestionsBySetId && !isSetSelected) {
      try {
        setIsCheckLoading(true);
        const response = await onLoadQuestionsBySetId(questionSetId);

        questionsToCheck = response;
      } catch (error) {
        showWarningFromServer(questionSetId);
      } finally {
        setIsCheckLoading(false);
      }
    }

    onTopicCheck({
      id: questionSetId,
      childrenCheckboxes: isRemoving
        ? questionsToCheck.filter((question) => !question.isMandatory)
        : questionsToCheck.filter((question) => !checkDeactivated(question)),
    });
  };

  const handleQuestionCheck = (questionId: string) => {
    if (!isSetSelected) {
      onTopicCheck({
        id: questionSetId,
        childrenCheckboxes: questions.filter((question) => (isRemoving
          ? question.id === questionId
          : question.isMandatory || question.id === questionId),),
      });
    } else {
      onQuestionCheck(
        {
          id: questionSetId,
          childrenCheckboxes: questions,
        },
        questionId,
      );
    }
  };

  const isDeactivated = !isRemoving && checkDeactivated(questionSet);
  const isUnavailable = questionSet.status === 'UNAVAILABLE';

  const language = useTypedSelector(languageSelectors.getLanguage);

  return (
    <div>
      <Style.QuestionIsEditContainer $isExpanded={isExpanded}>
        <Style.SetPartContainer $centered>
          <Checkbox
            type={isAllQuestionsSelected ? 'checkMark' : 'minus'}
            isChecked={isSetSelected}
            onChange={handleQuestionSetCheck}
            disabled={isDeactivated}
            isLoading={isCheckLoading}
          />

          <Style.QuestionDatesTitleContainer>
            <Style.CardQuestionDates>
              {formatDateRange({
                start: startDate,
                end: endDate,
                language,
              })}
            </Style.CardQuestionDates>

            <Style.CardQuestionTitle>
              {questionSetTitle}
            </Style.CardQuestionTitle>
          </Style.QuestionDatesTitleContainer>
        </Style.SetPartContainer>

        <Style.SetPartContainer>
          <Style.SetInfoWrapper>
            {!isDeactivated ? (
              <Style.SetPartContainer>
                {isUnavailable && (
                  <Style.UpcomingContainer>
                    {t('question.upcoming')}
                  </Style.UpcomingContainer>
                )}

                <Style.QuestionNumber>
                  {`${selectedQuestions.length}/${
                    questionCount || questions.length
                  }`}
                </Style.QuestionNumber>
              </Style.SetPartContainer>
            ) : (
              <Tooltip
                variant="darkGray"
                opacity={1}
                message={(
                  <Style.TooltipMessage>
                    {t('modal.setCannotBeActivatedAgain')}
                  </Style.TooltipMessage>
                )}
              >
                <Style.DeactivatedStatus>
                  {t('buttons.deactivated')}
                </Style.DeactivatedStatus>
              </Tooltip>
            )}
          </Style.SetInfoWrapper>

          <CircleButton
            onClick={onExpand}
            className="p-2.5"
          >
            {isExpandLoading ? (
              <Spinner
                size={24}
                color="inherit"
              />
            ) : (
              <ArrowUpWideIcon
                className={clsx({
                  'rotate-180': isExpanded,
                })}
              />
            )}
          </CircleButton>
        </Style.SetPartContainer>
      </Style.QuestionIsEditContainer>

      {isExpanded && !!questions.length && (
        <Style.QuestionsAllContainer>
          <ScrollContainer
            variant={ScrollContainerVariant.dark}
            className="max-h-410px"
            edgeHeight={16}
          >
            {questions.map((question, i) => {
              const isSelected = selectedQuestions.some(
                (questionItem) => questionItem === question.id,
              );

              return (
                <QuestionCard
                  key={question.id}
                  question={question}
                  index={i}
                  onQuestionCheck={handleQuestionCheck}
                  isSelected={isSelected}
                  isRemoving={isRemoving}
                  togglePreferred={togglePreferred}
                  preferredOverrides={preferredOverrides}
                />
              );
            })}
          </ScrollContainer>
        </Style.QuestionsAllContainer>
      )}
    </div>
  );
};
