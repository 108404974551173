import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Button, ModalContainer, successfulToast
} from 'src/shared/components';
import {
  usePostQuestionsRequestApprovalMutation
} from 'src/redux/openapi';
import {
  formatDateRange, showWarningFromServer
} from 'src/shared/utils';
import {
  DownloadSetRequest
} from 'src/widgets';

import * as Style from './ManageSetRequestModal.styles';

interface ManageSetRequestModalProps {
  isOpen: boolean;
  closeModal: () => void;
  id: string;
  setTitle: string;
  setStartDate: string;
  setEndDate: string;
  organisationName: string;
  fileUrl: string;
  onDecline: () => void;
}

export const ManageSetRequestModal: FC<ManageSetRequestModalProps> = ({
  isOpen,
  closeModal,
  id,
  setTitle,
  setStartDate,
  setEndDate,
  organisationName,
  fileUrl,
  onDecline,
}) => {
  const {
    t,
    i18n: {
      language
    },
  } = useTranslation();

  const [approveRequest, {
    isLoading: isApproveLoading
  }] = usePostQuestionsRequestApprovalMutation();

  const onApprove = async () => {
    if (isApproveLoading) {
      return;
    }

    try {
      const response = await approveRequest({
        id,
      }).unwrap();

      successfulToast(response.message);
      closeModal();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <ModalContainer
      closeModalHandler={closeModal}
      isOpen={isOpen}
      modalTitle={t('question.questionsCreationRequest')}
    >
      <Style.ContentContainer>
        <div>
          <Style.FieldTitle>
            {t('organisation.organisationName')}
          </Style.FieldTitle>

          <Style.FieldContainer>{organisationName}</Style.FieldContainer>
        </div>

        <div>
          <Style.FieldTitle>{t('question.setName')}</Style.FieldTitle>

          <Style.FieldContainer $flex>
            <div>
              {setTitle}

              <Style.DateContainer>
                {formatDateRange({
                  start: setStartDate,
                  end: setEndDate,
                  language,
                })}
              </Style.DateContainer>
            </div>

            <DownloadSetRequest url={fileUrl} />
          </Style.FieldContainer>
        </div>

        <Style.ButtonsContainer>
          <Button
            variant="big-blue"
            onClick={onApprove}
            isLoading={isApproveLoading}
            className="w-max font-bold text-xl"
          >
            {t('buttons.approve')}
          </Button>

          <Button
            variant="big-red"
            onClick={onDecline}
            className="w-max font-bold text-xl"
          >
            {t('buttons.decline')}
          </Button>
        </Style.ButtonsContainer>
      </Style.ContentContainer>
    </ModalContainer>
  );
};
