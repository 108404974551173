import React, {
  FC, useState, useEffect, useMemo
} from 'react';

import {
  HeartIcon
} from 'src/shared/icons';
import {
  PopoverMenu, Tooltip
} from 'src/shared/components';
import {
  usePatchCommentLikesSetMutation,
  usePatchCommentLikesUnsetMutation,
  Comment,
  ReplyComment,
  CommendationType,
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  ActivityButton
} from '../ActivityButton';

import * as Style from './CommentListItem.styles';
import './index.css';
import {
  LIKES_CONFIG_MAP,
  getCommentLikesData,
  getExtendedIcons,
  getMainIcon,
} from './utils';

type LikesData = {
  likesCount: number;
  userCommendation: keyof typeof CommendationType | null;
  commendations: (keyof typeof CommendationType)[] | null;
  isLiked: boolean;
};

interface CommentLikeButtonProps {
  isExtended: boolean;
  comment: Comment | ReplyComment;
  refetchComments: () => void;
}

export const CommentLikeButton: FC<CommentLikeButtonProps> = ({
  isExtended,
  comment,
  refetchComments,
}) => {
  const [likesData, setLikesData] = useState<LikesData>({
    likesCount: 0,
    userCommendation: null,
    commendations: [],
    isLiked: false,
  });

  const [shouldCloseMenu, setShouldCloseMenu] = useState(false);

  const {
    likesCount, userCommendation, commendations, isLiked
  } = likesData;

  const {
    id
  } = comment;

  useEffect(
    () => {
      const commentLikesData = getCommentLikesData(comment);
      setLikesData(commentLikesData);
    },
    [comment]
  );

  const [setCommentLike, {
    isLoading: isSetLikeLoading
  }] = usePatchCommentLikesSetMutation();

  const [unsetCommentLike, {
    isLoading: isUnsetLikeLoading
  }] = usePatchCommentLikesUnsetMutation();

  const isLoadingLikeChanges = isSetLikeLoading || isUnsetLikeLoading;

  const handleLikeClick = async (type?: CommendationType) => {
    if (!isLoadingLikeChanges) {
      setShouldCloseMenu(true);
      const archivedLikesData = likesData;

      try {
        const shouldChangeType = type !== userCommendation;
        const shouldUnset = isLiked && (!shouldChangeType || isExtended);

        if (shouldUnset) {
          setLikesData((prev) => ({
            ...prev,
            likesCount: prev.likesCount - 1,
            userCommendation: null,
            isLiked: false,
          }));

          await unsetCommentLike({
            id,
          }).unwrap();
        } else {
          const typeToSet = type || CommendationType.LIKE;

          setLikesData((prev) => ({
            ...prev,
            likesCount: isLiked ? prev.likesCount : prev.likesCount + 1,
            userCommendation: typeToSet,
            commendations: prev.likesCount > 1 ? prev.commendations : [],
            isLiked: true,
          }));

          await setCommentLike({
            id,
            setCommentCommendationBodySchema: {
              type: typeToSet,
            },
          }).unwrap();
        }

        refetchComments();
      } catch (error) {
        showWarningFromServer(
          error,
          'Unable to like. Please try again later.'
        );

        setLikesData(archivedLikesData);
      }
    }
  };

  const extendedIcons = useMemo(
    () => {
      if (isExtended) {
        return [];
      }

      return likesCount && isLiked
        ? getExtendedIcons(
          userCommendation,
          commendations
        )
        : [];
    },
    [likesCount, isExtended, userCommendation, commendations, isLiked]
  );

  if (isExtended) {
    return (
      <ActivityButton
        icon={HeartIcon}
        title={(likesCount || 0).toString()}
        isActive={isLiked}
        onClick={() => handleLikeClick(CommendationType.LIKE)}
      />
    );
  }

  return (
    <PopoverMenu
      placement="top-center"
      shouldCloseOnParentDisappear
      shouldForcedClose={shouldCloseMenu}
      onClose={() => setShouldCloseMenu(false)}
      renderCustomButton={({
        toggleMenu, triggerProps, isOpen
      }) => (
        <ActivityButton
          icon={getMainIcon(userCommendation as CommendationType)}
          extendedIcons={extendedIcons}
          title={(likesCount || 0).toString()}
          isActive={isLiked}
          onClick={toggleMenu}
          withMenuOpened={isOpen}
          {...triggerProps}
        />
      )}
    >
      <Style.CommendsContainer>
        {LIKES_CONFIG_MAP.map(({
          type, icon: Icon, label
        }) => (
          <Tooltip
            message={label}
            key={type}
            customShift={-6}
            pointerEvents="none"
            delayLeave={100}
          >
            <Style.CommendButton
              $isActive={type === userCommendation && isLiked}
              type="button"
              onClick={() => handleLikeClick(type)}
              key={type}
            >
              <Icon className="w-6 h-6" />
            </Style.CommendButton>
          </Tooltip>
        ))}
      </Style.CommendsContainer>
    </PopoverMenu>
  );
};
