import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const EmptyMessageContainer = styled.div`
  ${tw`
    flex flex-col gap-2 items-center text-center px-8 py-6 my-auto mx-6
    bg-alice-blue text-dark-gray text-17-26 font-medium rounded-md
  `}
`;

export const EmptyMessageTitle = styled.div`
  ${tw`text-gray7 font-bold`}
`;
