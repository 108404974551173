import React, {
  FC, useEffect, useState
} from 'react';

import {
  useGetAdminOrganizationQuery,
  usePutAdminOrgQuestionsAutoApprovalMutation,
} from 'src/redux/openapi';
import {
  successfulToast, Switcher
} from 'src/shared/components';
import {
  showWarningFromServer
} from 'src/shared/utils';

interface ToggleAutoApprovalProps {
  organizationId: string;
}

export const ToggleAutoApproval: FC<ToggleAutoApprovalProps> = ({
  organizationId,
}) => {
  const [isEnabled, setIsEnabled] = useState(false);

  const {
    data, isLoading: isEnabledLoading
  } = useGetAdminOrganizationQuery(
    {
      id: organizationId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(
    () => {
      if (data) {
        setIsEnabled(data.data.organization.questionAutoApproval);
      }
    },
    [data]
  );

  const [updateAutoApproval, {
    isLoading
  }] = usePutAdminOrgQuestionsAutoApprovalMutation();

  const onToggle = async () => {
    try {
      const newStatus = !isEnabled;

      setIsEnabled(newStatus);

      const result = await updateAutoApproval({
        id: organizationId,
        updateOrganizationQuestionAutoApprovalBodySchema: {
          questionAutoApproval: newStatus,
        },
      }).unwrap();

      successfulToast(result.message);
    } catch (error) {
      showWarningFromServer(error);
      setIsEnabled((prev) => !prev);
    }
  };

  return (
    <Switcher
      isActive={isEnabled}
      onChange={onToggle}
      isLoading={isLoading || isEnabledLoading}
    />
  );
};
