import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col md:flex-row gap-3 md:items-center mt-auto`}
`;

export const SubmitNextButtonsContainer = styled.div`
  ${tw`flex gap-2 md:gap-4 md:ml-auto justify-end`}
`;

export const NavigateButtonsContainer = styled.div`
  ${tw`flex gap-3 justify-end`}
`;
