import ms from 'ms';

import {
  MONTH, YEAR
} from 'src/shared/utils';

import {
  TimePeriodOptions
} from './types';

export const getTimePeriodSelectValue = (period: TimePeriodOptions) => {
  switch (period) {
    case TimePeriodOptions.ThreeMonths: {
      return ms(3 * MONTH);
    }
    case TimePeriodOptions.SixMonth: {
      return ms(6 * MONTH);
    }
    case TimePeriodOptions.OneYear: {
      return ms(YEAR);
    }
    case TimePeriodOptions.ThreeYears: {
      return ms(3 * YEAR);
    }
    case TimePeriodOptions.OneMonth:
    default: {
      return ms(MONTH);
    }
  }
};
