import React, {
  FC
} from 'react';

import * as Style from './EmptyMessage.styles';

interface EmptyMessageProps {
  title: string;
  subtitle: string;
}

export const EmptyMessage: FC<EmptyMessageProps> = ({
  title, subtitle
}) => {
  return (
    <Style.EmptyMessageContainer>
      <Style.EmptyMessageTitle>{title}</Style.EmptyMessageTitle>

      {subtitle}
    </Style.EmptyMessageContainer>
  );
};
