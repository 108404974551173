import dayjs from 'dayjs';

import {
  Comment, GetQuestionFeedApiArg
} from 'src/redux/openapi';

export const shouldAddComment = (
  comment: Comment,
  filters: GetQuestionFeedApiArg['filters'],
  currentUserId?: string,
) => {
  if (filters?.userOnly && comment.author.id !== currentUserId) {
    return false;
  }

  if (!filters?.withForecast && comment.forecast) {
    return false;
  }

  if (filters?.dateStart && filters?.dateEnd) {
    const startDate = dayjs(filters?.dateStart);
    const endDate = dayjs(filters?.dateEnd);
    const commentDate = dayjs(comment.createdAt);

    if (commentDate.isBefore(startDate) || commentDate.isAfter(endDate)) {
      return false;
    }
  }

  return true;
};
