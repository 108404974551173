import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';
import React from 'react';

import {
  Checkbox, NameCellSpan, Switcher
} from 'src/shared/components';

export enum Tabs {
  Groups = 'groups',
  Organisations = 'organisations',
}

export const getTabsMap = () => [
  {
    id: Tabs.Organisations,
    label: t('organisation.organisations'),
  },
  {
    id: Tabs.Groups,
    label: t('group.unaffiliatedGroups'),
  },
];

const columnsHelper = createColumnHelper<{
  id: string;
  name: string;
  createdAt: string;
}>();

export const getColumns = ({
  selectedIds,
  nameHeaderTitle,
  toggleAllOnPage,
  isAllSelected,
  isSomeSelected,
  isGlobal,
  toggleGlobal,
}: {
  selectedIds: Set<string>;
  nameHeaderTitle: string;
  toggleAllOnPage: () => void;
  isAllSelected: boolean;
  isSomeSelected: boolean;
  isGlobal: boolean;
  toggleGlobal: () => void;
}) => [
  columnsHelper.accessor(
    'id',
    {
      header: () => (
        <Checkbox
          type={isAllSelected ? 'checkMark' : 'minus'}
          onChange={toggleAllOnPage}
          isChecked={isAllSelected || isSomeSelected}
        />
      ),
      footer: (props) => props.column.id,
      size: 24,
      enableResizing: false,
      cell: (info) => {
        const id = info.getValue();

        return (
          <Checkbox
            type="checkMark"
            isChecked={selectedIds.has(id)}
            disabled
          />
        );
      },
    }
  ),
  columnsHelper.accessor(
    'name',
    {
      header: nameHeaderTitle,
      footer: (props) => props.column.id,
      minSize: 380,
      cell: (info) => <NameCellSpan>{info.getValue()}</NameCellSpan>,
    }
  ),
  columnsHelper.accessor(
    'createdAt',
    {
      header: () => (
        <Switcher
          isActive={isGlobal}
          onChange={toggleGlobal}
          label="Make question set global"
          labelClassName="flex-row-reverse"
        />
      ),
      footer: (props) => props.column.id,
      size: 200,
      minSize: 190,
      cell: () => null,
    }
  ),
];

type GetSelectedStatus = (
  items: { id: string }[],
  selectedIds: Set<string>,
) => boolean;

export const getIsAllSelected: GetSelectedStatus = (items, selectedIds) => items.every(({
  id
}) => selectedIds.has(id));

export const getIsSomeSelected: GetSelectedStatus = (items, selectedIds) => items.some(({
  id
}) => selectedIds.has(id));
