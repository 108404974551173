export const commentID = 'commentID';
export const questionId = 'questionId';
export const groupId = 'groupId';
export const followings = 'followings';
export const followingId = 'followingId';
export const commentsSortBy = 'commentsSortBy';
export const commentsTimeRange = 'commentsTimeRange';
export const showMyOnly = 'showMyOnly';
export const topicId = 'topicId';
export const FAVORITE = 'favorite';
export const questionView = 'questionView';
export const questionsVisible = 'questionsVisible';
export const questionsSortBy = 'questionsSortBy';
export const questionsFilterBy = 'questionsFilterBy';
export const questionsTimeRange = 'questionsTimeRange';
export const commentSearch = 'commentSearch';
export const notificationTab = 'notificationTab';
export const invitedGroupName = 'groupName';
export const invitedUserEmail = 'userEmail';
export const status = 'status';
export const groupInvitation = 'group-invitation';
export const showOne = 'showOne';
export const storedOrgSelectedItems = 'storedOrgSelectedItems';
export const allUsersList = 'all-users-list';
export const NOT_ASSIGNED = 'Not assigned';
export const verification = 'verification';
export const initVerification = 'init-verification';
export const manageDelegate = 'manageDelegate';
export const view = 'view';
export const organisationTab = 'organisationTab';
export const communityTab = 'communityTab';
export const activePage = 'activePage';
export const deactivatedPage = 'deactivatedPage';
export const availablePage = 'availablePage';
export const managementTab = 'managementTab';
export const questionTab = 'questionTab';
export const questionManagementFilter = 'questionManagementFilter';

export const XLSX_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const SVG_TYPE = 'image/svg+xml';
export const MAX_MB_FILE_SIZE = 1;
export const defaultHexColor = '#2261F5';

export const EN_USERS_TEMPLATE_URL = 'https://arqdecisions.blob.core.windows.net/static/templates/en/template.xlsx';
export const DE_USERS_TEMPLATE_URL = 'https://arqdecisions.blob.core.windows.net/static/templates/de/template.xlsx';
export const SET_TEMPLATE_URL = 'https://arqdecisions.blob.core.windows.net/static/templates/q-creation/template.xlsx';
