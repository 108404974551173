import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetGroupQuestionSetsQuery,
  useGetOrgGroupsQuestionsActiveQuery,
  UserGroupRole,
  UserGroupRoleType,
} from 'src/redux/openapi';
import {
  usePaginationSettings
} from 'src/shared/components/Pagination';
import {
  groupId as groupIdParam,
  questionsFilterBy,
  questionsSortBy,
} from 'src/shared/utils';
import {
  FilterBy, SortBy
} from 'src/entities/Questions';
import {
  QuestionSortBy
} from 'src/shared/api/types';
import {
  getQuestionSortBy,
  getGroupQuestionFilter,
} from 'src/widgets/QuestionsFilter/utils';
import {
  FilterAmountTile,
  Filters,
  useFiltersInfiniteScroll,
} from 'src/features/Filters';
import {
  EmptyResultsMessage,
  Spinner,
  TableContent,
  TableFooter,
  TableRow,
} from 'src/shared/components';
import {
  QuestionCards
} from 'src/widgets';

import {
  getSetsCols
} from './utils';
import * as Style from './SetsQuestionsContent.styles';

interface SetsQuestionsContentProps {
  organizationId: string;
  role: UserGroupRoleType;
  searchQuery: string;
}

export const SetsQuestionsContent: FC<SetsQuestionsContentProps> = ({
  organizationId,
  role,
  searchQuery,
}) => {
  const [groupsPage, setGroupsPage] = useState(1);
  const [wasFilterChanged, setWasFilterChanged] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const activeGroupId = searchParams.get(groupIdParam) || null;

  const setActiveGroupId = (id: string | null) => {
    if (id) {
      searchParams.set(
        groupIdParam,
        id
      );
    } else {
      searchParams.delete(groupIdParam);
    }

    setSearchParams(searchParams);
  };

  const {
    currentPage: questionSetsPage,
    setCurrentPage: setQuestionSetsPage,
    pageSize,
    onPageSizeChange,
  } = usePaginationSettings(searchQuery);

  const isAdmin = role !== UserGroupRole.USER;

  const filters = useMemo(
    () => {
      const sortParam = searchParams.get(questionsSortBy) as SortBy;
      const filterParam = searchParams.get(questionsFilterBy) as FilterBy;

      const sortByResult = getQuestionSortBy<QuestionSortBy>(
        sortParam || SortBy.Newest,
      );

      const filterByResult = filterParam
        ? getGroupQuestionFilter(filterParam)
        : {};

      return {
        ...sortByResult,
        ...filterByResult,
      };
    },
    [searchParams]
  );

  useEffect(
    () => {
      setQuestionSetsPage(1);
    },
    [filters, searchQuery, activeGroupId]
  );

  const {
    data: questionSetsData,
    isLoading: isQuestionsLoading,
    isFetching: isQuestionsSetsFetching,
  } = useGetGroupQuestionSetsQuery(
    {
      id: activeGroupId as string,
      filters: {
        ...filters,
        query: searchQuery,
      },
      limit: pageSize,
      page: questionSetsPage,
    },
    {
      skip: !activeGroupId,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalQuestionSets, questionSets
  } = useMemo(
    () => questionSetsData?.data || {
      total: 0,
      questionSets: [],
    },
    [questionSetsData],
  );

  const onFilterClick = (filterId: string | null) => {
    setWasFilterChanged(true);
    setActiveGroupId(filterId);
  };

  useEffect(
    () => {
      if (wasFilterChanged && !isQuestionsSetsFetching) {
        setWasFilterChanged(false);
      }
    },
    [isQuestionsSetsFetching]
  );

  const {
    t,
    i18n: {
      language
    },
  } = useTranslation();

  const {
    data: groupsData,
    isLoading: isFiltersLoading,
    isFetching: isFiltersFetching,
    isError: isFiltersError,
  } = useGetOrgGroupsQuestionsActiveQuery(
    {
      id: organizationId,
      limit: 10,
      page: groupsPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalGroups, groups
  } = useMemo(
    () => groupsData?.data || {
      total: 0,
      groups: [],
    },
    [groupsData],
  );

  const {
    allItems: allGroups,
    onLoadMore: onLoadGroupsMore,
    hasNextPage: hasGroupsNextPage,
    loading: isGroupsNextLoading,
  } = useFiltersInfiniteScroll({
    total: totalGroups,
    currentItems: groups,
    isFetching: isFiltersLoading,
    isLoading: isFiltersFetching,
    isError: isFiltersError,
    currentPage: groupsPage,
    setCurrentPage: setGroupsPage,
  });

  const groupsFilters = useMemo(
    () => {
      return (
        allGroups.map((group) => ({
          id: group.id,
          label: group.name,
          questionsCount: group.activeQuestionCount,
        })) || []
      );
    },
    [allGroups]
  );

  useEffect(
    () => {
      if (groupsFilters.length) {
        if (!activeGroupId) {
          setActiveGroupId(groupsFilters[0].id);
        }
      }
    },
    [groupsFilters]
  );

  const setsColumns = getSetsCols(
    isAdmin,
    language
  );

  return (
    <>
      <Style.FiltersWrapper>
        <Filters
          items={groupsFilters}
          onFilterClick={onFilterClick}
          activeFilterId={activeGroupId}
          canShrink
          onLoadMore={onLoadGroupsMore}
          hasNextPage={hasGroupsNextPage}
          loading={isGroupsNextLoading}
          isError={isFiltersError}
          renderRightElement={({
            questionsCount, id: groupId
          }) => {
            const isActive = groupId === activeGroupId;

            return (
              <FilterAmountTile isActive={isActive}>
                {questionsCount}
              </FilterAmountTile>
            );
          }}
        />
      </Style.FiltersWrapper>

      {isQuestionsLoading ? (
        <Spinner size={24} />
      ) : (
        <>
          {!!questionSets.length && (
            <TableContent
              data={questionSets}
              columns={setsColumns}
              renderRow={(row, index) => (
                <TableRow
                  row={row}
                  key={row.original.id}
                  index={index}
                  variant="white"
                  canClick
                />
              )}
              renderExpandedContent={(row) => {
                return (
                  <tr>
                    <Style.ExternalRow colSpan={5}>
                      <QuestionCards
                        groupId={activeGroupId || ''}
                        questionSetId={row.original.id}
                        filters={filters}
                        searchQuestion={searchQuery}
                        isAdmin={isAdmin}
                      />
                    </Style.ExternalRow>
                  </tr>
                );
              }}
              resetExpandedKeys={[filters, searchQuery, activeGroupId]}
            />
          )}

          {!questionSets.length && (
            <EmptyResultsMessage
              results={t('common.questions').toLowerCase()}
              withSearchQuery={!!searchQuery}
            />
          )}
        </>
      )}

      <TableFooter
        totalCount={totalQuestionSets}
        pageSize={pageSize}
        currentPage={questionSetsPage}
        onPageChange={setQuestionSetsPage}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={t('common.sets')}
      />
    </>
  );
};
