import {
  HomePreferencesReducer
} from './homePreferences';

const selectAllPreferences = (state: HomePreferencesReducer) => {
  return state.homePreferences;
};

const selectFilterBy = (state: HomePreferencesReducer) => {
  return state.homePreferences.filterBy;
};

const selectTimeRange = (state: HomePreferencesReducer) => {
  return state.homePreferences.timeRange;
};

export const homePreferencesSelectors = {
  selectAllPreferences,
  selectFilterBy,
  selectTimeRange,
};
