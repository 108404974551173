import React, {
  FC, useState, useMemo, useEffect
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useSearchParams
} from 'react-router-dom';

import {
  GlobeIcon,
  MessageIcon,
  PersonIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  commentID,
  formatDateRange,
  formatNumber,
  questionId,
  replaceDotWithComma,
} from 'src/shared/utils';
import {
  Select,
  TimePeriodSelect,
  TimePeriodSelectOption,
  Spinner,
  ScrollContainer,
  getTimePeriodSelectValue,
} from 'src/shared/components';
import {
  useGetDeactivatedStatisticsQuery
} from 'src/redux/openapi';
import {
  ActivityTile, StatisticTile
} from 'src/entities/Questions';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  useCustomInfiniteScroll, useMediaQuery
} from 'src/shared/hooks';
import {
  communitySelectors,
  CommunityTabId,
  setCommunityTabEmptyMessage,
  setCommunityTabLoading,
} from 'src/redux/community';

import * as Style from './ScoredSection.styles';
import {
  getScoreResult, getScoresFilters
} from './utils';

type SelectOption = {
  label: string;
  value: string;
};

const statisticStyle = {
  padding: '4px',
  paddingInline: '8px',
  fontSize: '17px',
  height: '34px',
};

export const ScoredSection: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);

  const activeQuestionId = searchParams.get(questionId);

  const {
    t,
    i18n: {
      language
    },
  } = useTranslation();

  const defaultOption = {
    label: t('select.allQuestionSet'),
    value: 'all',
  };

  const [selectedSets, setSelectedSets] = useState<SelectOption[]>([
    defaultOption,
  ]);

  const [timePeriod, setTimePeriod] = useState<TimePeriodSelectOption | null>(
    null,
  );

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const dispatch = useTypedDispatch();

  const {
    sortBy
  } = useTypedSelector(
    communitySelectors.selectScoredQuestionsTab,
  );

  const onCardClick = (id: string) => {
    searchParams.set(
      questionId,
      id
    );

    searchParams.delete(commentID);

    setSearchParams(searchParams);
  };

  const filters = useMemo(
    () => {
      const preparedSetsIds = selectedSets
        .filter((set) => set.value !== defaultOption.value)
        .map((set) => set.value);

      const questionSetIds = preparedSetsIds.length ? preparedSetsIds : [];

      const fromDate = timePeriod
        ? getTimePeriodSelectValue(timePeriod.value)
        : undefined;

      return {
        questionSetIds,
        fromDate,
        ...getScoresFilters(sortBy),
      };
    },
    [sortBy, selectedSets, timePeriod]
  );

  const {
    data: scoresData,
    isLoading,
    isFetching,
    isError,
  } = useGetDeactivatedStatisticsQuery(
    {
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
      questionSetIds: filters.questionSetIds,
      fromDate: filters.fromDate,
      withQuestionSets: true,
      page: 1,
      limit: currentPage * 10,
    },
    {
      pollingInterval: 20000,
    },
  );

  const {
    total, questionSets, statistics
  } = useMemo(
    () => scoresData?.data || {
      total: 0,
      questionSets: [],
      statistics: [],
    },
    [scoresData?.data],
  );

  const {
    sentryRef,
    allItems: scores,
    displayLoader,
  } = useCustomInfiniteScroll({
    total,
    currentItems: statistics,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  useEffect(
    () => {
      const tabName = CommunityTabId.Scored;

      dispatch(
        setCommunityTabLoading({
          tabName,
          loading: isLoading,
        }),
      );

      if (!isLoading) {
        const emptyMessage = {
          title: t('community.selectQuestionToOpenThread'),
        };

        dispatch(
          setCommunityTabEmptyMessage({
            tabName,
            emptyMessage,
          }),
        );
      }
    },
    [isLoading, total]
  );

  const onSelectChange = (newValue: SelectOption[] | SelectOption) => {
    if (Array.isArray(newValue)) {
      setSelectedSets(newValue);
    }
  };

  const preparedSets = useMemo(
    () => questionSets?.map(({
      id, title
    }) => ({
      label: title,
      value: id,
    })) || [],
    [questionSets],
  );

  const topicSelectOptions = [defaultOption, ...preparedSets];

  if (!questionSets || !questionSets?.length) {
    return isLoading ? (
      <Spinner size={40} />
    ) : (
      <Style.EmptyScores>
        <Style.EmptyScoresTitle>
          {t('empty.scoresIsEmpty')}
        </Style.EmptyScoresTitle>

        {t('empty.scoresBasedOnResults')}

        <br />

        {t('empty.keepAnsweringQuestions')}
      </Style.EmptyScores>
    );
  }

  return (
    <Style.ContentWrapper>
      <Style.FiltersContainer>
        <Select
          options={topicSelectOptions}
          placeholder={
            selectedSets.length > 1
              ? `${selectedSets.length} ${t('select.setsAreSelected')}`
              : t('select.allQuestionsSet')
          }
          onChange={onSelectChange}
          isMulti
          selectedValue={selectedSets}
          defaultValue={defaultOption}
        />

        <TimePeriodSelect
          placeholder={t('common.timePeriod')}
          timePeriod={timePeriod}
          onPeriodChange={setTimePeriod}
        />
      </Style.FiltersContainer>

      {scores?.length ? (
        <ScrollContainer>
          {scores.map(
            ({
              questionSet,
              id,
              title,
              userScore,
              result,
              userEstimatesAvg,
              globalEstimatesAvg,
              deactivatedAt,
              createdAt,
              forecastsCount,
              commentsCount,
            }) => {
              const scoreTitle = getScoreResult(result);

              return (
                <Style.QuestionCard
                  key={id}
                  $isActive={activeQuestionId === id}
                  onClick={() => onCardClick(id)}
                >
                  <Style.TopicInfo>
                    <Style.TopicName>
                      {questionSet?.title || ''}
                    </Style.TopicName>

                    <Style.Date>
                      {formatDateRange({
                        start: deactivatedAt || createdAt,
                        language,
                      })}
                    </Style.Date>
                  </Style.TopicInfo>

                  <Style.QuestionTitle>{title}</Style.QuestionTitle>

                  <Style.StatisticRow>
                    <Style.StatisticRowSide>
                      <StatisticTile
                        icon={<PersonIcon className="text-dim-gray" />}
                        amount={userEstimatesAvg}
                        withBorder
                        style={statisticStyle}
                      />

                      <StatisticTile
                        icon={<GlobeIcon className="text-dim-gray" />}
                        amount={globalEstimatesAvg}
                        withBorder
                        style={statisticStyle}
                      />
                    </Style.StatisticRowSide>

                    <Style.StatisticRowSide>
                      <ActivityTile
                        icon={<MessageIcon className="w-6 h-6 text-dim-gray" />}
                        amount={formatNumber(commentsCount)}
                        timeRange="upToday"
                      />

                      <ActivityTile
                        icon={
                          <TrendingUpIcon className="w-6 h-6 text-dim-gray" />
                        }
                        amount={formatNumber(forecastsCount)}
                        timeRange="upToday"
                      />
                    </Style.StatisticRowSide>
                  </Style.StatisticRow>

                  <Style.CardFooter>
                    {!isMobile && <p>{scoreTitle}</p>}

                    <Style.Score $isNegative={userScore < 0}>
                      {userScore > 0 && '+'}

                      {replaceDotWithComma(userScore)}
                    </Style.Score>
                  </Style.CardFooter>
                </Style.QuestionCard>
              );
            },
          )}

          {displayLoader && (
            <div ref={sentryRef}>
              <Spinner size={24} />
            </div>
          )}
        </ScrollContainer>
      ) : (
        <Style.EmptyScores>
          <Style.EmptyScoresTitle>
            {t('empty.keepAnsweringQuestions')}
          </Style.EmptyScoresTitle>

          {t('empty.tryOtherFilters')}
        </Style.EmptyScores>
      )}
    </Style.ContentWrapper>
  );
};
