import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Textarea = styled.textarea`
  ${tw`
    max-h-max p-1 text-15-20 md:text-17 text-gray7 placeholder:text-dim-gray
    bg-alice-blue border border-form-border rounded resize-none 
    focus:border-input-border-blue focus:bg-input-blue outline-0
  `}
`;

export const LabelText = styled.p`
  ${tw`font-medium text-15-20 text-grey-dark-40 mb-2`}
`;

export const ErrorMessage = styled.p`
  ${tw`text-13-21 font-medium text-red-text-error mt-1`}
`;

export const BottomMessage = styled.p`
  ${tw`text-13-21 font-medium text-dim-gray mt-1`}
`;
