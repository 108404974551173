import React, {
  FC, useMemo
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Spinner,
  TableContent,
  TableRow,
  usePaginationSettings,
  TableFooter,
  EmptyResultsMessage,
} from 'src/shared/components';
import {
  useGetAdminGroupsQuery
} from 'src/redux/openapi';
import {
  UseSelectionSet
} from 'src/shared/hooks';

import {
  getColumns, getIsAllSelected, getIsSomeSelected
} from './utils';

interface GroupsTabContentProps {
  searchQuery: string;
  selectConfig: UseSelectionSet<string>;
  isGlobal: boolean;
  toggleGlobal: () => void;
}

export const GroupsTabContent: FC<GroupsTabContentProps> = ({
  searchQuery,
  selectConfig,
  isGlobal,
  toggleGlobal,
}) => {
  const {
    currentPage, setCurrentPage, pageSize, onPageSizeChange
  } = usePaginationSettings(searchQuery);

  const {
    t
  } = useTranslation();

  const {
    data: unaffiliatedGroupsData, isLoading
  } = useGetAdminGroupsQuery(
    {
      query: searchQuery,
      limit: pageSize,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total, groups
  } = useMemo(
    () => unaffiliatedGroupsData?.data || {
      groups: [],
      total: 0,
    },
    [unaffiliatedGroupsData],
  );

  const {
    selectedItems: selectedIds,
    toggleSelection,
    addMultiple,
    removeMultiple,
  } = selectConfig;

  const isAllSelected = getIsAllSelected(
    groups,
    selectedIds
  );

  const isSomeSelected = getIsSomeSelected(
    groups,
    selectedIds
  );

  const toggleAllOnPage = () => {
    const idsToEdit = groups.map(({
      id
    }) => id);

    if (!isSomeSelected) {
      addMultiple(idsToEdit);

      return;
    }

    removeMultiple(idsToEdit);
  };

  const columns = getColumns({
    selectedIds,
    nameHeaderTitle: t('modal.groupName'),
    toggleAllOnPage,
    isAllSelected,
    isSomeSelected,
    isGlobal,
    toggleGlobal,
  });

  const label = t('group.Groups');

  if (isLoading) {
    return <Spinner withLoadingText />;
  }

  if (!groups?.length) {
    return (
      <EmptyResultsMessage
        results={label}
        withSearchQuery={!!searchQuery}
      />
    );
  }

  const onRowClick = (id: string) => {
    if (isGlobal) {
      toggleGlobal();
    }

    toggleSelection(id);
  };

  return (
    <>
      <TableContent
        columns={columns}
        data={groups}
        fixedLayout
        renderRow={(row, index) => (
          <TableRow
            row={row}
            index={index}
            onRowClick={onRowClick}
            canClick
          />
        )}
      />

      <TableFooter
        totalCount={total}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={label}
      />
    </>
  );
};
