import {
  useCallback, useState
} from 'react';

import {
  EditMenuActions
} from '../types';

export const useManagementAction = <T extends EditMenuActions>() => {
  const [idToModify, setIdToModify] = useState<string | null>(null);

  const [currentAction, setCurrentAction] = useState<T | null>(null);

  const removeAction = () => {
    setCurrentAction(null);
    setIdToModify(null);
  };

  const onAction = useCallback(
    (id: string, action: T) => {
      setIdToModify(id);
      setCurrentAction(action);
    },
    []
  );

  return {
    idToModify,
    currentAction,
    removeAction,
    onAction,
    setCurrentAction,
    setIdToModify,
  };
};
