import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`border-b border-b-gray94`}
`;

export const SetWrapper = styled.div`
  ${tw`flex items-center gap-4 pb-3 pt-2`}
`;

export const SetData = styled.div`
  ${tw`flex flex-col gap-1 grow`}
`;

export const SetDate = styled.p`
  ${tw`font-medium text-xs text-dim-gray`}
`;

export const SetTitle = styled.h4`
  ${tw`font-bold text-17-26 text-dark-gray`}
`;

export const QuestionContainer = styled.div`
  ${tw`flex gap-4 py-2.5 px-2 items-center border-b border-b-gray94 last:border-0`}
`;

export const QuestionTitle = styled.p`
  ${tw`grow text-gray7 font-medium`}
`;

export const QuestionNumber = styled.p`
  ${tw`font-bold text-dim-gray text-17-26 min-w-3.5`}
`;

export const BlueTile = styled.div`
  ${tw`bg-alice-blue text-center border-gray94 border font-bold text-xs px-4 py-1 rounded-100px`}
`;
