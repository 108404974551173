import React, {
  FC, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetGroupUsersQuery, UserGroupRole, Group
} from 'src/redux/openapi';
import {
  EmptyResultsMessage,
  ScrollContainer,
  Spinner,
} from 'src/shared/components';
import {
  useCustomInfiniteScroll
} from 'src/shared/hooks';
import {
  MemberRow,
  MembersContainer,
  MembersListContainer,
  MembersRole,
} from 'src/widgets';

interface GroupMembersProps {
  searchQuery: string;
  role: UserGroupRole;
  group: Group;
}

export const GroupMembers: FC<GroupMembersProps> = ({
  searchQuery,
  role,
  group,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const isAdmin = role !== UserGroupRole.USER;
  const isSuperAdmin = role === UserGroupRole.SUPERADMIN;

  const {
    data: membersData,
    refetch: refetchMembers,
    isFetching,
    isLoading,
    isError,
  } = useGetGroupUsersQuery(
    {
      id: group.id,
      query: searchQuery,
      limit: 20,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalUsers,
    members: currentMembers,
    admins,
  } = useMemo(
    () => membersData?.data || {
      total: 0,
      members: [],
      admins: [],
    },
    [membersData],
  );

  const adminNumbers = admins?.length || 0;

  const {
    t
  } = useTranslation();

  const {
    sentryRef,
    allItems: allMembers,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalUsers,
    currentItems: currentMembers,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  return (
    <MembersContainer>
      <MembersListContainer className="scroll-hidden">
        {!!admins?.length && (
          <div>
            <MembersRole usersRole="admins" />

            {admins.map((member) => (
              <MemberRow
                key={member.id}
                member={member}
                isAdminView={isAdmin}
                isSuperAdminView={isSuperAdmin}
                totalAdminsNumber={adminNumbers}
                refetchMembers={refetchMembers}
                group={group}
                isMemberAdmin
              />
            ))}
          </div>
        )}

        {!!allMembers?.length && (
          <div className="flex flex-col overflow-auto">
            <MembersRole usersRole="users" />

            <ScrollContainer className="grow">
              {isLoading ? (
                <Spinner size={24} />
              ) : (
                <>
                  {allMembers?.map((member) => (
                    <MemberRow
                      key={member.id}
                      member={member}
                      isAdminView={isAdmin}
                      isSuperAdminView={isSuperAdmin}
                      totalAdminsNumber={adminNumbers}
                      refetchMembers={refetchMembers}
                      group={group}
                    />
                  ))}

                  {displayLoader && (
                    <div ref={sentryRef}>
                      <Spinner size={24} />
                    </div>
                  )}
                </>
              )}
            </ScrollContainer>
          </div>
        )}
      </MembersListContainer>

      {!allMembers?.length && !admins?.length && (
        <EmptyResultsMessage results={t('group.members').toLowerCase()} />
      )}
    </MembersContainer>
  );
};
