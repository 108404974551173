import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  PayloadAction
} from '@reduxjs/toolkit';

import {
  CommunityTabId,
  TabState,
  ActiveTabSortBy,
  FollowingTabSortBy,
  ScoredTabSortBy,
  EmptyMessage,
  SortByInCommunity,
} from './types';

export interface CommunityState {
  [CommunityTabId.Active]: TabState<ActiveTabSortBy>;
  [CommunityTabId.Followings]: TabState<FollowingTabSortBy>;
  [CommunityTabId.Scored]: TabState<ScoredTabSortBy>;
}

export interface CommunityReducer {
  community: CommunityState;
}

const getDefaultState = <T>(sortBy: T) => ({
  loading: false,
  emptyMessage: {
    title: '',
    subtitle: '',
  },
  sortBy,
});

export const initialState: CommunityState = {
  [CommunityTabId.Active]: getDefaultState(ActiveTabSortBy.Latest),
  [CommunityTabId.Followings]: getDefaultState(FollowingTabSortBy.MostEngaged),
  [CommunityTabId.Scored]: getDefaultState(ScoredTabSortBy.Newest),
};

export const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    setCommunityTabLoading: (
      state,
      action: PayloadAction<{ tabName: CommunityTabId; loading: boolean }>,
    ) => {
      state[action.payload.tabName].loading = action.payload.loading;
    },
    setCommunityTabEmptyMessage: (
      state,
      action: PayloadAction<{
        tabName: CommunityTabId;
        emptyMessage: EmptyMessage;
      }>,
    ) => {
      state[action.payload.tabName].emptyMessage = action.payload.emptyMessage;
    },
    setCommunitySortBy: <T extends SortByInCommunity>(
      state: CommunityState,
      action: PayloadAction<{ tabName: CommunityTabId; sortBy: T }>,
    ) => {
      state[action.payload.tabName].sortBy = action.payload.sortBy;
    },
  },
});

export const {
  setCommunityTabLoading,
  setCommunityTabEmptyMessage,
  setCommunitySortBy,
} = communitySlice.actions;

export const communityReducer = communitySlice.reducer;
