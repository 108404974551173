import {
  emptySplitApi as api
} from '../../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiComments: build.query<
    GetApiCommentsApiResponse,
    GetApiCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/comments/`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiCommentsById: build.query<
    GetApiCommentsByIdApiResponse,
    GetApiCommentsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/comments/${queryArg.id}`,
      }),
    }),
    patchApiCommentsById: build.mutation<
    PatchApiCommentsByIdApiResponse,
    PatchApiCommentsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/comments/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateCommentBodySchema,
      }),
    }),
    deleteApiCommentsById: build.mutation<
    DeleteApiCommentsByIdApiResponse,
    DeleteApiCommentsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/comments/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiCommentsByIdComments: build.query<
    GetApiCommentsByIdCommentsApiResponse,
    GetApiCommentsByIdCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/comments/${queryArg.id}/comments`,
      }),
    }),
    postApiCommentsByParentModelAndParentId: build.mutation<
    PostApiCommentsByParentModelAndParentIdApiResponse,
    PostApiCommentsByParentModelAndParentIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/comments/${queryArg.parentModel}/${queryArg.parentId}`,
        method: 'POST',
        body: queryArg.createCommentBodySchema,
      }),
    }),
    patchApiCommentsByIdCommendationsSet: build.mutation<
    PatchApiCommentsByIdCommendationsSetApiResponse,
    PatchApiCommentsByIdCommendationsSetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/comments/${queryArg.id}/commendations/set`,
        method: 'PATCH',
        body: queryArg.setCommentCommendationBodySchema,
      }),
    }),
    patchApiCommentsByIdCommendationsUnset: build.mutation<
    PatchApiCommentsByIdCommendationsUnsetApiResponse,
    PatchApiCommentsByIdCommendationsUnsetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/comments/${queryArg.id}/commendations/unset`,
        method: 'PATCH',
      }),
    }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiComments
};
export type GetApiCommentsApiResponse =
  /** status 200 Default Response */ CommentsResponseSchema;
export type GetApiCommentsApiArg = {
  page?: number;
  limit?: number;
};
export type GetApiCommentsByIdApiResponse =
  /** status 200 Default Response */ CommentResponseSchema;
export type GetApiCommentsByIdApiArg = {
  id: string;
};
export type PatchApiCommentsByIdApiResponse =
  /** status 200 Default Response */ CommentResponseSchema;
export type PatchApiCommentsByIdApiArg = {
  id: string;
  updateCommentBodySchema: UpdateCommentBodySchema;
};
export type DeleteApiCommentsByIdApiResponse =
  /** status 200 Default Response */ CommentResponseSchema;
export type DeleteApiCommentsByIdApiArg = {
  id: string;
};
export type GetApiCommentsByIdCommentsApiResponse =
  /** status 200 Default Response */ CommentsResponseSchema;
export type GetApiCommentsByIdCommentsApiArg = {
  id: string;
};
export type PostApiCommentsByParentModelAndParentIdApiResponse =
  /** status 201 Default Response */ CreateCommentResponseSchema;
export type PostApiCommentsByParentModelAndParentIdApiArg = {
  parentId: string;
  parentModel: 'question' | 'forecast' | 'comment';
  createCommentBodySchema: CreateCommentBodySchema;
};
export type PatchApiCommentsByIdCommendationsSetApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiCommentsByIdCommendationsSetApiArg = {
  id: string;
  setCommentCommendationBodySchema: SetCommentCommendationBodySchema;
};
export type PatchApiCommentsByIdCommendationsUnsetApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiCommentsByIdCommendationsUnsetApiArg = {
  id: string;
};
export type Comment = {
  id: string;
  createdAt: string;
  updatedAt: string;
  text: string | null;
  isPrivate: boolean;
  author: {
    id: string;
  };
  comments: {
    id: string;
    createdAt: string;
    updatedAt: string;
    text: string;
    isPrivate: boolean;
    author: {
      id: string;
    };
    rootComment: {
      id: string;
    } | null;
    question: {
      id: string;
    } | null;
    forecast: {
      id: string;
    } | null;
  }[];
  rootComment: {
    id: string;
  } | null;
  question: {
    id: string;
  } | null;
  forecast: {
    id: string;
  } | null;
};
export type CommentsResponseSchema = {
  message: string;
  data: {
    comments: Comment[];
    total: number;
  };
};
export type CommentResponseSchema = {
  message: string;
  data: {
    comment: {
      id: string;
      createdAt: string;
      updatedAt: string;
      text: string | null;
      isPrivate: boolean;
      author: {
        id: string;
      };
      comments: {
        id: string;
        createdAt: string;
        updatedAt: string;
        text: string;
        isPrivate: boolean;
        author: {
          id: string;
        };
        rootComment: {
          id: string;
        } | null;
        question: {
          id: string;
        } | null;
        forecast: {
          id: string;
        } | null;
      }[];
      rootComment: {
        id: string;
      } | null;
      question: {
        id: string;
      } | null;
      forecast: {
        id: string;
      } | null;
    };
  };
};
export type Text = string | null;
export type IsPrivate = boolean;
export type UpdateCommentBodySchema = {
  text?: Text;
  isPrivate?: IsPrivate;
};
export type Author = {
  id: string;
  name: string;
  avatarUrl: string | null;
  displayName: string | null;
  isFollowed: boolean;
};
export type Items = {
  id: string;
  createdAt: string;
  updatedAt: string;
  text: string | null;
  isPrivate: boolean;
  commendationsCount: number;
  userCommendation: ('LIKE' | 'RELEVANT' | 'INSIGHTFUL' | 'INSPIRING') | null;
  commendationTypes:
  | ('LIKE' | 'RELEVANT' | 'INSIGHTFUL' | 'INSPIRING')[]
  | null;
  groupRemoveAccess?: boolean;
  author: {
    id: string;
    name: string;
    avatarUrl: string | null;
    displayName: string | null;
    isFollowed: boolean;
  };
  forecast: {
    id: string;
    estimates: {
      title: string;
      value: number;
    }[];
  } | null;
  question: {
    id: string;
    title: string;
    hasUserOnboarded?: boolean;
  };
  commentsCount: number;
  comments: {
    id: string;
    createdAt: string;
    updatedAt: string;
    text: string;
    author: Author;
    likes: number;
    isLiked: boolean;
  }[];
  estimatesDiff: number | null;
};
export type CreateCommentResponseSchema = {
  message: string;
  data: {
    comment: Items;
  };
};
export type CreateCommentBodySchema = {
  text: string | null;
  isPrivate?: boolean;
};
export type MessageResponseSchema = {
  message: string;
};
export type SetCommentCommendationBodySchema = {
  type: 'LIKE' | 'RELEVANT' | 'INSIGHTFUL' | 'INSPIRING';
};
export const {
  useGetApiCommentsQuery,
  useGetApiCommentsByIdQuery,
  usePatchApiCommentsByIdMutation,
  useDeleteApiCommentsByIdMutation,
  useGetApiCommentsByIdCommentsQuery,
  usePostApiCommentsByParentModelAndParentIdMutation,
  usePatchApiCommentsByIdCommendationsSetMutation,
  usePatchApiCommentsByIdCommendationsUnsetMutation,
} = injectedRtkApi;
