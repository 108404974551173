import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Table,
  TableHeader,
  TableSearch,
  TableTabs,
} from 'src/shared/components';
import {
  useDebouncedValue, UseSelectionSet
} from 'src/shared/hooks';

import {
  getTabsMap, Tabs
} from './utils';
import {
  OrganisationTabContent
} from './OrganisationTabContent';
import {
  GroupsTabContent
} from './GroupsTabContent';

interface SelectEntityTableProps {
  groupSelectConfig: UseSelectionSet<string>;
  orgSelectConfig: UseSelectionSet<string>;
  isGlobal: boolean;
  toggleGlobal: () => void;
}

export const SelectEntityTable: FC<SelectEntityTableProps> = ({
  groupSelectConfig,
  orgSelectConfig,
  isGlobal,
  toggleGlobal,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState(Tabs.Organisations);

  const {
    t
  } = useTranslation();

  const isOrgTab = activeTab === Tabs.Organisations;

  const debouncedQuery = useDebouncedValue(
    searchQuery,
    500
  );

  const onTabChange = (id: Tabs) => {
    setSearchQuery('');
    setActiveTab(id);
  };

  const tabs = getTabsMap();

  const entity = isOrgTab ? t('organisation.organisations') : t('group.Groups');

  return (
    <Table>
      <TableHeader>
        <TableTabs
          tabs={tabs}
          currentTab={activeTab}
          onTabChange={onTabChange}
        />

        <TableSearch
          searchQuery={searchQuery}
          handleSearch={setSearchQuery}
          placeholder={t(
            'filters.searchByName',
            {
              entity: entity.toLowerCase(),
            }
          )}
        />
      </TableHeader>

      {isOrgTab ? (
        <OrganisationTabContent
          searchQuery={debouncedQuery}
          selectConfig={orgSelectConfig}
          isGlobal={isGlobal}
          toggleGlobal={toggleGlobal}
        />
      ) : (
        <GroupsTabContent
          searchQuery={debouncedQuery}
          selectConfig={groupSelectConfig}
          isGlobal={isGlobal}
          toggleGlobal={toggleGlobal}
        />
      )}
    </Table>
  );
};
