import {
  t
} from 'i18next';

import {
  groupId, ROUTE
} from 'src/shared/utils';

export enum OrganisationTabs {
  SetsAndQuestions = 'setsAndQuestions',
  Community = 'community',
  Groups = 'groups',
  QuestionScoring = 'questionScoring',
}

export const getTabsMap = () => [
  {
    id: OrganisationTabs.SetsAndQuestions,
    label: t('question.setsAndQuestions'),
  },
  {
    id: OrganisationTabs.Community,
    label: t('organisation.community'),
  },
  {
    id: OrganisationTabs.Groups,
    label: t('group.Groups'),
  },
  {
    id: OrganisationTabs.QuestionScoring,
    label: t('question.questionScoring'),
  },
];

export const getEditMenuMap = (
  pathname: string,
  activeGroupId: string | null,
) => [
  {
    label: t('group.editGroup'),
    link: `${pathname}/${ROUTE.EDIT_QUESTIONS}${
      groupId ? `?${groupId}=${activeGroupId}` : ''
    }`,
  },
  {
    label: t('question.addInBulk'),
    link: `${pathname}/${ROUTE.ADD_IN_BULK}`,
  },
  {
    label: t('question.removeInBulk'),
    link: `${pathname}/${ROUTE.REMOVE_IN_BULK}`,
  },
];
