import {
  ActiveTabSortBy
} from 'src/redux/community';

export const getSortByValue = (sort: ActiveTabSortBy) => {
  switch (sort) {
    case ActiveTabSortBy.Latest:
      return 'commentsCreatedAt';
    case ActiveTabSortBy.MostCommented:
      return 'commentsCount';
    case ActiveTabSortBy.MostParticipants:
      return 'participantsCount';
    default:
      return undefined;
  }
};
