import React, {
  FC, useEffect, useMemo
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';

import {
  useGetAdminQuestionsActiveQuery,
  useGetAdminQuestionsScoredQuery,
  useGetAdminQuestionsDeactivatedQuery,
  useGetAdminQuestionsSurveyQuery,
} from 'src/redux/openapi';
import {
  usePaginationSettings
} from 'src/shared/components';
import {
  questionManagementFilter, questionTab
} from 'src/shared/utils';
import {
  ScoringTableContent,
  QuestionScoringTabs,
  QuestionScoringFilters,
} from 'src/widgets';

interface QuestionScoringContentProps {
  searchQuery: string;
}

export const QuestionScoringContent: FC<QuestionScoringContentProps> = ({
  searchQuery,
}) => {
  const [searchParams] = useSearchParams();

  const currentTab = searchParams.get(questionTab) || QuestionScoringTabs.Active;

  const activeFilter = searchParams.get(questionManagementFilter);

  const withOrganization = activeFilter
    ? activeFilter === QuestionScoringFilters.OrgQuestions
    : undefined;

  const withFeedback = activeFilter
    ? activeFilter === QuestionScoringFilters.WithFeedback
    : undefined;

  const {
    currentPage, setCurrentPage, pageSize, onPageSizeChange
  } = usePaginationSettings(searchQuery);

  useEffect(
    () => {
      setCurrentPage(1);
    },
    [activeFilter]
  );

  const {
    data: activeData, isLoading: isActiveLoading
  } = useGetAdminQuestionsActiveQuery(
    {
      page: currentPage,
      limit: pageSize,
      query: searchQuery,
      withOrganization,
    },
    {
      skip: currentTab !== QuestionScoringTabs.Active,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: scoredData, isLoading: isScoredLoading
  } = useGetAdminQuestionsScoredQuery(
    {
      page: currentPage,
      limit: pageSize,
      query: searchQuery,
      withOrganization,
      withFeedback,
    },
    {
      skip: currentTab !== QuestionScoringTabs.Scored,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: deactivatedData, isLoading: isDeactivatedLoading
  } = useGetAdminQuestionsDeactivatedQuery(
    {
      page: currentPage,
      limit: pageSize,
      query: searchQuery,
      withOrganization,
    },
    {
      skip: currentTab !== QuestionScoringTabs.Deactivated,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: surveyData, isLoading: isSurveyLoading
  } = useGetAdminQuestionsSurveyQuery(
    {
      page: currentPage,
      limit: pageSize,
      query: searchQuery,
    },
    {
      skip: currentTab !== QuestionScoringTabs.Survey,
      refetchOnMountOrArgChange: true,
    },
  );

  const data = useMemo(
    () => {
      switch (currentTab) {
        case QuestionScoringTabs.Scored:
          return {
            questions: scoredData?.data.questions || [],
            total: scoredData?.data.total || 0,
          };

        case QuestionScoringTabs.Active:
          return {
            questions: activeData?.data.questions || [],
            total: activeData?.data.total || 0,
          };

        case QuestionScoringTabs.Deactivated:
          return {
            questions: deactivatedData?.data.questions || [],
            total: deactivatedData?.data.total || 0,
          };

        case QuestionScoringTabs.Survey:
          return {
            questions: surveyData?.data.questions || [],
            total: surveyData?.data.total || 0,
          };

        default:
          return {
            questions: [],
            total: 0,
          };
      }
    },
    [currentTab, scoredData, activeData, deactivatedData, surveyData]
  );

  const isLoading = isActiveLoading
    || isScoredLoading
    || isDeactivatedLoading
    || isSurveyLoading;

  return (
    <ScoringTableContent
      data={data.questions}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      total={data.total}
      isLoading={isLoading}
      withSearchQuery={!!searchQuery}
    />
  );
};
