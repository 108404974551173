import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const TileContainer = styled.div<{
  $withBorder: boolean;
  $height: number | string;
}>`
  ${tw`
    flex items-center gap-1 bg-lotion
  `}

  ${({
    $withBorder
  }) => $withBorder
    && tw`rounded-3xl md:rounded-100px border border-gray90 p-1 md:pl-3`}
  
  ${({
    $height
  }) => `
    height: ${$height === 'auto' ? 'auto' : `${$height}px`};
  `}
`;

export const TileContent = styled.div`
  ${tw`grow flex items-baseline gap-1`}
`;

export const AmountContainer = styled.div<{ $isMulti: boolean }>`
  ${tw`flex items-center`}

  ${({
    $isMulti
  }) => $isMulti && tw`gap-1`}
`;
export const Amount = styled.p<{ $applyMinWidth: boolean }>`
  ${tw`text-gray7 text-center md:font-normal font-medium`}

  ${({
    $applyMinWidth
  }) => $applyMinWidth && tw`min-w-44px`}
`;
export const AmountLeader = styled.p`
  ${tw`text-xs text-dim-gray truncate max-w-74px`}
`;

export const Growth = styled.p<{ $isGrowing: boolean }>`
  ${tw`font-['Inter'] text-15-20 md:text-xs leading-4 text-forest-green`}

  ${({
    $isGrowing
  }) => !$isGrowing && tw`text-red-text-error`}
`;

export const InfoContainer = styled.div`
  ${tw`
    flex items-center justify-center 
    w-10 h-10 rounded-full border border-gray90 
    hover:bg-input-blue transition-all
  `}
`;
export const TooltipContainer = styled.div`
  ${tw`text-center max-w-263px text-13-21 font-medium`}
`;

export const RowContainer = styled.div<{ $withBackground: boolean }>`
  ${tw`flex gap-y-1 flex-wrap items-center`}

  ${({
    $withBackground
  }) => (!$withBackground
    ? tw`bg-pale-blue rounded-md gap-x-3 px-2 py-2 md:py-3`
    : tw`flex-1 gap-x-1`)}
`;
