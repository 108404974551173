import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const GroupDetailTitle = styled.div`
  ${tw`text-22-30 font-bold text-gray7`}
`;

export const InvitedEmailsBlock = styled.div`
  ${tw`flex justify-between items-center gap-4 pt-3 pb-2 border-b border-b-gray94`}
`;
export const InvitedEmailsList = styled.div`
  ${tw`flex gap-3 items-center`}
`;
export const InvitedEmailContainer = styled.div`
  ${tw`flex flex-col gap-1`}
`;
export const InviteEmail = styled.div<{
  $isTitle?: boolean;
  $isSmall?: boolean;
}>`
  ${tw`font-medium`}

  ${({
    $isTitle
  }) => ($isTitle ? tw`text-gray7` : tw`text-grey-dark-40`)}

  ${({
    $isSmall
  }) => ($isSmall ? tw`text-13-21` : tw`text-17-26`)}
`;

export const ModalRedSpan = styled.span`
  ${tw`text-red-label`}
`;

export const ModalLabelButton = styled.button`
  ${tw`text-15-20 font-medium text-grey-dark-40 mb-2 cursor-default`}
`;

export const ModalGroupedLabel = styled.label`
  ${tw`max-h-376px min-h-48px overflow-y-scroll flex flex-wrap gap-2 p-3 rounded border border-input-border-blue bg-alice-blue`}
`;

export const ModalLabel = styled.label`
  ${tw`block text-15-20 font-medium text-grey-dark-40 mb-2`}
`;

export const ModalTextarea = styled.textarea`
  ${tw`h-48 w-full px-4 py-2 rounded border border-gray94 bg-alice-blue outline-0`}

  resize: none;
`;

export const ModalEmailHookToInvite = styled.input<{
  $isFullWidth?: boolean;
  $isTransparent?: boolean;
}>`
  ${tw`px-3 py-0.5 rounded-123px block`}

  ${({
    $isFullWidth
  }) => ($isFullWidth ? tw`w-full` : tw`w-max`)}

  ${({
    $isTransparent
  }) => ($isTransparent ? tw`border-none outline-0 bg-alice-blue` : tw`bg-list-blue`)}
`;

export const ErrorMessage = styled.div`
  ${tw`text-13-21 font-medium text-red-text-error mt-1`}
`;

export const ModalSaveSpan = styled.span`
  ${tw`text-xl text-white font-bold`}
`;

export const EditQuestionTooltip = styled.p`
  ${tw`text-center max-w-128px`}
`;

export const SideBlockToolsContainer = styled.div`
  ${tw`flex gap-4`}
`;

export const SideBlockTitleContainer = styled.div`
  ${tw`flex gap-2 items-center`}
`;

export const SideBlockMainContainer = styled.div`
  ${tw`p-6 rounded bg-white h-full flex flex-col`}
`;

export const SideBlockContainer = styled.div<{ $isRelative: boolean }>`
  ${tw`h-full`}

  ${({
    $isRelative
  }) => $isRelative && tw`relative`}
`;

export const SideBlockInfoRow = styled.div`
  ${tw`flex justify-between gap-4 mb-6`}
`;
