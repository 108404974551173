import {
  zodResolver
} from '@hookform/resolvers/zod';
import React, {
  FC
} from 'react';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  useTranslation
} from 'react-i18next';
import {
  z
} from 'zod';

import {
  usePostQuestionsRequestDeclineMutation
} from 'src/redux/openapi';
import {
  Button,
  InputTextArea,
  ModalContainer,
  successfulToast,
} from 'src/shared/components';
import {
  showWarningFromServer
} from 'src/shared/utils';

interface DeclineSetRequestModalProps {
  isOpen: boolean;
  closeModal: () => void;
  id: string;
}

export const DeclineSetRequestModal: FC<DeclineSetRequestModalProps> = ({
  isOpen,
  closeModal,
  id,
}) => {
  const {
    t
  } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: {
      errors
    },
  } = useForm({
    defaultValues: {
      message: '',
    },
    resolver: zodResolver(
      z.object({
        message: z.string().optional(),
      }),
    ),
  });

  const [declineSetRequest, {
    isLoading
  }] = usePostQuestionsRequestDeclineMutation();

  const onDecline = handleSubmit(async (data) => {
    const {
      message
    } = data;

    try {
      const response = await declineSetRequest({
        id,
        rejectQuestionCreationRequestBodySchema: {
          message,
        },
      }).unwrap();

      successfulToast(response.message);
      closeModal();
    } catch (error) {
      showWarningFromServer(error);
    }
  });

  return (
    <ModalContainer
      closeModalHandler={closeModal}
      isOpen={isOpen}
      modalTitle={t('management.declineSet')}
    >
      <form>
        <Controller
          name="message"
          control={control}
          render={({
            field: {
              value, onChange, onBlur
            }
          }) => {
            return (
              <InputTextArea
                placeholder={t(
                  'inputMessages.DESCRIBE_REASON_FOR_DECLINING_SET',
                )}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                rows={6}
                className="w-full !px-4 !py-2"
                errorMessage={errors.message?.message}
                textLabel={`${t('common.message')} (${t(
                  'common.optional',
                ).toLowerCase()})`}
                bottomMessage={t('inputMessages.SET_CREATOR_WILL_BE_NOTIFIED')}
              />
            );
          }}
        />
      </form>

      <Button
        variant="big-red"
        onClick={onDecline}
        className="w-max font-bold text-xl ml-auto mt-6"
        isLoading={isLoading}
      >
        {t('buttons.decline')}
      </Button>
    </ModalContainer>
  );
};
