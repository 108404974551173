import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const NameCellSpan = styled.span<{ $isUnread?: boolean }>`
  ${tw`font-semibold text-gray7`}

  ${({
    $isUnread
  }) => $isUnread
    && tw`relative before:(w-2.5 h-2.5 rounded-full block bg-button-blue absolute -top-2.5 -left-2.5)`}
`;

export const RightAlignedCellText = styled.p`
  ${tw`text-end`}
`;

export const CenteredCellText = styled.p`
  ${tw`text-center`}
`;

export const Status = styled.div<{ $color: string }>`
  ${tw`flex items-center gap-2 before:(w-2 h-2 rounded-full block) capitalize`}

  ${({
    $color
  }) => `
    color: ${$color};

    &:before {
      background-color: ${$color};
    }
  `}
`;
