import React from 'react';
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';

import {
  OrganizationGroup
} from 'src/redux/openapi';
import {
  numberWithPeriods
} from 'src/shared/utils';
import {
  GrayText
} from 'src/shared/components';

import {
  GroupDetails
} from '../GroupDetails';

const groupColumnsHelper = createColumnHelper<OrganizationGroup>();

export const getGroupCols = () => [
  groupColumnsHelper.accessor(
    'name',
    {
      header: t('modal.groupName'),
      footer: (props) => props.column.id,
      size: 380,
      minSize: 380,
      cell: ({
        row: {
          original: group
        }
      }) => <GroupDetails group={group} />,
    }
  ),
  groupColumnsHelper.accessor(
    'memberCount',
    {
      header: t('common.participants'),
      footer: (props) => props.column.id,
      size: 200,
      minSize: 128,
      cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
    }
  ),
  groupColumnsHelper.accessor(
    'commentCount',
    {
      header: t('common.comments'),
      footer: (props) => props.column.id,
      size: 200,
      minSize: 128,
      cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
    }
  ),
  groupColumnsHelper.accessor(
    'forecastCount',
    {
      header: t('common.updates'),
      footer: (props) => props.column.id,
      size: 200,
      minSize: 128,
      cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
    }
  ),
];
