import React from 'react';
import {
  t
} from 'i18next';
import ms from 'ms';

import {
  CommunityTabId,
  ScoredTabSortBy,
  ActiveTabSortBy,
  FollowingTabSortBy,
} from 'src/redux/community';
import {
  ClockIcon,
  HeightIcon,
  MessageIcon,
  PeopleIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  TimePeriodOptions
} from 'src/shared/components';
import {
  MONTH, YEAR
} from 'src/shared/utils';

import {
  ActiveSection, FollowingsSection, ScoredSection
} from './ui';

export const getTabsMap = () => [
  {
    id: CommunityTabId.Active,
    label: t('question.questionDiscussion'),
    content: <ActiveSection />,
  },
  {
    id: CommunityTabId.Followings,
    label: t('question.followings'),
    content: <FollowingsSection />,
  },
  {
    id: CommunityTabId.Scored,
    label: t('editAccount.scored'),
    content: <ScoredSection />,
  },
];

export const getScoredSortOptionsMap = () => [
  {
    id: ScoredTabSortBy.Newest,
    label: t('profile.Newest'),
    icon: <HeightIcon />,
  },
  {
    id: ScoredTabSortBy.Oldest,
    label: t('profile.Oldest'),
    icon: <HeightIcon className="-scale-y-100" />,
  },
  {
    id: ScoredTabSortBy.Higher,
    label: t('profile.Higher'),
    icon: <TrendingUpIcon />,
  },
  {
    id: ScoredTabSortBy.Lower,
    label: t('profile.Lower'),
    icon: <TrendingUpIcon className="-scale-y-100" />,
  },
];

export const getActiveSortOptionsMap = () => [
  {
    id: ActiveTabSortBy.Latest,
    label: t('question.latest'),
    icon: <ClockIcon />,
  },
  {
    id: ActiveTabSortBy.MostCommented,
    label: t('question.mostCommented'),
    icon: <MessageIcon />,
  },
  {
    id: ActiveTabSortBy.MostParticipants,
    label: t('question.mostParticipants'),
    icon: <PeopleIcon />,
  },
];

export const getFollowingSortOptionsMap = () => [
  {
    id: FollowingTabSortBy.MostEngaged,
    label: t('question.mostEngaged'),
    icon: <MessageIcon />,
  },
];

export const getProfileTimePeriodSelectValue = (period: TimePeriodOptions) => {
  switch (period) {
    case TimePeriodOptions.ThreeMonths: {
      return ms(3 * MONTH);
    }
    case TimePeriodOptions.SixMonth: {
      return ms(6 * MONTH);
    }
    case TimePeriodOptions.OneYear: {
      return ms(YEAR);
    }
    case TimePeriodOptions.ThreeYears: {
      return ms(3 * YEAR);
    }
    case TimePeriodOptions.OneMonth:
    default: {
      return ms(MONTH);
    }
  }
};
