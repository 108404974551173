import React, {
  FC, useEffect
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useSearchParams
} from 'react-router-dom';

import {
  Organization,
  useGetOrgGroupsUsersInvitedCountQuery,
  UserGroupRole,
} from 'src/redux/openapi';
import {
  Filters, FilterAmountTile
} from 'src/features';
import {
  CommunitySection
} from 'src/widgets';
import {
  communityTab, ROUTE
} from 'src/shared/utils';
import {
  SubIcon
} from 'src/shared/icons';

import {
  CommunityFilters, getCommunityFilters
} from './utils';
import * as Style from './CommunityContent.styles';

interface CommunityContentProps {
  searchQuery: string;
  organization: Organization;
  setSearchQuery: (value: string) => void;
}

export const CommunityContent: FC<CommunityContentProps> = ({
  searchQuery,
  setSearchQuery,
  organization,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeFilterId = (searchParams.get(communityTab) as CommunityFilters)
    || CommunityFilters.Members;

  const setActiveFilterId = (filter: CommunityFilters | null) => {
    if (!filter) {
      return;
    }

    searchParams.set(
      communityTab,
      filter
    );

    setSearchParams(
      searchParams,
      {
        replace: true,
      }
    );
  };

  useEffect(
    () => {
      setSearchQuery('');
    },
    [activeFilterId]
  );

  const {
    id, role, memberCount
  } = organization;

  const communityFilters = getCommunityFilters();

  const {
    invitedCount, refetch
  } = useGetOrgGroupsUsersInvitedCountQuery(
    {
      id,
    },
    {
      selectFromResult: ({
        data
      }) => ({
        invitedCount: data?.data.total,
      }),
    },
  );

  const {
    t
  } = useTranslation();

  const isInvitedTab = activeFilterId === CommunityFilters.Invited;

  return (
    <>
      <Style.FiltersContainer>
        <Filters
          items={communityFilters}
          activeFilterId={activeFilterId}
          onFilterClick={setActiveFilterId}
          renderRightElement={(item) => (
            <FilterAmountTile isActive={item.id === activeFilterId}>
              {item.id === CommunityFilters.Members && memberCount}

              {item.id === CommunityFilters.Invited && invitedCount}
            </FilterAmountTile>
          )}
        />

        {isInvitedTab && (
          <Style.ActivityButton
            to={`/${ROUTE.ORGANISATION}/${id}/${ROUTE.MANAGE_INVITATIONS}`}
          >
            {t('common.manage')}

            <SubIcon />
          </Style.ActivityButton>
        )}
      </Style.FiltersContainer>

      {activeFilterId === CommunityFilters.Members && (
        <CommunitySection.MembersTabContent
          searchQuery={searchQuery}
          role={role as UserGroupRole}
          organization={organization}
        />
      )}

      {isInvitedTab && (
        <CommunitySection.InvitedTabContent
          organizationId={organization.id}
          searchQuery={searchQuery}
          onInvitedUpdate={refetch}
        />
      )}
    </>
  );
};
