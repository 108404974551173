import React, {
  FC
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';

import {
  resetHomePreferences
} from 'src/redux/homePreferences';
import {
  useTypedDispatch
} from 'src/redux';

import * as Style from './EmptyResultsMessage.style';

export const EmptyResultsMessage: FC = () => {
  const {
    t
  } = useTranslation();

  const dispatch = useTypedDispatch();

  const onReset = () => dispatch(resetHomePreferences());

  return (
    <Style.EmptyMessageContainer>
      <Style.EmptyMessageTitle>
        {t('empty.thereAreNoMatchesWithFilters')}
      </Style.EmptyMessageTitle>

      <Style.EmptyMessageSubtitle>
        <Trans i18nKey="empty.tryOtherSettingsOrResetFilters" />
      </Style.EmptyMessageSubtitle>

      <Style.ResetFiltersButton onClick={onReset}>
        {t('filters.resetFilters')}
      </Style.ResetFiltersButton>
    </Style.EmptyMessageContainer>
  );
};
