import tw from 'twin.macro';
import {
  styled
} from 'styled-components';
import {
  Link
} from 'react-router-dom';

export const GroupHeaderRightSide = styled.div`
  ${tw`flex flex-col gap-1 items-start`}
`;

export const GroupDetailsButton = styled(Link)`
  ${tw`text-button-blue text-15-20 font-bold flex gap-1 items-center p-2 rounded-100px
  hover:text-accent80`}
`;

export const PinContainer = styled.div`
  ${tw`px-2`}
`;
