import React, {
  useEffect
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import {
  useGetFollowingsUsers
} from 'src/shared/hooks';
import {
  ScrollContainer, Spinner
} from 'src/shared/components';
import {
  CommunityTabId,
  setCommunityTabEmptyMessage,
  setCommunityTabLoading,
} from 'src/redux/community';
import {
  useTypedDispatch
} from 'src/redux';

import {
  FollowingCard
} from '../FollowingCard';
import {
  EmptyMessage
} from '../EmptyMessage';

export const FollowingsSection = () => {
  const {
    followingsList,
    loadNextPage,
    isLoadingMore,
    refetch,
    total: totalFollowings,
    isLoading,
  } = useGetFollowingsUsers();

  const {
    t
  } = useTranslation();

  const dispatch = useTypedDispatch();

  useEffect(
    () => {
      const tabName = CommunityTabId.Followings;

      dispatch(
        setCommunityTabLoading({
          tabName,
          loading: isLoading,
        }),
      );

      if (!isLoading) {
        const emptyMessage = totalFollowings
          ? {
            title: t('community.selectUserToOpenThread'),
          }
          : {
            title: t('community.hereActivityOfUsersYouFollow'),
          };

        dispatch(
          setCommunityTabEmptyMessage({
            tabName,
            emptyMessage,
          }),
        );
      }
    },
    [isLoading, totalFollowings]
  );

  if (isLoading) {
    return <Spinner size={24} />;
  }

  if (!totalFollowings) {
    return (
      <EmptyMessage
        title={t('community.currentlyNoFollowings')}
        subtitle={t('community.hereWillDisplayedUsersYouFollow')}
      />
    );
  }

  return (
    <ScrollContainer
      edgeHeight={48}
      className="border-t border-t-gray94"
    >
      <InfiniteScroll
        pageStart={1}
        loadMore={loadNextPage}
        hasMore={!isLoadingMore && followingsList.length < totalFollowings}
        loader={(
          <Spinner
            size={24}
            key="spinner"
          />
        )}
        useWindow={false}
        threshold={50}
      >
        {followingsList.map((user) => (
          <FollowingCard
            key={user.id}
            user={user}
            refetch={refetch}
          />
        ))}
      </InfiniteScroll>
    </ScrollContainer>
  );
};
