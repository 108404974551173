import React, {
  MouseEvent, useState
} from 'react';

import {
  Divider,
  MenuContainer,
  MenuItem,
  PopoverMenu,
} from 'src/shared/components';
import {
  MoreVerticalIcon
} from 'src/shared/icons';
import {
  themeColors
} from 'src/shared/utils';

import {
  ActionHandler, ManagementEntity
} from '../../types';

import * as Style from './EditMenu.styles';
import {
  EditMenuConfigMap, getEditMenuConfig
} from './utils';

type Action<T extends ManagementEntity> =
  EditMenuConfigMap[T][number]['action'];

interface EditMenuProps<T extends ManagementEntity> {
  onAction: ActionHandler<Action<T>>;
  type: T;
  id: string;
}

export const EditMenu = <T extends ManagementEntity>({
  onAction,
  type,
  id,
}: EditMenuProps<T>) => {
  const [shouldCloseMenu, setShouldCloseMenu] = useState(false);

  const menuConfig = getEditMenuConfig(type);

  const onMenuClick = (
    event: MouseEvent<HTMLButtonElement>,
    toggleCallback: () => void,
  ) => {
    event.stopPropagation();

    toggleCallback();
  };

  const onActionClick = (
    event: MouseEvent<HTMLButtonElement>,
    action: Action<T>,
  ) => {
    event.stopPropagation();

    onAction(
      id,
      action
    );

    setShouldCloseMenu(true);
  };

  if (!menuConfig) {
    return null;
  }

  return (
    <PopoverMenu
      placement="bottom-end"
      shouldForcedClose={shouldCloseMenu}
      onClose={() => setShouldCloseMenu(false)}
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <Style.EditButton
          type="button"
          onClick={(event) => onMenuClick(
            event,
            toggleMenu
          )}
          {...triggerProps}
          $isOpen={isOpen}
        >
          <MoreVerticalIcon className="w-6 h-6" />
        </Style.EditButton>
      )}
    >
      <MenuContainer $width={294}>
        {menuConfig.map(
          (
            {
              action, label, icon: Icon, isDelete, renderCustomAction
            },
            index,
          ) => (
            <React.Fragment key={action}>
              {isDelete && !!index && <Divider />}

              <MenuItem
                key={action}
                $color={
                  isDelete
                    ? themeColors['red-text-error']
                    : themeColors['dark-gray']
                }
                onClick={(event) => onActionClick(
                  event,
                  action
                )}
                disabled={!!renderCustomAction}
              >
                <Icon />

                {label}

                {renderCustomAction && renderCustomAction(id)}
              </MenuItem>
            </React.Fragment>
          ),
        )}
      </MenuContainer>
    </PopoverMenu>
  );
};
