import React, {
  FC, useRef, useEffect, useMemo
} from 'react';
import {
  Link, useSearchParams
} from 'react-router-dom';

import {
  SortPopoverMenu
} from 'src/shared/components';
import {
  ROUTE, communityTab, groupId, questionId
} from 'src/shared/utils';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  communitySelectors,
  CommunityTabId,
  setCommunitySortBy,
  SortByInCommunity,
} from 'src/redux/community';

import {
  Title
} from '../Sections.styles';

import * as Style from './DiscussionSection.styles';
import {
  getActiveSortOptionsMap,
  getFollowingSortOptionsMap,
  getScoredSortOptionsMap,
  getTabsMap,
} from './utils';

export const DiscussionSection: FC = () => {
  const commentToScrollRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const activeQuestionId = searchParams.get(questionId) || null;
  const activeFilterId = searchParams.get(groupId);

  const activeTab = (searchParams.get(communityTab) as CommunityTabId) || CommunityTabId.Active;

  const tabs = getTabsMap();

  const activeTabContent = tabs.find((tab) => tab.id === activeTab)?.content || null;

  const dispatch = useTypedDispatch();

  const onSortClick = (sortType: SortByInCommunity) => {
    dispatch(
      setCommunitySortBy({
        tabName: activeTab,
        sortBy: sortType,
      }),
    );
  };

  useEffect(
    () => {
      if (commentToScrollRef.current && activeQuestionId) {
        commentToScrollRef.current.scrollIntoView();
      }
    },
    [activeFilterId]
  );

  const tabsData = useTypedSelector(communitySelectors.selectAllTabs);

  const activeSortBy = tabsData[activeTab].sortBy;

  const sortByItems = useMemo(
    () => {
      if (activeTab === CommunityTabId.Followings) {
        return getFollowingSortOptionsMap();
      }

      if (activeTab === CommunityTabId.Scored) {
        return getScoredSortOptionsMap();
      }

      return getActiveSortOptionsMap();
    },
    [activeTab]
  );

  return (
    <Style.SectionContainer>
      <Style.SectionHeader>
        <Style.TitleWrapper>
          <SortPopoverMenu
            options={sortByItems}
            onSelect={(value) => onSortClick(value)}
            currentValue={activeSortBy}
          />

          <Style.TabsContainer className="scroll-hidden">
            {tabs.map((tab) => (
              <Link
                to={`/${ROUTE.COMMUNITY}?${communityTab}=${tab.id}`}
                key={tab.id}
                className="shrink-0"
              >
                <Title $isActive={activeTab === tab.id}>{tab.label}</Title>
              </Link>
            ))}
          </Style.TabsContainer>
        </Style.TitleWrapper>
      </Style.SectionHeader>

      {activeTabContent}
    </Style.SectionContainer>
  );
};
