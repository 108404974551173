import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Button, Spinner
} from 'src/shared/components';
import {
  ArrowLeftIcon
} from 'src/shared/icons';

import * as Style from './QuestionDetailsNavigation.styles';

interface QuestionDetailsNavigationProps {
  onNavigateClick: (value: number) => void;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  isDeactivated: boolean;
  handleSubmitClick: () => Promise<void>;
  disableSubmitButton: boolean;
  isSubmitting: boolean;
  totalQuestions: number;
}

export const QuestionDetailsNavigation: FC<QuestionDetailsNavigationProps> = ({
  onNavigateClick,
  isFirstQuestion,
  isLastQuestion,
  isDeactivated,
  handleSubmitClick,
  disableSubmitButton,
  isSubmitting,
  totalQuestions,
}) => {
  const {
    t
  } = useTranslation();

  const onSubmitAndNext = async () => {
    await handleSubmitClick();

    onNavigateClick(1);
  };

  return (
    <Style.MainContainer>
      {!!totalQuestions && (
        <Style.NavigateButtonsContainer>
          <Button
            variant="big-grey-bordered"
            className="w-max px-4 md:px-6 py-2.5 md:py-3 text-xl text-dim-gray font-bold h-max md:h-52px disabled:opacity-35"
            disabled={isFirstQuestion}
            onClick={() => onNavigateClick(-1)}
          >
            <ArrowLeftIcon />
          </Button>

          <Button
            variant="big-grey-bordered"
            className="w-max px-4 md:px-6 py-2.5 md:py-3 text-xl text-dim-gray font-bold h-max md:h-52px disabled:opacity-35"
            disabled={isLastQuestion}
            onClick={() => onNavigateClick(1)}
          >
            <ArrowLeftIcon className="rotate-180" />
          </Button>
        </Style.NavigateButtonsContainer>
      )}

      {!isDeactivated && (
        <Style.SubmitNextButtonsContainer>
          <Button
            variant="big-black"
            className="w-max text-16-28 md:text-xl text-white font-bold h-max md:h-52px"
            onClick={handleSubmitClick}
            disabled={disableSubmitButton}
          >
            {t('buttons.submit')}

            {isSubmitting && (
              <Spinner
                color="white"
                size={24}
                centered={false}
              />
            )}
          </Button>

          {!isLastQuestion && (
            <Button
              variant="big-blue"
              className="w-max text-16-28 md:text-xl text-white font-bold h-max md:h-52px"
              onClick={onSubmitAndNext}
              disabled={disableSubmitButton}
            >
              {t('buttons.submitAndNext')}

              {isSubmitting && (
                <Spinner
                  color="white"
                  size={24}
                  centered={false}
                />
              )}
            </Button>
          )}
        </Style.SubmitNextButtonsContainer>
      )}
    </Style.MainContainer>
  );
};
