import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const SwitcherContainer = styled.div<{ $isActive?: boolean }>`
  ${tw`
    rounded-55px w-12 p-px after:content-[''] after:block after:w-1/2
    after:bg-white after:pb-50% after:rounded-full after:transition-all cursor-pointer
  `}

  ${({
    $isActive = false
  }) => ($isActive ? tw`bg-button-blue after:translate-x-full` : tw`bg-dim-gray`)}
`;

export const SpinnerContainer = styled.div<{ $isActive?: boolean }>`
  ${tw`absolute top-0`}

  ${({
    $isActive
  }) => $isActive && tw`right-0`}
`;

export const Wrapper = styled.div`
  ${tw`relative text-button-blue`}
`;
