import React from 'react';

import {
  GridIcon, ListIcon
} from 'src/shared/icons';

export const listViewSwitchOptions = [
  {
    id: 0,
    label: <ListIcon />,
  },
  {
    id: 2,
    label: <GridIcon />,
  },
];
