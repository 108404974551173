import React, {
  FC, useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Group, useGetGroupUsersInvitesCountQuery
} from 'src/redux/openapi';
import {
  FilterAmountTile, Filters
} from 'src/features';

import {
  GroupSideBlockContainer
} from './GroupSideBlockContainer';
import {
  getGroupUserRole,
  GroupFilters,
  getGroupMembersFilters,
} from './utils';
import {
  GroupInvites, GroupMembers
} from './ui';

interface GroupMembersBlockProps {
  group: Group;
  isAdminView: boolean;
  isSuperAdminView: boolean;
}

export const GroupMembersBlock: FC<GroupMembersBlockProps> = ({
  group,
  isAdminView,
  isSuperAdminView,
}) => {
  const [membersFilter, setMembersFilter] = useState<GroupFilters | null>(
    GroupFilters.Active,
  );

  const [searchMember, setSearchMember] = useState('');

  const {
    t
  } = useTranslation();

  const {
    id: groupId,
    groupRole,
    organizationRole,
    userRole,
    memberCount,
    organizationStatus,
  } = group;

  const {
    invitedCount, refetch: refetchInvitedCount
  } = useGetGroupUsersInvitesCountQuery(
    {
      id: groupId,
    },
    {
      selectFromResult: ({
        data
      }) => ({
        invitedCount: data?.data.total || 0,
      }),
    },
  );

  const isAdmin = isAdminView || isSuperAdminView;

  const role = getGroupUserRole({
    groupRole: organizationStatus === 'ACCEPTED' ? 'USER' : groupRole,
    organizationRole,
    userRole,
  });

  useEffect(
    () => {
      setMembersFilter(GroupFilters.Active);
    },
    [groupId]
  );

  const handleSearch = (query: string) => {
    setSearchMember(query);
  };

  useEffect(
    () => {
      setSearchMember('');
    },
    [membersFilter, groupId]
  );

  const filters = getGroupMembersFilters();

  return (
    <GroupSideBlockContainer
      isMemberBlock
      searchValue={searchMember}
      handleSetSearchValue={handleSearch}
      title={t('group.members')}
    >
      {isAdmin && (
        <Filters
          items={filters}
          activeFilterId={membersFilter}
          onFilterClick={setMembersFilter}
          renderRightElement={(item) => (
            <FilterAmountTile isActive={item.id === membersFilter}>
              {item.id === GroupFilters.Active && memberCount}

              {item.id === GroupFilters.Invited && invitedCount}
            </FilterAmountTile>
          )}
        />
      )}

      {membersFilter === GroupFilters.Active && (
        <GroupMembers
          searchQuery={searchMember}
          group={group}
          role={role}
        />
      )}

      {membersFilter === GroupFilters.Invited && isAdmin && (
        <GroupInvites
          groupId={groupId}
          refetchInvitedCount={refetchInvitedCount}
          searchQuery={searchMember}
        />
      )}
    </GroupSideBlockContainer>
  );
};
