import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useNavigate
} from 'react-router-dom';

import {
  Button, Spinner
} from 'src/shared/components';
import {
  ArrowRightIcon, CheckmarkThinIcon
} from 'src/shared/icons';

import {
  FooterContainer
} from '../AddQuestionSets.styles';

interface FooterProps {
  canCreate: boolean;
  isCreationInProcess: boolean;
  handleCreation: () => Promise<void>;
  isFirstStep?: boolean;
  isLastStep?: boolean;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
}

export const Footer: FC<FooterProps> = ({
  canCreate,
  isCreationInProcess,
  handleCreation,
  isFirstStep,
  isLastStep,
  onNextClick,
  onPreviousClick,
}) => {
  const {
    t
  } = useTranslation();

  const navigate = useNavigate();

  const onCancel = () => navigate(-1);

  return (
    <FooterContainer>
      {onPreviousClick && !isFirstStep && (
        <Button
          variant="big-grey-bordered"
          className="w-max !px-7"
          onClick={onPreviousClick}
        >
          <ArrowRightIcon className="rotate-180" />
        </Button>
      )}

      <Button
        variant="big-grey-bordered"
        className="w-max text-20-24 !px-7 font-bold ml-auto"
        onClick={onCancel}
      >
        {t('buttons.cancel')}
      </Button>

      {canCreate && isLastStep && (
        <Button
          variant="big-blue"
          className="w-max text-20-24 font-bold"
          onClick={handleCreation}
        >
          <p className="shrink-0">{t('buttons.submit')}</p>

          {isCreationInProcess ? (
            <Spinner
              size={24}
              color="inherit"
            />
          ) : (
            <CheckmarkThinIcon />
          )}
        </Button>
      )}

      {onNextClick && !isLastStep && (
        <Button
          variant="big-blue"
          className="w-max text-20-24 font-bold"
          onClick={onNextClick}
        >
          <p className="shrink-0">{t('buttons.next')}</p>

          <ArrowRightIcon />
        </Button>
      )}
    </FooterContainer>
  );
};
