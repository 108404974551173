import React, {
  useId,
  useState,
  ReactNode,
  ChangeEvent,
  MouseEventHandler,
  FC,
  InputHTMLAttributes,
} from 'react';
import {
  Link
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  PasswordEye
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';

import {
  InputType
} from './types';
import * as Style from './Input.styles';

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange'> {
  type?: InputType;
  label?: string;
  onChange?: (e: string) => void;
  isRequired?: boolean;
  bottomMessage?: ReactNode;
  errorMessage?: string;
  placeholder?: string;
  step?: number;
  renderRightContent?: () => ReactNode;
  onClick?: MouseEventHandler<HTMLInputElement>;
}

export const Input: FC<InputProps> = ({
  onChange,
  type,
  value,
  label,
  isRequired,
  bottomMessage,
  errorMessage,
  placeholder,
  onBlur = () => {},
  className,
  step,
  renderRightContent,
  onClick,
}) => {
  const {
    t
  } = useTranslation();

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const htmlID = useId();

  const getInputType = () => {
    if (type === InputType.PASSWORD) {
      return isPasswordShown ? InputType.TEXT : InputType.PASSWORD;
    }

    return type;
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <label>
      {label && (
        <Style.LabelWrapper>
          <Style.Label>
            {label}

            {isRequired && <Style.Asterisk>*</Style.Asterisk>}
          </Style.Label>

          {type === InputType.PASSWORD && !isRequired && (
            <Style.ForgotPasswordContainer>
              <Link to={`/${ROUTE.RESET_PASSWORD}`}>
                {t('login.forgotPassword')}
              </Link>
            </Style.ForgotPasswordContainer>
          )}
        </Style.LabelWrapper>
      )}

      <Style.InputWrapper $isError={!!errorMessage}>
        <Style.Input
          type={getInputType()}
          id={htmlID}
          name={`${htmlID}_field`}
          value={value}
          placeholder={placeholder || label}
          autoComplete="off"
          readOnly={!onChange}
          onChange={onInputChange}
          onBlur={onBlur}
          className={className}
          step={step}
          onClick={onClick}
        />

        {renderRightContent && renderRightContent()}

        {type === InputType.PASSWORD && (
          <Style.ShowButton
            type="button"
            tabIndex={-1}
            onClick={() => setIsPasswordShown(!isPasswordShown)}
          >
            <img
              src={PasswordEye}
              alt="eye"
            />
          </Style.ShowButton>
        )}
      </Style.InputWrapper>

      {bottomMessage && !errorMessage && (
        <Style.BottomMessage>{bottomMessage}</Style.BottomMessage>
      )}

      {errorMessage && <Style.ErrorMessage>{errorMessage}</Style.ErrorMessage>}
    </label>
  );
};
