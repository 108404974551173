import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Label = styled.label`
  ${tw`flex gap-2 items-center`}
`;

export const InputContainer = styled.button`
  ${tw`block flex-1 rounded`}
`;

export const FileContainer = styled.div`
  ${tw`flex flex-col px-4 py-2 rounded justify-center items-center bg-alice-blue`}
`;

export const LoadingBlock = styled.div`
  ${tw`flex gap-1 items-center py-2 justify-center text-gray7 text-xs leading-5 font-medium`}
`;

export const Text = styled.p<{
  $isBlue?: boolean;
}>`
  ${tw`text-xs leading-5 font-medium text-center`}

  ${({
    $isBlue
  }) => ($isBlue ? tw`text-button-blue` : tw`text-gray7`)}
`;

export const Input = styled.input`
  ${tw`hidden`}
`;
