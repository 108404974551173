import React, {
  useState, FC, useEffect
} from 'react';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  ActivityTile
} from 'src/entities/Questions';
import {
  CircleButton
} from 'src/shared/components';
import {
  ArrowUpWideIcon,
  MessageIcon,
  PeopleIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  formatNumber
} from 'src/shared/utils';
import {
  QuestionEntityStatus,
  QuestionEntityType,
  QuestionSortBy,
} from 'src/shared/api/types';
import {
  SortOrder
} from 'src/redux/openapi';

import * as Style from './QuestionsOverviewCard.styles';
import {
  QuestionCards
} from './UI';

interface QuestionsOverviewCardProps {
  questionSetId?: string;
  title: string;
  dates: string;
  participantsNumber: number;
  commentsPerDay: number;
  activeMembersPerDay: number;
  questionCount?: number;
  status?: QuestionEntityType | null;
  id: string;
  filters: {
    sortBy: QuestionSortBy;
    sortOrder?: SortOrder;
  };
  searchQuestion: string;
  isAdmin?: boolean;
  canOnboardingText?: boolean;
}

export const QuestionsOverviewCard: FC<QuestionsOverviewCardProps> = ({
  title,
  dates,
  participantsNumber,
  commentsPerDay,
  activeMembersPerDay,
  questionSetId = '',
  questionCount = 0,
  status,
  id,
  filters,
  searchQuestion,
  isAdmin,
  canOnboardingText,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    t
  } = useTranslation();

  const toggleExpand = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  useEffect(
    () => {
      setIsExpanded(false);
    },
    [id]
  );

  return (
    <Style.MainContainer id="QuestionsOverviewCard">
      <Style.OverviewContainer>
        <Style.OverviewHeader>
          <Style.OverviewTitle>
            <Style.Title>{title}</Style.Title>

            <Style.Subtitle>{dates}</Style.Subtitle>
          </Style.OverviewTitle>

          {status === QuestionEntityStatus.Deactivated ? (
            <Style.DeactivatedTile>
              {t('question.deactivated')}
            </Style.DeactivatedTile>
          ) : (
            <Style.QuestionsAmount>{questionCount}</Style.QuestionsAmount>
          )}

          <CircleButton onClick={toggleExpand}>
            <ArrowUpWideIcon
              className={clsx(
                'w-6 h-6 text-dark-gray transition-all',
                {
                  'rotate-180': isExpanded,
                }
              )}
            />
          </CircleButton>
        </Style.OverviewHeader>

        <Style.ActivityRow>
          <ActivityTile
            icon={<PeopleIcon className="text-dim-gray" />}
            amount={`${participantsNumber}`}
            className="p-1 grow"
          />

          <ActivityTile
            icon={<MessageIcon className="text-dim-gray" />}
            amount={formatNumber(commentsPerDay)}
            timeRange="upToday"
            className="p-1"
          />

          <ActivityTile
            icon={<TrendingUpIcon className="text-dim-gray" />}
            amount={`${activeMembersPerDay}`}
            timeRange="upToday"
            className="p-1"
          />
        </Style.ActivityRow>
      </Style.OverviewContainer>

      {isExpanded && (
        <QuestionCards
          groupId={id}
          questionSetId={questionSetId}
          filters={filters}
          searchQuestion={searchQuestion}
          canAddOnboardingText={!!canOnboardingText}
          isAdmin={!!isAdmin}
        />
      )}
    </Style.MainContainer>
  );
};
