import React, {
  FC
} from 'react';
import {
  useParams
} from 'react-router-dom';

import {
  useGetGroupQuery
} from 'src/redux/openapi';
import {
  CreateEntityLayout,
  CreateEntityContent,
  PageLayout,
  SetOnboardingTextList,
} from 'src/widgets';
import {
  Stepper
} from 'src/shared/components';
import {
  Breadcrumbs, EditQuestionsList
} from 'src/features';
import {
  ModalSaveEditQuestions
} from 'src/features/EditQuestions/ui';

import {
  getBreadcrumbsGroupRoutes, Steps
} from './utils';
import {
  useEditQuestionsInGroup
} from './hooks/useEditQuestionsInGroup';

export const EditQuestionsInGroup: FC = () => {
  const params = useParams();

  const {
    groupId = ''
  } = params;

  const {
    data: groupApiData
  } = useGetGroupQuery(
    {
      id: groupId,
    },
    {
      skip: !groupId,
      refetchOnMountOrArgChange: true,
    },
  );

  const breadcrumbsRoutes = getBreadcrumbsGroupRoutes(
    groupApiData?.data.group.name,
  );

  const {
    isDataLoading,
    nextStepButtonConfig,
    onNextStepClick,
    questionsOnboardingText,
    onTextChange,
    completedStatus,
    activeStepId,
    steps,
    preparedSets,
    selectConfig,
    handleProceed,
    deactivationList,
    isSavingInProcess,
    onFilterChange,
    activeGroupId,
    clearDeactivationList,
    groupsFiltersConfig,
    onLoadQuestionsBySetId,
  } = useEditQuestionsInGroup();

  return (
    <>
      <PageLayout>
        <CreateEntityLayout variant="large">
          <CreateEntityContent>
            <Breadcrumbs routes={breadcrumbsRoutes} />

            <Stepper
              steps={steps}
              activeStepId={activeStepId}
              completedStatus={completedStatus}
              nextStepButtonTitle={nextStepButtonConfig.title}
              nextStepButtonIcon={nextStepButtonConfig.icon}
              handleNextStep={onNextStepClick}
              isLoading={isSavingInProcess && !deactivationList}
            />

            {activeStepId === Steps.ChooseQuestions && (
              <EditQuestionsList
                isQuestionsLoading={isDataLoading}
                preparedSets={preparedSets}
                selectConfig={selectConfig}
                groupsFiltersConfig={groupsFiltersConfig}
                activeGroupId={activeGroupId}
                onFilterChange={onFilterChange}
                onLoadQuestionsBySetId={onLoadQuestionsBySetId}
              />
            )}

            {activeStepId === Steps.SetOnboardingText && (
              <SetOnboardingTextList
                questions={questionsOnboardingText}
                onTextChange={onTextChange}
                isLoading={isDataLoading}
              />
            )}
          </CreateEntityContent>
        </CreateEntityLayout>
      </PageLayout>

      <ModalSaveEditQuestions
        closeModalHandler={clearDeactivationList}
        backButtonHandler={clearDeactivationList}
        handleProceed={handleProceed}
        isOpen={!!deactivationList}
        deactivatedList={deactivationList}
        isProceedInProcess={isSavingInProcess && !!deactivationList}
      />
    </>
  );
};
