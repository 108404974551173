import {
  createApi
} from '@reduxjs/toolkit/query/react';

import {
  Comment,
  GetQuestionArg,
  GetQuestionResponse,
  GetQuestionFeedApiArg,
  GetQuestionFeedResponse,
  GetQuestionGroupsArg,
  GetQuestionGroupsResponse,
  GetQuestionsStatisticsArg,
  GetQuestionsStatisticsResponse,
} from 'src/redux/openapi';
import {
  CommunityEventSocket, socketCommunity
} from 'src/shared/api';

import {
  baseQuery
} from '../baseQuery';

import {
  shouldAddComment
} from './utils';

interface SocketNewComment {
  message: string;
  data: {
    comment: Comment;
    isReply: boolean;
  };
}

export const questionApi = createApi({
  baseQuery,
  reducerPath: 'questionApi',
  endpoints: (builder) => ({
    getQuestionById: builder.mutation<GetQuestionResponse, GetQuestionArg>({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}`,
      }),
    }),
    getQuestionStatistics: builder.mutation<
    GetQuestionsStatisticsResponse,
    GetQuestionsStatisticsArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/statistics`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getQuestionGroups: builder.mutation<
    GetQuestionGroupsResponse,
    GetQuestionGroupsArg
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/groups`,
      }),
    }),
    getQuestionsByIdFeed: builder.query<
    GetQuestionFeedResponse,
    GetQuestionFeedApiArg & { currentUserId?: string }
    >({
      query: (queryArg) => ({
        url: `/api/questions/${queryArg.id}/feed`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
      async onCacheEntryAdded(
        queryArg,
        {
          cacheDataLoaded, cacheEntryRemoved, updateCachedData
        },
      ) {
        const socket = socketCommunity;

        try {
          await cacheDataLoaded;

          socket.on(
            `${CommunityEventSocket.QuestionComments}-${queryArg.id}`,
            (data: SocketNewComment) => {
              if (data.data.comment) {
                updateCachedData((draft) => {
                  if (!data.data.isReply) {
                    const shouldAdd = shouldAddComment(
                      data.data.comment,
                      queryArg.filters,
                      queryArg.currentUserId,
                    );

                    if (!shouldAdd) {
                      return;
                    }

                    draft.data.feed.unshift(data.data.comment);
                    draft.data.total += 1;
                  } else {
                    const index = draft.data.feed.findIndex(
                      (comment) => comment.id === data.data.comment.id,
                    );

                    if (index !== -1) {
                      draft.data.feed.splice(
                        index,
                        1,
                        data.data.comment
                      );
                    }
                  }
                });
              }
            },
          );
        } catch (error) {
          /** empty */
        }

        await cacheEntryRemoved;

        socket.off(`${CommunityEventSocket.QuestionComments}-${queryArg.id}`);
      },
    }),
  }),
});

export const {
  useGetQuestionByIdMutation,
  useGetQuestionStatisticsMutation,
  useGetQuestionGroupsMutation,
  useGetQuestionsByIdFeedQuery,
} = questionApi;
