import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const QuestionContainer = styled.div`
  ${tw`py-3 flex flex-col gap-1 border-t border-t-gray94 first:border-t-0`}
`;

export const QuestionTitleRow = styled.div`
  ${tw`flex justify-between gap-1 py-2`}
`;

export const QuestionTitle = styled.p`
  ${tw`font-bold text-13-21 text-dark-gray`}
`;

export const QuestionDetails = styled.div`
  ${tw`text-xs leading-5 font-medium text-dim-gray`}
`;

export const QuestionInfo = styled.div`
  ${tw`flex items-center justify-between gap-6`}
`;

export const QuestionInfoWrapper = styled.div<{ $gap: number }>`
  ${tw`flex items-center justify-between text-13`}

  ${({
    $gap
  }) => `gap: ${$gap}px`}
`;

export const DeactivatedTile = styled.div`
  ${tw`rounded-100px bg-gray-whisper py-1 px-2 text-dim-gray font-medium leading-4 text-xs`}
`;

export const MenuWrapper = styled.div`
  ${tw`flex gap-3 items-center`}
`;
