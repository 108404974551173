import React from 'react';
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';
import clsx from 'clsx';

import {
  GroupQuestionSet
} from 'src/redux/openapi';
import {
  QuestionsOverviewCardStyles as QuestionStyles
} from 'src/widgets';
import {
  formatDateRange
} from 'src/shared/utils';
import {
  QuestionEntityStatus
} from 'src/shared/api/types';
import {
  CircleButton, GrayText
} from 'src/shared/components';
import {
  ArrowUpWideIcon
} from 'src/shared/icons';

const setsColumnsHelper = createColumnHelper<GroupQuestionSet>();

export const getSetsCols = (isAdmin: boolean, language: string) => [
  setsColumnsHelper.accessor(
    'title',
    {
      header: t('question.setName'),
      footer: (props) => props.column.id,
      size: 380,
      minSize: 380,
      cell: ({
        row: {
          original: questionSet
        }
      }) => {
        const dates = formatDateRange({
          start: questionSet.startDate,
          end: questionSet.endDate,
          language,
        });

        return (
          <QuestionStyles.OverviewTitle>
            <QuestionStyles.Title>{questionSet.title}</QuestionStyles.Title>

            <QuestionStyles.Subtitle>{dates}</QuestionStyles.Subtitle>
          </QuestionStyles.OverviewTitle>
        );
      },
    }
  ),
  setsColumnsHelper.accessor(
    'participantsCount',
    {
      header: t('common.participants'),
      footer: (props) => props.column.id,
      size: 200,
      minSize: 128,
      cell: (info) => <GrayText>{info.getValue()}</GrayText>,
    }
  ),
  setsColumnsHelper.accessor(
    'commentCount',
    {
      header: t('common.comments'),
      footer: (props) => props.column.id,
      size: 200,
      minSize: 128,
      cell: (info) => <GrayText>{info.getValue()}</GrayText>,
    }
  ),
  setsColumnsHelper.accessor(
    'forecastCount',
    {
      header: t('common.updates'),
      footer: (props) => props.column.id,
      size: 200,
      minSize: 128,
      cell: (info) => <GrayText>{info.getValue()}</GrayText>,
    }
  ),
  setsColumnsHelper.accessor(
    'id',
    {
      header: '',
      footer: (props) => props.column.id,
      size: 200,
      minSize: 128,
      cell: ({
        row: {
          original: questionSet, toggleExpanded, getIsExpanded
        },
      }) => (
        <div className="flex gap-3 items-center justify-end">
          {questionSet.status === QuestionEntityStatus.Deactivated ? (
            <QuestionStyles.DeactivatedTile>
              {t('question.deactivated')}
            </QuestionStyles.DeactivatedTile>
          ) : (
            <QuestionStyles.QuestionsAmount>
              {questionSet.questionCount}
            </QuestionStyles.QuestionsAmount>
          )}

          <CircleButton onClick={() => toggleExpanded()}>
            <ArrowUpWideIcon
              className={clsx(
                'w-6 h-6 text-dark-gray transition-all',
                {
                  'rotate-180': getIsExpanded(),
                }
              )}
            />
          </CircleButton>
        </div>
      ),
    }
  ),
];
