import React, {
  FC
} from 'react';

import {
  Pagination
} from 'src/shared/components';

import * as Style from './TableFooter.styles';

interface TableFooterProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  onPageSizeChange?: (value: number) => void;
  selectorLabel?: string;
}

export const TableFooter: FC<TableFooterProps> = ({
  totalCount,
  pageSize,
  currentPage,
  onPageChange,
  onPageSizeChange,
  selectorLabel,
}) => {
  return (
    <Style.TableFooter>
      <Pagination
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        pageRange={12}
        pageMargin={1}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={selectorLabel}
      />
    </Style.TableFooter>
  );
};
