import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  t
} from 'i18next';
import hexToRgba from 'hex-to-rgba';

import {
  TimelineConfig
} from 'src/entities/Graph/types';
import {
  FilterDateType
} from 'src/widgets/CommentsFilterContext/types';
import {
  Comment, ErrorResponse
} from 'src/redux/openapi';
import {
  TimeSpansValues
} from 'src/redux/timeSpans';
import {
  warningToast
} from 'src/shared/components/Toasts';

import {
  defaultHexColor
} from './constants';

dayjs.extend(utc);
dayjs.extend(timezone);

export const noop = () => {};

export const getPercent = (val: number, total: number) => Math.floor((val / total) * 100) || 0;

export const numberWithPeriods = (x: number) => {
  return x.toString().replace(
    /\B(?=(\d{3})+(?!\d))/g,
    '.'
  );
};

export const formatNumber = (value: number) => {
  return value.toLocaleString('en-US').replace(
    /,/g,
    '.'
  );
};

export const getIsNewComment = (time: number, days?: number) => {
  const now = dayjs().utc();
  const daysToAdd = days || 0;
  const oneSecond = 1000;
  const oneDay = oneSecond * 60 * 60 * 24;

  const newTime = dayjs(time).add(
    1,
    'seconds'
  ).add(
    daysToAdd,
    'days'
  );

  const timeDeltaComment = days
    ? now.diff(newTime) / oneDay
    : now.diff(newTime) / oneSecond;

  return timeDeltaComment < 0;
};

export const getSearchRegex = (searchQuery: string) => new RegExp(
  `(${searchQuery.replace(
    /[.*+?^${}()|[\]\\]/g,
    '\\$&'
  )})`,
  'gi'
);

export const getTimeLineTicks = ({
  startDate,
  endDate,
  timeGap,
}: TimelineConfig) => {
  const timelineTicks: number[] = [endDate];

  while (true) {
    const prevTickDate = new Date(timelineTicks[0]).setHours(
      0,
      0,
      0,
      0
    );

    const currentTickDate = new Date(prevTickDate - timeGap).setHours(
      0,
      0,
      0,
      0,
    );

    if (currentTickDate <= startDate) {
      timelineTicks.unshift(currentTickDate);

      break;
    }

    timelineTicks.unshift(currentTickDate);
  }

  return timelineTicks;
};

export const measureText = (pText: string, pFontSize: number) => {
  let lDiv: HTMLDivElement | null = document.createElement('div');

  document.body.appendChild(lDiv);

  lDiv.style.fontSize = `${pFontSize}px`;
  lDiv.style.fontFamily = `Urbanist`;
  lDiv.style.position = 'absolute';
  lDiv.style.left = `-1000px`;
  lDiv.style.top = '-1000px';

  lDiv.textContent = pText;

  const lResult = {
    width: lDiv.clientWidth,
  };

  document.body.removeChild(lDiv);
  lDiv = null;

  return lResult.width;
};

export const getTextareaRowsCount = (lineWidth: number, textData: string) => {
  let rows = 1;
  let lineText = '';

  const splittedText = textData.split(' ');

  splittedText.forEach((text) => {
    const line = `${lineText}${lineText === '' ? '' : ' '}${text}`;

    const isBiggerLine = measureText(
      line,
      17
    ) > lineWidth;

    if (isBiggerLine) {
      rows += 1;
      lineText = text;
    } else {
      lineText = `${lineText} ${text}`;
    }
  });

  return rows;
};

export const getSimpleCardGapValue = (
  isChildren: boolean,
  isOnboardText: boolean,
  isMobile: boolean,
) => {
  if (isChildren && !isMobile) {
    return 16;
  }

  if (isOnboardText) {
    return 8;
  }

  return 12;
};

export const getCharacterValidationError = (str: string) => {
  const errorMessageStart = t('passwordValidation.errorMessageStart');
  const character = t('passwordValidation.character');

  return `${errorMessageStart} ${str} ${character}`;
};

export const replaceDotWithComma = (value: number | string) => {
  return value.toString().replace(
    '.',
    ','
  );
};

export const isOrganization = (
  objectToCheck: Record<string, unknown>,
): objectToCheck is { groupName: string } => {
  return 'groupName' in objectToCheck;
};

export const prepareGroupIdForGraph = (id: string) => {
  return id.endsWith('.total') ? id.slice(
    0,
    -6
  ) : id;
};

export const isComment = (
  objectToCheck: Record<string, unknown>,
): objectToCheck is Comment => {
  return 'comments' in objectToCheck;
};

export const getCommentsTimeRange = (timeRange: FilterDateType) => {
  switch (timeRange) {
    case FilterDateType.IN_48_HOURS:
      return `/ 48${t('date.h')}`;
    case FilterDateType.WEEK:
      return `/ 7${t('date.d')}`;
    case FilterDateType.ALL_TIME:
      return t('filters.allTime');
    case FilterDateType.IN_24_HOURS:
    default:
      return `/ 24${t('date.h')}`;
  }
};

export const getCapacitySingle = (value: TimeSpansValues) => {
  switch (value) {
    case TimeSpansValues.last7Days:
      return 7;

    case TimeSpansValues.twoWeeks:
      return 14;

    case TimeSpansValues.last30Days:
      return 10;

    case TimeSpansValues.upToday:
    default:
      return 5;
  }
};

export const isErrorWithMessage = (error: unknown): error is ErrorResponse => {
  const errorResponse = error as ErrorResponse;

  return !!errorResponse?.data?.message;
};

export const showWarningFromServer = (
  error: unknown,
  customErrorMessage?: string,
) => {
  const errorMessage = isErrorWithMessage(error)
    ? error.data.message
    : customErrorMessage;

  if (errorMessage) {
    warningToast(errorMessage);
  }
};

const isArrayEmpty = <T>(arr: T[]) => arr.length === 0;

export const areAllArraysInObjectEmpty = <T>(
  obj: Record<string, T>,
): boolean => {
  return Object.values(obj).every((value): boolean => {
    if (Array.isArray(value)) {
      return isArrayEmpty(value);
    }

    if (typeof value === 'object' && value !== null) {
      return areAllArraysInObjectEmpty(value as Record<string, T>);
    }

    return true;
  });
};

export const getMaxAllowedFileSize = (maxMbFileSize: number) => {
  return maxMbFileSize * 1024 * 1024;
};

export const getTrimmedName = (name: string) => {
  return name.length > 5 ? `${name.slice(
    0,
    5
  )}...` : name;
};

export const handleSetHexColorValues = (hexColor: string) => {
  document.documentElement.style.setProperty(
    '--color-primary',
    hexToRgba(hexColor),
  );

  document.documentElement.style.setProperty(
    '--color-primary-80',
    hexToRgba(
      hexColor,
      '0.8'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-60',
    hexToRgba(
      hexColor,
      '0.6'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-40',
    hexToRgba(
      hexColor,
      '0.4'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-20',
    hexToRgba(
      hexColor,
      '0.2'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-10',
    hexToRgba(
      hexColor,
      '0.1'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-05',
    hexToRgba(
      hexColor,
      '0.05'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-bg',
    hexToRgba(
      hexColor,
      '0.05'
    ),
  );
};

export const rgbToRgba = (rgb: string, opacityValue: string) => {
  const [r, g, b] = rgb.match(/\d+/g) || [];

  return `rgba(${r}, ${g}, ${b}, ${opacityValue})`;
};

export const handleSetRgbColorValues = (rgbColor: string) => {
  document.documentElement.style.setProperty(
    '--color-primary',
    rgbToRgba(
      rgbColor,
      '1'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-80',
    rgbToRgba(
      rgbColor,
      '0.8'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-60',
    rgbToRgba(
      rgbColor,
      '0.6'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-40',
    rgbToRgba(
      rgbColor,
      '0.4'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-20',
    rgbToRgba(
      rgbColor,
      '0.2'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-10',
    rgbToRgba(
      rgbColor,
      '0.1'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-05',
    rgbToRgba(
      rgbColor,
      '0.05'
    ),
  );

  document.documentElement.style.setProperty(
    '--color-primary-bg',
    rgbToRgba(
      rgbColor,
      '0.05'
    ),
  );
};

export const handleSetDefaultColorValues = () => {
  document.documentElement.style.setProperty(
    '--color-primary',
    defaultHexColor,
  );

  document.documentElement.style.setProperty(
    '--color-primary-80',
    '#4E81F7'
  );

  document.documentElement.style.setProperty(
    '--color-primary-60',
    '#7AA0F9'
  );

  document.documentElement.style.setProperty(
    '--color-primary-40',
    '#A7C0FB'
  );

  document.documentElement.style.setProperty(
    '--color-primary-20',
    '#D3DFFD'
  );

  document.documentElement.style.setProperty(
    '--color-primary-10',
    '#E9EFFE'
  );

  document.documentElement.style.setProperty(
    '--color-primary-05',
    '#F4F7FE'
  );

  document.documentElement.style.setProperty(
    '--color-primary-bg',
    '#F1F3F6'
  );
};

export const isHexColor = (color: string) => color.startsWith('#');
export const cutRgbInColorName = (color: string) => color.replace(
  'rgb',
  ''
);

export const getArrayOfDates = (totalDelta: number, start: number) => {
  return Array.from(
    {
      length: totalDelta + 1,
    },
    (_, i) => start + i,
  );
};

export const getConfidenceStatus = ({
  correct,
  confidence,
  total,
}: {
  correct: number;
  confidence: number;
  total: number;
}) => {
  const correctPercent = getPercent(
    correct,
    total
  );

  const deltaOfConfidence = correctPercent - confidence;

  if (deltaOfConfidence < -5) {
    return 'overconfident';
  }

  if (deltaOfConfidence > 5) {
    return 'underconfident';
  }

  return 'confident';
};

export const onFileDownload = (data: Blob, filename: string) => {
  const downloadUrl = window.URL.createObjectURL(data as Blob);

  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
