import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Divider
} from 'src/shared/components';
import {
  themeColors
} from 'src/shared/utils';

import * as Style from './MembersRole.styles';
import {
  Variant, UsersRole
} from './types';

interface MembersRoleProps {
  usersRole?: UsersRole;
  variant?: Variant;
}

export const MembersRole: FC<MembersRoleProps> = ({
  usersRole = 'users',
  variant = 'horizontal',
}) => {
  const {
    t
  } = useTranslation();

  const label = usersRole === 'users' ? t('group.members') : t('common.admins');

  return (
    <Style.MemberRoleWrapper $variant={variant}>
      <Style.MemberRole $variant={variant}>{label}</Style.MemberRole>

      <Divider $color={themeColors['gray-whisper']} />
    </Style.MemberRoleWrapper>
  );
};
