import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CardForecast = styled.div`
  ${tw`flex items-center gap-1 text-dark-gray font-medium text-13-21`}
`;

export const HeaderWrapper = styled.div<{ $isCard?: boolean }>`
  ${tw`flex flex-col py-2 h-full`}

  ${({
    $isCard
  }) => ($isCard ? tw`gap-4` : tw`gap-1 pr-8`)}
`;

export const InfoWrapper = styled.div`
  ${tw`flex justify-between items-center`}
`;

export const ForecastWrapper = styled.div`
  ${tw`flex flex-col gap-1 items-end`}
`;

export const ActivityRow = styled.div`
  ${tw`flex justify-between items-center mt-auto`}
`;

export const ActivityChild = styled.div`
  ${tw`flex items-center gap-18px`}
`;
