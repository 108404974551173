import {
  useEffect, useState
} from 'react';

import {
  paginationSelectOptions
} from '../ui';

export const usePaginationSettings = (searchQuery?: string) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(paginationSelectOptions[0].value);

  const onPageSizeChange = (value: number) => {
    if (!value) {
      return;
    }

    setPageSize(value);
    setCurrentPage(1);
  };

  useEffect(
    () => {
      setCurrentPage(1);
    },
    [searchQuery]
  );

  return {
    pageSize,
    onPageSizeChange,
    setCurrentPage,
    currentPage,
  };
};
