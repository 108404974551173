import {
  CommentsFilterReducer
} from './commentsFilterSlice';

const selectAllFeed = (state: CommentsFilterReducer) => {
  return state.commentsFilter.feed;
};

const selectFeedShowMyOnly = (state: CommentsFilterReducer) => {
  return state.commentsFilter.feed.showMyOnly;
};

const selectFeedShowForecasts = (state: CommentsFilterReducer) => {
  return state.commentsFilter.feed.showForecasts;
};

const selectFeedSelectedGroups = (state: CommentsFilterReducer) => {
  return state.commentsFilter.feed.selectedGroups;
};

const selectFeedSelectedDateRange = (state: CommentsFilterReducer) => {
  return state.commentsFilter.feed.selectedDateRange;
};

const selectFeedCommentsTimeRange = (state: CommentsFilterReducer) => {
  return state.commentsFilter.feed.commentsTimeRange;
};

const selectFeedCommentsSortBy = (state: CommentsFilterReducer) => {
  return state.commentsFilter.feed.commentsSortBy;
};

export const commentsFilterSelectors = {
  selectAllFeed,
  selectFeedShowMyOnly,
  selectFeedSelectedGroups,
  selectFeedSelectedDateRange,
  selectFeedCommentsTimeRange,
  selectFeedCommentsSortBy,
  selectFeedShowForecasts,
};
