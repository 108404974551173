import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';

import * as Style from './Table.styles';

const TOP_SHIFT = 24;

export const Table: FC<PropsWithChildren> = ({
  children
}) => {
  const [maxTableHeight, setMaxTableHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const calculateMaxHeight = () => {
    if (contentRef?.current && window) {
      const {
        top
      } = contentRef.current.getBoundingClientRect();

      const distance = window.innerHeight - top - TOP_SHIFT;
      setMaxTableHeight(distance);
    }
  };

  useEffect(
    () => {
      calculateMaxHeight();

      window.addEventListener(
        'resize',
        calculateMaxHeight
      );

      return () => {
        window.removeEventListener(
          'resize',
          calculateMaxHeight
        );
      };
    },
    []
  );

  return (
    <Style.TableContentWrapper
      ref={contentRef}
      style={{
        height: `${maxTableHeight}px`,
      }}
    >
      {children}
    </Style.TableContentWrapper>
  );
};
