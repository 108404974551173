import {
  useState, useEffect, useMemo
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';

import {
  useGetOrgGroupsQuestionsActiveQuery
} from 'src/redux/openapi';
import {
  showWarningFromServer,
  groupId as groupIdParam,
} from 'src/shared/utils';
import {
  useLocalStorage, useNestedSelect
} from 'src/shared/hooks';
import {
  useFiltersInfiniteScroll
} from 'src/features/Filters';

import {
  UseEditQuestionsArgs, CachedSelectedItem
} from '../types';
import {
  getInitialSelectedItems, getSelectedIds
} from '../utils';

import {
  useGetQuestionsToEdit
} from './useGetQuestionsToEdit';
import {
  useSaveEditQuestionsResult
} from './useSaveEditQuestionsResult';
import {
  useSaveEditQuestionsAllResult
} from './useSaveEditQuestionsAllResult';

const storageKey = 'selectedQuestions';

export const useEditQuestions = ({
  groupId,
  organizationId,
  shouldCache,
  skip,
}: UseEditQuestionsArgs) => {
  const [groupsPage, setGroupsPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const activeGroupId = searchParams.get(groupIdParam) || null;

  const setActiveGroupId = (id: string | null) => {
    if (id) {
      searchParams.set(
        groupIdParam,
        id
      );
    } else {
      searchParams.delete(groupIdParam);
    }

    setSearchParams(searchParams);
  };

  const [cachedSelectedItems, setCachedAllSelectedItems] = useLocalStorage<
  CachedSelectedItem[]
  >(
    storageKey,
    []
  );

  const cachedInitialSelectedItems = useMemo(
    () => cachedSelectedItems.find((item) => item.id === activeGroupId)
      ?.selectedItems,
    [activeGroupId],
  );

  useEffect(
    () => {
      const removeCachedItems = () => {
        localStorage.removeItem(storageKey);
      };

      if (shouldCache) {
        window.addEventListener(
          'beforeunload',
          removeCachedItems
        );
      }

      return () => {
        removeCachedItems();

        window.removeEventListener(
          'beforeunload',
          removeCachedItems
        );
      };
    },
    []
  );

  const {
    activatedQuestions,
    isQuestionsLoading,
    setIsQuestionsLoading,
    preparedSets,
    onLoadQuestionsBySetId,
  } = useGetQuestionsToEdit({
    groupId: groupId ?? activeGroupId,
  });

  const idToSave = groupId ?? activeGroupId ?? '';

  const {
    isSavingInProcess: isSingleSavingInProcess,
    onSave: onSingleSave,
    deactivationList: deactivationSingleList,
    handleProceed: handleSingleProceed,
    clearDeactivationList: clearSingleDeactivationList,
  } = useSaveEditQuestionsResult(idToSave);

  const {
    isSavingInProcess: isAllSavingInProcess,
    onSave: onAllSave,
    deactivationList: deactivationAllList,
    handleProceed: handleAllProceed,
    clearDeactivationList: clearAllDeactivationList,
  } = useSaveEditQuestionsAllResult();

  const isSavingInProcess = shouldCache
    ? isAllSavingInProcess
    : isSingleSavingInProcess;

  const handleProceed = shouldCache ? handleAllProceed : handleSingleProceed;

  const deactivationList = shouldCache
    ? deactivationAllList
    : deactivationSingleList;

  const clearDeactivationList = shouldCache
    ? clearAllDeactivationList
    : clearSingleDeactivationList;

  const initialSelectedItems = useMemo(
    () => getInitialSelectedItems(activatedQuestions),
    [activatedQuestions],
  );

  const selectConfig = useNestedSelect({
    initialSelectedItems: cachedInitialSelectedItems || initialSelectedItems,
  });

  const {
    selectedItems
  } = selectConfig;

  const {
    data: organizationGroupsData,
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
    isError: isGroupsError,
  } = useGetOrgGroupsQuestionsActiveQuery(
    {
      id: organizationId || '',
      page: groupsPage,
      limit: 10,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !organizationId || skip,
    },
  );

  const {
    total: totalGroups, groups: currentGroups
  } = useMemo(
    () => organizationGroupsData?.data || {
      total: 0,
      groups: [],
    },
    [organizationGroupsData],
  );

  const {
    allItems: allGroups,
    onLoadMore: onLoadGroupsMore,
    hasNextPage: hasGroupsNextPage,
    loading: isGroupsNextLoading,
    setAllItems: setAllGroups,
  } = useFiltersInfiniteScroll({
    total: totalGroups,
    currentItems: currentGroups,
    isFetching: isGroupsFetching,
    isLoading: isGroupsLoading,
    isError: isGroupsError,
    currentPage: groupsPage,
    setCurrentPage: setGroupsPage,
  });

  const preparedGroupFilters = useMemo(
    () => {
      if (!allGroups) {
        return null;
      }

      return (
        allGroups.map(({
          id: orgGroupId, name, activeQuestionCount
        }) => {
          return {
            id: orgGroupId,
            label: name,
            activeQuestionCount,
          };
        }) || []
      );
    },
    [allGroups, cachedInitialSelectedItems, shouldCache]
  );

  const groupsFiltersConfig = {
    onLoadGroupsMore,
    hasGroupsNextPage,
    isGroupsNextLoading,
    filters: preparedGroupFilters,
  };

  useEffect(
    () => {
      if (!activeGroupId || !shouldCache) {
        return;
      }

      const isExists = cachedSelectedItems.find(
        (item) => item.id === activeGroupId,
      );

      if (!isExists) {
        const name = preparedGroupFilters?.find((filter) => filter.id === activeGroupId)
          ?.label || '';

        setCachedAllSelectedItems([
          ...cachedSelectedItems,
          {
            id: activeGroupId,
            name,
            selectedItems,
          },
        ]);

        return;
      }

      const updatedValues = cachedSelectedItems.map((item) => (item.id === activeGroupId
        ? {
          ...item,
          selectedItems,
        }
        : item),);

      setCachedAllSelectedItems(updatedValues);
    },
    [selectedItems]
  );

  useEffect(
    () => {
      if (!activeGroupId && preparedGroupFilters?.length) {
        setActiveGroupId(preparedGroupFilters[0].id);
      }
    },
    [preparedGroupFilters]
  );

  const handleSave = async () => {
    try {
      const selectedList = getSelectedIds(selectedItems);

      await onSingleSave(selectedList);

      if (!organizationId) {
        return;
      }

      setAllGroups((prevGroups) => prevGroups.map((group) => {
        return group.id === activeGroupId
          ? {
            ...group,
            activeQuestionCount: selectedList.length,
          }
          : group;
      }),);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const handleSaveAll = async () => {
    await onAllSave(cachedSelectedItems);
  };

  const onSave = shouldCache ? handleSaveAll : handleSave;

  const onFilterChange = (currentId: string | null) => {
    if (shouldCache) {
      setAllGroups((prevGroups) => prevGroups.map((group) => {
        const selected = cachedSelectedItems.find(
          (item) => item.id === activeGroupId,
        )?.selectedItems;

        const count = selected
          ? selected.flatMap(
            (selectedItem) => selectedItem.selectedChildrenId,
          ).length
          : group.activeQuestionCount;

        return group.id === activeGroupId
          ? {
            ...group,
            activeQuestionCount: count,
          }
          : group;
      }),);
    }

    setIsQuestionsLoading(true);
    setActiveGroupId(currentId);
  };

  return {
    onSave,
    preparedSets,
    isQuestionsLoading,
    onFilterChange,
    selectConfig,
    isSavingInProcess,
    activeGroupId,
    deactivationList,
    handleProceed,
    clearDeactivationList,
    groupsFiltersConfig,
    onLoadQuestionsBySetId,
  };
};

export type UseEditQuestions = ReturnType<typeof useEditQuestions>;
