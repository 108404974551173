import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

import {
  themeColors
} from 'src/shared/utils';

export const MenuContainer = styled.div<{
  $width?: number | 'min-content' | 'max-content';
}>`
  ${tw`bg-white shadow-menu-shadow rounded flex flex-col gap-1 p-2`}

  ${({
    $width = 230
  }) => `width: ${typeof $width === 'number' ? `${$width}px` : $width};`}
`;

export const MenuTitle = styled.div`
  ${tw`text-13 font-medium text-dark-gray px-2 py-1`}
`;

export const MenuItem = styled.button<{
  $isActive?: boolean;
  $withHover?: boolean;
  $color?: string;
}>`
  ${tw`flex gap-2 text-17-26 font-medium p-2 transition-all items-center`}

  ${({
    $color = themeColors['dark-gray']
  }) => `color: ${$color};`}

  ${({
    $isActive
  }) => $isActive && tw`bg-alice-blue text-button-blue`}

  ${({
    $withHover
  }) => $withHover && tw`hover:(bg-alice-blue text-button-blue)`}
`;
