import React from 'react';
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';

import {
  ManagementActiveQuestion,
  ManagementDeactivatedQuestion,
  ManagementSurveyQuestion,
} from 'src/redux/openapi';
import {
  formatDateRange, numberWithPeriods, ROUTE
} from 'src/shared/utils';
import {
  Button, GrayText
} from 'src/shared/components';
import {
  HeaderRow, StatisticTile
} from 'src/entities/Questions';

import {
  DownloadExPostDebasing
} from '../Buttons';

import * as Style from './ScoringTabContent.style';

export enum Filters {
  ArqQuestions = 'arqQuestions',
  OrgQuestions = 'organisationQuestions',
  WithFeedback = 'withFeedback',
}

export enum QuestionScoringTabs {
  Active = 'active',
  Deactivated = 'deactivated',
  Scored = 'scored',
  Survey = 'survey',
}

export const getFiltersMap = (
  isAdmin: boolean,
  currentTab: QuestionScoringTabs,
) => {
  const isSurveyTab = currentTab === QuestionScoringTabs.Survey;
  const isScoredTab = currentTab === QuestionScoringTabs.Scored;

  if ((!isAdmin && !isScoredTab) || isSurveyTab) {
    return null;
  }

  const adminFilters = [
    {
      id: Filters.ArqQuestions,
      label: t('management.arqQuestions'),
    },
    {
      id: Filters.OrgQuestions,
      label: t('management.orgQuestions'),
    },
  ];

  if (!isScoredTab) {
    return adminFilters;
  }

  const scoredFilters = [
    {
      id: Filters.WithFeedback,
      label: t('management.withFeedback'),
    },
  ];

  if (!isAdmin) {
    return scoredFilters;
  }

  return [...adminFilters, ...scoredFilters];
};

export const getTabsMap = (isArqAdmin: boolean) => {
  const defaultTabs = [
    {
      id: QuestionScoringTabs.Active,
      label: t('common.active'),
    },
    {
      id: QuestionScoringTabs.Deactivated,
      label: t('common.deactivated'),
    },
    {
      id: QuestionScoringTabs.Scored,
      label: t('common.scored'),
    },
  ];

  if (!isArqAdmin) {
    return defaultTabs;
  }

  return [
    ...defaultTabs,
    {
      id: QuestionScoringTabs.Survey,
      label: t('common.surveys'),
    },
  ];
};

export type QuestionRow =
  | ManagementActiveQuestion
  | ManagementDeactivatedQuestion
  | ManagementSurveyQuestion;

const activeColumnsHelper = createColumnHelper<QuestionRow>();

const checkResult = (
  question: QuestionRow,
): question is ManagementDeactivatedQuestion => {
  return 'result' in question && question.result !== null;
};

const checkSurvey = (
  question: QuestionRow,
): question is ManagementSurveyQuestion => {
  return 'surveyQuestions' in question;
};

export type QuestionToScore = Pick<
ManagementActiveQuestion,
'id' | 'startDate' | 'endDate'
>;

export const getQuestionColumns = (
  language: string,
  setQuestionToScore: (question: QuestionToScore) => void,
  currentTab: QuestionScoringTabs,
) => {
  const isScoredTab = currentTab === QuestionScoringTabs.Scored;
  const isSurveyTab = currentTab === QuestionScoringTabs.Survey;

  const columns = [
    activeColumnsHelper.accessor(
      'title',
      {
        header: t('modal.groupName'),
        footer: (props) => props.column.id,
        size: isSurveyTab || isScoredTab ? 550 : 450,
        minSize: 400,
        cell: ({
          row: {
            original: question
          }
        }) => {
          const isResult = checkResult(question);
          const isSurvey = checkSurvey(question);

          return (
            <Style.DetailsLink
              className="group"
              to={`/${ROUTE.QUESTION}/${question.id}`}
            >
              <HeaderRow
                question={question.questionSet.title}
                date={formatDateRange({
                  start: question.startDate,
                  end: question.endDate,
                  language,
                })}
                isSurvey={question.type === 'SURVEY'}
                isTable
              />

              <Style.QuestionTitle>{question.title}</Style.QuestionTitle>

              {isResult && (
              <Style.Result>
                {question.result ? t('buttons.yes') : t('buttons.no')}
              </Style.Result>
              )}

              {isSurvey
              && question.surveyQuestions.map((surveyQuestion) => (
                <Style.Result>
                  {`${surveyQuestion.title}: ${surveyQuestion.topOption.title}`}
                </Style.Result>
              ))}
            </Style.DetailsLink>
          );
        },
      }
    ),
    activeColumnsHelper.accessor(
      'globalEstimatesAvg',
      {
        header: t('common.forecasts'),
        footer: (props) => props.column.id,
        size: 150,
        minSize: 120,
        cell: ({
          row: {
            original: question
          }
        }) => {
          const isSurvey = checkSurvey(question);

          return (
            <StatisticTile
              amount={isSurvey ? null : question.globalEstimatesAvg}
              icon={null}
              growth={isSurvey ? null : question.globalEstimatesChange || null}
              className="!p-1 max-h-min"
            />
          );
        },
      }
    ),
    activeColumnsHelper.accessor(
      'participantsCount',
      {
        header: t('common.participants'),
        footer: (props) => props.column.id,
        size: 150,
        minSize: 120,
        cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
      }
    ),
    activeColumnsHelper.accessor(
      'commentsCount',
      {
        header: t('common.comments'),
        footer: (props) => props.column.id,
        size: 150,
        minSize: 120,
        cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
      }
    ),
    activeColumnsHelper.accessor(
      'updatesCount',
      {
        header: t('common.updates'),
        footer: (props) => props.column.id,
        size: 150,
        minSize: 120,
        cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
      }
    ),
  ];

  if (isSurveyTab) {
    return columns;
  }

  return [
    ...columns,
    activeColumnsHelper.accessor(
      'id',
      {
        header: '',
        footer: (props) => props.column.id,
        size: isScoredTab ? 70 : 165,
        minSize: isScoredTab ? 70 : 165,
        cell: ({
          row: {
            original: question
          }
        }) => {
          const isResult = checkResult(question);

          const {
            id, startDate, endDate
          } = question;

          if (isResult) {
            return (
              <Style.DownloadButtonWrapper>
                <DownloadExPostDebasing
                  organisationId={question.organization?.id}
                  questionId={id}
                  hasFeedback={question.hasFeedbacks}
                />
              </Style.DownloadButtonWrapper>
            );
          }

          return (
            <Button
              variant="big-blue"
              className="!py-2 !px-4 !text-15-20 font-bold !shrink-0 !h-max !w-max"
              onClick={() => setQuestionToScore({
                id,
                startDate,
                endDate,
              })}
            >
              {t('question.scoreTheQuestion')}
            </Button>
          );
        },
      }
    ),
  ];
};
