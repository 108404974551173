import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const FiltersWrapper = styled.div`
  ${tw`overflow-x-auto py-4 px-3 shrink-0`}
`;

export const ExternalRow = styled.td`
  ${tw`px-3 border-b border-gray94`}
`;
