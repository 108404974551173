import React from 'react';
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';

import {
  Group
} from 'src/redux/openapi';
import {
  numberWithPeriods
} from 'src/shared/utils';
import {
  GrayText
} from 'src/shared/components';

import {
  GroupInfo
} from '../GroupInfo';
import {
  GroupSets
} from '../GroupSets';
import {
  GroupAction
} from '../GroupAction';

const groupColumnsHelper = createColumnHelper<Group>();

export const getGroupCols = () => {
  const columns = [
    groupColumnsHelper.accessor(
      'name',
      {
        header: t('modal.groupName'),
        footer: (props) => props.column.id,
        size: 380,
        minSize: 380,
        cell: ({
          row: {
            original: group
          }
        }) => (
          <div className="flex flex-col gap-1 py-2">
            <GroupInfo group={group} />

            <GroupSets
              questionSets={group.questionSets}
              setContainerClassName="!px-0 py-2"
            />
          </div>
        ),
      }
    ),
    groupColumnsHelper.accessor(
      'memberCount',
      {
        header: t('common.participants'),
        footer: (props) => props.column.id,
        size: 200,
        minSize: 128,
        cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
      }
    ),
    groupColumnsHelper.accessor(
      'commentCount',
      {
        header: t('common.comments'),
        footer: (props) => props.column.id,
        size: 200,
        minSize: 128,
        cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
      }
    ),
    groupColumnsHelper.accessor(
      'forecastCount',
      {
        header: t('common.updates'),
        footer: (props) => props.column.id,
        size: 200,
        minSize: 128,
        cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
      }
    ),
    groupColumnsHelper.accessor(
      'id',
      {
        header: undefined,
        footer: (props) => props.column.id,
        size: 150,
        minSize: 128,
        cell: ({
          row: {
            original: group
          }
        }) => (
          <GroupAction
            isPublic={!!group.isPublic}
            id={group.id}
          />
        ),
      }
    ),
  ];

  return columns;
};
