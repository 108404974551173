import {
  t
} from 'i18next';

import {
  OrgToDeactivateQuestions,
  GroupToDeactivateQuestions,
} from 'src/redux/openapi';
import {
  QuestionEntityStatus
} from 'src/shared/api/types';

import {
  DeactivationList, ItemToDeactivateAll
} from './types';

export const getSelectedIds = (
  items: {
    selectedChildrenId: string[];
  }[],
) => items.flatMap(({
  selectedChildrenId
}) => selectedChildrenId);

export const getInitialSelectedItems = (
  activatedQuestions: { id: string; questions: { id: string }[] }[],
) => {
  return (
    activatedQuestions
      .map(({
        id, questions
      }) => {
        return {
          parentId: id,
          selectedChildrenId: questions.map((question) => question.id),
        };
      })
      .filter(({
        selectedChildrenId
      }) => selectedChildrenId.length) || []
  );
};

export const getDeactivationConfig = (
  deactivatedList: OrgToDeactivateQuestions | GroupToDeactivateQuestions,
) => {
  const isOrgList = Array.isArray(deactivatedList);

  let isQuestions = false;
  let isQuestionSets = false;
  let titleEndPhrase = '';
  let deactivationList: DeactivationList = {
    setsData: [],
    questionsData: [],
  };

  if (isOrgList) {
    isQuestions = !!deactivatedList?.some((list) => list?.questions?.length);

    isQuestionSets = !!deactivatedList?.some((list) => list?.sets?.length);

    deactivationList = {
      setsData:
        deactivatedList?.map(({
          groupNames, sets
        }) => ({
          groupNames: sets?.length ? groupNames : null,
          sets,
        })) || [],
      questionsData:
        deactivatedList?.map(({
          groupNames, questions
        }) => ({
          groupNames: questions?.length ? groupNames : null,
          questions,
        })) || [],
    };
  } else {
    const {
      sets, questions
    } = deactivatedList;

    isQuestions = !!questions.length;
    isQuestionSets = !!sets.length;

    deactivationList = {
      setsData: [
        {
          sets,
        },
      ],
      questionsData: [
        {
          questions,
        },
      ],
    };
  }

  const isQuestionsAndSets = isQuestions && isQuestionSets;
  const isQuestionsOnly = isQuestions && !isQuestionSets;
  const isQuestionSetsOnly = !isQuestions && isQuestionSets;

  if (isQuestionsAndSets) {
    titleEndPhrase = '';
  } else if (isQuestionsOnly) {
    titleEndPhrase = t('common.questions');
  } else if (isQuestionSetsOnly) {
    titleEndPhrase = t('common.sets');
  }

  return {
    isQuestions,
    isQuestionSets,
    isQuestionsAndSets,
    isQuestionsOnly,
    isQuestionSetsOnly,
    titleEndPhrase,
    deactivationList,
  };
};

export const checkDeactivated = ({
  groupStatus,
  status,
}: {
  groupStatus?: string | null;
  status?: string | null;
}) => {
  return [groupStatus, status].includes(QuestionEntityStatus.Deactivated);
};

export const getTooltipMessage = ({
  isMandatory,
  isDeactivated,
}: Record<'isMandatory' | 'isDeactivated', boolean>) => {
  if (isMandatory) {
    return t('question.isMandatory');
  }

  if (isDeactivated) {
    return t('modal.questionCannotBeActivatedAgain');
  }

  return null;
};

const areEqual = <T>(array1: T[], array2: T[]) => {
  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every((element) => array2.includes(element));
};

export const getMergedGroups = (listToDeactivateAll: ItemToDeactivateAll[]) => {
  return listToDeactivateAll.reduce<OrgToDeactivateQuestions>(
    (acc, group) => {
      const existingSet = acc.find((item) => {
        if (item.sets && group.sets) {
          return areEqual(
            item.sets.map((s) => s.title),
            group.sets.map((s) => s.title),
          );
        }

        return false;
      });

      const existingQuestions = acc.find((item) => {
        if (item.questions && group.questions) {
          return areEqual(
            item.questions,
            group.questions
          );
        }

        return false;
      });

      if (existingSet) {
        existingSet.groupNames.push(group.groupName);
      } else if (group.sets?.length) {
        acc.push({
          groupNames: [group.groupName],
          sets: group.sets,
        });
      }

      if (existingQuestions) {
        existingQuestions.groupNames.push(group.groupName);
      } else if (group.questions?.length) {
        acc.push({
          groupNames: [group.groupName],
          questions: group.questions,
        });
      }

      return acc;
    },
    []
  );
};

export const getPreferredTooltipMessage = ({
  isMandatory,
  isPreferred,
  isSelected,
}: {
  isMandatory: boolean;
  isPreferred: boolean;
  isSelected: boolean;
}) => {
  if (isMandatory) {
    return t('question.cannotOverridePreferredForMandatory');
  }

  if (!isSelected) {
    return t('question.selectToOverridePreferred');
  }

  if (isPreferred) {
    return t('question.isPreferred');
  }

  return t('question.markTheQuestionAsPreferred');
};
