import {
  useEffect, useMemo
} from 'react';
import {
  useParams, useSearchParams
} from 'react-router-dom';
import dayjs from 'dayjs';
import {
  useTranslation
} from 'react-i18next';

import {
  getCurrentSortByValue,
  getFilteredTimeRangeComments,
  groupId,
  questionId,
} from 'src/shared/utils';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  commentsFilterSelectors
} from 'src/redux/commentsFilters';
import {
  FilterDateType
} from 'src/widgets/CommentsFilterContext/types';
import {
  useGetGroupFeedQuery,
  useGetQuestionsFeedQuery,
  QuestionsFeedResponse,
  GroupFeedResponse,
} from 'src/redux/openapi';
import {
  commentsListSelectors,
  setCommentsList,
  setPageOfCommentsList,
  setTotalOfCommentsList,
} from 'src/redux/commentsList';
import {
  warningToast
} from 'src/shared/components';

const PAGE_LIMIT = 10;

type ResponseCommentsType = GroupFeedResponse | QuestionsFeedResponse;

export const useGetPollingComments = ({
  skip = false
} = {}) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const dispatch = useTypedDispatch();

  const {
    t
  } = useTranslation();

  const {
    comments, total, page
  } = useTypedSelector(
    commentsListSelectors.selectCommentsData,
  );

  const questionIdSearch = searchParams.get(questionId);
  const groupIdSearch = searchParams.get(groupId);

  const questionIdToSearch = params.id || questionIdSearch;
  const groupIdToSearch = params.groupId || groupIdSearch;

  const feedFilters = useTypedSelector(commentsFilterSelectors.selectAllFeed);

  const {
    showMyOnly,
    showForecasts,
    commentsSortBy,
    commentsTimeRange,
    selectedDateRange,
    selectedGroups,
  } = feedFilters;

  const filters = useMemo(
    () => {
      const isCustomTimeRange = commentsTimeRange === FilterDateType.CUSTOM && selectedDateRange;

      return {
        userOnly: showMyOnly || undefined,
        withForecast: !!showForecasts,
        sortBy: getCurrentSortByValue(commentsSortBy),
        dateStart: isCustomTimeRange
          ? dayjs(selectedDateRange[0]).toDate().toJSON()
          : getFilteredTimeRangeComments(commentsTimeRange),
        dateEnd: isCustomTimeRange
          ? dayjs(selectedDateRange[selectedDateRange.length - 1])
            .toDate()
            .toJSON()
          : undefined,
      };
    },
    [feedFilters]
  );

  const {
    data: groupsPageFeedData,
    isLoading: isLoadingGroupsPageFeedData,
    isFetching: isFetchingGroupsPageFeedData,
    refetch: groupsPageRefetch,
  } = useGetGroupFeedQuery(
    {
      id: groupIdToSearch || '',
      filters: {
        ...filters,
        questionId: questionIdToSearch || undefined,
      },
      page: 1,
      limit: PAGE_LIMIT * page,
    },
    {
      skip: !groupIdToSearch || skip,
      refetchOnMountOrArgChange: true,
      pollingInterval: 10000,
    },
  );

  const {
    data: commentsAllData,
    isLoading: isLoadingCommentsList,
    isFetching: isFetchingCommentsList,
    refetch: commentsAllRefetch,
  } = useGetQuestionsFeedQuery(
    {
      filters: {
        ...filters,
        groupIds: selectedGroups,
      },
      page: 1,
      limit: PAGE_LIMIT * page,
    },
    {
      skip: !!questionIdToSearch || !!groupIdToSearch || skip,
      refetchOnMountOrArgChange: true,
      pollingInterval: 10000,
    },
  );

  const updateState = (reply: ResponseCommentsType) => {
    dispatch(setCommentsList(reply.data.feed));
    dispatch(setTotalOfCommentsList(reply.data.total));
  };

  useEffect(
    () => {
      if (groupIdToSearch && groupsPageFeedData) {
        updateState(groupsPageFeedData);
      } else if (!questionIdToSearch && commentsAllData) {
        updateState(commentsAllData);
      }
    },
    [commentsAllData, groupsPageFeedData]
  );

  const hasAvailable = useMemo(
    () => {
      if (groupIdToSearch && groupsPageFeedData) {
        return groupsPageFeedData.data.hasAvailable;
      }

      if (!questionIdToSearch && commentsAllData) {
        return commentsAllData.data.hasOnboarded;
      }

      return false;
    },
    [commentsAllData, groupsPageFeedData]
  );

  useEffect(
    () => {
      dispatch(setPageOfCommentsList(1));
    },
    [feedFilters, questionIdToSearch, groupIdToSearch]
  );

  const isLoadingComments = isLoadingCommentsList || isLoadingGroupsPageFeedData;

  const isLoadingMoreComments = isFetchingGroupsPageFeedData || isFetchingCommentsList;

  const loadNextPage = async () => {
    try {
      if (total > comments.length && !isLoadingMoreComments) {
        dispatch(setPageOfCommentsList(page + 1));
      }
    } catch (error) {
      warningToast(t('errors.errorLoadComments'));
    }
  };

  const refetch = () => {
    if (groupIdToSearch) {
      return groupsPageRefetch();
    }

    if (!questionIdToSearch) {
      return commentsAllRefetch();
    }

    return null;
  };

  return {
    data: comments,
    isLoading: isLoadingComments,
    isLoadingMoreComments,
    loadNextPage,
    hasOnboarded: hasAvailable,
    refetch,
    total,
  };
};
