import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  PayloadAction
} from '@reduxjs/toolkit';

import {
  authApi
} from 'src/shared/api';
import {
  FilterDateType,
  SortBy,
} from 'src/widgets/CommentsFilterContext/types';

type TDate = Date | null;

export interface CommentsFilterState {
  feed: {
    selectedGroups: string[];
    selectedDateRange: TDate[] | null;
    showMyOnly: boolean;
    showForecasts: boolean;
    commentsSortBy: SortBy;
    commentsTimeRange: FilterDateType;
  };
}

export interface CommentsFilterReducer {
  commentsFilter: CommentsFilterState;
}

export const initialState: CommentsFilterState = {
  feed: {
    selectedGroups: [],
    selectedDateRange: null,
    showMyOnly: false,
    showForecasts: true,
    commentsSortBy: SortBy.CHRONOLOGICAL,
    commentsTimeRange: FilterDateType.IN_24_HOURS,
  },
};

export const commentsFilterSlice = createSlice({
  name: 'commentsFilter',
  initialState,
  reducers: {
    resetCommentsFilter: () => initialState,
    setFeedShowMyOnly: (state, action: PayloadAction<boolean>) => {
      state.feed.showMyOnly = action.payload;
    },
    setFeedShowForecasts: (state, action: PayloadAction<boolean>) => {
      state.feed.showForecasts = action.payload;
    },
    setFeedTimeRange: (state, action: PayloadAction<FilterDateType>) => {
      state.feed.commentsTimeRange = action.payload;
    },
    setFeedSelectedDateRange: (state, action: PayloadAction<TDate[]>) => {
      state.feed.selectedDateRange = action.payload;
    },
    resetFeedSelectedDateRange: (state) => {
      state.feed.selectedDateRange = null;
    },
    setFeedSelectedGroups: (state, action: PayloadAction<string[]>) => {
      state.feed.selectedGroups = action.payload;
    },
    resetFeedSelectedGroups: (state) => {
      state.feed.selectedGroups = [];
    },
    setFeedCommentsSortBy: (state, action: PayloadAction<SortBy>) => {
      state.feed.commentsSortBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.signOut.matchFulfilled,
      () => initialState,
    );
  },
});

export const {
  resetCommentsFilter,
  setFeedShowMyOnly,
  setFeedShowForecasts,
  setFeedSelectedGroups,
  setFeedCommentsSortBy,
  setFeedSelectedDateRange,
  resetFeedSelectedDateRange,
  setFeedTimeRange,
} = commentsFilterSlice.actions;

export const commentsFilterReducer = commentsFilterSlice.reducer;
