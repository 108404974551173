import {
  combineReducers
} from 'redux';

import {
  authApi,
  groupsApi,
  usersApi,
  questionApi,
  notificationsApi,
  commentsApi,
  organizationsApi,
} from 'src/shared/api';

import {
  userReducer
} from './user';
import {
  languageReducer
} from './language';
import {
  timeSpansReducer
} from './timeSpans';
import {
  groupsReducer
} from './groups';
import {
  emptySplitApi
} from './openapi/emptyApi';
import {
  organizationsReducer
} from './organizations';
import {
  commentsFilterReducer
} from './commentsFilters';
import {
  commentsListReducer
} from './commentsList';
import {
  notificationsReducer
} from './notifications';
import {
  verificationReducer
} from './verification';
import {
  homePreferencesReducer
} from './homePreferences';
import {
  communityReducer
} from './community';

export const rootReducer = combineReducers({
  user: userReducer,
  language: languageReducer,
  timeSpans: timeSpansReducer,
  commentsFilter: commentsFilterReducer,
  commentsList: commentsListReducer,
  groups: groupsReducer,
  organizations: organizationsReducer,
  notifications: notificationsReducer,
  verification: verificationReducer,
  [authApi.reducerPath]: authApi.reducer,
  [groupsApi.reducerPath]: groupsApi.reducer,
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [questionApi.reducerPath]: questionApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  organizationsApi: organizationsApi.reducer,
  homePreferences: homePreferencesReducer,
  community: communityReducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;
