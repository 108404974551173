import React, {
  FC, useEffect, useMemo
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  EmptyResultsMessage,
  Spinner,
  TableContent,
  TableFooter,
  usePaginationSettings,
} from 'src/shared/components';
import {
  useGetAdminGroupsQuery
} from 'src/redux/openapi';
import {
  DeleteGroupModal, EditGroupAdminModal
} from 'src/features/Modals';

import {
  ManagementTableRow
} from '../ManagementTableRow';
import {
  GroupActions
} from '../../types';
import {
  useManagementAction
} from '../../hooks';

import {
  getGroupCols
} from './utils';

interface GroupsContentProps {
  searchQuery: string;
}

export const GroupsContent: FC<GroupsContentProps> = ({
  searchQuery
}) => {
  const {
    currentPage, setCurrentPage, pageSize, onPageSizeChange
  } = usePaginationSettings(searchQuery);

  const {
    onAction, currentAction, idToModify, removeAction
  } = useManagementAction<GroupActions>();

  const {
    data: unaffiliatedGroupsData,
    refetch: refetchUnaffiliatedGroupsData,
    isLoading,
  } = useGetAdminGroupsQuery(
    {
      query: searchQuery,
      limit: pageSize,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalGroups, groups
  } = useMemo(
    () => unaffiliatedGroupsData?.data || {
      groups: [],
      total: 0,
    },
    [unaffiliatedGroupsData],
  );

  useEffect(
    () => {
      if (!groups.length && currentPage !== 1) {
        setCurrentPage((prev) => prev - 1);
      }
    },
    [groups.length]
  );

  const {
    t,
    i18n: {
      language
    },
  } = useTranslation();

  const label = t('group.Groups');

  const groupColumns = useMemo(
    () => getGroupCols(
      onAction,
      language
    ),
    [onAction, language],
  );

  if (isLoading) {
    return <Spinner withLoadingText />;
  }

  if (!groups.length) {
    return (
      <EmptyResultsMessage
        results={label.toLowerCase()}
        withSearchQuery={!!searchQuery}
      />
    );
  }

  return (
    <>
      <TableContent
        data={groups}
        columns={groupColumns}
        renderRow={(row, index) => (
          <ManagementTableRow
            row={row}
            key={row.original.id}
            index={index}
          />
        )}
      />

      <TableFooter
        totalCount={totalGroups}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={label}
      />

      <EditGroupAdminModal
        isOpen={currentAction === GroupActions.EditGroup}
        onClose={removeAction}
        groupId={idToModify || ''}
        onSuccess={refetchUnaffiliatedGroupsData}
      />

      <DeleteGroupModal
        isOpen={currentAction === GroupActions.DeleteGroup}
        closeModal={removeAction}
        id={idToModify || ''}
        onSuccessDelete={refetchUnaffiliatedGroupsData}
      />
    </>
  );
};
