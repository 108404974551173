import React, {
  FC
} from 'react';
import {
  t
} from 'i18next';

import {
  FilterBy
} from 'src/entities/Questions';
import {
  TableTabs
} from 'src/shared/components';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  homePreferencesSelectors,
  setHomePreferencesFilterBy,
} from 'src/redux/homePreferences';

const getTabsMap = (isFavorite: boolean) => {
  const defaultTabs = [
    {
      id: FilterBy.Active,
      label: t('common.ACTIVE'),
    },
  ];

  if (isFavorite) {
    return defaultTabs;
  }

  return [
    ...defaultTabs,
    {
      id: FilterBy.Available,
      label: t('common.AVAILABLE'),
    },
  ];
};

export const TableHeaderTabs: FC = () => {
  const {
    filterBy: activeTab, isFavorites
  } = useTypedSelector(
    homePreferencesSelectors.selectAllPreferences,
  );

  const dispatch = useTypedDispatch();

  const onTabChange = (tab: FilterBy) => dispatch(setHomePreferencesFilterBy(tab));

  const tabs = getTabsMap(isFavorites);

  return (
    <TableTabs
      tabs={tabs}
      currentTab={activeTab || FilterBy.Active}
      onTabChange={onTabChange}
    />
  );
};
