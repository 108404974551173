import {
  configureStore, AnyAction, ThunkDispatch
} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer, persistStore
} from 'redux-persist';
import {
  useDispatch, TypedUseSelectorHook, useSelector
} from 'react-redux';
import {
  setupListeners
} from '@reduxjs/toolkit/query/react';

import {
  authApi,
  groupsApi,
  usersApi,
  questionApi,
  notificationsApi,
  commentsApi,
  organizationsApi,
} from 'src/shared/api';

import {
  rootReducer
} from './rootReducers';
import {
  emptySplitApi
} from './openapi/emptyApi';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'timeSpans',
    'groups',
    'commentsFilter',
    'notifications',
    'language',
    'organizations',
    'verification',
    'homePreferences',
    'community',
  ],
};

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat([
    authApi.middleware,
    groupsApi.middleware,
    emptySplitApi.middleware,
    usersApi.middleware,
    questionApi.middleware,
    notificationsApi.middleware,
    commentsApi.middleware,
    organizationsApi.middleware,
  ]),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const useTypedDispatch = () => useDispatch<TypedDispatch>();

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
