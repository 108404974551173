import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  HeaderRow,
  StatisticTile,
  StatisticRow,
  formatNumber,
  QuestionCard,
  isQuestionWithStatistics,
} from 'src/entities/Questions';
import {
  ActionButton
} from 'src/widgets';
import {
  Button, Tooltip
} from 'src/shared/components';
import {
  ROUTE, formatDateRange
} from 'src/shared/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';

import {
  DelegateButton
} from '../DelegateButton';
import {
  FavoriteButton
} from '../FavoriteButton';

import * as Style from './TableView.styles';

interface TableRowProps {
  onUpdate: (card: QuestionCard) => void;
  row: QuestionCard;
  isExtendedTable: boolean;
}

export const TableRow: FC<TableRowProps> = ({
  onUpdate,
  row,
  isExtendedTable,
}) => {
  const {
    t
  } = useTranslation();

  const {
    id,
    questionSet,
    title,
    startDate,
    endDate,
    activeSurveyQuestion,
    type,
  } = row;

  const isStatistics = isQuestionWithStatistics(row);

  const displayStatistics = isStatistics && (!!row.userEstimatesAvg || !!row.globalEstimatesAvg);

  const language = useTypedSelector(languageSelectors.getLanguage);

  const isFavorite = 'isFavorite' in row && row.isFavorite;
  const isSurvey = type === 'SURVEY';

  const canDelegate = 'hasDelegate' in row;
  const hasDelegate = canDelegate && row.hasDelegate;

  const date = formatDateRange({
    start: activeSurveyQuestion?.startDate || startDate,
    end: activeSurveyQuestion?.endDate || endDate,
    language,
  });

  const questionLink = `/${ROUTE.QUESTION}/${id}`;

  return (
    <Style.TableRow key={id}>
      <Style.TableCell $truncated>
        <HeaderRow
          date={date}
          datePrefix={activeSurveyQuestion?.title}
          isSurvey={isSurvey}
          question={questionSet?.title || ''}
          isTable
        />

        <Style.Title href={questionLink}>
          <Style.TitleSpan>{title}</Style.TitleSpan>
        </Style.Title>

        {!isStatistics && !!row.onboardingText && (
          <Style.OnboardText>{row.onboardingText}</Style.OnboardText>
        )}
      </Style.TableCell>

      {!isExtendedTable && (
        <Style.ButtonCell>
          <Style.ButtonCellRow>
            <ActionButton href={questionLink} />
          </Style.ButtonCellRow>
        </Style.ButtonCell>
      )}

      {isStatistics && (
        <>
          <Style.TableCell>
            {displayStatistics && (
              <StatisticRow isTableView>
                <StatisticTile
                  amount={row.userEstimatesAvg}
                  icon={null}
                  applyMinWidth={false}
                />
                /

                <StatisticTile
                  amount={row.globalEstimatesAvg}
                  icon={null}
                  growth={row.globalEstimatesChange}
                  applyMinWidth={false}
                />
              </StatisticRow>
            )}
          </Style.TableCell>

          <Style.TableCell>
            <Style.TextCell>
              {formatNumber(row.participantsCount || 0)}
            </Style.TextCell>
          </Style.TableCell>

          <Style.TableCell>
            <Style.TextCell>
              {formatNumber(row.commentsCount || 0)}
            </Style.TextCell>
          </Style.TableCell>

          <Style.TableCell>
            <Tooltip message={t('question.clickToUpdate')}>
              <Button
                onClick={() => onUpdate(row)}
                className="w-full"
              >
                <Style.TextCell $underlined>
                  {formatNumber(row.updatesCount || 0)}
                </Style.TextCell>
              </Button>
            </Tooltip>
          </Style.TableCell>

          <Style.TableCell>
            {!isSurvey && (
              <Style.ButtonsContainer>
                {canDelegate && (
                  <DelegateButton
                    hasDelegate={hasDelegate}
                    questionId={id}
                  />
                )}

                <FavoriteButton
                  defaultValue={isFavorite}
                  id={id}
                />
              </Style.ButtonsContainer>
            )}
          </Style.TableCell>
        </>
      )}
    </Style.TableRow>
  );
};
