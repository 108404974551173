import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  GlobeIcon,
  MessageIcon,
  PeopleIcon,
  PersonIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  SimpleCard,
  ActivityRow,
  ActivityTile,
  StatisticTile,
} from 'src/entities/Questions';
import {
  ActionButtonProps
} from 'src/widgets';
import {
  themeColors
} from 'src/shared/utils/themeColors';
import {
  ButtonTitles
} from 'src/shared/api/types';
import {
  QuestionsActiveStatistics
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  homePreferencesSelectors
} from 'src/redux/homePreferences';

interface ActiveCardProps {
  card: QuestionsActiveStatistics;
  buttonProps?: ActionButtonProps;
}

export const ActiveCard: FC<ActiveCardProps> = ({
  card, buttonProps
}) => {
  const {
    t
  } = useTranslation();

  const {
    participantsCount,
    commentsCount,
    updatesCount,
    userEstimatesAvg,
    globalEstimatesAvg,
    globalEstimatesChange,
    type,
  } = card;

  const timeRange = useTypedSelector(homePreferencesSelectors.selectTimeRange);

  const isSurvey = type === 'SURVEY';

  return (
    <SimpleCard
      card={card}
      buttonProps={{
        buttonTextColor: themeColors['button-blue'],
        buttonTitle: isSurvey
          ? t(`buttons.${ButtonTitles.Participate}`)
          : t(`buttons.${ButtonTitles.Update}`),
        buttonWithBorder: false,
        ...buttonProps,
      }}
    >
      <ActivityRow>
        <ActivityTile
          icon={<PeopleIcon className="text-dim-gray w-4 h-4 md:w-6 md:h-6" />}
          amount={participantsCount}
        />

        {!!userEstimatesAvg && (
          <StatisticTile
            amount={userEstimatesAvg}
            icon={
              <PersonIcon className="text-dim-gray w-4 h-4 md:w-6 md:h-6" />
            }
            withBorder
            className="!p-1 max-h-min"
          />
        )}

        {!!globalEstimatesAvg && (
          <StatisticTile
            amount={globalEstimatesAvg}
            icon={<GlobeIcon className="text-dim-gray w-4 h-4 md:w-6 md:h-6" />}
            growth={globalEstimatesChange || 0}
            withBorder
            className="!p-1 max-h-min"
          />
        )}

        <ActivityTile
          icon={<MessageIcon className="text-dim-gray w-4 h-4 md:w-6 md:h-6" />}
          amount={commentsCount}
          timeRange={timeRange}
        />

        <ActivityTile
          icon={
            <TrendingUpIcon className="text-dim-gray w-4 h-4 md:w-6 md:h-6" />
          }
          amount={updatesCount}
          timeRange={timeRange}
        />
      </ActivityRow>
    </SimpleCard>
  );
};
