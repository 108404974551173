import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import z from 'zod';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useNavigate, useParams
} from 'react-router-dom';
import {
  useForm, Controller
} from 'react-hook-form';
import {
  useTranslation
} from 'react-i18next';

import {
  Button,
  InformationBlock,
  InputTextArea,
  SliderBlock,
  UsefulLinksBlock,
  PrivateCommentCheckMark,
  CircleButton,
  YourForecastBlock,
  warningToast,
  successfulToast,
  Spinner,
} from 'src/shared/components';
import {
  ArrowLeftIcon, ArrowUpIcon
} from 'src/shared/icons';
import {
  GraphSection,
  PageLayout,
  SurveySection,
  Star,
  QuestionDetailsNavigation,
} from 'src/widgets';
import {
  YourFeed
} from 'src/entities/YourFeed';
import {
  ROUTE,
  forecastsColorValues,
  formatDateRange,
  getPercent,
  showWarningFromServer,
  themeColors,
  topicId,
} from 'src/shared/utils';
import {
  QuestionStatisticsSchema,
  QuestionSurveyQuestion,
  useGetQuestionQuery,
  useGetQuestionsStatisticsQuery,
  usePostQuestionForecastsMutation,
  usePostQuestionSurveyOptionIdMutation,
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  groupsSelectors
} from 'src/redux/groups';
import {
  useGetAllGroupsMutation
} from 'src/shared/api/groups/groupsApi';
import {
  useMediaQuery, useToggleFavQuestion
} from 'src/shared/hooks';
import {
  Breadcrumbs
} from 'src/features';
import {
  languageSelectors
} from 'src/redux/language';

import {
  CommentFormData, CommentForm
} from './types';
import * as Style from './Question.styles';

const schema = z.object({
  [CommentFormData.COMMENT]: z.string(),
  [CommentFormData.IS_PRIVATE]: z.boolean(),
});

const QUESTION_SET_LIMIT = 10;

export const Question: FC = () => {
  const [allQuestionSet, setAllQuestionSet] = useState<
  QuestionStatisticsSchema[]
  >([]);

  const [currentPageToLoadSet, setCurrentPageToLoadSet] = useState(1);
  const [isExpanded, setIsExpanded] = useState(true);

  const [surveyQuestionInfo, setSurveyQuestionInfo] = useState<QuestionSurveyQuestion | null>(null);

  const [selectedSurveyValue, setSelectedSurveyValue] = useState<string | null>(
    null,
  );

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const {
    t
  } = useTranslation();

  const {
    control, reset, getValues
  } = useForm<CommentForm>({
    mode: 'onTouched',
    defaultValues: {
      [CommentFormData.COMMENT]: '',
      [CommentFormData.IS_PRIVATE]: false,
    },
    resolver: zodResolver(schema),
  });

  const allUserGroups = useTypedSelector(groupsSelectors.groups);
  const [getAllGroups] = useGetAllGroupsMutation();

  const loadAllGroups = async () => {
    try {
      await getAllGroups({}).unwrap();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  useEffect(
    () => {
      if (!allUserGroups.length) {
        loadAllGroups();
      }
    },
    [allUserGroups.length]
  );

  const params = useParams();

  const {
    id: questionID = ''
  } = params;

  const {
    isLoading: isQuestionLoading, data: questionData
  } = useGetQuestionQuery(
    {
      id: questionID,
    },
    {
      skip: !questionID,
      refetchOnMountOrArgChange: true,
    },
  );

  const question = useMemo(
    () => questionData?.data.question || null,
    [questionData],
  );

  const {
    type,
    estimatesTitles,
    forecasts = [],
    questionSet,
    title = '',
    status,
    groupStatus: questionGroupStatus,
    isActive,
    baseRate,
    isFavorite = false,
    onboardingText = null,
    startDate = null,
    endDate = null,
    surveyQuestions = [],
  } = question || {};

  const questionSetId = questionSet?.id || null;
  const isMultiQuestion = type === 'MULTIPLE';
  const isSurvey = question?.type === 'SURVEY';

  const emptyValues: number[] = isMultiQuestion
    ? Array(estimatesTitles?.length).fill(1)
    : [1];

  const [valuesInitial, setValuesInitial] = useState<Array<number>>(emptyValues);

  const [values, setValues] = useState<Array<number>>(emptyValues);

  const [isLessThan100, setIsLessThan100] = useState(false);

  const [isQuestionFavorite, setIsQuestionFavorite] = useState(false);

  useEffect(
    () => {
      setValues(valuesInitial);
    },
    [valuesInitial]
  );

  const navigate = useNavigate();

  const isOnboarding = question && status === 'AVAILABLE' && !isActive;

  const isDeactivated = status === 'DEACTIVATED' || questionGroupStatus === 'DEACTIVATED';

  const onExpand = () => setIsExpanded((prevExpanded) => !prevExpanded);

  const questionIndexInSet = useMemo(
    () => {
      const questionIndex = allQuestionSet.findIndex(
        (set) => set.id === questionID,
      );

      return questionIndex > -1 ? questionIndex : 0;
    },
    [allQuestionSet, questionID]
  );

  useEffect(
    () => {
      if (type === 'SINGLE') {
        setValuesInitial([forecasts[0]?.estimates[0]?.value || 1]);
      } else if (forecasts[0]?.estimates?.length && type === 'MULTIPLE') {
        const forecastValues = forecasts[0]?.estimates.map(
          (estimate) => estimate.value,
        );

        setValuesInitial(forecastValues);
      }
    },
    [question]
  );

  const {
    data: questionsSetData, isFetching: isLoadingQuestionsSet
  } = useGetQuestionsStatisticsQuery(
    {
      filters: {
        questionSetId: questionSetId || '',
      },
      limit: QUESTION_SET_LIMIT,
      page: currentPageToLoadSet,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !questionSetId,
    },
  );

  useEffect(
    () => {
      if (questionsSetData) {
        setAllQuestionSet((prev) => {
          return [...prev, ...questionsSetData.data.statistics];
        });
      }
    },
    [questionsSetData]
  );

  const isMorePages = useMemo(
    () => {
      const totalQuestions = questionsSetData?.data.total || 0;
      const totalQuestionsLoaded = allQuestionSet.length;

      const isLastLoadedQuestion = questionIndexInSet === totalQuestionsLoaded - 1;

      return totalQuestions > totalQuestionsLoaded && isLastLoadedQuestion;
    },
    [questionsSetData, allQuestionSet, questionIndexInSet]
  );

  useEffect(
    () => {
      if (isMorePages) {
        setCurrentPageToLoadSet((prev) => prev + 1);
      }
    },
    [isMorePages]
  );

  useEffect(
    () => {
      if (questionID) {
        setIsLessThan100(false);
      }
    },
    [questionID]
  );

  useEffect(
    () => {
      if (question && !forecasts?.length) {
        const valuesRate = !isMultiQuestion && baseRate ? [baseRate] : emptyValues;

        setValues(valuesRate);
      }

      if (isFavorite) {
        setIsQuestionFavorite(true);
      }
    },
    [question]
  );

  const totalQuestionsInSet = questionsSetData?.data.total || 0;

  const multiOptionsInQuestion = isMultiQuestion ? estimatesTitles || [] : [];

  const topicTitle = questionSet?.title
    ? questionSet?.title
    : `${t('common.loading')}...`;

  const questionTitle = question && !isQuestionLoading ? title : `${t('common.loading')}...`;

  const setNewValArr = (index: number) => {
    return (newValue: number) => setValues((prev) => prev.map((val, i) => {
      if (i === index) {
        return newValue;
      }

      return val;
    }),);
  };

  const totalValues = values.reduce(
    (acc, val) => acc + val,
    0
  );

  const isBigger = totalValues > 100;
  const isLess = isMultiQuestion && totalValues < 100;

  const resetValues = () => {
    setValues(valuesInitial);
  };

  const equalValues = (num: number) => {
    setValues((prev) => prev.map((val) => val + num));
  };

  const equalRestValues = (rest: number) => {
    setValues((prev) => prev.map((val, i) => {
      if (rest >= i + 1) {
        return val + 1;
      }

      return val;
    }),);
  };

  const subtractEqual = () => {
    const newValues = values.map((val) => getPercent(
      val,
      totalValues
    ));

    const totalNewValues = newValues.reduce(
      (acc, val) => acc + val,
      0
    );

    setValues(newValues);

    if (totalNewValues < 100) {
      equalRestValues(100 - totalNewValues);
    }
  };

  const distributeEqually = () => {
    const rest = 100 - totalValues;

    if (rest > 0) {
      const part = Math.floor(rest / multiOptionsInQuestion.length);

      const addRest = part > 0 ? rest % part : rest;

      equalValues(part);
      equalRestValues(addRest);
    } else {
      subtractEqual();
    }
  };

  const handleSetSingleValue = (value: number) => {
    setValues([value]);
  };

  const [isOpenForecast, setIsOpenForecast] = useState(true);

  const onOpenForecast = () => setIsOpenForecast((prevExpanded) => !prevExpanded);

  useEffect(
    () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    [questionID]
  );

  const handleNextQuestionClick = (nextPageAdder: number) => {
    if (isLoadingQuestionsSet) {
      return;
    }

    const hasNextPage = nextPageAdder > 0
      ? totalQuestionsInSet > questionIndexInSet + 1
      : totalQuestionsInSet > 0 && questionIndexInSet !== 0;

    if (hasNextPage) {
      const nextPageData = allQuestionSet[questionIndexInSet + nextPageAdder]
        ? allQuestionSet[questionIndexInSet + nextPageAdder]
        : allQuestionSet[0];

      const {
        id, isActive: isNextActive, groupStatus
      } = nextPageData;

      reset();
      setSelectedSurveyValue(null);
      setSurveyQuestionInfo(null);

      navigate(
        `/${ROUTE.QUESTION}/${id}`,
        {
          state: {
            isOnboarding: !isNextActive && groupStatus === 'ACTIVATED',
            isDeactivated: groupStatus === 'DEACTIVATED',
          },
        }
      );
    }
  };

  const [postNewForecast, {
    isLoading: isLoadingPostNewForecast
  }] = usePostQuestionForecastsMutation();

  const postNewForecastHandler = async () => {
    if (!questionID) {
      return;
    }

    const comment = getValues(CommentFormData.COMMENT);
    const isPrivate = getValues(CommentFormData.IS_PRIVATE);

    const estimates = type === 'SINGLE'
      ? [
        {
          title,
          value: values[0],
        },
      ]
      : estimatesTitles?.map((estimateTitle, i) => ({
        title: estimateTitle,
        value: values[i],
      })) || [];

    try {
      const {
        message,
        data: {
          forecast: forecastResponse
        },
      } = await postNewForecast({
        questionId: questionID,
        createForecastBodySchema: {
          estimates,
          comment: {
            text: comment,
            isPrivate,
          },
        },
      }).unwrap();

      successfulToast(message);
      reset();

      setValuesInitial(
        forecastResponse.estimates?.map((estimate) => estimate.value),
      );
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const [submitSurveyOption, {
    isLoading: isSurveyAnswerLoading
  }] = usePostQuestionSurveyOptionIdMutation();

  const onSurveySubmit = async () => {
    if (!surveyQuestionInfo || !selectedSurveyValue) {
      return;
    }

    try {
      const response = await submitSurveyOption({
        id: questionID,
        surveyQuestionId: surveyQuestionInfo?.id,
        optionId: selectedSurveyValue,
      }).unwrap();

      successfulToast(response.message);
      setSelectedSurveyValue(null);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const isLoading = isLoadingPostNewForecast || isSurveyAnswerLoading;

  const handleSubmitClick = async () => {
    if (isLoading) {
      return;
    }

    if (isSurvey) {
      await onSurveySubmit();

      return;
    }

    if (isBigger) {
      warningToast(t('errors.totalMoreThan100'));

      return;
    }

    if (isLess) {
      warningToast(t('errors.totalLessThan100'));
      setIsLessThan100(true);

      return;
    }

    await postNewForecastHandler();
  };

  const colorsAndOptions = forecastsColorValues.slice(
    0,
    values.length
  );

  const forecastData = colorsAndOptions.map((val, i) => ({
    forecastTitle: multiOptionsInQuestion[i] || '',
    forecastColor: val.forecastColor,
    forecastValue: values[i],
  }));

  const isCountError = isBigger || (isLessThan100 && isLess);

  const breadcrumbsRoutes = [
    {
      path: `/${ROUTE.QUESTION}`,
      breadcrumb: topicTitle,
      props: {
        pathState: {
          ...(questionSetId && {
            [topicId]: questionSetId,
          }),
        },
      },
    },
  ];

  const infoContent = useMemo(
    () => {
      return question?.information || '';
    },
    [question]
  );

  const infoLinks = useMemo(
    () => {
      return question?.links || [];
    },
    [question]
  );

  const language = useTypedSelector(languageSelectors.getLanguage);

  const handleSurveySelect = (newSurvey: string | null) => {
    setSelectedSurveyValue(newSurvey);
  };

  const toggleFavQuestion = () => setIsQuestionFavorite((prev) => !prev);

  const {
    handleChangeFavQuestion, isChangingFavQuestion
  } = useToggleFavQuestion(
    isQuestionFavorite,
    toggleFavQuestion
  );

  const handleChangeFavorite = () => {
    if (!questionID) {
      return;
    }

    handleChangeFavQuestion(questionID);
  };

  return (
    <PageLayout>
      {isMobile ? (
        <Style.MobileButton
          type="button"
          onClick={() => navigate(ROUTE.ROOT)}
        >
          <ArrowLeftIcon />

          {t('buttons.back')}
        </Style.MobileButton>
      ) : (
        <div />
      )}

      <Style.MainContainer $isExpanded={isExpanded}>
        <Style.ContainerBlock $isExpanded={isExpanded}>
          <Style.MainInfoBlock>
            <Style.HeadWrapper>
              <Style.HeadInfo>
                <Style.TitleContainer>
                  <Style.BreadcrumbsRow>
                    <Breadcrumbs
                      hideHome={false}
                      routes={breadcrumbsRoutes}
                      excludePaths={[`/${ROUTE.QUESTION}/:id`]}
                    />

                    {question && (
                      <Style.QuestionDates>
                        {isSurvey
                          ? surveyQuestionInfo && (
                          <p>
                            <b>{`${surveyQuestionInfo.title} - `}</b>

                            {formatDateRange({
                              start: surveyQuestionInfo.startDate,
                              end: surveyQuestionInfo.endDate,
                              language,
                            })}
                          </p>
                          )
                          : formatDateRange({
                            start: startDate,
                            end: endDate,
                            language,
                          })}
                      </Style.QuestionDates>
                    )}
                  </Style.BreadcrumbsRow>

                  <Style.QuestionTitle>{questionTitle}</Style.QuestionTitle>

                  {isOnboarding && onboardingText && (
                    <Style.QuestionOnboardingText>
                      {onboardingText}
                    </Style.QuestionOnboardingText>
                  )}
                </Style.TitleContainer>

                <div className="hidden md:block">
                  {!isOnboarding && (
                    <CircleButton onClick={handleChangeFavorite}>
                      {isChangingFavQuestion ? (
                        <Spinner
                          color={themeColors['button-blue']}
                          size={24}
                        />
                      ) : (
                        <Star isFilled={isQuestionFavorite} />
                      )}
                    </CircleButton>
                  )}
                </div>
              </Style.HeadInfo>

              {!isExpanded && (
                <Style.ExpandedContainer>
                  <Style.ExpandedTitle>
                    {t('question.questionDiscussion')}
                  </Style.ExpandedTitle>

                  <CircleButton onClick={onExpand}>
                    <ArrowUpIcon
                      width={24}
                      height={24}
                      className="rotate-180"
                    />
                  </CircleButton>
                </Style.ExpandedContainer>
              )}
            </Style.HeadWrapper>

            {isQuestionLoading ? (
              <Spinner withLoadingText />
            ) : (
              <>
                {isOnboarding && !isSurvey ? (
                  <div>
                    {!multiOptionsInQuestion.length ? (
                      <SliderBlock
                        value={values[0]}
                        setValue={handleSetSingleValue}
                        isSingle
                        formattedValue={`${values[0]}%`}
                        defaultText
                      />
                    ) : (
                      multiOptionsInQuestion.map((option, i) => (
                        <SliderBlock
                          key={option}
                          value={values[i]}
                          setValue={setNewValArr(i)}
                          text={option}
                          formattedValue={`${values[i]}%`}
                        />
                      ))
                    )}
                  </div>
                ) : (
                  <div className="mt-6">
                    {question && !isSurvey && (
                      <GraphSection
                        isExpanded={isExpanded}
                        question={question}
                      />
                    )}

                    {question && isSurvey && (
                      <SurveySection
                        selectedValue={selectedSurveyValue}
                        onSelectValue={handleSurveySelect}
                        surveyQuestions={surveyQuestions}
                        questionId={questionID}
                        onFilterChange={setSurveyQuestionInfo}
                        isDeactivated={isDeactivated}
                      />
                    )}

                    {!isDeactivated && !isSurvey && (
                      <YourForecastBlock
                        isMulti={!!multiOptionsInQuestion.length}
                        singleValue={values[0]}
                        setSingleValue={handleSetSingleValue}
                        values={forecastData}
                        setNewValues={setNewValArr}
                        isOpenForecast={isOpenForecast}
                        onOpenForecast={onOpenForecast}
                      />
                    )}
                  </div>
                )}

                {isOpenForecast && !isDeactivated && !isSurvey && (
                  <Style.ButtonsContainer>
                    <div className="flex gap-4">
                      <Button
                        type="button"
                        variant="big-grey-bordered"
                        className="px-6 md:py-3 py-1 w-max h-max md:h-52px"
                        onClick={resetValues}
                      >
                        <Style.SpanGrey>{t('buttons.reset')}</Style.SpanGrey>
                      </Button>

                      {!!multiOptionsInQuestion.length && (
                        <Button
                          type="button"
                          variant="big-grey-bordered"
                          className="px-6 py-3 w-max"
                          onClick={distributeEqually}
                        >
                          <Style.SpanBlue $isButton>
                            {t('buttons.equallyDistribute')}
                          </Style.SpanBlue>
                        </Button>
                      )}
                    </div>

                    {!!multiOptionsInQuestion.length && (
                      <Style.TotalValueBlock $isBigger={isCountError}>
                        {isCountError && (
                          <Style.TextBlock>
                            {t('question.correctTo100')}
                          </Style.TextBlock>
                        )}

                        {`${
                          isCountError
                            ? t('question.total')
                            : t('question.yourTotal')
                        }: ${totalValues}%`}
                      </Style.TotalValueBlock>
                    )}
                  </Style.ButtonsContainer>
                )}

                {!isDeactivated && !isSurvey && (
                  <>
                    <div className="mb-2 md:mb-3">
                      <Controller
                        name={CommentFormData.COMMENT}
                        control={control}
                        render={({
                          field
                        }) => {
                          return (
                            <InputTextArea
                              className="w-full pl-4 py-17px"
                              value={field.value}
                              onChange={field.onChange}
                            />
                          );
                        }}
                      />
                    </div>

                    <Controller
                      name={CommentFormData.IS_PRIVATE}
                      control={control}
                      render={({
                        field
                      }) => {
                        return (
                          <PrivateCommentCheckMark
                            isPrivateComment={field.value}
                            changeIsPrivateComment={() => field.onChange(!field.value)}
                          />
                        );
                      }}
                    />
                  </>
                )}
              </>
            )}

            <QuestionDetailsNavigation
              onNavigateClick={handleNextQuestionClick}
              isFirstQuestion={questionIndexInSet === 0}
              isLastQuestion={questionIndexInSet === totalQuestionsInSet - 1}
              isDeactivated={isDeactivated}
              handleSubmitClick={handleSubmitClick}
              disableSubmitButton={isSurvey && !selectedSurveyValue}
              isSubmitting={isLoading}
              totalQuestions={totalQuestionsInSet}
            />
          </Style.MainInfoBlock>

          <Style.InfoContentContainer>
            <Style.FlexOne>
              <InformationBlock content={infoContent} />
            </Style.FlexOne>

            <Style.FlexOne>
              <UsefulLinksBlock links={infoLinks} />
            </Style.FlexOne>
          </Style.InfoContentContainer>
        </Style.ContainerBlock>

        {isExpanded && (
          <Style.FeedBlock>
            <Style.FeedContainer $isExpanded={isExpanded}>
              <YourFeed
                isExpanded={isExpanded}
                onExpand={onExpand}
                headerTitle={
                  !isOnboarding ? t('feed.questionThread') : undefined
                }
                canAddComments={!isOnboarding && !isDeactivated}
              />
            </Style.FeedContainer>
          </Style.FeedBlock>
        )}
      </Style.MainContainer>
    </PageLayout>
  );
};
