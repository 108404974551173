import React from 'react';

import * as Style from './TableTabs.styles';

type Tab<U> = {
  id: U;
  label: string;
};

interface TableTabsProps<T extends Tab<U>, U> {
  tabs: T[];
  renderInfo?: (id: U) => React.ReactNode;
  onTabChange: (id: U) => void;
  currentTab: U;
}

export const TableTabs = <T extends Tab<U>, U>({
  tabs,
  renderInfo,
  onTabChange,
  currentTab,
}: TableTabsProps<T, U>) => {
  return (
    <Style.Tabs className="scroll-hidden">
      {tabs.map(({
        id, label
      }) => {
        const info = !!renderInfo && renderInfo(id);

        return (
          <Style.Tab
            key={id as string}
            onClick={() => onTabChange(id)}
            $isActive={id === currentTab}
          >
            {label}

            {info && <Style.TabInfo>{info}</Style.TabInfo>}
          </Style.Tab>
        );
      })}
    </Style.Tabs>
  );
};
