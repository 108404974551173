import React, {
  FC, ReactNode
} from 'react';
import useBreadcrumbs, {
  type BreadcrumbsRoute,
  BreadcrumbData,
} from 'use-react-router-breadcrumbs';

import * as Style from './Breadcrumbs.styles';

type PathState = Record<string, string>;

interface ExtendedBreadcrumbData extends BreadcrumbData<string> {
  customPath?: string;
  pathState?: PathState;
}

interface BreadcrumbsProps {
  hideHome?: boolean;
  routes?: BreadcrumbsRoute<string>[];
  disableDefaults?: boolean;
  excludePaths?: string[];
}

interface BreadcrumbProps {
  link: string;
  breadcrumb: ReactNode;
  isLast: boolean;
  pathState?: PathState;
}

const Breadcrumb: FC<BreadcrumbProps> = ({
  link,
  pathState,
  breadcrumb,
  isLast,
}) => {
  return (
    <Style.Breadcrumb
      to={link}
      state={pathState}
      $isLast={isLast}
    >
      {breadcrumb}

      {!isLast && <Style.Divider>{' / '}</Style.Divider>}
    </Style.Breadcrumb>
  );
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  hideHome = true,
  routes,
  disableDefaults = false,
  excludePaths,
}) => {
  const breadcrumbs: ExtendedBreadcrumbData[] = useBreadcrumbs(
    routes,
    {
      disableDefaults,
      excludePaths,
    }
  );

  return (
    <div>
      {breadcrumbs.map(
        ({
          breadcrumb, match, key, customPath, pathState
        }, index) => {
          if (match.pathname === '/' && hideHome) {
            return null;
          }

          return (
            <Breadcrumb
              key={key}
              link={customPath ?? match.pathname}
              pathState={pathState}
              isLast={index === breadcrumbs.length - 1}
              breadcrumb={breadcrumb}
            />
          );
        },
      )}
    </div>
  );
};
