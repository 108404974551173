import React, {
  FC
} from 'react';
import {
  useParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  usePostOrgInviteFileTemplateMutation
} from 'src/shared/api/organizations/organizationsApi';
import {
  DE_USERS_TEMPLATE_URL,
  EN_USERS_TEMPLATE_URL,
  showWarningFromServer,
} from 'src/shared/utils';

import {
  successfulToast
} from '../Toasts';
import {
  DragAndDropInput
} from '../DragAndDropInput';

import * as Style from './UploadUsersList.styles';

const FILE_KEY = 'template';

export const UploadUsersList: FC = () => {
  const params = useParams();

  const {
    t,
    i18n: {
      language
    },
  } = useTranslation();

  const templateLink = language === 'en' ? EN_USERS_TEMPLATE_URL : DE_USERS_TEMPLATE_URL;

  const organisationId = params.organizationId;

  const [postOrgFileTemplate] = usePostOrgInviteFileTemplateMutation();

  const handleFileLoading = async (formData: FormData) => {
    if (!organisationId) {
      return;
    }

    try {
      const response = await postOrgFileTemplate({
        id: organisationId,
        formData,
      }).unwrap();

      successfulToast(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <Style.Container>
      <p>{t('organisation.uploadFileWithGroupsAndUsersList')}</p>

      <DragAndDropInput
        templateLink={templateLink}
        handleFileLoading={handleFileLoading}
        fileKey={FILE_KEY}
      />
    </Style.Container>
  );
};
