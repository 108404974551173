import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const GroupCards = styled.div`
  ${tw`grid md:grid-cols-2 lg:grid-cols-3 mt-4 px-3 pb-4 gap-3 overflow-auto`}
`;

export const SwitchButtonContainer = styled.div`
  ${tw`flex justify-end mt-4 px-3`}
`;
