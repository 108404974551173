import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const FieldTitle = styled.div`
  ${tw`font-medium text-grey-dark-40 text-15-20 mb-2`}
`;

export const FieldContainer = styled.div<{ $flex?: boolean }>`
  ${tw`p-2 border border-input-blue rounded font-medium text-17-26 text-gray7`}

  ${({
    $flex
  }) => $flex && tw`flex justify-between items-center`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex justify-end gap-4`}
`;

export const ContentContainer = styled.div`
  ${tw`flex flex-col gap-6`}
`;

export const DateContainer = styled.p`
  ${tw`text-dim-gray font-medium text-xs mt-1`}
`;
