import {
  Items as Comment
} from './comments';

export type ReplyComment = Comment['comments'][number];

export enum CommendationType {
  LIKE = 'LIKE',
  RELEVANT = 'RELEVANT',
  INSIGHTFUL = 'INSIGHTFUL',
  INSPIRING = 'INSPIRING',
}

export type Commendation = keyof typeof CommendationType;
