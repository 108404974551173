import tw from 'twin.macro';
import {
  styled
} from 'styled-components';
import {
  Link
} from 'react-router-dom';

const TOP_PADDING = '17px';
const BOTTOM_MARGIN = '31px';

export const CardsContainer = styled.div`
  ${tw`grid grid-cols-2 gap-6 mt-4`}
`;

export const SectionsContainer = styled.div<{ $shift: number }>`
  ${tw`grid grid-cols-[minmax(440px,1.5fr),minmax(440px,1fr),320px] gap-6`}

  padding-top: ${TOP_PADDING};
  margin-bottom: ${BOTTOM_MARGIN};

  ${({
    $shift
  }) => `grid-template-rows: minmax(620px, calc(100vh - ${$shift}px - ${TOP_PADDING} - ${BOTTOM_MARGIN}));`}
`;

export const EmptyMessageContainer = styled.div`
  ${tw`text-center text-17-26 p-4 h-full flex flex-col items-center justify-center`}
`;

export const EmptyMessageTitle = styled.p`
  ${tw`font-bold text-dark-gray mb-2`}
`;

export const EmptyMessageSubtitle = styled.p`
  ${tw`font-medium text-dim-gray mb-6`}
`;

export const EmptyMessageLink = styled(Link)`
  ${tw`rounded-100px border border-light-grey font-bold text-15-20 text-dim-gray py-9px px-15px bg-white`}
`;

export const CreateMessageContainer = styled.div`
  ${tw`bg-white rounded-lg p-6`}
`;

export const OrganisationWrapper = styled.div`
  ${tw`mt-6`}
`;

export const HeaderContainer = styled.div`
  ${tw`flex justify-between items-center gap-2`}
`;
