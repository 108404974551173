import React, {
  FC, useState
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';
import {
  useNavigate, useParams
} from 'react-router-dom';

import {
  PageLayout
} from 'src/widgets';
import {
  Button, Table, TableContent, TableRow
} from 'src/shared/components';
import {
  ROUTE, getConfidenceStatus
} from 'src/shared/utils';
import {
  CheckmarkCircleEmptyIcon, RefreshIcon
} from 'src/shared/icons';
import {
  useGetCalibrationByIdQuery,
  useGetCalibrationResultsQuery,
} from 'src/redux/openapi';

import {
  ModalSetConfidenceRate
} from '../CalibrationTool/ui';

import * as Style from './CalibrationResults.style';
import {
  LoadingResults
} from './ui';
import {
  getResultsCols
} from './utils';

export const CalibrationResults: FC = () => {
  const {
    t
  } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();

  const {
    calibrationId
  } = params;

  const {
    data: resultsData
  } = useGetCalibrationResultsQuery(
    {
      id: calibrationId || '',
    },
    {
      skip: !calibrationId,
    },
  );

  const resultsColumns = getResultsCols();

  const {
    resultData, calibrationStep, isCalibrated, isResultLoading
  } = useGetCalibrationByIdQuery(
    {
      id: calibrationId || '',
    },
    {
      skip: !calibrationId,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data, isLoading
      }) => ({
        resultData: data?.data.calibration,
        isCalibrated: data?.data.calibration.calibrated,
        calibrationStep: data?.data.calibratedCount || 0,
        isResultLoading: isLoading,
      }),
    },
  );

  const calibratedResult = isCalibrated
    ? ''
    : getConfidenceStatus({
      confidence: resultData?.confidence || 0,
      correct: resultData?.correctAnswers || 0,
      total: resultData?.totalQuestions || 0,
    });

  const [isStartCalibration, setIsStartCalibration] = useState(false);

  const toggleModal = () => {
    setIsStartCalibration((prev) => !prev);
  };

  const handleFinishClick = () => {
    navigate(`/${ROUTE.CALIBRATION_TOOL}`);
  };

  const isCalibrationCompleted = isCalibrated && calibrationStep > 1;
  const isFirstStepCalibrated = isCalibrated && calibrationStep === 1;

  return (
    <PageLayout>
      {isResultLoading ? (
        <LoadingResults />
      ) : (
        <div>
          <Style.Title>{t('calibration.calibrationResults')}</Style.Title>

          <Table>
            <TableContent
              data={resultsData?.data.results || []}
              columns={resultsColumns}
              renderRow={(row) => (
                <TableRow
                  row={row}
                  key={row.original.calibrationQuestion.id}
                  variant="white"
                />
              )}
            />

            <Style.Recommendation>
              {isCalibrated ? (
                <Style.SuccessResult>
                  <CheckmarkCircleEmptyIcon className="text-accent80 w-6 h-6" />

                  <p>
                    {isCalibrationCompleted
                      && `${t('calibration.answersCalibrated')} ${t(
                        'calibration.calibrationCompletedHint',
                      )}.`}

                    {isFirstStepCalibrated
                      && `${t('calibration.round1Completed')} - ${t(
                        'calibration.passSecondRoundOfCalibration',
                      )}`}
                  </p>
                </Style.SuccessResult>
              ) : (
                <p>
                  <strong>{`${t('calibration.recommendation')}: `}</strong>

                  <Trans
                    i18nKey="calibration.notCalibratedResultHint"
                    values={{
                      calibratedResult,
                    }}
                  />
                </p>
              )}
            </Style.Recommendation>

            <Style.ResultButtons>
              {!isCalibrationCompleted && (
                <Button
                  variant="big-grey-bordered"
                  onClick={toggleModal}
                  className="text-xl font-bold text-dark-gray !w-max"
                >
                  {isFirstStepCalibrated
                    ? t('buttons.continue')
                    : t('buttons.restart')}

                  {!isFirstStepCalibrated && <RefreshIcon />}
                </Button>
              )}

              <Button
                variant="big-blue"
                onClick={handleFinishClick}
                className="text-xl font-bold text-white !w-max"
              >
                {t('buttons.finish')}
              </Button>
            </Style.ResultButtons>

            <ModalSetConfidenceRate
              isOpen={isStartCalibration}
              closeModal={toggleModal}
              isTestResult={resultData?.isTest}
            />
          </Table>
        </div>
      )}
    </PageLayout>
  );
};
