import React from 'react';
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';

import {
  defaultHexColor,
  formatDateRange,
  themeColors,
} from 'src/shared/utils';
import {
  AdminQuestionsRequest
} from 'src/redux/openapi';
import {
  DownloadSetRequest
} from 'src/widgets';

import * as Style from '../OrgManagementTable.styles';

const requestsColumnHelper = createColumnHelper<AdminQuestionsRequest>();

const getStatusConfig = (status: AdminQuestionsRequest['status']) => {
  switch (status) {
    case 'PENDING':
      return {
        color: defaultHexColor,
        label: t('common.pending'),
      };
    case 'REJECTED':
      return {
        color: themeColors['red-text-error'],
        label: t('answerStatus.declined'),
      };
    case 'ACCEPTED':
    default:
      return {
        color: themeColors['forest-green'],
        label: t('answerStatus.approved'),
      };
  }
};

export const getSetRequestsCols = (language: string) => {
  const columns = [
    requestsColumnHelper.accessor(
      'organization.name',
      {
        header: t('organisation.organisationName'),
        footer: (props) => props.column.id,
        size: 453,
        minSize: 150,
        cell: (info) => {
          return <Style.NameCellSpan>{info.getValue()}</Style.NameCellSpan>;
        },
      }
    ),
    requestsColumnHelper.accessor(
      'setTitle',
      {
        header: t('question.setName'),
        footer: (props) => props.column.id,
        size: 453,
        minSize: 150,
        cell: ({
          row: {
            original: {
              setTitle, setStartDate, setEndDate
            },
          },
        }) => (
          <div>
            <p className="mb-1 text-dark-gray font-medium">{setTitle}</p>

            <p className="text-dim-gray font-medium text-xs">
              {formatDateRange({
                start: setStartDate,
                end: setEndDate,
                language,
              })}
            </p>
          </div>
        ),
      }
    ),
    requestsColumnHelper.accessor(
      'createdAt',
      {
        header: t('filters.requestDate'),
        footer: (props) => props.column.id,
        size: 200,
        minSize: 120,
        cell: (info) => (
          <p className="text-dark-gray font-medium">
            {formatDateRange({
              start: info.getValue(),
              language,
            })}
          </p>
        ),
      }
    ),
    requestsColumnHelper.accessor(
      'status',
      {
        header: t('question.setProcessing'),
        footer: (props) => props.column.id,
        size: 111,
        minSize: 100,
        cell: (info) => {
          const status = info.getValue();

          const {
            color, label
          } = getStatusConfig(status);

          return <Style.Status $color={color}>{label}</Style.Status>;
        },
      }
    ),
    requestsColumnHelper.accessor(
      'xlsxUrl',
      {
        header: t('question.downloadSet'),
        footer: (props) => props.column.id,
        size: 111,
        minSize: 100,
        cell: (info) => <DownloadSetRequest url={info.getValue()} />,
      }
    ),
  ];

  return columns;
};
