import React, {
  FC, useState
} from 'react';

import {
  Spinner
} from 'src/shared/components';
import {
  useToggleFavQuestion
} from 'src/shared/hooks';
import {
  Star
} from 'src/widgets';

interface FavoriteButtonProps {
  defaultValue: boolean;
  id: string;
}

export const FavoriteButton: FC<FavoriteButtonProps> = ({
  defaultValue,
  id,
}) => {
  const [isQuestionFavorite, setIsQuestionFavorite] = useState(defaultValue);

  const toggleFavQuestion = () => setIsQuestionFavorite((prev) => !prev);

  const {
    handleChangeFavQuestion, isChangingFavQuestion
  } = useToggleFavQuestion(
    isQuestionFavorite,
    toggleFavQuestion
  );

  const handleChangeFavorite = () => {
    handleChangeFavQuestion(id);
  };

  return (
    <button
      type="button"
      onClick={handleChangeFavorite}
      disabled={isChangingFavQuestion}
      className="text-button-blue"
    >
      {isChangingFavQuestion ? (
        <Spinner size={20} />
      ) : (
        <Star isFilled={isQuestionFavorite} />
      )}
    </button>
  );
};
