import {
  OrgManagementActiveQuestion,
  OrgManagementDeactivatedQuestion,
} from './organizations';
import {
  AdminManagementActiveQuestion,
  AdminManagementDeactivatedQuestion,
} from './admin';

export type SortOrder = 'asc' | 'desc';

export enum UserGroupRole {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type UserGroupRoleType = keyof typeof UserGroupRole;

export interface ErrorResponse {
  data: {
    message: string;
  };
}

export type ManagementActiveQuestion =
  | OrgManagementActiveQuestion
  | AdminManagementActiveQuestion;

export type ManagementDeactivatedQuestion =
  | OrgManagementDeactivatedQuestion
  | AdminManagementDeactivatedQuestion;
