import React, {
  FC, PropsWithChildren, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Spinner, Tooltip
} from 'src/shared/components';
import {
  StatisticTile
} from 'src/entities/Questions';
import {
  GlobeIcon, PeopleIcon, PersonIcon
} from 'src/shared/icons';
import {
  useGetGroupsQuestionsQuery
} from 'src/redux/openapi';
import {
  useCustomInfiniteScroll
} from 'src/shared/hooks';

import * as Style from './CommunityGroupTooltip.styles';

interface CommunityGroupTooltipProps extends PropsWithChildren {
  id: string;
}

export const CommunityGroupTooltip: FC<CommunityGroupTooltipProps> = ({
  children,
  id,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [wasHovered, setWasHovered] = useState(false);

  const {
    t
  } = useTranslation();

  const {
    data: groupQuestions,
    isFetching,
    isLoading,
    isError,
  } = useGetGroupsQuestionsQuery(
    {
      id,
      page: currentPage,
      limit: 10,
    },
    {
      skip: !wasHovered,
    },
  );

  const {
    total: totalSets, questions: currentItems
  } = useMemo(
    () => groupQuestions?.data || {
      total: 0,
      questions: [],
    },
    [groupQuestions],
  );

  const {
    sentryRef,
    allItems: questions,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalSets,
    currentItems,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  const content = (
    <Style.MainContainer>
      {isLoading ? (
        <Spinner
          size={24}
          withLoadingText
        />
      ) : (
        <>
          {questions.length > 0 ? (
            <Style.Title>{t('question.questionForecast')}</Style.Title>
          ) : (
            <Style.Title>{t('question.groupDoesntHaveForecasts')}</Style.Title>
          )}

          <Style.QuestionsContainer>
            {!!questions.length
              && questions.map((question) => (
                <Style.QuestionContainer key={question.id}>
                  {question.title}

                  <Style.StatisticRow>
                    <StatisticTile
                      icon={<PersonIcon className="w-4 h-4 text-dim-gray" />}
                      amount={question.userEstimatesAvg}
                      applyMinWidth={false}
                    />

                    <StatisticTile
                      icon={<GlobeIcon className="w-4 h-4 text-dim-gray" />}
                      amount={question.globalEstimatesAvg}
                      growth={question.globalEstimatesChange}
                      applyMinWidth={false}
                    />

                    <StatisticTile
                      icon={<PeopleIcon className="w-4 h-4 text-dim-gray" />}
                      amount={question.groupEstimatesAvg}
                      growth={question.groupEstimatesChange}
                      applyMinWidth={false}
                    />
                  </Style.StatisticRow>
                </Style.QuestionContainer>
              ))}

            {displayLoader && (
              <div ref={sentryRef}>
                <Spinner size={24} />
              </div>
            )}
          </Style.QuestionsContainer>
        </>
      )}
    </Style.MainContainer>
  );

  return (
    <Tooltip
      message={content}
      variant="light"
      hideOnScroll={false}
      onHoverCallback={setWasHovered}
    >
      {children}
    </Tooltip>
  );
};
