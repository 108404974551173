import React, {
  useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useLocation, useNavigate, useParams
} from 'react-router-dom';

import {
  AddQuestionSets, ModalSuccess, FilterTile
} from 'src/features';
import {
  SetForCreation,
  useGetOrganizationQuery,
  useGetOrgQuestionsRequestQuery,
  usePostQuestionsRequestSubmitMutation,
} from 'src/redux/openapi';
import {
  usePostOrgQuestionsRequestsMutation
} from 'src/shared/api/organizations/organizationsApi';
import {
  successfulToast
} from 'src/shared/components';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  PageLayout
} from 'src/widgets';

import * as Style from './AddOgrQuestionSet.styles';

export const AddOgrQuestionSet = () => {
  const [questionSets, setQuestionSets] = useState<SetForCreation[]>([]);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    requestId
  } = location.state || {};

  const {
    organizationId = ''
  } = params;

  const {
    data: organizationData
  } = useGetOrganizationQuery(
    {
      id: organizationId,
    },
    {
      skip: !organizationId,
      refetchOnMountOrArgChange: true,
    },
  );

  const organizationName = organizationData?.data.organization.name;

  const {
    data
  } = useGetOrgQuestionsRequestQuery(
    {
      id: organizationId,
      requestId,
    },
    {
      skip: !requestId || !!questionSets.length || !organizationId,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(
    () => {
      const {
        sets, isSubmitted
      } = data?.data.createRequest || {};

      if (sets && !isSubmitted) {
        setQuestionSets(sets);
      }
    },
    [data]
  );

  const [parseQuestions] = usePostOrgQuestionsRequestsMutation();

  const [postQuestions, {
    isLoading: isCreationInProcess
  }] = usePostQuestionsRequestSubmitMutation();

  const {
    t
  } = useTranslation();

  const handleNavigate = (idToSave: string | null) => {
    navigate(
      '.',
      {
        state: {
          requestId: idToSave,
        },
        replace: true,
      }
    );
  };

  const handleFileLoading = async (file: FormData) => {
    try {
      const response = await parseQuestions({
        id: organizationId,
        file,
      }).unwrap();

      successfulToast(response.message);
      setQuestionSets(response.data.sets);

      handleNavigate(response.data.createRequest.id);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onModalClose = () => {
    handleNavigate(null);

    setIsSuccessModalOpen(false);
    setQuestionSets([]);
  };

  const onCreate = async () => {
    try {
      await postQuestions({
        id: organizationId,
        requestId,
      }).unwrap();

      setIsSuccessModalOpen(true);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <PageLayout>
      <Style.ContentWrapper>
        {organizationName && (
          <FilterTile
            $isActive
            className="w-max"
          >
            {organizationName}
          </FilterTile>
        )}

        <AddQuestionSets.ContentWrapper>
          <AddQuestionSets.Header loadFile={handleFileLoading}>
            <Style.Title>{t('question.createOrExpandSet')}</Style.Title>
          </AddQuestionSets.Header>

          <AddQuestionSets.QuestionSetList questionSets={questionSets} />

          <AddQuestionSets.Footer
            canCreate={!!questionSets.length}
            isCreationInProcess={isCreationInProcess}
            handleCreation={onCreate}
            isLastStep
          />
        </AddQuestionSets.ContentWrapper>
      </Style.ContentWrapper>

      <ModalSuccess
        closeModal={onModalClose}
        isOpen={isSuccessModalOpen}
        title={t('modal.changesSaved')}
        textContent={t('question.setWillBeSentToAdmin')}
        titleClassName="capitalize"
        textClassName="!text-center"
        buttonTitle={`${t('buttons.gotIt')}`}
        onButtonClick={onModalClose}
        buttonIcon={null}
        buttonClassName="mx-auto"
        modalWidth={509}
      />
    </PageLayout>
  );
};
