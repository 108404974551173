import tw from 'twin.macro';
import {
  styled
} from 'styled-components';
import {
  Link
} from 'react-router-dom';

export const FiltersContainer = styled.div`
  ${tw`py-4 px-3 shrink-0 overflow-hidden flex gap-4 justify-between items-center`}
`;

export const ActivityButton = styled(Link)`
  ${tw`
    flex items-center justify-center gap-1 rounded-lg border border-gray90
    font-bold text-15-20 text-dark-gray shadow-button-shadow py-2.5 px-8
  `}
`;
