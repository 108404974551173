import React, {
  FC
} from 'react';

import {
  GroupInfo, GroupSets
} from 'src/entities';
import {
  OrganizationGroup
} from 'src/redux/openapi';
import {
  CommunityGroupTooltip, PublicPin
} from 'src/widgets';
import {
  CircleButton
} from 'src/shared/components';
import {
  InfoIcon,
  MessageIcon,
  PeopleIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  ActivityTile
} from 'src/entities/Questions';
import {
  formatNumber
} from 'src/shared/utils';

import * as Style from './GroupDetails.styles';

interface GroupDetailsProps {
  group: OrganizationGroup;
  isCard?: boolean;
}

export const GroupDetails: FC<GroupDetailsProps> = ({
  group, isCard
}) => {
  const {
    id, isPublic, memberCount, forecastCount, commentCount
  } = group;

  return (
    <Style.HeaderWrapper $isCard={isCard}>
      <Style.InfoWrapper>
        <GroupInfo group={group} />

        <Style.ForecastWrapper>
          {isPublic && <PublicPin />}

          <Style.CardForecast>
            <CommunityGroupTooltip id={id}>
              <CircleButton size={24}>
                <InfoIcon className="w-4 h-4 text-dim-gray" />
              </CircleButton>
            </CommunityGroupTooltip>
          </Style.CardForecast>
        </Style.ForecastWrapper>
      </Style.InfoWrapper>

      {isCard && (
        <Style.ActivityRow>
          <ActivityTile
            icon={<PeopleIcon className="w-6 h-6 text-dim-gray" />}
            amount={memberCount}
          />

          <Style.ActivityChild>
            <ActivityTile
              icon={<MessageIcon className="w-6 h-6 text-dim-gray" />}
              amount={formatNumber(commentCount)}
              timeRange="upToday"
            />

            <ActivityTile
              icon={<TrendingUpIcon className="w-6 h-6 text-dim-gray" />}
              amount={formatNumber(forecastCount)}
              timeRange="upToday"
            />
          </Style.ActivityChild>
        </Style.ActivityRow>
      )}

      <GroupSets
        questionSets={group.questionSets}
        setContainerClassName={!isCard ? '!px-0 py-2' : ''}
      />
    </Style.HeaderWrapper>
  );
};
