import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetAdminPostEstimationDownloadMutation,
  useGetOrgPostEstimationDownloadMutation,
} from 'src/redux/openapi';
import {
  Button, Tooltip, warningToast
} from 'src/shared/components';
import {
  DownloadIcon
} from 'src/shared/icons';
import {
  onFileDownload
} from 'src/shared/utils';

interface DownloadExPostDebasingProps {
  hasFeedback: boolean;
  organisationId?: string;
  questionId: string;
}

const WITH_FEEDBACK_TOOLTIP_WIDTH = 155;
const NO_FEEDBACK_TOOLTIP_WIDTH = 200;

export const DownloadExPostDebasing: FC<DownloadExPostDebasingProps> = ({
  hasFeedback,
  organisationId,
  questionId,
}) => {
  const {
    t
  } = useTranslation();

  const message = hasFeedback
    ? t('feedback.downloadPostEstimationFeedback')
    : t('feedback.canDownloadOnceUsersProvideFeedback');

  const maxTooltipWidth = hasFeedback
    ? WITH_FEEDBACK_TOOLTIP_WIDTH
    : NO_FEEDBACK_TOOLTIP_WIDTH;

  const [downloadForOrgAdmin] = useGetOrgPostEstimationDownloadMutation();
  const [downloadForArqAdmin] = useGetAdminPostEstimationDownloadMutation();

  const onDownload = async () => {
    const response = organisationId
      ? await downloadForOrgAdmin({
        id: organisationId,
        questionId,
      })
      : await downloadForArqAdmin({
        id: questionId,
      });

    if ('error' in response) {
      if ('data' in response.error) {
        warningToast(JSON.parse(response.error.data as string).message);
      }

      return;
    }

    onFileDownload(
      response.data as Blob,
      'estimation-feedbacks'
    );
  };

  return (
    <Tooltip
      message={message}
      maxWidth={maxTooltipWidth}
      messageContainerProps={{
        className: hasFeedback ? 'text-center' : 'text-left',
      }}
    >
      <Button
        variant="big-blue"
        className="!px-5 !py-1.5 w-max !h-max disabled:!bg-light-grey"
        disabled={!hasFeedback}
        onClick={onDownload}
      >
        <DownloadIcon className="w-6 h-6" />
      </Button>
    </Tooltip>
  );
};
