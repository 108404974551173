import React, {
  FC, useState
} from 'react';
import {
  useParams
} from 'react-router-dom';

import {
  Divider,
  MenuContainer,
  MenuItem,
  PopoverMenu,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  MoreVerticalIcon
} from 'src/shared/icons';
import {
  DeactivateGroupModal,
  DeleteGroupModal,
  EditGroupModal,
  MergeGroupsModal,
} from 'src/features/Modals';
import {
  OrgInvitationsGroup,
  usePutOrgGroupsAndGroupIdStatusesMutation,
} from 'src/redux/openapi';
import {
  CreateFromGroupModal
} from 'src/features/Modals/ui/CreateFromGroupModal';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  GroupAction, getMenuMap
} from './utils';

interface GroupMenuProps {
  groupData: OrgInvitationsGroup;
  refetchGroups: () => void;
  onGroupDelete: (id: string) => void;
  isDeactivated?: boolean;
}

export const GroupMenu: FC<GroupMenuProps> = ({
  groupData,
  refetchGroups,
  onGroupDelete,
  isDeactivated,
}) => {
  const [activeAction, setActiveAction] = useState<GroupAction | null>(null);

  const params = useParams();

  const {
    organizationId = ''
  } = params;

  const {
    id, activeUsersCount, isPublic
  } = groupData;

  const menuMap = getMenuMap({
    activeUsersCount,
    isDeactivated,
    isPublic,
  });

  const removeAction = () => {
    setActiveAction(null);
  };

  const [activateGroup, {
    isLoading: isActivationLoading
  }] = usePutOrgGroupsAndGroupIdStatusesMutation();

  const onActivate = async () => {
    try {
      const response = await activateGroup({
        id: organizationId,
        groupId: id,
        updateOrganizationGroupStatusBodySchema: {
          status: 'ACTIVATED',
        },
      }).unwrap();

      successfulToast(response.message);

      refetchGroups();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onActionClick = (action: GroupAction) => {
    if (action === GroupAction.ActivateGroup) {
      onActivate();

      return;
    }

    setActiveAction(action);
  };

  return (
    <>
      <PopoverMenu
        placement="bottom-end"
        buttonContent={<MoreVerticalIcon className="h-6 w-6" />}
        shouldForcedClose={!!activeAction}
        applyMaxHeight={false}
      >
        <MenuContainer $width={268}>
          {menuMap.map(
            ({
              id: menuItemId, label, icon: Icon, color, withDivider
            }) => {
              const isLoading = menuItemId === GroupAction.ActivateGroup && isActivationLoading;

              return (
                <React.Fragment key={menuItemId}>
                  {withDivider && <Divider />}

                  <MenuItem
                    key={menuItemId}
                    $color={color}
                    className="hover:bg-cultured"
                    onClick={() => onActionClick(menuItemId)}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner
                        color="inherit"
                        size={24}
                        centered={false}
                      />
                    ) : (
                      <Icon className="w-6 h-6" />
                    )}

                    {label}
                  </MenuItem>
                </React.Fragment>
              );
            },
          )}
        </MenuContainer>
      </PopoverMenu>

      <EditGroupModal
        isOpen={activeAction === GroupAction.EditGroup}
        closeModal={removeAction}
        onGroupEdit={refetchGroups}
        group={groupData}
      />

      <DeleteGroupModal
        closeModal={removeAction}
        isOpen={activeAction === GroupAction.DeleteGroup}
        id={id}
        onSuccessDelete={() => onGroupDelete(id)}
      />

      <MergeGroupsModal
        closeModal={removeAction}
        isOpen={activeAction === GroupAction.MergeGroups}
        currentGroup={groupData}
      />

      <CreateFromGroupModal
        closeModal={removeAction}
        isOpen={activeAction === GroupAction.CreateFromGroup}
        groupId={groupData.id}
        onGroupCreation={refetchGroups}
      />

      <DeactivateGroupModal
        closeModal={removeAction}
        isOpen={activeAction === GroupAction.DeactivateGroup}
        id={groupData.id}
        onSuccess={refetchGroups}
      />
    </>
  );
};
