import React, {
  FC, MouseEvent
} from 'react';

import {
  Button
} from 'src/shared/components';
import {
  DownloadIcon
} from 'src/shared/icons';

interface DownloadSetRequestProps {
  url: string;
}

export const DownloadSetRequest: FC<DownloadSetRequestProps> = ({
  url
}) => {
  const onClick = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  return (
    <Button
      component="link"
      to={url}
      type="download"
      onClick={onClick}
    >
      <DownloadIcon className="text-dim-gray mx-auto" />
    </Button>
  );
};
