import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MembersContainer = styled.div`
  ${tw`pb-6 h-full overflow-auto flex flex-col`}
`;

export const MembersListContainer = styled.div`
  ${tw`flex flex-col gap-6 overflow-auto mt-6`}
`;
