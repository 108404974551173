import React, {
  FC
} from 'react';
import RCSlider from 'rc-slider';
import type {
  SliderProps as RCSliderProps
} from 'rc-slider';
import clsx from 'clsx';

import './styles.css';

type SliderProps = Pick<
RCSliderProps<number>,
'min' | 'max' | 'value' | 'onChange' | 'step' | 'disabled'
> & {
  variant: 'dot' | 'scrollbar';
  size?: 'big' | 'small' | 'label-small';
  isMulti?: boolean;
};

export const Slider: FC<SliderProps> = ({
  onChange,
  variant,
  size = 'big',
  isMulti,
  step = 1,
  ...props
}) => {
  const {
    disabled = false
  } = props;

  const isSmallLabel = size === 'label-small';

  return (
    <div
      className={clsx(
        variant,
        {
          active: !disabled,
          'flex px-10': variant === 'scrollbar',
          'label-slider-rail px-55px': isSmallLabel,
        }
      )}
    >
      <RCSlider
        classNames={{
          rail: clsx({
            '!bg-transparent rounded-30px h-1': isSmallLabel,
            '!bg-gray-whisper rounded-r-1000px h-2': !isSmallLabel,
          }),
          track: clsx({
            '!bg-transparent rounded-30px h-0.5': isSmallLabel,
            '!bg-gray7 rounded-l-1000px h-2': !isSmallLabel,
          }),
          handle: clsx({
            'bg-gray85 h-2.5 w-32 rounded-30px mt-8 rotate-90 -translate-y-1/2 origin-top-right opacity-50':
              isSmallLabel,
            '-translate-x-[25px]': isSmallLabel && isMulti,
            'bg-gray7 border-2 border-white rounded-full rotate-0 -translate-x-2/4 origin-center opacity-100':
              !isSmallLabel,
            'w-8 h-8 -mt-3': size === 'big',
            'w-6 h-6 -mt-2': size === 'small',
          }),
        }}
        onChange={(newValue) => {
          if (!onChange) {
            return;
          }

          if (Array.isArray(newValue)) {
            onChange(newValue[0]);

            return;
          }

          onChange(newValue);
        }}
        step={step}
        {...props}
      />
    </div>
  );
};
