import React, {
  useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useSearchParams
} from 'react-router-dom';

import {
  PageLayout
} from 'src/widgets';
import {
  PlusRoundedIcon
} from 'src/shared/icons';
import {
  CreateOrganizationModal,
  OrgManagementTable,
  OrgManagementTableTabs,
} from 'src/features';
import {
  managementTab
} from 'src/shared/utils';
import {
  QuestionSetControls
} from 'src/widgets/QuestionSetControls';
import {
  Button
} from 'src/shared/components';

import * as Style from './OrganizationManagement.styles';

export const OrganizationManagement = () => {
  const [searchParams] = useSearchParams();
  const [isModalOpened, setIsModalOpened] = useState(false);

  const currentTab = searchParams.get(managementTab) as OrgManagementTableTabs;

  const toggleModal = () => setIsModalOpened((prev) => !prev);

  const {
    t
  } = useTranslation();

  const showQuestionManagement = currentTab
    && [
      OrgManagementTableTabs.SetManagement,
      OrgManagementTableTabs.QuestionScoring,
    ].includes(currentTab);

  return (
    <PageLayout>
      <Style.ContentWrapper>
        <Style.ContentHeader>
          <Style.Title>{t('management.managementBoard')}</Style.Title>

          <Style.HeaderRightSide>
            {showQuestionManagement ? (
              <QuestionSetControls />
            ) : (
              <Button
                onClick={toggleModal}
                variant="big-blue-outlined"
                className="!py-2.5 !px-4 !bg-transparent !gap-2"
              >
                <PlusRoundedIcon className="w-6 h-6 text-button-blue" />

                {t('organisation.createOrganisation')}
              </Button>
            )}
          </Style.HeaderRightSide>
        </Style.ContentHeader>

        <OrgManagementTable />
      </Style.ContentWrapper>

      <CreateOrganizationModal
        isOpen={isModalOpened}
        onClose={toggleModal}
      />
    </PageLayout>
  );
};
