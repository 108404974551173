import React, {
  ChangeEvent, FC, useEffect, useRef, useState
} from 'react';
import {
  useDropzone
} from 'react-dropzone';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  DownloadIcon
} from 'src/shared/icons';
import {
  MAX_MB_FILE_SIZE,
  XLSX_TYPE,
  getMaxAllowedFileSize,
  showWarningFromServer,
} from 'src/shared/utils';

import {
  Spinner
} from '../Spinner';
import {
  Button
} from '../Button';
import {
  errorToast
} from '../Toasts';

import * as Style from './DragAndDropInput.styles';
import './index.css';

interface DragAndDropInputProps {
  handleFileLoading: (formData: FormData) => Promise<void>;
  templateLink?: string;
  fileKey?: string;
}

export const DragAndDropInput: FC<DragAndDropInputProps> = ({
  handleFileLoading,
  templateLink,
  fileKey = 'file',
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    t
  } = useTranslation();

  const loadFile = async (formData: FormData) => {
    try {
      setIsFileUploading(true);

      await handleFileLoading(formData);

      setSelectedFile(null);
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsFileUploading(false);
    }
  };

  useEffect(
    () => {
      if (selectedFile) {
        const formData = new FormData();

        formData.append(
          fileKey,
          selectedFile,
          selectedFile.name
        );

        loadFile(formData);
      }
    },
    [selectedFile]
  );

  const validateFile = (file: File) => {
    const isNotValidTypeFile = file.type !== XLSX_TYPE;

    if (isNotValidTypeFile) {
      errorToast(t('errors.selectValidXlsxFile'));

      return;
    }

    const isBigFile = file.size > getMaxAllowedFileSize(MAX_MB_FILE_SIZE);

    if (isBigFile) {
      errorToast(`${t('errors.selectXlsxFileLessThen')} ${MAX_MB_FILE_SIZE}Mb`);

      return;
    }

    setSelectedFile(file);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files?.length) {
      return;
    }

    const inputFile = event.target.files[0];

    validateFile(inputFile);
  };

  const {
    getRootProps, getInputProps, isDragActive
  } = useDropzone({
    accept: {
      [XLSX_TYPE]: ['.xlsx'],
    },
    maxSize: getMaxAllowedFileSize(MAX_MB_FILE_SIZE),
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (!acceptedFiles.length) {
        return;
      }

      const file = acceptedFiles[0];

      validateFile(file);
    },
  });

  const inputDrugProps = getInputProps();
  inputDrugProps.accept = `.xlsx, ${XLSX_TYPE}`;
  inputDrugProps.multiple = false;

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Style.Label>
      <Style.Input
        type="file"
        accept={`.xlsx, ${XLSX_TYPE}`}
        onChange={handleFileChange}
        ref={inputRef}
        {...inputDrugProps}
      />

      <Style.InputContainer
        type="button"
        onClick={handleInputClick}
        className={clsx({
          'border-sides': !isDragActive && !isFileUploading,
          'border-sides-input': isDragActive && !isFileUploading,
          'border border-input-blue': isFileUploading,
        })}
        {...getRootProps()}
      >
        <Style.FileContainer>
          {isFileUploading ? (
            <Style.LoadingBlock>
              <Spinner size={24} />

              {`${t('organisation.uploading')}...`}
            </Style.LoadingBlock>
          ) : (
            <>
              <Style.Text>
                {t('organisation.dragAndDrop')}

                <strong>{` xlsx`}</strong>

                {`. ${t('common.or')}`}
              </Style.Text>

              <Style.Text $isBlue>{t('organisation.clickToUpload')}</Style.Text>
            </>
          )}
        </Style.FileContainer>
      </Style.InputContainer>

      {!!templateLink && (
        <Button
          variant="big-grey-bordered"
          className="!h-11 !w-max !font-bold"
          component="link"
          to={templateLink}
          download
        >
          <p>{t('buttons.downloadTemplate')}</p>

          <DownloadIcon />
        </Button>
      )}
    </Style.Label>
  );
};
