import React, {
  FC
} from 'react';
import {
  Link
} from 'react-router-dom';

import {
  ROUTE
} from 'src/shared/utils';
import {
  OrganizationGroup
} from 'src/redux/openapi';

import {
  GroupDetails
} from '../GroupDetails';

import * as Style from './GroupOrgCard.styles';

interface GroupOrgCardProps {
  group: OrganizationGroup;
}

export const GroupOrgCard: FC<GroupOrgCardProps> = ({
  group
}) => {
  return (
    <Link to={`/${ROUTE.GROUPS}/${group.id}`}>
      <Style.GroupCard>
        <GroupDetails
          group={group}
          isCard
        />
      </Style.GroupCard>
    </Link>
  );
};
