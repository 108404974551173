import React, {
  FC
} from 'react';
import {
  t
} from 'i18next';

import * as Style from './ClearableSelectFooter.styles';

interface ClearableSelectFooterProps {
  onClear: () => void;
  onApply: () => void;
  isApplyDisabled?: boolean;
  clearButtonTitle?: string;
  applyButtonTitle?: string;
  withApplyButton?: boolean;
}

export const ClearableSelectFooter: FC<ClearableSelectFooterProps> = ({
  onClear,
  onApply,
  isApplyDisabled = false,
  clearButtonTitle = t('buttons.resetFilter'),
  applyButtonTitle = t('buttons.applyFilter'),
  withApplyButton,
}) => {
  return (
    <Style.ButtonsContainer>
      <Style.ResetButton
        type="button"
        onClick={onClear}
      >
        {clearButtonTitle}
      </Style.ResetButton>

      {withApplyButton && (
        <Style.ApplyButton
          type="button"
          onClick={onApply}
          disabled={isApplyDisabled}
        >
          {applyButtonTitle}
        </Style.ApplyButton>
      )}
    </Style.ButtonsContainer>
  );
};
