import React, {
  useEffect, useState
} from 'react';
import {
  Row
} from '@tanstack/react-table';
import {
  useInView
} from 'react-intersection-observer';

import {
  TableRow
} from 'src/shared/components';
import {
  usePatchOrgRequestsUnreadMutation
} from 'src/redux/openapi';

import {
  EntityModel
} from '../../types';

interface ManagementTableRowProps<T extends EntityModel> {
  onRowClick?: (id: string) => void;
  row: Row<T>;
  index: number;
}

export const ManagementTableRow = <T extends EntityModel>({
  row,
  onRowClick,
  index,
}: ManagementTableRowProps<T>) => {
  const [wasUpdated, setWasUpdated] = useState(false);

  const {
    id, isRead, status
  } = row.original;

  const canClick = status === 'PENDING';

  const [updateStatus] = usePatchOrgRequestsUnreadMutation();

  const handleStatusUpdate = async () => {
    await updateStatus({
      updateGroupOrganizatinoRequestReadStatusBodySchema: {
        groupRequestIds: [id],
      },
    });

    setWasUpdated(true);
  };

  const isNewRequest = typeof isRead === 'boolean' && !isRead;

  const {
    ref, inView
  } = useInView({
    skip: !isNewRequest,
  });

  useEffect(
    () => {
      if (inView && isNewRequest && !wasUpdated) {
        handleStatusUpdate();
      }
    },
    [inView, isNewRequest, wasUpdated]
  );

  const onClick = () => {
    if (onRowClick) {
      onRowClick(row.original.id);
    }
  };

  return (
    <TableRow
      row={row}
      key={row.original.id}
      index={index}
      onRowClick={onClick}
      canClick={canClick}
      ref={ref}
      rowKey="id"
    />
  );
};
