import React, {
  FC, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useParams
} from 'react-router-dom';
import i18next from 'i18next';

import {
  Button,
  ModalContainer,
  Select,
  SelectOption,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  useCustomInfiniteScroll,
  useGetInputValidation,
} from 'src/shared/hooks';
import {
  useGetOrgGroupsQuestionsActiveQuery,
  usePostOrgUsersMessagesMutation,
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  EmailNeededCheckMark
} from '../EmailNeededCheckMark';

import * as Style from './ModalSendMsgToMembers.style';

interface ModalSendMsgToMembersProps {
  closeModal: () => void;
  isOpen: boolean;
}

interface FormValues {
  message: string;
  isEmailNeeded: boolean;
}

const getDefaultOption = () => ({
  label: i18next.t('common.allGroups'),
  value: 'all',
});

export const ModalSendMsgToMembers: FC<ModalSendMsgToMembersProps> = ({
  closeModal,
  isOpen,
}) => {
  const defaultOption = getDefaultOption();

  const [groupsPage, setGroupsPage] = useState(0);

  const [selectedGroups, setSelectedGroups] = useState<SelectOption<string>[]>([
    defaultOption,
  ]);

  const {
    t
  } = useTranslation();

  const {
    sendMsgFromOrgAdminSchema
  } = useGetInputValidation();

  const {
    control,
    reset,
    formState: {
      errors
    },
    handleSubmit,
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      message: '',
      isEmailNeeded: false,
    },
    resolver: zodResolver(sendMsgFromOrgAdminSchema),
  });

  const handleCloseModal = () => {
    closeModal();
    setSelectedGroups([defaultOption]);
    reset();
  };

  const params = useParams();

  const {
    organizationId = ''
  } = params;

  const [sendMessages, {
    isLoading: isSendingMessages
  }] = usePostOrgUsersMessagesMutation();

  const onSubmit = async (data: FormValues) => {
    const filteredGroups = selectedGroups.filter(
      (group) => group.value !== defaultOption.value,
    );

    const groupIds = filteredGroups.length
      ? filteredGroups.map((group) => group.value)
      : null;

    try {
      const resp = await sendMessages({
        id: organizationId,
        sendOrganizationUsersMessageBodySchema: {
          groupIds,
          message: data.message,
          emailIncluded: data.isEmailNeeded,
        },
      }).unwrap();

      successfulToast(resp.message);
      reset();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const {
    data: groupsData,
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
    isError: isGroupsError,
  } = useGetOrgGroupsQuestionsActiveQuery(
    {
      id: organizationId,
      limit: 10,
      page: groupsPage,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !isOpen || !groupsPage,
    },
  );

  const {
    total: totalGroups, groups
  } = useMemo(
    () => groupsData?.data || {
      total: 0,
      groups: [],
    },
    [groupsData],
  );

  const {
    sentryRef,
    allItems: allGroups,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalGroups,
    currentItems: groups,
    isFetching: isGroupsFetching,
    isLoading: isGroupsLoading,
    isError: isGroupsError,
    currentPage: groupsPage,
    setCurrentPage: setGroupsPage,
    rootMargin: '0px 0px 10px 0px',
  });

  const preparedGroups = allGroups.map(({
    id, name
  }) => ({
    value: id,
    label: name,
  }));

  const onSelectChange = (
    newValue: SelectOption<string>[] | SelectOption<string>,
  ) => {
    if (Array.isArray(newValue)) {
      setSelectedGroups(newValue);
    }
  };

  const onSelectOpen = () => {
    if (!groupsPage) {
      setGroupsPage(1);
    }
  };

  return (
    <ModalContainer
      modalWidth={634}
      isDarkBackground
      closeModalHandler={handleCloseModal}
      isOpen={isOpen}
      modalTitle={t('organisation.notificationForMembers')}
    >
      <Style.MainContainer>
        <div>
          <Select
            options={[defaultOption, ...preparedGroups]}
            placeholder={
              selectedGroups.length > 1
                ? t(
                  'group.groupsAreSelected',
                  {
                    amount: selectedGroups.length,
                  }
                )
                : defaultOption.label
            }
            onChange={onSelectChange}
            isMulti
            selectedValue={selectedGroups}
            defaultValue={defaultOption}
            selectConfig={{
              sentryRef,
              displayLoader,
            }}
            onMenuOpen={onSelectOpen}
            closeMenuOnSelect={false}
          />

          <Style.Label htmlFor="OnboardingText">
            {t('organisation.writeYourNotification')}

            <Style.RedText>{' *'}</Style.RedText>
          </Style.Label>

          <Controller
            name="message"
            control={control}
            render={({
              field
            }) => {
              return (
                <Style.TextArea
                  id="OnboardingText"
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={t('organisation.writeYourNotification')}
                />
              );
            }}
          />

          <Style.TextHint>
            {errors.message?.message ? (
              <Style.RedText>{errors.message.message}</Style.RedText>
            ) : (
              t('organisation.everyMemberReceiveNotifications')
            )}
          </Style.TextHint>
        </div>

        <Style.ButtonContainer>
          <Controller
            name="isEmailNeeded"
            control={control}
            render={({
              field
            }) => {
              return (
                <EmailNeededCheckMark
                  isChecked={field.value}
                  changeHandler={() => field.onChange(!field.value)}
                />
              );
            }}
          />

          <Button
            variant="big-blue"
            className="text-xl font-bold text-white !w-max shrink-0"
            onClick={handleSubmit(onSubmit)}
            disabled={isSendingMessages}
          >
            {isSendingMessages ? (
              <Spinner
                size={20}
                color="white"
              />
            ) : (
              t('buttons.sendMessage')
            )}
          </Button>
        </Style.ButtonContainer>
      </Style.MainContainer>
    </ModalContainer>
  );
};
