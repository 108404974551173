import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentHeader = styled.div`
  ${tw`flex justify-between items-center mb-4`}
`;

export const Title = styled.p`
  ${tw`text-28-36 font-bold text-gray7`}
`;

export const HeaderRightSide = styled.div`
  ${tw`flex items-center gap-4`}
`;

export const ContentWrapper = styled.div`
  ${tw`flex flex-col h-full`}
`;
