import {
  t
} from 'i18next';

import {
  Tabs
} from './types';

export const getTabsMap = () => [
  {
    id: Tabs.AllOrganizations,
    label: t('organisation.allOrganizations'),
  },
  {
    id: Tabs.GroupsRequests,
    label: t('organisation.groupsRequests'),
  },
  {
    id: Tabs.UnaffiliatedGroups,
    label: t('group.unaffiliatedGroups'),
  },
  {
    id: Tabs.QuestionScoring,
    label: t('question.questionScoring'),
  },
  {
    id: Tabs.SetManagement,
    label: t('management.setManagement'),
  },
];
