import React from 'react';
import clsx from 'clsx';
import {
  Link
} from 'react-router-dom';

import {
  Spinner
} from '../Spinner';

import {
  Variant, Component, ExternalProps
} from './types';

export type ButtonProps<T extends Component = 'button'> = {
  variant?: Variant;
  component?: T;
} & ExternalProps<T>;

const variantClasses: Record<Variant, string> = {
  'big-blue':
    'w-full !bg-button-blue text-white gap-1 justify-center items-center rounded-100px h-11 md:h-52px px-4 md:px-6 py-2.5 md:py-3 disabled:bg-light-blue hover:bg-accent80',
  'big-blue-outlined':
    'w-full py-2.5 md:py-3 px-4 bg-white text-button-blue text-15-20 font-bold rounded-100px gap-1 items-center border border-button-blue shadow-menu-shadow',
  'big-black':
    'w-full bg-gray7 text-white gap-1 justify-center items-center rounded-100px h-11 md:h-52px px-4 md:px-6 py-2.5 md:py-3 disabled:bg-dark-gray hover:bg-dark-gray',
  'big-grey-bordered':
    'w-full bg-white text-dark-gray border border-gray90 gap-1 justify-center items-center rounded-100px h-11 md:h-52px px-4 py-2.5',
  'input-control-rounded':
    'w-10 h-10 bg-input-blue rounded-full justify-center items-center',
  'input-control-rounded-small':
    'w-6 h-6 bg-input-blue rounded-full justify-center items-center',
  'invite-grey':
    'border border-gray90 px-4 py-2.5 bg-white rounded-lg justify-center items-center',
  'invite-blue':
    'border border-button-blue px-4 py-2.5 bg-white rounded-lg justify-center items-center',
  'big-red':
    'w-full bg-red-text-error hover:bg-red-text-error hover:bg-opacity-80 text-white gap-1 justify-center items-center rounded-100px h-11 md:h-52px px-6 py-3 disabled:bg-opacity-80',
};

export const Button = <T extends Component>({
  children,
  variant,
  component,
  className,
  ...props
}: ButtonProps<T>) => {
  const elementClassName = clsx(
    'flex cursor-pointer disabled:cursor-default',
    variant && variantClasses[variant],
    className,
  );

  if (component === 'link') {
    return (
      <Link
        className={elementClassName}
        {...(props as ExternalProps<'link'>)}
      >
        {children}
      </Link>
    );
  }

  const {
    isLoading = false,
    spinnerSize = 24,
    spinnerColor = 'inherit',
    ...buttonProps
  } = props as ExternalProps<'button'>;

  return (
    <button
      className={elementClassName}
      {...buttonProps}
    >
      {children}

      {isLoading && (
        <Spinner
          size={spinnerSize}
          color={spinnerColor}
        />
      )}
    </button>
  );
};
