import {
  emptySplitApi as api
} from '../../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiCalibrations: build.mutation<
    PostApiCalibrationsApiResponse,
    PostApiCalibrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/calibrations/`,
        method: 'POST',
        body: queryArg.createCalibrationBodySchema,
      }),
    }),
    getApiCalibrations: build.query<
    GetApiCalibrationsApiResponse,
    GetApiCalibrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/calibrations/`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    postApiCalibrationsByIdStarted: build.mutation<
    PostApiCalibrationsByIdStartedApiResponse,
    PostApiCalibrationsByIdStartedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/calibrations/${queryArg.id}/started`,
        method: 'POST',
      }),
    }),
    postApiCalibrationsByIdFinished: build.mutation<
    PostApiCalibrationsByIdFinishedApiResponse,
    PostApiCalibrationsByIdFinishedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/calibrations/${queryArg.id}/finished`,
        method: 'POST',
      }),
    }),
    getApiCalibrationsByIdQuestionsCurrent: build.query<
    GetApiCalibrationsByIdQuestionsCurrentApiResponse,
    GetApiCalibrationsByIdQuestionsCurrentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/calibrations/${queryArg.id}/questions/current`,
      }),
    }),
    getApiCalibrationsById: build.query<
    GetApiCalibrationsByIdApiResponse,
    GetApiCalibrationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/calibrations/${queryArg.id}`,
      }),
    }),
    getApiCalibrationsByIdResults: build.query<
    GetApiCalibrationsByIdResultsApiResponse,
    GetApiCalibrationsByIdResultsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/calibrations/${queryArg.id}/results`,
      }),
    }),
    postApiCalibrationsByIdQuestionsAndQuestionIdAnswers: build.mutation<
    PostApiCalibrationsByIdQuestionsAndQuestionIdAnswersApiResponse,
    PostApiCalibrationsByIdQuestionsAndQuestionIdAnswersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/calibrations/${queryArg.id}/questions/${queryArg.questionId}/answers`,
        method: 'POST',
        body: queryArg.answerCalibrationQuestionBodySchema,
      }),
    }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiCalibrations
};
export type PostApiCalibrationsApiResponse =
  /** status 201 Default Response */ CreateCalibrationResponseSchema;
export type PostApiCalibrationsApiArg = {
  createCalibrationBodySchema: CreateCalibrationBodySchema;
};
export type GetApiCalibrationsApiResponse =
  /** status 200 Default Response */ FetchUserCalibrationsResponseSchema;
export type GetApiCalibrationsApiArg = {
  page?: number;
  limit?: number;
};
export type PostApiCalibrationsByIdStartedApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiCalibrationsByIdStartedApiArg = {
  id: string;
};
export type PostApiCalibrationsByIdFinishedApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiCalibrationsByIdFinishedApiArg = {
  id: string;
};
export type GetApiCalibrationsByIdQuestionsCurrentApiResponse =
  /** status 200 Default Response */ FetchCurrentCalibrationQuestionResponseSchema;
export type GetApiCalibrationsByIdQuestionsCurrentApiArg = {
  id: string;
};
export type GetApiCalibrationsByIdApiResponse =
  /** status 200 Default Response */ FetchCalibrationByIdResponseSchema;
export type GetApiCalibrationsByIdApiArg = {
  id: string;
};
export type GetApiCalibrationsByIdResultsApiResponse =
  /** status 200 Default Response */ FetchCalibrationResultsResponseSchema;
export type GetApiCalibrationsByIdResultsApiArg = {
  id: string;
};
export type PostApiCalibrationsByIdQuestionsAndQuestionIdAnswersApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiCalibrationsByIdQuestionsAndQuestionIdAnswersApiArg = {
  id: string;
  questionId: string;
  answerCalibrationQuestionBodySchema: AnswerCalibrationQuestionBodySchema;
};
export type CreateCalibrationResponseSchema = {
  message: string;
  data: {
    calibration: {
      id: string;
      totalQuestions: number;
      isTest: boolean;
    };
  };
};
export type CreateCalibrationBodySchema = {
  confidence: number;
  isTest?: boolean;
};
export type FetchUserCalibrationsResponseSchema = {
  message: string;
  data: {
    calibrations: {
      id: string;
      startedAt: string;
      finishedAt: string;
      createdAt: string;
      confidence: number;
      calibrated: null | boolean;
      isTest: boolean;
      status: 'ABANDONED' | 'CREATED' | 'STARTED' | 'COMPLETED';
      correctAnswers: null | number;
      totalQuestions: number;
    }[];
    total: number;
  };
};
export type MessageResponseSchema = {
  message: string;
};
export type FetchCurrentCalibrationQuestionResponseSchema = {
  message: string;
  data: {
    calibration: {
      id: string;
      totalQuestions: number;
      order: number;
      isTest: boolean;
    };
    currentQuestion: {
      id: string;
      title: string;
      rangeStart: number;
      rangeEnd: number;
      endAt: string;
      startAt: string;
    };
  };
};
export type FetchCalibrationByIdResponseSchema = {
  message: string;
  data: {
    calibration: {
      id: string;
      startedAt: null | string;
      finishedAt: null | string;
      createdAt: string;
      confidence: number;
      calibrated: null | boolean;
      status: string;
      isTest: boolean;
      correctAnswers: null | number;
      totalQuestions: null | number;
    };
    calibratedCount: number;
  };
};
export type FetchCalibrationResultsResponseSchema = {
  message: string;
  data: {
    results: {
      order: number;
      status: string;
      rangeEnd: number | null;
      rangeStart: number | null;
      createdAt: null | string;
      questionStartAt: null | string;
      questionEndAt: null | string;
      calibrationQuestion: {
        id: string;
        title: string;
        answer: number;
      };
    }[];
  };
};
export type AnswerCalibrationQuestionBodySchema = {
  rangeStart: number;
  rangeEnd: number;
};
export const {
  usePostApiCalibrationsMutation,
  useGetApiCalibrationsQuery,
  usePostApiCalibrationsByIdStartedMutation,
  usePostApiCalibrationsByIdFinishedMutation,
  useGetApiCalibrationsByIdQuestionsCurrentQuery,
  useGetApiCalibrationsByIdQuery,
  useGetApiCalibrationsByIdResultsQuery,
  usePostApiCalibrationsByIdQuestionsAndQuestionIdAnswersMutation,
} = injectedRtkApi;
