import React, {
  FC
} from 'react';
import {
  t
} from 'i18next';

import * as Style from './InputTextArea.styles';

type InputProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  errorMessage?: string;
  textLabel?: string;
  bottomMessage?: string;
};

export const InputTextArea: FC<InputProps> = ({
  className,
  rows = 1,
  value,
  onChange,
  placeholder = t('comment.commentForecastOptional'),
  errorMessage,
  textLabel,
  bottomMessage,
  ...props
}) => {
  return (
    <label>
      {textLabel && <Style.LabelText>{textLabel}</Style.LabelText>}

      <Style.Textarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={className}
        rows={rows}
        {...props}
      />

      {errorMessage && <Style.ErrorMessage>{errorMessage}</Style.ErrorMessage>}

      {bottomMessage && (
        <Style.BottomMessage>{bottomMessage}</Style.BottomMessage>
      )}
    </label>
  );
};
