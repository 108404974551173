import React, {
  useEffect, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useSearchParams
} from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import {
  Filters, useFiltersInfiniteScroll
} from 'src/features';
import {
  useGetCommunitySetsQuery,
  useGetGroupsCommunityQuery,
} from 'src/redux/openapi';
import {
  groupId, questionId
} from 'src/shared/utils';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  ScrollContainer, Spinner
} from 'src/shared/components';
import {
  communitySelectors,
  CommunityTabId,
  setCommunityTabEmptyMessage,
  setCommunityTabLoading,
} from 'src/redux/community';

import {
  TopicCard
} from '../TopicCard';
import {
  EmptyMessage
} from '../EmptyMessage';

import {
  getSortByValue
} from './utils';

export const ActiveSection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [groupsPage, setGroupsPage] = useState(1);
  const [questionSetsPage, setQuestionSetsPage] = useState(1);
  const activeGroupId = searchParams.get(groupId);

  const {
    t
  } = useTranslation();

  const dispatch = useTypedDispatch();

  const {
    data: groupsData,
    isLoading: isFiltersLoading,
    isFetching: isFiltersFetching,
    isError: isFiltersError,
  } = useGetGroupsCommunityQuery(
    {
      limit: 10,
      page: groupsPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalGroups, groups
  } = useMemo(
    () => {
      const data = groupsData?.data || {
        groups: [],
        total: 0,
      };

      const preparedGroups = data.groups.map(({
        id, name
      }) => ({
        id,
        label: name,
      }));

      return {
        total: data.total,
        groups: preparedGroups,
      };
    },
    [groupsData]
  );

  const {
    allItems: allGroups,
    onLoadMore: onLoadGroupsMore,
    hasNextPage: hasGroupsNextPage,
    loading: isGroupsNextLoading,
  } = useFiltersInfiniteScroll({
    total: totalGroups,
    currentItems: groups,
    isFetching: isFiltersLoading,
    isLoading: isFiltersFetching,
    isError: isFiltersError,
    currentPage: groupsPage,
    setCurrentPage: setGroupsPage,
  });

  const onFilterClick = (id: string | null) => {
    if (id) {
      searchParams.set(
        groupId,
        id
      );
    } else {
      searchParams.delete(groupId);
    }

    if (id !== activeGroupId) {
      searchParams.delete(questionId);
    }

    setSearchParams(searchParams);
  };

  const {
    sortBy
  } = useTypedSelector(
    communitySelectors.selectActiveQuestionsTab,
  );

  const sortByValue = getSortByValue(sortBy);

  const {
    data: questionSetsData,
    isLoading,
    isFetching,
    isError,
  } = useGetCommunitySetsQuery(
    {
      limit: questionSetsPage * 20,
      filters: {
        sortBy: sortByValue,
        groupId: activeGroupId || undefined,
      },
      page: 1,
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 20000,
    },
  );

  const {
    total: totalSets, questionSets
  } = useMemo(
    () => questionSetsData?.data || {
      total: 0,
      questionSets: [],
    },
    [questionSetsData],
  );

  const hasNextPage = totalSets > questionSets.length;

  const onLoadMore = () => {
    if (isLoading || isFetching || !questionSets.length) {
      return;
    }

    setQuestionSetsPage((prev) => prev + 1);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading || isFetching,
    hasNextPage,
    onLoadMore,
    disabled: !!isError,
    rootMargin: '0px 0px 100px 0px',
  });

  const displayLoader = hasNextPage || isLoading || isFetching;

  useEffect(
    () => {
      const tabName = CommunityTabId.Active;

      dispatch(
        setCommunityTabLoading({
          tabName,
          loading: isLoading,
        }),
      );

      if (!isLoading) {
        const emptyMessage = questionSets.length
          ? {
            title: t('community.selectQuestionToOpenThread'),
          }
          : {
            title: t('community.onboardQuestion'),
            subtitle: t('community.submitFirstForecast'),
          };

        dispatch(
          setCommunityTabEmptyMessage({
            tabName,
            emptyMessage,
          }),
        );
      }
    },
    [isLoading, totalSets]
  );

  useEffect(
    () => {
      setQuestionSetsPage(1);
    },
    [activeGroupId, sortByValue]
  );

  if (isLoading || isFiltersLoading) {
    return <Spinner size={24} />;
  }

  return (
    <>
      <div className="scroll-hidden overflow-x-scroll mx-4 relative shrink-0 pb-8">
        <Filters
          items={[
            {
              id: null,
              label: t('common.allGroups'),
            },
            ...allGroups,
          ]}
          onFilterClick={onFilterClick}
          activeFilterId={activeGroupId}
          canShrink
          onLoadMore={onLoadGroupsMore}
          hasNextPage={hasGroupsNextPage}
          loading={isGroupsNextLoading}
          isError={isFiltersError}
        />
      </div>

      {!questionSets.length ? (
        <EmptyMessage
          title={t('community.onboardQuestionFirst')}
          subtitle={t('community.hereYouWillFindAllDiscussions')}
        />
      ) : (
        <ScrollContainer edgeHeight={48}>
          {questionSets.map((set) => (
            <TopicCard
              topic={set}
              key={set.id}
              groupId={activeGroupId}
              sortBy={sortByValue}
              isStatisticsVisible={!!allGroups.length}
            />
          ))}

          {displayLoader && (
            <div ref={sentryRef}>
              <Spinner size={24} />
            </div>
          )}
        </ScrollContainer>
      )}
    </>
  );
};
