import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  clsx
} from 'clsx';

import {
  CheckBoxFalseIcon,
  CheckBoxTrueIcon,
  PreferredFlagIcon,
  PreferredFlagOutlinedIcon,
} from 'src/shared/icons';
import {
  Button, Tooltip
} from 'src/shared/components';
import {
  formatDateRange
} from 'src/shared/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';

import * as Style from '../EditQuestions.styles';
import {
  EditQuestionType
} from '../types';
import {
  checkDeactivated,
  getPreferredTooltipMessage,
  getTooltipMessage,
} from '../utils';

interface QuestionCardProps {
  question: EditQuestionType;
  isSelected: boolean;
  onQuestionCheck: (id: string) => void;
  index: number;
  isRemoving?: boolean;
  togglePreferred?: (id: string, isPreferred: boolean) => void;
  preferredOverrides?: { id: string; isPreferred: boolean }[];
}

export const QuestionCard: FC<QuestionCardProps> = ({
  question,
  isSelected,
  onQuestionCheck,
  index,
  isRemoving,
  togglePreferred,
  preferredOverrides,
}) => {
  const {
    id,
    title,
    isMandatory = false,
    status,
    startDate,
    endDate,
    isPreferred = false,
  } = question;

  const isDeactivated = !isRemoving && checkDeactivated(question);
  const isUnavailable = !isDeactivated && status === 'UNAVAILABLE';

  const {
    t
  } = useTranslation();

  const language = useTypedSelector(languageSelectors.getLanguage);

  const isDisabled = isDeactivated || (isMandatory && isSelected) || (isMandatory && isRemoving);

  const isActive = !isDeactivated && !(isMandatory && isRemoving);

  const tooltipMessage = isDisabled
    ? getTooltipMessage({
      isMandatory,
      isDeactivated,
    })
    : null;

  const preferredValue = preferredOverrides?.find((value) => value.id === id)?.isPreferred
    ?? isPreferred;

  const preferredTooltipMessage = getPreferredTooltipMessage({
    isMandatory,
    isPreferred: preferredValue,
    isSelected,
  });

  const handlePreferredClick = () => {
    if (!togglePreferred) {
      return;
    }

    togglePreferred(
      id,
      !preferredValue
    );
  };

  const handleQuestionCheck = () => {
    if (isSelected && preferredValue !== isPreferred) {
      handlePreferredClick();
    }

    onQuestionCheck(id);
  };

  return (
    <Style.TopicDataContainer $isActive={isActive}>
      <Style.QuestionNumberModal>{`${index + 1}.`}</Style.QuestionNumberModal>

      <Style.QuestionContentContainer>
        <button
          type="button"
          onClick={handleQuestionCheck}
          disabled={isDisabled}
          className="flex"
        >
          <Tooltip
            variant="darkGray"
            opacity={1}
            disabled={!tooltipMessage}
            message={
              <Style.TooltipMessage>{tooltipMessage}</Style.TooltipMessage>
            }
          >
            {isSelected ? (
              <CheckBoxTrueIcon
                className={clsx(
                  'w-6 h-6 text-gray7',
                  {
                    'text-grey-dark-40': isDisabled,
                  }
                )}
              />
            ) : (
              <CheckBoxFalseIcon
                className={clsx(
                  'w-6 h-6 text-gray7',
                  {
                    'text-grey-dark-40': isDisabled,
                  }
                )}
              />
            )}
          </Tooltip>
        </button>

        <Style.QuestionInfoContainer>
          <Style.QuestionInfoTitleWrapper>
            <p className="grow">{title}</p>

            {togglePreferred && (
              <Tooltip
                message={preferredTooltipMessage}
                maxWidth={
                  !preferredValue && isSelected && !isMandatory ? 126 : 200
                }
                customShift={4}
                messageContainerProps={{
                  className: 'text-center',
                }}
              >
                <Button
                  disabled={isMandatory || !isSelected}
                  className="h-max-content"
                  onClick={handlePreferredClick}
                >
                  {!preferredValue && !isMandatory ? (
                    <PreferredFlagOutlinedIcon className="text-grey-dark-40" />
                  ) : (
                    <PreferredFlagIcon
                      className={clsx({
                        'text-button-blue': preferredValue && !isMandatory,
                        'text-grey-dark-40': isMandatory || !isSelected,
                      })}
                    />
                  )}
                </Button>
              </Tooltip>
            )}
          </Style.QuestionInfoTitleWrapper>

          <Style.QuestionExternalInfo>
            <Style.CardQuestionDates>
              {formatDateRange({
                start: startDate,
                end: endDate,
                language,
              })}
            </Style.CardQuestionDates>

            {isUnavailable && (
              <Style.UpcomingContainer $small>
                {t('question.upcoming')}
              </Style.UpcomingContainer>
            )}
          </Style.QuestionExternalInfo>
        </Style.QuestionInfoContainer>
      </Style.QuestionContentContainer>
    </Style.TopicDataContainer>
  );
};
