import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentWrapper = styled.div`
  ${tw`flex items-center gap-2 p-4 justify-end`}
`;

export const ListContainer = styled.div`
  ${tw`p-4 max-h-410px overflow-auto`}
`;

export const IconPlaceholder = styled.div`
  ${tw`h-6 w-6`}
`;
