import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import {
  useNavigate, useParams
} from 'react-router-dom';
import {
  Trans, useTranslation
} from 'react-i18next';

import {
  PlusRoundedIcon
} from 'src/shared/icons';
import {
  GroupToOrganizationContext,
  ModalLeave,
  ModalSuccess,
  Spinner,
  SwitchButton,
  listViewSwitchOptions,
} from 'src/shared/components';
import {
  ModalGroupToOrganization
} from 'src/entities/GroupsRoute/ModalGroupToOrganization';
import {
  ROUTE
} from 'src/shared/utils';
import {
  Filters
} from 'src/features';
import {
  useGetApiGroupsQuery, UserGroupRole
} from 'src/redux/openapi';
import {
  getGroupUserRole
} from 'src/entities/GroupsRoute/utils';
import {
  DeleteGroupModal
} from 'src/features/Modals';
import {
  EditGroupModal
} from 'src/features/Modals/ui/EditGroupModal';
import {
  GroupCards, GroupTable
} from 'src/entities';
import {
  useListView
} from 'src/shared/hooks';

import {
  GroupCurrent
} from '../GroupCurrent';

import * as Style from './GroupsOverview.styles';

export enum ActionsWithGroup {
  Request = 'Request',
  Leave = 'Leave',
  Delete = 'Delete',
  None = '',
  Edit = 'Edit',
}

export const GroupsOverview: FC = () => {
  const [isModalRequestSuccessOpen, setIsModalRequestSuccessOpen] = useState(false);

  const {
    t
  } = useTranslation();

  const [groupIdToModify, setGroupIdToModify] = useState('');
  const [actionWithGroup, setActionWithGroup] = useState(ActionsWithGroup.None);

  const {
    data: groupsData,
    isLoading,
    refetch,
  } = useGetApiGroupsQuery({
    limit: 5000,
    // TODO: add pagination after adding new endpoint for filters to BE
  });

  const params = useParams();
  const navigate = useNavigate();

  useEffect(
    () => {
      refetch();
    },
    []
  );

  const activeFilterId = params.groupId || null;

  const setActiveFilterId = (id: string | null) => {
    if (id) {
      navigate(`/${ROUTE.GROUPS}/${id}`);
    } else {
      navigate(`/${ROUTE.GROUPS}`);
    }
  };

  const groups = useMemo(
    () => groupsData?.data.groups || [],
    [groupsData]
  );

  const currentGroup = groups.find((group) => group.id === activeFilterId) || null;

  const filters = useMemo(
    () => {
      const preparedFilters = groups.map(
        ({
          id,
          name,
          groupRole,
          userRole,
          organizationRole,
          organizationStatus,
        }) => {
          const isGroupInOrganization = organizationStatus === 'ACCEPTED';

          const canChangeSettings = (isGroupInOrganization ? false : !!groupRole)
            || !!organizationRole
            || userRole === UserGroupRole.ADMIN;

          return {
            id,
            label: name,
            role: canChangeSettings
              ? getGroupUserRole({
                groupRole,
                userRole,
                organizationRole,
              })
              : null,
            organizationStatus,
            groupRole,
          };
        },
      ) || [];

      return [
        {
          label: t('common.allGroups'),
          id: null,
        },
        ...preparedFilters,
      ];
    },
    [groups, t]
  );

  const handleCloseModal = () => {
    setActionWithGroup(ActionsWithGroup.None);
    setGroupIdToModify('');

    if (isModalRequestSuccessOpen) {
      setIsModalRequestSuccessOpen(false);
    }
  };

  const handleRequestSuccessOpen = () => {
    setIsModalRequestSuccessOpen(true);
    setActionWithGroup(ActionsWithGroup.None);
    setGroupIdToModify('');
  };

  const groupToModify = groups.find((group) => group.id === groupIdToModify);

  const handleOpenModalConfirm = (id: string, action: ActionsWithGroup) => {
    setGroupIdToModify(id);
    setActionWithGroup(action);
  };

  const {
    isTableView, onSwitch
  } = useListView();

  if (isLoading) {
    return <Spinner />;
  }

  if (!groups.length) {
    return (
      <Style.EmptyMessageContainer>
        <Style.EmptyMessageTitle>
          {t('empty.notMemberOfGroupAtThisTime')}
        </Style.EmptyMessageTitle>

        <Style.EmptyMessageSubtitle>
          <Trans i18nKey="empty.youCanCreateNewGroup" />
        </Style.EmptyMessageSubtitle>

        <Style.CreateGroupTitle to={`/${ROUTE.GROUPS}/${ROUTE.CREATE_GROUP}`}>
          {t('group.personalGroup')}
        </Style.CreateGroupTitle>
      </Style.EmptyMessageContainer>
    );
  }

  return (
    <>
      <div>
        <Style.HeaderContainer>
          <Style.FiltersWrapper>
            <Filters
              items={filters}
              canShrink
              onFilterClick={setActiveFilterId}
              activeFilterId={activeFilterId}
              renderMenuContent={(item) => !!item.id
                && (!!item.role || !!item.groupRole) && (
                  <GroupToOrganizationContext
                    openModalConfirm={(action: ActionsWithGroup) => {
                      handleOpenModalConfirm(
                        item.id,
                        action
                      );
                    }}
                    role={item.role as UserGroupRole}
                    organizationStatus={item.organizationStatus}
                    groupRole={item.groupRole as UserGroupRole}
                  />
              )}
            />
          </Style.FiltersWrapper>

          {!currentGroup && (
            <SwitchButton
              options={listViewSwitchOptions}
              onChange={onSwitch}
              defaultOption={
                isTableView
                  ? listViewSwitchOptions[0]
                  : listViewSwitchOptions[1]
              }
            />
          )}

          <Style.CreateGroupTitle to={`/${ROUTE.GROUPS}/${ROUTE.CREATE_GROUP}`}>
            <PlusRoundedIcon className="w-6 h-6 text-button-blue" />

            {t('group.personalGroup')}
          </Style.CreateGroupTitle>
        </Style.HeaderContainer>

        {!currentGroup ? (
          <>
            {isTableView && <GroupTable groups={groups} />}

            {!isTableView && <GroupCards groups={groups} />}
          </>
        ) : (
          <GroupCurrent group={currentGroup} />
        )}
      </div>

      <ModalGroupToOrganization
        handleCloseModal={handleCloseModal}
        isOpen={actionWithGroup === ActionsWithGroup.Request}
        groupToModify={groupToModify}
        handleRequestSuccess={handleRequestSuccessOpen}
      />

      <ModalSuccess
        isOpen={isModalRequestSuccessOpen}
        closeModal={handleCloseModal}
      />

      <ModalLeave
        isOpen={actionWithGroup === ActionsWithGroup.Leave}
        closeModal={handleCloseModal}
        group={groupToModify}
        onLeave={refetch}
      />

      <DeleteGroupModal
        isOpen={actionWithGroup === ActionsWithGroup.Delete}
        closeModal={handleCloseModal}
        id={groupToModify?.id}
        onSuccessDelete={refetch}
      />

      <EditGroupModal
        isOpen={actionWithGroup === ActionsWithGroup.Edit}
        closeModal={handleCloseModal}
        group={groupToModify}
        refetchGroup={refetch}
      />
    </>
  );
};
