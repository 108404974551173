import React, {
  FC
} from 'react';

import {
  MessageIcon, PeopleIcon, TrendingUpIcon
} from 'src/shared/icons';
import {
  numberWithPeriods
} from 'src/shared/utils/functions';
import {
  Group
} from 'src/redux/openapi';
import {
  ActivityTile
} from 'src/entities/Questions';

import {
  GroupInfo
} from '../GroupInfo';
import {
  GroupAction
} from '../GroupAction';
import {
  GroupSets
} from '../GroupSets';

import * as Style from './GroupCards.styles';

interface GroupCardsProps {
  groups: Group[];
}

export const GroupCards: FC<GroupCardsProps> = ({
  groups
}) => {
  return (
    <Style.CardWrapper>
      {groups.map((group) => {
        const {
          memberCount: numberOfGroupMembers,
          commentCount: groupCommentsPerDay,
          forecastCount: activeGroupMembersPerDay,
          id,
          questionSets,
          isPublic,
        } = group;

        return (
          <Style.GroupContainer key={group.id}>
            <Style.GroupHeaderContainer>
              <GroupInfo group={group} />

              <GroupAction
                isPublic={!!isPublic}
                id={id}
              />
            </Style.GroupHeaderContainer>

            <Style.CardStatisticsRow>
              <Style.CardStatistics>
                <ActivityTile
                  icon={<PeopleIcon />}
                  amount={numberWithPeriods(numberOfGroupMembers)}
                />
              </Style.CardStatistics>

              <Style.CardStatisticsRowRight>
                <Style.CardStatistics>
                  <ActivityTile
                    icon={<MessageIcon className="text-dim-gray" />}
                    amount={numberWithPeriods(groupCommentsPerDay)}
                    timeRange="upToday"
                  />
                </Style.CardStatistics>

                <Style.CardStatistics>
                  <ActivityTile
                    icon={<TrendingUpIcon className="text-dim-gray" />}
                    amount={`${activeGroupMembersPerDay}`}
                    timeRange="upToday"
                  />
                </Style.CardStatistics>
              </Style.CardStatisticsRowRight>
            </Style.CardStatisticsRow>

            <GroupSets questionSets={questionSets} />
          </Style.GroupContainer>
        );
      })}
    </Style.CardWrapper>
  );
};
