import React, {
  FC
} from 'react';

import {
  SearchInput
} from 'src/shared/components/SearchInput';

import * as Style from './TableSearch.styles';

interface TableSearchProps {
  searchQuery: string;
  placeholder: string;
  handleSearch: (value: string) => void;
}

export const TableSearch: FC<TableSearchProps> = ({
  searchQuery,
  placeholder,
  handleSearch,
}) => {
  return (
    <Style.SearchWrapper>
      <SearchInput
        value={searchQuery}
        onChange={handleSearch}
        placeholder={placeholder}
        size={32}
        withBorder={false}
        shouldGrow
      />
    </Style.SearchWrapper>
  );
};
