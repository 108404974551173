import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  SetForCreation
} from 'src/redux/openapi';
import {
  ScrollContainer
} from 'src/shared/components';

import {
  QuestionSetItem
} from '../QuestionSetItem';
import {
  EmptyMessage
} from '../AddQuestionSets.styles';

interface QuestionSetListProps {
  questionSets: SetForCreation[];
}

export const QuestionSetList: FC<QuestionSetListProps> = ({
  questionSets
}) => {
  const {
    t
  } = useTranslation();

  if (!questionSets.length) {
    return <EmptyMessage>{t('question.downloadTemplate')}</EmptyMessage>;
  }

  return (
    <ScrollContainer
      className="px-3 mt-6 grow"
      edgeHeight={0}
      visibleScroll={false}
    >
      {questionSets.map((set) => (
        <QuestionSetItem
          key={set.title}
          set={set}
        />
      ))}
    </ScrollContainer>
  );
};
