import {
  useState
} from 'react';

export const useSelectionSet = <T>() => {
  const [selectedItems, setSelectedItems] = useState(new Set<T>());

  const toggleSelection = (item: T) => {
    setSelectedItems((prev) => {
      const updated = new Set(prev);

      if (updated.has(item)) {
        updated.delete(item);
      } else {
        updated.add(item);
      }

      return updated;
    });
  };

  const addMultiple = (items: T[]) => {
    setSelectedItems((prev) => {
      const updated = new Set(prev);
      items.forEach((item) => updated.add(item));

      return updated;
    });
  };

  const removeMultiple = (items: T[]) => {
    setSelectedItems((prev) => {
      const updated = new Set(prev);
      items.forEach((item) => updated.delete(item));

      return updated;
    });
  };

  const clearSelection = () => setSelectedItems(new Set());

  return {
    selectedItems,
    toggleSelection,
    addMultiple,
    removeMultiple,
    clearSelection,
  };
};

export type UseSelectionSet<T> = ReturnType<typeof useSelectionSet<T>>;
