import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import {
  useLocation
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetGroupSetsAndQuestionsQuery, SortOrder
} from 'src/redux/openapi';
import {
  EmptyResultsMessage,
  ScrollContainer,
  Spinner,
} from 'src/shared/components';
import {
  QuestionSortBy
} from 'src/shared/api/types';
import {
  useCustomInfiniteScroll
} from 'src/shared/hooks';
import {
  checkDeactivatedQuestionStatus
} from 'src/features';
import {
  formatNumber, ROUTE
} from 'src/shared/utils';
import {
  ActivityTile, StatisticTile
} from 'src/entities/Questions';
import {
  GlobeIcon,
  MessageIcon,
  PeopleIcon,
  PersonIcon,
  TrendingUpIcon,
} from 'src/shared/icons';

import {
  ContextMenu
} from '../ContextMenu';
import {
  ModalEditOnboardingText
} from '../ModalEditOnboardingText';

import * as Style from './QuestionCards.styles';

interface QuestionCardsProps {
  groupId: string;
  questionSetId: string;
  filters: {
    sortBy: QuestionSortBy;
    sortOrder?: SortOrder;
  };
  searchQuestion: string;
  canAddOnboardingText?: boolean;
  isAdmin: boolean;
}

export const QuestionCards: FC<QuestionCardsProps> = ({
  groupId,
  questionSetId,
  filters,
  searchQuestion,
  canAddOnboardingText,
  isAdmin,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [questionIdTextOnboard, setQuestionIdTextOnboard] = useState('');

  const {
    pathname
  } = useLocation();

  const isOrganizationPage = pathname.includes(`/${ROUTE.ORGANISATION}/`);

  const handleCloseEditOnboardingTextModal = () => {
    setQuestionIdTextOnboard('');
  };

  useEffect(
    () => {
      setCurrentPage(1);
    },
    [searchQuestion, filters]
  );

  const {
    data: questionsDataGroup,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetGroupSetsAndQuestionsQuery(
    {
      id: groupId,
      questionSetId,
      filters: {
        ...filters,
        query: searchQuestion,
      },
      limit: 10,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalQuestions, questions
  } = useMemo(
    () => questionsDataGroup?.data || {
      total: 0,
      questions: [],
    },
    [questionsDataGroup],
  );

  const {
    sentryRef,
    allItems: allQuestions,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalQuestions,
    currentItems: questions,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  const {
    t
  } = useTranslation();

  const onAddedOnboardingText = () => {
    refetch();
    setCurrentPage(1);
  };

  if (isLoading) {
    return <Spinner size={24} />;
  }

  return (
    <ScrollContainer
      className="max-h-500px"
      visibleScroll
    >
      {allQuestions.length ? (
        allQuestions.map(
          ({
            id: questionId,
            title: questionTitle,
            globalEstimatesAvg,
            userEstimatesAvg,
            globalEstimatesChange,
            groupEstimatesAvg,
            groupEstimatesChange,
            participantsCount,
            commentsCount,
            isActive,
            type,
            onboardingText,
            updatesCount,
            ...question
          }) => {
            const isDeactivated = checkDeactivatedQuestionStatus(question);

            const handleSetQuestionIdTextOnboard = () => {
              setQuestionIdTextOnboard(questionId);
            };

            const onboardQuestionType = type === 'SINGLE' || type === 'MULTIPLE';

            const hasContextMenu = onboardQuestionType
              ? canAddOnboardingText
                || (isAdmin && !isDeactivated && isOrganizationPage)
              : false;

            return (
              <Style.QuestionContainer key={questionId}>
                <Style.QuestionTitleRow>
                  <Style.QuestionTitle>{questionTitle}</Style.QuestionTitle>

                  <Style.MenuWrapper>
                    {isDeactivated && (
                      <Style.DeactivatedTile>
                        {t('question.deactivated')}
                      </Style.DeactivatedTile>
                    )}

                    {hasContextMenu && (
                      <ContextMenu
                        setActiveQuestion={handleSetQuestionIdTextOnboard}
                      />
                    )}
                  </Style.MenuWrapper>
                </Style.QuestionTitleRow>

                {!!onboardingText && (
                  <Style.QuestionDetails>
                    {onboardingText}
                  </Style.QuestionDetails>
                )}

                {(!!userEstimatesAvg || isDeactivated) && (
                  <Style.QuestionInfo>
                    {userEstimatesAvg && (
                      <>
                        <Style.QuestionInfoWrapper $gap={12}>
                          <StatisticTile
                            amount={userEstimatesAvg}
                            icon={<PersonIcon className="w-4 h-4" />}
                            applyMinWidth={false}
                            height="auto"
                          />

                          <StatisticTile
                            amount={globalEstimatesAvg}
                            icon={<GlobeIcon className="w-4 h-4" />}
                            growth={globalEstimatesChange}
                            applyMinWidth={false}
                            height="auto"
                          />

                          <StatisticTile
                            amount={groupEstimatesAvg}
                            icon={<PeopleIcon className="w-4 h-4" />}
                            growth={groupEstimatesChange}
                            applyMinWidth={false}
                            height="auto"
                          />
                        </Style.QuestionInfoWrapper>

                        <Style.QuestionInfoWrapper $gap={8}>
                          <ActivityTile
                            icon={<PeopleIcon className="w-4 h-4" />}
                            amount={`${formatNumber(participantsCount)}`}
                            className="p-1"
                          />

                          <ActivityTile
                            icon={
                              <MessageIcon className="w-4 h-4 text-dim-gray" />
                            }
                            amount={formatNumber(commentsCount)}
                            className="p-1"
                          />

                          <ActivityTile
                            icon={
                              <TrendingUpIcon className="w-4 h-4 text-dim-gray" />
                            }
                            amount={formatNumber(updatesCount)}
                            className="p-1"
                          />
                        </Style.QuestionInfoWrapper>
                      </>
                    )}
                  </Style.QuestionInfo>
                )}
              </Style.QuestionContainer>
            );
          },
        )
      ) : (
        <EmptyResultsMessage
          results={t('common.questions').toLowerCase()}
          withSearchQuery={false}
        />
      )}

      {displayLoader && (
        <div ref={sentryRef}>
          <Spinner size={24} />
        </div>
      )}

      {!!questionIdTextOnboard && (
        <ModalEditOnboardingText
          isOpen={!!questionIdTextOnboard}
          closeModal={handleCloseEditOnboardingTextModal}
          questionId={questionIdTextOnboard}
          onAddedOnboardingText={onAddedOnboardingText}
        />
      )}
    </ScrollContainer>
  );
};
