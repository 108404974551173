import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Title = styled.h1`
  ${tw`font-bold text-28-36 text-gray7`}
`;

export const ContentWrapper = styled.h1`
  ${tw`flex flex-col gap-4 h-full overflow-auto`}
`;
