import {
  emptySplitApi as api
} from '../../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiQuestionSets: build.query<
    GetApiQuestionSetsApiResponse,
    GetApiQuestionSetsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/question-sets/`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    postApiQuestionSets: build.mutation<
    PostApiQuestionSetsApiResponse,
    PostApiQuestionSetsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/question-sets/`,
        method: 'POST',
        body: queryArg.createQuestionSetBodySchema,
      }),
    }),
    getApiQuestionSetsAvailable: build.query<
    GetApiQuestionSetsAvailableApiResponse,
    GetApiQuestionSetsAvailableApiArg
    >({
      query: (queryArg) => ({
        url: `/api/question-sets/available`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
      providesTags: ['Favorites'],
    }),
    getApiQuestionSetsCommunity: build.query<
    GetApiQuestionSetsCommunityApiResponse,
    GetApiQuestionSetsCommunityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/question-sets/community`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiQuestionSetsEditing: build.query<
    GetApiQuestionSetsEditingApiResponse,
    GetApiQuestionSetsEditingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/question-sets/editing`,
        params: {
          filters: queryArg.filters,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiQuestionSetsStatistics: build.query<
    GetApiQuestionSetsStatisticsApiResponse,
    GetApiQuestionSetsStatisticsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/question-sets/statistics`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiQuestionSetsById: build.query<
    GetApiQuestionSetsByIdApiResponse,
    GetApiQuestionSetsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/question-sets/${queryArg.id}`,
      }),
    }),
    patchApiQuestionSetsById: build.mutation<
    PatchApiQuestionSetsByIdApiResponse,
    PatchApiQuestionSetsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/question-sets/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateQuestionSetBodySchema,
      }),
    }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiQuestionSets
};
export type GetApiQuestionSetsApiResponse =
  /** status 200 Default Response */ QuestionSetsResponseSchema;
export type GetApiQuestionSetsApiArg = {
  filters?: {
    title?: string;
    userAvailableOnly?: boolean;
    not?: {
      status?: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
    };
    sortBy?: 'createdAt';
    sortOrder?: 'asc' | 'desc';
  };
  page?: number;
  limit?: number;
};
export type PostApiQuestionSetsApiResponse =
  /** status 201 Default Response */ QuestionSetResponseSchema;
export type PostApiQuestionSetsApiArg = {
  createQuestionSetBodySchema: CreateQuestionSetBodySchema;
};
export type GetApiQuestionSetsAvailableApiResponse =
  /** status 200 Default Response */ FindAllAvailableQuestionSetsResponseSchema;
export type GetApiQuestionSetsAvailableApiArg = {
  page?: Page;
  limit?: Limit;
};
export type GetApiQuestionSetsCommunityApiResponse =
  /** status 200 Default Response */ CommunityQuestionSetsResponseSchema;
export type GetApiQuestionSetsCommunityApiArg = {
  filters?: {
    groupId?: string;
    sortBy?: 'commentsCreatedAt' | 'commentsCount' | 'participantsCount';
    sortOrder?: SortOrder;
  };
  page?: Page;
  limit?: Limit;
};
export type GetApiQuestionSetsEditingApiResponse =
  /** status 200 Default Response */ ExistingQuestionSetsResponseSchema;
export type GetApiQuestionSetsEditingApiArg = {
  filters?: {
    notStatus?: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
  };
  sortBy?: 'createdAt' | 'startDate';
  sortOrder?: SortOrder;
  page?: Page;
  limit?: Limit;
};
export type GetApiQuestionSetsStatisticsApiResponse =
  /** status 200 Default Response */ QuestionSetStatisticsResponseSchema;
export type GetApiQuestionSetsStatisticsApiArg = {
  filters?: {
    active?: boolean;
    status?: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
    sortBy?: 'commentsCreatedAt' | 'commentsCount' | 'participantsCount';
    sortOrder?: SortOrder;
  };
  page?: Page;
  limit?: Limit;
};
export type GetApiQuestionSetsByIdApiResponse =
  /** status 200 Default Response */ QuestionSetResponseSchema;
export type GetApiQuestionSetsByIdApiArg = {
  id: string;
};
export type PatchApiQuestionSetsByIdApiResponse =
  /** status 200 Default Response */ QuestionSetResponseSchema;
export type PatchApiQuestionSetsByIdApiArg = {
  id: string;
  updateQuestionSetBodySchema: UpdateQuestionSetBodySchema;
};
export type QuestionSet = {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  startDate: string | null;
  endDate: string | null;
  questions: {
    id: string;
    createdAt: string;
    isMandatory: boolean;
    updatedAt: string;
    title: string;
    startDate: string;
    endDate: string;
    type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
    estimatesTitles: string[] | null;
    status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
    questionSet: {
      id: string;
      title: string;
    } | null;
  }[];
};
export type QuestionSetsResponseSchema = {
  data: {
    questionSets: QuestionSet[];
    total: number;
  };
  message: string;
};
export type QuestionSetResponseSchema = {
  data: {
    questionSet: {
      id: string;
      createdAt: string;
      updatedAt: string;
      title: string;
      startDate: string | null;
      endDate: string | null;
      questions: {
        id: string;
        createdAt: string;
        isMandatory: boolean;
        updatedAt: string;
        title: string;
        startDate: string;
        endDate: string;
        type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
        estimatesTitles: string[] | null;
        status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
        questionSet: {
          id: string;
          title: string;
        } | null;
      }[];
    };
  };
  message: string;
};
export type CreateQuestionSetBodySchema = {
  title: string;
};
export type FindAllAvailableQuestionSetsResponseSchema = {
  message: string;
  data: {
    hasFavorite: boolean;
    questionSets: {
      title: string;
      createdAt: string;
      updatedAt: string;
      id: string;
    }[];
    total: number;
  };
};
export type Page = number;
export type Limit = number;
export type CommunityQuestionSetsResponseSchema = {
  data: {
    questionSets: {
      id: string;
      title: string;
      createdAt: string;
      updatedAt: string;
      startDate: string | null;
      endDate: string | null;
      questionsCount: number;
      participantsCount: number;
      commentsCount: number;
      updatesCount: number;
    }[];
    total: number;
  };
  message: string;
};
export type SortOrder = 'asc' | 'desc';
export type ExistingQuestionSetsResponseSchema = {
  message: string;
  data: {
    questionSets: {
      id: string;
      title: string;
      createdAt: string;
      updatedAt: string;
      startDate: string;
      endDate: string;
      questions: {
        id: string;
        createdAt: string;
        isMandatory: boolean;
        updatedAt: string;
        title: string;
        startDate: string;
        endDate: string;
        type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
        estimatesTitles: string[] | null;
        status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      }[];
    }[];
    total: number;
  };
};
export type QuestionSetStatisticsResponseSchema = {
  data: {
    statistics: {
      id: string;
      title: string;
      createdAt: string;
      updatedAt: string;
      groupStatus: ('ACTIVATED' | 'DEACTIVATED') | null;
      startDate: string | null;
      endDate: string | null;
      questionsCount: number;
      participantsCount: number;
      updatesCount: number;
      commentsCount: number;
    }[];
    total: number;
  };
  message: string;
};
export type Title = string;
export type UpdateQuestionSetBodySchema = {
  title?: Title;
};
export const {
  useGetApiQuestionSetsQuery,
  usePostApiQuestionSetsMutation,
  useGetApiQuestionSetsAvailableQuery,
  useGetApiQuestionSetsCommunityQuery,
  useGetApiQuestionSetsEditingQuery,
  useGetApiQuestionSetsStatisticsQuery,
  useGetApiQuestionSetsByIdQuery,
  usePatchApiQuestionSetsByIdMutation,
} = injectedRtkApi;
