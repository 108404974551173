import {
  CommunityReducer
} from './communitySlice';

const selectActiveQuestionsTab = (state: CommunityReducer) => {
  return state.community.active;
};

const selectScoredQuestionsTab = (state: CommunityReducer) => {
  return state.community.scored;
};

const selectFollowingsTab = (state: CommunityReducer) => {
  return state.community.followings;
};

const selectAllTabs = (state: CommunityReducer) => {
  return state.community;
};

export const communitySelectors = {
  selectActiveQuestionsTab,
  selectScoredQuestionsTab,
  selectFollowingsTab,
  selectAllTabs,
};
