import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Tabs = styled.div`
  ${tw`flex gap-4 overflow-x-auto`}
`;

export const Tab = styled.button<{ $isActive?: boolean }>`
  ${tw`
    flex gap-1 items-center
    pt-4 pb-3 px-2 relative cursor-pointer
    font-medium text-15-20 text-dark-gray text-center
    hover:text-gray7 transition duration-150 shrink-0
  `}

  ${({
    $isActive
  }) => $isActive
    && tw`
    text-button-blue
    after:(content-[""] w-full h-1 bg-button-blue absolute bottom-0 right-0 rounded-t-20px)
    hover:text-button-blue
  `}
`;

export const TabInfo = styled.div`
  ${tw`
    flex items-center justify-center bg-alice-blue 
    px-1 min-w-28px text-15-20 font-medium rounded-100px
  `}
`;
