import React from 'react';
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';

import {
  AdminOrganizationRequests,
  AdminOrgRequestsStatus,
} from 'src/redux/openapi';
import {
  defaultHexColor,
  formatDateRange,
  themeColors,
} from 'src/shared/utils';

import * as Style from '../OrgManagementTable.styles';
import {
  EditMenu
} from '../EditMenu';
import {
  ActionHandler, GroupRequestsActions
} from '../../types';

const requestsColumnHelper = createColumnHelper<AdminOrganizationRequests>();

const getStatusColor = (status: AdminOrgRequestsStatus) => {
  switch (status) {
    case 'PENDING':
      return defaultHexColor;
    case 'REJECTED':
      return themeColors['red-text-error'];
    case 'ACCEPTED':
    default:
      return themeColors['forest-green'];
  }
};

export const getGroupRequestsCols = (
  onAction: ActionHandler<GroupRequestsActions>,
  language: string,
) => {
  const columns = [
    requestsColumnHelper.accessor(
      'group.name',
      {
        header: t('modal.groupName'),
        footer: (props) => props.column.id,
        size: 325,
        minSize: 128,
        cell: (info) => {
          return (
            <Style.NameCellSpan $isUnread={!info.row.original.isRead}>
              {info.getValue()}
            </Style.NameCellSpan>
          );
        },
      }
    ),
    requestsColumnHelper.accessor(
      'group.users',
      {
        id: 'admin',
        header: t('common.admin'),
        footer: (props) => props.column.id,
        size: 325,
        minSize: 128,
        cell: (info) => {
          const adminsInfo = info.getValue();

          if (!adminsInfo) {
            return '';
          }

          const superAdmin = adminsInfo?.find(
            ({
              role
            }) => role === 'SUPERADMIN',
          );

          return superAdmin?.user.email || '';
        },
      }
    ),
    requestsColumnHelper.accessor(
      'group.users',
      {
        id: 'subAdmins',
        header: t('common.subAdmins'),
        footer: (props) => props.column.id,
        size: 325,
        minSize: 128,
        cell: (info) => {
          const adminsInfo = info.getValue();

          if (!adminsInfo) {
            return '';
          }

          const admins = adminsInfo
            ?.filter(({
              role
            }) => role === 'ADMIN')
            .map((admin) => admin.user.email)
            .join(', ');

          return admins;
        },
      }
    ),
    requestsColumnHelper.accessor(
      'group._count.users',
      {
        header: () => (
          <Style.RightAlignedCellText>
            {t('common.usersAmount')}
          </Style.RightAlignedCellText>
        ),
        footer: (props) => props.column.id,
        size: 108,
        minSize: 108,
        cell: (info) => (
          <Style.RightAlignedCellText>
            {info.getValue()}
          </Style.RightAlignedCellText>
        ),
      }
    ),
    requestsColumnHelper.accessor(
      'createdAt',
      {
        header: () => (
          <Style.CenteredCellText>
            {t('filters.requestDate')}
          </Style.CenteredCellText>
        ),
        footer: (props) => props.column.id,
        minSize: 132,
        cell: (info) => (
          <Style.CenteredCellText>
            {formatDateRange({
              start: info.getValue(),
              language,
            })}
          </Style.CenteredCellText>
        ),
      }
    ),
    requestsColumnHelper.accessor(
      'status',
      {
        header: t('filters.requestProcessing'),
        footer: (props) => props.column.id,
        size: 140,
        cell: (info) => {
          const status = info.getValue();
          const color = getStatusColor(status);
          const statusKey = status.toLowerCase() as Lowercase<typeof status>;

          return (
            <Style.Status $color={color}>{t(`common.${statusKey}`)}</Style.Status>
          );
        },
      }
    ),
    requestsColumnHelper.accessor(
      'id',
      {
        id: 'menu-column',
        header: '',
        footer: (props) => props.column.id,
        size: 48,
        cell: (info) => (
          <EditMenu
            type="groupRequest"
            id={info.getValue()}
            onAction={onAction}
          />
        ),
      }
    ),
  ];

  return columns;
};
