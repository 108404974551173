import clsx from 'clsx';
import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import Select from 'react-select';

import {
  DropdownIndicator
} from 'src/shared/components';

const selectOptionsMap = [20, 30, 40, 50];

export const paginationSelectOptions = selectOptionsMap.map((option) => ({
  value: option,
  label: option,
}));

export type PaginationSelectOption = (typeof paginationSelectOptions)[0];

export interface ItemsSelectorProps {
  label?: string;
  pageSize: number;
  onPageSizeChange: (value: number) => void;
}

export const ItemsAmountSelector: FC<ItemsSelectorProps> = ({
  label,
  pageSize,
  onPageSizeChange,
}) => {
  const {
    t
  } = useTranslation();

  const selectValue = paginationSelectOptions.find(
    (item) => item.value === pageSize,
  );

  return (
    <div className="flex gap-2 items-center text-sm text-dim-gray ml-auto">
      {`${label || t('common.items')} ${t('common.perPage')}`}

      <Select
        options={paginationSelectOptions}
        defaultValue={paginationSelectOptions[0]}
        value={selectValue || paginationSelectOptions[0]}
        onChange={(value) => onPageSizeChange((value as PaginationSelectOption).value)}
        menuPlacement="top"
        isSearchable={false}
        components={{
          DropdownIndicator,
        }}
        classNames={{
          control: () => `
                px-2 py-1 border outline-0 !rounded-md
                text-15-20 font-medium
                !shadow-none !border-gray90 !h-8 !min-h-8 !w-20
              `,
          singleValue: () => `!text-dim-gray`,
          valueContainer: () => `!px-0 !h-6`,
          indicatorSeparator: () => `hidden`,
          indicatorsContainer: () => `!h-6`,
          option: ({
            isSelected
          }) => clsx(
            `text-17-26 font-medium mb-2 last:mb-0 p-2 rounded hover:!bg-cultured cursor-pointer`,
            {
              '!bg-alice-blue !text-button-blue': isSelected,
              '!text-dark-gray !bg-white': !isSelected,
            },
          ),
          menu: () => `p-2 !shadow-menu-shadow !border-0 rounded !mt-1 !z-10`,
          menuList: () => '!max-h-min',
        }}
      />
    </div>
  );
};
