import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  PublicPin
} from 'src/widgets';
import {
  ArrowRightIcon
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';

import * as Style from './GroupAction.styles';

interface GroupActionProps {
  isPublic?: boolean;
  id: string;
}

export const GroupAction: FC<GroupActionProps> = ({
  isPublic, id
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.GroupHeaderRightSide>
      {isPublic && (
        <Style.PinContainer>
          <PublicPin />
        </Style.PinContainer>
      )}

      <Style.GroupDetailsButton to={`/${ROUTE.GROUPS}/${id}`}>
        {t('buttons.details')}

        <ArrowRightIcon />
      </Style.GroupDetailsButton>
    </Style.GroupHeaderRightSide>
  );
};
