import React, {
  FC, useMemo
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  FilterBy,
  FilterByMenu,
  SortBy,
  SortByMenu,
} from 'src/entities/Questions';
import {
  FilterPopoverMenu
} from 'src/shared/components';
import {
  questionsFilterBy, questionsSortBy
} from 'src/shared/utils';

import * as Style from './QuestionsFilter.styles';

interface QuestionsFilterProps {
  withBorder?: boolean;
}

export const QuestionsFilter: FC<QuestionsFilterProps> = ({
  withBorder
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    t
  } = useTranslation();

  const sortBy = useMemo(
    () => searchParams.get(questionsSortBy) || SortBy.Newest,
    [searchParams],
  ) as SortBy;

  const filterBy = useMemo(
    () => searchParams.get(questionsFilterBy) || null,
    [searchParams],
  ) as FilterBy;

  const onSelect = (value: string | null, param: string) => {
    if (value) {
      searchParams.set(
        param,
        value
      );
    } else {
      searchParams.delete(param);
    }

    setSearchParams(searchParams);
  };

  const onSort = (value: SortBy | null) => onSelect(
    value,
    questionsSortBy
  );

  const onFilter = (value: FilterBy | null) => onSelect(
    value,
    questionsFilterBy
  );

  return (
    <FilterPopoverMenu
      tooltipMessage={t('filters.filterQuestions')}
      width={292}
      withBorder={withBorder}
    >
      <Style.MainContainer>
        <FilterByMenu
          withAvailable={false}
          currentValue={filterBy}
          onSelect={onFilter}
        />

        <Style.Divider />

        <SortByMenu
          withExtendedOptions
          currentValue={sortBy}
          onSelect={onSort}
        />
      </Style.MainContainer>
    </FilterPopoverMenu>
  );
};
