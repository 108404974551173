import clsx from 'clsx';
import React, {
  FC, InputHTMLAttributes
} from 'react';

import {
  RadialСheckFalseIcon, RadialСheckTrueIcon
} from 'src/shared/icons';

interface RadioInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  labelClassName?: string;
  label: string;
}

export const RadioInput: FC<RadioInputProps> = ({
  checked,
  labelClassName,
  label,
  ...props
}) => {
  return (
    <label
      className={clsx(
        'flex gap-2 text-gray7 items-center cursor-pointer',
        labelClassName,
      )}
    >
      <input
        type="radio"
        checked={checked}
        className="hidden"
        {...props}
      />

      {checked ? <RadialСheckTrueIcon /> : <RadialСheckFalseIcon />}

      {label}
    </label>
  );
};
