import React, {
  FC
} from 'react';

import * as Style from './GroupCardQuestionBlock.styles';

interface GroupCardQuestionBlockProps {
  data: {
    dates: string;
    questionTitle: string;
    activeQuestionsCount?: number | null;
    questionsCount?: number | null;
  };
  isInvited?: boolean;
  setContainerClassName?: string;
}

export const GroupCardQuestionBlock: FC<GroupCardQuestionBlockProps> = ({
  data,
  isInvited,
  setContainerClassName,
}) => {
  const {
    dates, questionTitle, activeQuestionsCount, questionsCount
  } = data;

  const isStatistics = !!questionsCount && !!activeQuestionsCount;

  return (
    <Style.CardQuestionContainer
      $isInvited={isInvited}
      className={setContainerClassName}
    >
      <Style.CardQuestionDates>
        {dates}

        {isStatistics && (
          <Style.QuestionStatistic>
            {`${activeQuestionsCount}/${questionsCount}`}
          </Style.QuestionStatistic>
        )}
      </Style.CardQuestionDates>

      <Style.CardQuestionTitle $isInvited={isInvited}>
        {questionTitle}
      </Style.CardQuestionTitle>
    </Style.CardQuestionContainer>
  );
};
