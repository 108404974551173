import React, {
  FC
} from 'react';
import clsx from 'clsx';
import {
  flexRender, Row
} from '@tanstack/react-table';

import {
  BasicColoredList,
  EmailInviteButton,
  Spinner,
  TableRowStyles,
} from 'src/shared/components';
import {
  ArrowDownIcon, PowerIcon
} from 'src/shared/icons';
import {
  GroupInvitedPerson, GroupInvited
} from 'src/redux/openapi';
import {
  useGetGroupInvites
} from 'src/entities/GroupsRoute/hooks';
import {
  PublicPin
} from 'src/widgets/PublicPin';

import {
  GroupMenu
} from '../GroupMenu';

import * as Style from './GroupWithUsersCard.styles';

export interface GroupWithUsersCardProps {
  row: Row<GroupInvited>;
  onInvitedUpdate: () => void;
  onGroupDelete: (id: string) => void;
  refetchGroups: () => void;
}

export const GroupWithUsersCard: FC<GroupWithUsersCardProps> = ({
  onInvitedUpdate,
  refetchGroups,
  onGroupDelete,
  row,
}) => {
  const {
    getIsExpanded, toggleExpanded, original: groupData
  } = row;

  const isExpanded = getIsExpanded();

  const {
    id, invitedCount, status, isPublic
  } = groupData;

  const isGroupDeactivated = status === 'DEACTIVATED';

  const onInviteDeleteSuccess = () => {
    refetchGroups();
    onInvitedUpdate();
  };

  const {
    invites,
    sentryRef,
    displayLoader,
    removeUserInvitation,
    onInvitedListUpdate,
  } = useGetGroupInvites({
    shouldSkip: !isExpanded,
    id,
    onInvitesUpdate: onInvitedUpdate,
    onDeleteSuccess: onInviteDeleteSuccess,
  });

  const onDelete = async ({
    id: userId
  }: Pick<GroupInvitedPerson, 'id'>) => {
    if (!userId) {
      return;
    }

    await removeUserInvitation(userId);
  };

  return (
    <>
      <TableRowStyles.TableRow
        $variant="white"
        className={clsx({
          '[&*]:!text-grey-dark-40': isGroupDeactivated,
        })}
      >
        {row.getAllCells().map((cell) => {
          return (
            <TableRowStyles.TableCell key={cell.id}>
              {cell.column.id !== 'id' ? (
                flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )
              ) : (
                <Style.ContentWrapper>
                  {isGroupDeactivated && <PowerIcon />}

                  {isPublic && <PublicPin />}

                  <div>
                    <EmailInviteButton
                      refetchUsers={onInvitedListUpdate}
                      groupId={id}
                    />
                  </div>

                  <GroupMenu
                    groupData={groupData}
                    isDeactivated={isGroupDeactivated}
                    refetchGroups={refetchGroups}
                    onGroupDelete={onGroupDelete}
                  />

                  {!isGroupDeactivated && !!invitedCount ? (
                    <button
                      type="button"
                      onClick={() => toggleExpanded()}
                    >
                      <ArrowDownIcon
                        className={clsx(
                          'w-6 h-6',
                          {
                            'rotate-180': isExpanded,
                          }
                        )}
                      />
                    </button>
                  ) : (
                    <Style.IconPlaceholder />
                  )}
                </Style.ContentWrapper>
              )}
            </TableRowStyles.TableCell>
          );
        })}
      </TableRowStyles.TableRow>

      {isExpanded && (
        <tr>
          <td colSpan={3}>
            <Style.ListContainer>
              {!!invites.length && (
                <BasicColoredList
                  list={invites}
                  valueKey="email"
                  onDelete={onDelete}
                />
              )}

              {displayLoader && (
                <div ref={sentryRef}>
                  <Spinner size={24} />
                </div>
              )}
            </Style.ListContainer>
          </td>
        </tr>
      )}
    </>
  );
};
