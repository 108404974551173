import {
  useState
} from 'react';

import {
  Step, CompletedStatus
} from '../types';

export const useStepper = (steps: Step[]) => {
  const [currentStep, setCurrentStep] = useState(0);

  const defaultCompletedStatus = Object.fromEntries(
    steps.map((step) => [step.id, false]),
  );

  const activeStepId = steps[currentStep].id;

  const [completedStatus, setCompletedStatus] = useState<CompletedStatus>(
    defaultCompletedStatus,
  );

  const completeCurrentStep = (value = true) => {
    setCompletedStatus((prevStatus) => ({
      ...prevStatus,
      [activeStepId]: value,
    }));
  };

  const resetSteps = () => {
    setCurrentStep(0);
    setCompletedStatus(defaultCompletedStatus);
  };

  const handleNextStep = () => setCurrentStep((prev) => prev + 1);

  const handlePreviousStep = () => {
    setCurrentStep((prev) => {
      return prev ? prev - 1 : prev;
    });
  };

  const isCurrentStepCompleted = completedStatus[activeStepId];
  const lastStep = steps.length - 1;
  const isLastStep = currentStep === lastStep;
  const isLastStepCompleted = completedStatus[steps[lastStep].id];

  const onStepClick = (stepNumber: number) => {
    const stepId = steps[stepNumber].id;

    const isCompletedAfter = steps
      .slice(currentStep + 1)
      .some((step) => completedStatus[step.id]);

    const isStepCompleted = completedStatus[stepId];

    if (
      (isStepCompleted && isCurrentStepCompleted)
      || (isStepCompleted && !isCompletedAfter)
    ) {
      setCurrentStep(stepNumber);
    }
  };

  return {
    currentStep,
    setCurrentStep,
    completeCurrentStep,
    activeStepId,
    isLastStep,
    isLastStepCompleted,
    lastStep,
    completedStatus,
    handleNextStep,
    handlePreviousStep,
    onStepClick,
    isCurrentStepCompleted,
    resetSteps,
  };
};
