import {
  AdminOrganizationRequests,
  AdminUnaffiliatedGroup,
} from 'src/redux/openapi';

export enum OrganisationActions {
  EditOrganisation = 'editOrg',
  ToggleAutoApproval = 'toggleAutoApproval',
  DeleteOrg = 'deleteOrg',
}

export enum GroupRequestsActions {
  DeleteRequest = 'deleteRequest',
  Request = 'request',
}

export enum GroupActions {
  EditGroup = 'editGroup',
  DeleteGroup = 'deleteGroup',
}

export enum SetRequestsActions {
  ManageSetRequest = 'manageSetRequest',
  DeclineSetRequest = 'declineSetRequest',
}

export type EditMenuActions =
  | OrganisationActions
  | GroupRequestsActions
  | GroupActions
  | SetRequestsActions;

export type ActionHandler<T> = (id: string, action: T) => void;

export type ManagementEntity = 'organization' | 'group' | 'groupRequest';

export type SelectOption = {
  value: number;
  label: number;
};

export interface EntityModel {
  id: string;
  isRead?: boolean;
  status?:
  | AdminOrganizationRequests['status']
  | AdminUnaffiliatedGroup['status'];
}

export enum Tabs {
  AllOrganizations = 'allOrganizations',
  GroupsRequests = 'groupsRequests',
  UnaffiliatedGroups = 'unaffiliatedGroups',
  SetManagement = 'setManagement',
  QuestionScoring = 'questionScoring',
}
