import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const LabelWrapper = styled.div`
  ${tw`flex flex-row justify-between`}
`;

export const Label = styled.div`
  ${tw`block text-13-21 md:text-15-20 font-medium text-grey-dark-40`}
`;

export const Asterisk = styled.span`
  ${tw`text-red-label`}
`;

export const ForgotPasswordContainer = styled.div`
  ${tw`text-dim-gray text-13-21 md:text-15-20 font-bold underline`}

  text-decoration-skip-ink: none;
`;

export const InputWrapper = styled.div<{ $isError: boolean }>`
  ${tw`rounded border mt-2 flex flex-row gap-4 justify-between items-center`}

  ${({
    $isError
  }) => ($isError
    ? tw`bg-red-bg-error border-red-border-error focus-within:bg-red-bg-error focus-within:border-red-border-error`
    : tw`bg-alice-blue border-gray94 focus-within:bg-input-blue focus-within:border-input-border-blue`)}
`;

export const Input = styled.input`
  ${tw`block rounded w-full h-12 py-1 px-4 text-15-20 md:text-17-26 text-gray7 border-0 focus:outline-none bg-transparent read-only:cursor-pointer`}
`;

export const ShowButton = styled.button`
  ${tw`pr-4 outline-none`}
`;

export const BottomMessage = styled.div`
  ${tw`text-13-21 font-medium text-dim-gray mt-1`}
`;

export const ErrorMessage = styled.div`
  ${tw`text-13-21 font-medium text-red-text-error mt-1`}
`;
