import React, {
  FC
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';

import {
  Spinner
} from 'src/shared/components';
import {
  CommunityCommentsSection
} from 'src/widgets';
import {
  questionId
} from 'src/shared/utils';
import {
  useGetQuestionQuery
} from 'src/redux/openapi';
import {
  communitySelectors, CommunityTabId
} from 'src/redux/community';
import {
  useTypedSelector
} from 'src/redux';

import * as Style from './Community.styles';

interface ActiveQuestionCommunityProps {
  tab: CommunityTabId.Active | CommunityTabId.Scored;
}

export const ActiveQuestionCommunity: FC<ActiveQuestionCommunityProps> = ({
  tab,
}) => {
  const [searchParams] = useSearchParams();

  const activeQuestionId = searchParams.get(questionId) || null;

  const {
    data: questionsData, isLoading: isQuestionLoading
  } = useGetQuestionQuery(
    {
      id: activeQuestionId || '',
    },
    {
      skip: !activeQuestionId,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    loading, emptyMessage
  } = tab === CommunityTabId.Active
    ? useTypedSelector(communitySelectors.selectActiveQuestionsTab)
    : useTypedSelector(communitySelectors.selectScoredQuestionsTab);

  if (loading || isQuestionLoading) {
    return <Spinner />;
  }

  const activeQuestion = activeQuestionId ? questionsData?.data.question : null;

  return activeQuestion ? (
    <CommunityCommentsSection activeQuestion={activeQuestion} />
  ) : (
    <Style.EmptyChatContainer>
      <Style.EmptyMessageTitle>{emptyMessage.title}</Style.EmptyMessageTitle>

      {emptyMessage.subtitle}
    </Style.EmptyChatContainer>
  );
};
