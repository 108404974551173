import {
  PayloadAction, createSlice
} from '@reduxjs/toolkit';

import {
  Organization,
  arqApiUsers,
  arqApiOrganization,
} from 'src/redux/openapi';
import {
  authApi, usersApi
} from 'src/shared/api';
import {
  defaultHexColor,
  handleSetDefaultColorValues,
  handleSetHexColorValues,
  handleSetRgbColorValues,
  isHexColor,
} from 'src/shared/utils';

import {
  getGroupsUsersWithGroups,
  getGroupUsersWithGroups,
  getUniqueCharacteristics,
  getUniqueUsers,
} from './utils';
import {
  DeleteGroupUser,
  GroupWithCharacteristics,
  GroupUser,
  UserListItem,
  OrganizationGroupWithUsers,
} from './types';

interface UpdateName {
  id: string;
  name: string;
  oldName: string;
}

interface RemoveOrgGroup {
  id: string;
  name: string;
}

interface OrganizationsState {
  organizations: Organization[];
  groupsToSetUp: OrganizationGroupWithUsers[];
  userList: UserListItem[];
  orgTheme: null | {
    colorPrimary: string;
    logoUrl: string | null;
  };
  characteristics: string[];
}

const initialState: OrganizationsState = {
  organizations: [],
  userList: [],
  groupsToSetUp: [],
  orgTheme: null,
  characteristics: [],
};

export interface OrganizationReducer {
  organizations: OrganizationsState;
}

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    resetUsersToSetUp: (state) => {
      state.userList = [];
      state.characteristics = [];
      state.groupsToSetUp = [];
    },
    addNewUsers: (state, action: PayloadAction<GroupUser[]>) => {
      const users = action.payload;

      const usersWithGroups = getGroupUsersWithGroups(users);

      state.userList = getUniqueUsers(
        usersWithGroups,
        state.userList
      );

      state.groupsToSetUp = state.groupsToSetUp.map((group) => {
        const groupUsers = users
          .filter((user) => user.group === group.name)
          .map((user) => ({
            email: user.email,
          }));

        return {
          ...group,
          users: [...groupUsers, ...group.users],
        };
      });
    },
    addUsersToGroup: (
      state,
      action: PayloadAction<OrganizationGroupWithUsers>,
    ) => {
      const {
        users, name
      } = action.payload;

      state.groupsToSetUp = state.groupsToSetUp.map((group) => {
        if (group.name !== name) {
          return group;
        }

        const newUsers = getUniqueUsers(
          users,
          group.users
        );

        return {
          ...group,
          users: newUsers,
        };
      });

      state.userList = state.userList.map((user) => {
        const userToUpdate = users.find(({
          email
        }) => email === user.email);

        if (!userToUpdate || user.groups.includes(name)) {
          return user;
        }

        return {
          ...user,
          groups: [...user.groups, name],
        };
      });
    },
    addGroup: (state, action: PayloadAction<OrganizationGroupWithUsers>) => {
      state.groupsToSetUp = [...state.groupsToSetUp, action.payload];
    },
    removeUser: (state, action: PayloadAction<string>) => {
      state.userList = state.userList.filter(
        (user) => user.email !== action.payload,
      );

      state.groupsToSetUp = state.groupsToSetUp.map((group) => {
        if (!group.users.some(({
          email
        }) => email === action.payload)) {
          return group;
        }

        const filteredUsers = group.users.filter(
          (user) => user.email !== action.payload,
        );

        return {
          ...group,
          users: filteredUsers,
        };
      });
    },
    removeUserFromGroup: (state, action: PayloadAction<DeleteGroupUser>) => {
      state.groupsToSetUp = state.groupsToSetUp.map((group) => {
        if (group.name !== action.payload.group) {
          return group;
        }

        const filteredUsers = group.users.filter(
          (user) => user.email !== action.payload.email,
        );

        return {
          ...group,
          users: filteredUsers,
        };
      });

      state.userList = state.userList.map((user) => {
        if (user.email !== action.payload.email) {
          return user;
        }

        return {
          ...user,
          groups: user.groups.filter(
            (userGroup) => userGroup !== action.payload.group,
          ),
        };
      });
    },
    loadOrgGroupsData: (
      state,
      action: PayloadAction<OrganizationGroupWithUsers[]>,
    ) => {
      state.groupsToSetUp = action.payload;
      const newUsers = getGroupsUsersWithGroups(action.payload);

      if (!state.userList.length) {
        state.userList = newUsers;
      } else {
        state.userList = getUniqueUsers(
          newUsers,
          state.userList
        );
      }

      const characteristicsToAdd = newUsers
        .filter((user) => user.characteristics)
        .flatMap(({
          characteristics
        }) => characteristics || [])
        .flatMap(({
          value
        }) => value)
        .filter(Boolean) as string[];

      state.characteristics = getUniqueCharacteristics(
        characteristicsToAdd,
        state.characteristics,
      );
    },
    updateOrgGroupName: (state, action: PayloadAction<UpdateName>) => {
      state.groupsToSetUp = state.groupsToSetUp.map((group) => {
        if (group.id !== action.payload.id) {
          return group;
        }

        return {
          ...group,
          name: action.payload.name,
        };
      });

      state.userList = state.userList.map((user) => {
        if (!user.groups.includes(action.payload.oldName)) {
          return user;
        }

        const userGroups = user.groups.map((group) => {
          return group === action.payload.oldName ? action.payload.name : group;
        });

        return {
          ...user,
          groups: userGroups,
        };
      });
    },
    removeOrgGroup: (state, action: PayloadAction<RemoveOrgGroup>) => {
      state.groupsToSetUp = state.groupsToSetUp.filter(
        (group) => group.id !== action.payload.id,
      );

      state.userList = state.userList.map((user) => {
        if (!user.groups.includes(action.payload.name)) {
          return user;
        }

        const userGroups = user.groups.filter(
          (group) => group !== action.payload.name,
        );

        return {
          ...user,
          groups: userGroups,
        };
      });
    },
    addGroupCharacteristic: (
      state,
      action: PayloadAction<GroupWithCharacteristics>,
    ) => {
      // TODO: update logic after characteristics clarification

      const {
        group, characteristics
      } = action.payload;

      const updatedList = state.userList.map((user) => {
        const isUserInGroup = user.groups.includes(group);

        if (isUserInGroup) {
          return user;
        }

        const isCharacteristic = characteristics?.some(({
          value
        }) => user.characteristics?.some(
          (characteristic) => value === characteristic.value,
        ),);

        if (!isCharacteristic) {
          return user;
        }

        return {
          ...user,
          groups: [group, ...user.groups],
        };
      });

      state.userList = updatedList;

      state.groupsToSetUp = state.groupsToSetUp.map((groupToSetup) => {
        if (group !== groupToSetup.name) {
          return groupToSetup;
        }

        const groupUsers = updatedList
          .filter((user) => user.groups.includes(groupToSetup.name))
          .map((user) => ({
            email: user.email,
          }));

        return {
          ...groupToSetup,
          users: groupUsers,
        };
      });

      const characteristicsToAdd = characteristics
        ?.flatMap(({
          value
        }) => value)
        .filter(Boolean) as string[];

      state.characteristics = getUniqueCharacteristics(
        characteristicsToAdd,
        state.characteristics,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      arqApiOrganization.endpoints.postApiOrganizationsByIdGroupsUsers
        .matchFulfilled,
      (state) => {
        state.groupsToSetUp = [];
        state.userList = [];
      },
    );

    builder.addMatcher(
      authApi.endpoints.signOut.matchFulfilled,
      () => initialState,
    );

    builder.addMatcher(
      usersApi.endpoints.getCurrentUser.matchRejected,
      () => {
        handleSetDefaultColorValues();

        return initialState;
      }
    );

    builder.addMatcher(
      arqApiUsers.endpoints.getApiUsersCurrentTheme.matchFulfilled,
      (state, {
        payload
      }) => {
        const {
          theme
        } = payload.data;

        const themeToSet = theme?.colorPrimary || defaultHexColor;

        const isDefaultTheme = !theme || themeToSet === defaultHexColor;

        const isColorHex = isHexColor(themeToSet);

        if (isDefaultTheme) {
          handleSetDefaultColorValues();
        } else if (isColorHex) {
          handleSetHexColorValues(themeToSet);
        } else {
          handleSetRgbColorValues(themeToSet);
        }

        state.orgTheme = theme;
      },
    );
  },
});

export const {
  resetUsersToSetUp,
  loadOrgGroupsData,
  updateOrgGroupName,
  removeOrgGroup,
  addGroupCharacteristic,
  removeUser,
  removeUserFromGroup,
  addNewUsers,
  addUsersToGroup,
  addGroup,
} = organizationsSlice.actions;

export const organizationsReducer = organizationsSlice.reducer;
