import {
  useEffect, useMemo, useState
} from 'react';
import {
  useLocation, useParams, useSearchParams
} from 'react-router-dom';
import dayjs from 'dayjs';
import {
  useTranslation
} from 'react-i18next';

import {
  commentID,
  getCurrentSortByValue,
  getFilteredTimeRangeComments,
  groupId,
  questionId,
  ROUTE,
  showOne,
} from 'src/shared/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  commentsFilterSelectors
} from 'src/redux/commentsFilters';
import {
  FilterDateType
} from 'src/widgets/CommentsFilterContext/types';
import {
  useGetQuestionsByIdFeedQuery
} from 'src/shared/api';
import {
  warningToast
} from 'src/shared/components';
import {
  userSelectors
} from 'src/redux/user';

const PAGE_LIMIT = 10;

export const useGetComments = ({
  skip = false
} = {}) => {
  const params = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {
    t
  } = useTranslation();

  const [page, setPage] = useState(1);

  const questionIdSearch = searchParams.get(questionId);
  const groupIdSearch = searchParams.get(groupId);
  const shouldShowOneOnly = searchParams.get(showOne) === 'true';
  const commentId = searchParams.get(commentID) || '';

  const questionIdToSearch = params.id || questionIdSearch;

  const feedFilters = useTypedSelector(commentsFilterSelectors.selectAllFeed);

  const {
    id: currentUserId
  } = useTypedSelector(userSelectors.user);

  const {
    showMyOnly,
    showForecasts,
    commentsSortBy,
    commentsTimeRange,
    selectedDateRange,
    selectedGroups,
  } = feedFilters;

  const filters = useMemo(
    () => {
      const isCustomTimeRange = commentsTimeRange === FilterDateType.CUSTOM && selectedDateRange;

      return {
        userOnly: showMyOnly || undefined,
        withForecast: !!showForecasts,
        sortBy: getCurrentSortByValue(commentsSortBy),
        dateStart: isCustomTimeRange
          ? dayjs(selectedDateRange[0]).toDate().toJSON()
          : getFilteredTimeRangeComments(commentsTimeRange),
        dateEnd: isCustomTimeRange
          ? dayjs(selectedDateRange[selectedDateRange.length - 1])
            .toDate()
            .toJSON()
          : undefined,
      };
    },
    [feedFilters]
  );

  useEffect(
    () => {
      setPage(1);
    },
    [feedFilters, questionIdToSearch, groupIdSearch]
  );

  const isCommunityPage = location.pathname === `/${ROUTE.COMMUNITY}`;
  const communityGroupIds = groupIdSearch ? [groupIdSearch] : undefined;

  const groupIds = isCommunityPage
    ? communityGroupIds
    : selectedGroups ?? undefined;

  const {
    data: questionCommentsData,
    isLoading: isLoadingQuestionCommentsList,
    isFetching: isLoadingMoreComments,
    refetch: refetchQuestionComments,
  } = useGetQuestionsByIdFeedQuery(
    {
      id: questionIdToSearch || '',
      filters: {
        ...filters,
        groupIds,
        commentId: shouldShowOneOnly ? commentId : undefined,
      },
      page: 1,
      limit: PAGE_LIMIT * page,
      currentUserId,
    },
    {
      skip: !questionIdToSearch || skip,
      refetchOnMountOrArgChange: true,
    },
  );

  const loadNextPage = async () => {
    try {
      if (
        questionCommentsData
        && questionCommentsData.data.total
          > questionCommentsData.data.feed.length
        && !isLoadingMoreComments
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      warningToast(t('errors.errorLoadComments'));
    }
  };

  return {
    data: questionCommentsData?.data.feed || [],
    isLoading: isLoadingQuestionCommentsList,
    loadNextPage,
    isLoadingMoreComments,
    refetch: refetchQuestionComments,
    total: questionCommentsData?.data.total || 0,
    hasOnboarded: !!questionCommentsData?.data.hasOnboarded,
  };
};
