import React, {
  useState
} from 'react';
import ReactDatePicker from 'react-datepicker';
import i18next from 'i18next';

import {
  ClearableSelectFooter
} from 'src/shared/components';

import {
  CalendarHeader
} from './CalendarHeader';
import {
  TDate
} from './types';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import {
  getCorrectDayOfWeek
} from './utils';

type PickerValue<T extends boolean = true> =
  | (T extends true ? TDate[] : TDate)
  | null;

interface DatePickerMenuProps<T extends boolean = true> {
  onApply: (date: PickerValue<T>) => void;
  clearButtonTitle?: string;
  applyButtonTitle?: string;
  selectedDateRange?: PickerValue<T>;
  isMultiple?: T;
  saveOnChange?: boolean;
  maxDate?: Date | null;
  minDate?: Date | null;
}

const getDefaultDates = (dateRange?: TDate[] | TDate) => {
  if (Array.isArray(dateRange) && dateRange.length > 0) {
    const [start, end] = dateRange;

    return {
      startDate: start instanceof Date ? start : null,
      endDate: end instanceof Date ? end : null,
    };
  }

  return {
    startDate: dateRange instanceof Date ? dateRange : null,
    endDate: null,
  };
};

export const DatePickerMenu = <T extends boolean = true>({
  onApply: setSelectedValue,
  clearButtonTitle,
  applyButtonTitle,
  selectedDateRange,
  isMultiple = true as T,
  saveOnChange,
  maxDate,
  minDate,
}: DatePickerMenuProps<T>) => {
  const {
    startDate, endDate
  } = getDefaultDates(selectedDateRange);

  const [selectedStartDate, setSelectedStartDate] = useState<TDate>(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<TDate>(endDate);

  const onApply = (start = selectedStartDate, end = selectedEndDate) => {
    return isMultiple
      ? setSelectedValue([start, end] as PickerValue<T>)
      : setSelectedValue(start as PickerValue<T>);
  };

  const onChange = (dates: TDate[] | TDate) => {
    if (!Array.isArray(dates)) {
      setSelectedStartDate(dates);

      if (saveOnChange) {
        onApply(dates);
      }

      return;
    }

    const [start, end] = dates;
    setSelectedStartDate(start);
    setSelectedEndDate(end);

    if (saveOnChange) {
      onApply(
        start,
        end
      );
    }
  };

  const onClear = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedValue(null);
  };

  return (
    <ReactDatePicker
      open
      locale={i18next.language}
      className={isMultiple ? 'multiple' : ''}
      dayClassName={() => 'datepicker-day'}
      popperClassName="datepicker-popper"
      popperPlacement="top-end"
      selected={selectedStartDate}
      onChange={onChange}
      startDate={selectedStartDate}
      endDate={selectedEndDate}
      selectsRange={isMultiple}
      calendarStartDay={1}
      showPopperArrow={false}
      formatWeekDay={getCorrectDayOfWeek}
      maxDate={maxDate}
      minDate={minDate}
      renderDayContents={(dayOfMonth) => <p>{dayOfMonth}</p>}
      renderCustomHeader={({
        date, decreaseMonth, increaseMonth
      }) => (
        <CalendarHeader
          date={date}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
        />
      )}
    >
      <ClearableSelectFooter
        onClear={onClear}
        onApply={onApply}
        isApplyDisabled={!selectedStartDate}
        clearButtonTitle={clearButtonTitle}
        applyButtonTitle={applyButtonTitle}
        withApplyButton={!saveOnChange}
      />
    </ReactDatePicker>
  );
};
