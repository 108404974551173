import React, {
  FC
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import i18next from 'i18next';

import {
  MessageIcon
} from 'src/shared/icons';
import {
  ScrollContainer, Spinner
} from 'src/shared/components';
import {
  FilterDateType
} from 'src/widgets/CommentsFilterContext/types';
import {
  useTypedSelector
} from 'src/redux';
import {
  commentsFilterSelectors
} from 'src/redux/commentsFilters';
import {
  formatDateValue
} from 'src/widgets/CommentsFilterContext/utils';
import {
  getCommentsTimeRange
} from 'src/shared/utils';
import {
  Comment
} from 'src/redux/openapi';

import {
  CommentListItem
} from '../CommentListItem';

import * as Style from './CommentList.styles';

interface CommentListProps {
  comments: Comment[];
  isLoadingComments: boolean;
  isLoadingMoreComments: boolean;
  loadNextPage: () => void;
  refetch: () => void;
  total: number;
}

export const CommentList: FC<CommentListProps> = ({
  comments,
  isLoadingComments,
  loadNextPage,
  isLoadingMoreComments,
  refetch,
  total,
}) => {
  const timeRange = useTypedSelector(
    commentsFilterSelectors.selectFeedCommentsTimeRange,
  );

  const selectedDateRange = useTypedSelector(
    commentsFilterSelectors.selectFeedSelectedDateRange,
  );

  const {
    language
  } = i18next;

  const formattedDateRange = formatDateValue(
    selectedDateRange,
    language
  );

  const isLoading = isLoadingComments || isLoadingMoreComments;

  const loadNextPageHandler = () => {
    if (!isLoading) {
      loadNextPage();
    }
  };

  return (
    <>
      <Style.CommentsAmount>
        {timeRange !== FilterDateType.ALL_TIME && (
          <>
            {total}

            <MessageIcon
              width={20}
              height={20}
            />
          </>
        )}

        {timeRange === FilterDateType.CUSTOM
          ? formattedDateRange
          : getCommentsTimeRange(timeRange)}
      </Style.CommentsAmount>

      <ScrollContainer className="flex-1">
        <InfiniteScroll
          pageStart={1}
          loadMore={loadNextPageHandler}
          hasMore={comments.length < total}
          loader={(
            <Spinner
              size={24}
              key="spinner"
            />
          )}
          useWindow={false}
        >
          {comments.map((comment) => (
            <CommentListItem
              comment={comment}
              key={comment.id}
              refetch={refetch}
              groupRemoveAccess={!!comment.groupRemoveAccess}
            />
          ))}
        </InfiniteScroll>
      </ScrollContainer>
    </>
  );
};
