import {
  t
} from 'i18next';
import z from 'zod';

import {
  FormField, InputNames, MenuField
} from 'src/pages/Login/types';
import {
  getCharacterValidationError
} from 'src/shared/utils';
import {
  CreateOrganizationFormField,
  EditGroupAdminFormField,
} from 'src/features';

enum FormFieldGroup {
  GroupName = 'groupName',
  GroupLocation = 'groupLocation',
  GroupQuestions = 'groupQuestions',
}

const HEX_WITHOUT_TRANSPARENCY = /^#([A-Fa-f0-9]{6})$/;

const RGB = /^rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)$/;

export const useGetInputValidation = () => {
  const lowercase = t('passwordValidation.lowercase');
  const uppercase = t('passwordValidation.uppercase');
  const digit = t('passwordValidation.digit');

  const emailValidation = z
    .string()
    .email({
      message: t('inputMessages.ENTER_VALID_EMAIL'),
    })
    .min(
      1,
      {
        message: t('inputMessages.ENTER_MAIL'),
      }
    );

  const optionalEmailValidation = z
    .string()
    .email({
      message: t('inputMessages.ENTER_VALID_EMAIL'),
    })
    .or(z.literal(''))
    .optional();

  const mailInListValidation = z.string().email({
    message: t('inputMessages.INVALID_EMAIL'),
  });

  const emailOrUserNameValidation = z.string().min(
    1,
    {
      message: t('inputMessages.ENTER_MAIL_USERNAME'),
    }
  );

  const passwordValidation = z
    .string()
    .min(
      1,
      {
        message: t('inputMessages.ENTER_PASSWORD'),
      }
    )
    .regex(
      /[a-z]/,
      {
        message: getCharacterValidationError(lowercase),
      }
    )
    .regex(
      /[A-Z]/,
      {
        message: getCharacterValidationError(uppercase),
      }
    )
    .regex(
      /[0-9]/,
      {
        message: getCharacterValidationError(digit),
      }
    )
    .min(
      8,
      t('inputMessages.ENTER_CORRECT_PASSWORD_LENGTH')
    );

  const loginPasswordValidation = z
    .string()
    .min(
      1,
      {
        message: t('inputMessages.ENTER_PASSWORD'),
      }
    )
    .min(
      8,
      t('inputMessages.ENTER_CORRECT_PASSWORD_LENGTH')
    );

  const userNameValidation = z
    .string()
    .min(
      1,
      {
        message: t('inputMessages.ENTER_USERNAME'),
      }
    )
    .min(
      3,
      {
        message: t('inputMessages.USERNAME_TOO_SHORT'),
      }
    )
    .max(
      20,
      {
        message: t('inputMessages.USERNAME_TOO_LONG'),
      }
    )
    .regex(
      /^[a-z0-9_.]+$/,
      {
        message: t('inputMessages.INVALID_USERNAME'),
      }
    );

  const userFullNameValidation = z
    .string()
    .min(
      1,
      {
        message: t('inputMessages.ENTER_FULL_NAME'),
      }
    )
    .refine(
      (value) => {
        const regex = /\b\w+\b.*\b\w+\b/;

        return regex.test(value.trim());
      },
      {
        message: t('inputMessages.INVALID_FULL_NAME'),
      },
    );

  const confirmPasswordValidation = z.string().min(
    1,
    {
      message: t('inputMessages.MATCH_PASSWORDS'),
    }
  );

  const agreementValidation = z.boolean().refine(
    (data) => !!data,
    {
      message: t('inputMessages.AGREE_TO_TERMS'),
    }
  );

  const stringValidation = z.string();

  const emailsSchema = z.object({
    emails: z.array(
      z.object({
        address: mailInListValidation,
      }),
    ),
  });

  const emailsUniqueSchema = z.object({
    emails: z
      .array(
        z.object({
          address: mailInListValidation,
        }),
      )
      .refine(
        (items) => new Set(items.map(({
          address
        }) => address)).size === items.length,
        {
          message: t('inputMessages.UNIQUE_EMAILS'),
        },
      ),
  });

  const shortDescriptionValidation = z.string().max(
    50,
    {
      message: t(
        'inputMessages.SHORT_DESCRIPTION',
        {
          value: 50,
        }
      ),
    }
  );

  const currentPasswordValidation = z.string().min(
    1,
    {
      message: t('inputMessages.CURRENT_PASSWORD'),
    }
  );

  const repeatPasswordValidation = z.string().min(
    1,
    {
      message: t('inputMessages.REPEAT_PASSWORD'),
    }
  );

  const loginPageSchema = z.object({
    email: emailOrUserNameValidation,
  });

  const createAccountSchema = z.object({
    [InputNames.EMAIL]: emailValidation,
    [InputNames.USERNAME]: userNameValidation,
    [InputNames.FULL_NAME]: userFullNameValidation,
    [InputNames.PASSWORD]: passwordValidation,
    [InputNames.AGREEMENT]: agreementValidation,
  });

  const createPasswordSchema = z
    .object({
      password: passwordValidation,
      confirmPass: confirmPasswordValidation,
    })
    .refine(
      (data) => data.password === data.confirmPass,
      {
        message: t('inputMessages.MATCH_PASSWORDS'),
        path: [InputNames.CONFIRM_PASS],
      }
    );

  const enterPasswordPageSchema = z.object({
    email: emailOrUserNameValidation,
    password: loginPasswordValidation,
  });

  const emailSchema = z.object({
    email: emailValidation,
  });

  const editProfileSchema = z.object({
    [MenuField.DisplayName]: stringValidation,
    [MenuField.Username]: userNameValidation,
    [MenuField.FullName]: userFullNameValidation,
    [MenuField.ShortDescription]: shortDescriptionValidation,
    [MenuField.MainEmail]: emailValidation,
  });

  const editProfileSecuritySchema = z.object({
    [MenuField.Username]: userNameValidation,
    [MenuField.FullName]: userFullNameValidation,
    [MenuField.MainEmail]: emailValidation,
  });

  const editPublicInfoSchema = z.object({
    [MenuField.DisplayName]: stringValidation,
    [MenuField.ShortDescription]: shortDescriptionValidation,
  });

  const editPasswordSchema = z
    .object({
      [FormField.CurrentPassword]: currentPasswordValidation,
      [FormField.NewPassword]: passwordValidation,
      [FormField.RepeatPassword]: repeatPasswordValidation,
    })
    .refine(
      (values) => {
        return (
          values[FormField.NewPassword] === values[FormField.RepeatPassword]
        );
      },
      {
        message: t('inputMessages.MATCH_PASSWORDS'),
        path: [FormField.RepeatPassword],
      },
    );

  const signUpSocialSchema = z.object({
    username: userNameValidation,
  });

  const addMemberSchema = z.object({
    mailList: z.array(
      z.object({
        value: mailInListValidation,
      }),
    ),
    comment: stringValidation,
  });

  const orgNameValidation = z
    .string()
    .min(
      1,
      {
        message: t('inputMessages.ORG_NAME'),
      }
    )
    .max(
      30,
      {
        message: t(
          'inputMessages.SHORT_DESCRIPTION',
          {
            value: 30,
          }
        ),
      }
    );

  const orgDescriptionValidation = z.string().min(
    1,
    {
      message: t('inputMessages.ORG_DESCRIPTION'),
    }
  );

  const upgradeGroupToOrgSchema = z.object({
    organizationName: orgNameValidation,
    description: orgDescriptionValidation,
  });

  const groupNameValidation = z
    .string()
    .min(
      1,
      {
        message: t('inputMessages.GROUP_NAME'),
      }
    )
    .max(
      30,
      {
        message: t(
          'inputMessages.SHORT_DESCRIPTION',
          {
            value: 30,
          }
        ),
      }
    );

  const groupLocationValidation = z.string().min(
    1,
    {
      message: t('inputMessages.GROUP_LOCATION'),
    }
  );

  const createGroupSchema = z.object({
    [FormFieldGroup.GroupName]: groupNameValidation,
    [FormFieldGroup.GroupLocation]: z.object({
      value: groupLocationValidation,
      label: groupLocationValidation,
    }),
  });

  const domainValidation = z
    .string()
    .regex(
      /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/,
      {
        message: t('inputMessages.VALID_DOMAIN'),
      },
    )
    .or(z.literal(''))
    .optional();

  const createOrganizationSchema = z.object({
    [CreateOrganizationFormField.OrganizationName]: orgNameValidation,
    [CreateOrganizationFormField.Domain]: domainValidation,
    [CreateOrganizationFormField.MainAdmin]: emailValidation,
    [CreateOrganizationFormField.FirstSubAdmin]: optionalEmailValidation,
    [CreateOrganizationFormField.SecondSubAdmin]: optionalEmailValidation,
  });

  const organizationNameSchema = z.object({
    name: orgNameValidation,
  });

  const orgThemeNameValidation = z.string().min(
    1,
    {
      message: t('inputMessages.ORG_THEME_NAME'),
    }
  );

  const organizationThemeNameSchema = z.object({
    name: orgThemeNameValidation,
    color: z.string().regex(HEX_WITHOUT_TRANSPARENCY).or(z.string().regex(RGB)),
  });

  const postEstimationFeedbackValidation = z.string().max(
    150,
    {
      message: t('inputMessages.NO_LONGER_FEEDBACK'),
    }
  );

  const postEstimationFeedbackSchema = z.object({
    feedback: postEstimationFeedbackValidation,
  });

  const sendMsgFromOrgAdminSchema = z.object({
    message: z.string().min(
      1,
      {
        message: t('organisation.writeYourNotification'),
      }
    ),
    isEmailNeeded: z.boolean(),
  });

  const editAdminGroupSchema = z.object({
    [EditGroupAdminFormField.GroupName]: groupNameValidation,
    [EditGroupAdminFormField.Location]: z.object({
      value: groupLocationValidation,
      label: groupLocationValidation,
    }),
    [EditGroupAdminFormField.MainAdmin]: emailValidation,
    [EditGroupAdminFormField.FirstSubAdmin]: optionalEmailValidation,
    [EditGroupAdminFormField.SecondSubAdmin]: optionalEmailValidation,
  });

  const scoreQuestionSchema = z.object({
    date: z.string(),
    result: z.boolean(),
    feedbackAvailable: z.boolean(),
    message: z.string().optional(),
  });

  return {
    emailsSchema,
    emailsUniqueSchema,
    loginPageSchema,
    createAccountSchema,
    createPasswordSchema,
    enterPasswordPageSchema,
    emailSchema,
    editProfileSchema,
    editPasswordSchema,
    signUpSocialSchema,
    addMemberSchema,
    upgradeGroupToOrgSchema,
    createGroupSchema,
    createOrganizationSchema,
    organizationNameSchema,
    domainValidation,
    organizationThemeNameSchema,
    editPublicInfoSchema,
    editProfileSecuritySchema,
    postEstimationFeedbackSchema,
    sendMsgFromOrgAdminSchema,
    editAdminGroupSchema,
    scoreQuestionSchema,
  };
};
