import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const PageContainer = styled.div`
  ${tw`max-h-screen`}
`;

export const MainContainer = styled.div`
  ${tw`grid grid-cols-[39%,61%] bg-white h-full border border-gray90 overflow-hidden`}

  grid-template-rows: calc(100vh - 124px);
  max-height: calc(100vh - 124px);
`;

export const EmptyChatContainer = styled.div`
  ${tw`
    flex flex-col gap-2 items-center text-center px-8 py-6 m-auto max-w-[430px]
    bg-alice-blue text-dark-gray text-17-26 font-medium rounded-md
  `}
`;

export const EmptyMessageTitle = styled.div`
  ${tw`text-gray7 font-bold`}
`;
