import React, {
  ForwardedRef, forwardRef, MouseEvent, Ref
} from 'react';
import {
  flexRender, Row
} from '@tanstack/react-table';

import {
  TableVariant
} from '../../types';

import * as Style from './TableRow.styles';

interface TableRowProps<T extends object, Key extends keyof T> {
  onRowClick?: (key: T[Key]) => void;
  rowKey?: Key;
  row: Row<T>;
  index?: number;
  canClick?: boolean;
  variant?: TableVariant;
}

const TableRowComponent = <T extends object, Key extends keyof T>(
  {
    onRowClick,
    row,
    index,
    canClick,
    variant = 'stripped',
    rowKey,
  }: TableRowProps<T, Key>,
  ref: ForwardedRef<HTMLTableRowElement>,
) => {
  const onClick = (event: MouseEvent<HTMLTableRowElement>) => {
    event.stopPropagation();

    if (!canClick || !onRowClick) {
      return;
    }

    if (onRowClick) {
      const key = rowKey ?? ('id' in row.original ? row.original.id : null);

      if (!key) {
        return;
      }

      onRowClick(key as T[Key]);
    }
  };

  return (
    <Style.TableRow
      ref={ref}
      $index={index}
      $variant={variant}
      onClick={onClick}
      $shouldHover={!!onRowClick && canClick}
    >
      {row.getVisibleCells().map((cell) => {
        return (
          <Style.TableCell key={cell.id}>
            {flexRender(
              cell.column.columnDef.cell,
              cell.getContext()
            )}
          </Style.TableCell>
        );
      })}
    </Style.TableRow>
  );
};

export const TableRow = forwardRef(TableRowComponent) as <
  T extends object,
  Key extends keyof T,
>(
  props: TableRowProps<T, Key> & { ref?: Ref<HTMLTableRowElement> },
) => ReturnType<typeof TableRowComponent>;
