import React, {
  forwardRef
} from 'react';

import {
  CircleButton, type ButtonProps
} from 'src/shared/components';
import {
  themeColors
} from 'src/shared/utils/themeColors';
import {
  EditPencilIcon
} from 'src/shared/icons';

export interface EditButtonProps extends ButtonProps {
  iconColor?: string;
  borderColor?: string;
  iconSize?: string;
  size?: number;
  onClick: () => void;
  withBorder?: boolean;
}

export const EditButton = forwardRef<HTMLButtonElement, EditButtonProps>(
  (
    {
      iconColor = themeColors['dim-gray'],
      borderColor = themeColors.gray90,
      iconSize = 24,
      size,
      onClick,
      ...props
    },
    ref,
  ) => (
    <CircleButton
      size={size}
      borderColor={borderColor}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      <EditPencilIcon
        color={iconColor}
        width={iconSize}
        height={iconSize}
      />
    </CircleButton>
  ),
);
