import React, {
  FC, FunctionComponent
} from 'react';

import {
  ArrowRightIcon
} from 'src/shared/icons';

import {
  Spinner
} from '../Spinner';

import * as Style from './Stepper.styles';
import {
  Step
} from './types';

interface StepperProps {
  steps: Step[];
  activeStepId: string;
  completedStatus: Record<string, boolean>;
  nextStepButtonTitle?: string;
  nextStepButtonIcon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isNextButtonOutline?: boolean;
  handleNextStep?: () => void;
  isLoading?: boolean;
  onStepClick?: (step: number) => void;
}

export const Stepper: FC<StepperProps> = ({
  steps,
  completedStatus,
  activeStepId,
  nextStepButtonTitle,
  isNextButtonOutline,
  handleNextStep,
  isLoading,
  nextStepButtonIcon: NextStepButtonIcon = ArrowRightIcon,
  onStepClick,
}) => {
  const onClick = (step: number) => {
    if (onStepClick) {
      onStepClick(step);
    }
  };

  return (
    <div>
      <Style.Stepper>
        <Style.StepsContainer>
          {steps.map(({
            id, label
          }, index) => {
            const isActive = activeStepId === id;

            return (
              <Style.Step
                key={id}
                type="button"
                $isActive={activeStepId === id}
                $isCompleted={!isActive && completedStatus[id]}
                onClick={() => onClick(index)}
                disabled={!completedStatus[id]}
              >
                {`${index + 1}. ${label}`}
              </Style.Step>
            );
          })}
        </Style.StepsContainer>

        {!!nextStepButtonTitle && handleNextStep && (
          <Style.NextStepButton
            type="button"
            onClick={handleNextStep}
            $isOutline={isNextButtonOutline}
          >
            {nextStepButtonTitle}

            {isLoading ? (
              <Spinner
                size={24}
                color="inherit"
              />
            ) : (
              <NextStepButtonIcon className="w-6 h-6" />
            )}
          </Style.NextStepButton>
        )}
      </Style.Stepper>
    </div>
  );
};
