import React, {
  FC
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';

import {
  getTimeAgo, ROUTE
} from 'src/shared/utils';
import {
  AnswerStatus
} from 'src/shared/api/notifications/types';
import {
  NotificationContainer
} from 'src/entities/NotificationsRoute';
import {
  Button
} from 'src/shared/components';

import * as Style from './Notifications.styles';

interface QuestionRequestNotificationProps {
  organizationId: string;
  createdAt: string;
  isNew: boolean;
  answerStatus: AnswerStatus;
  message?: string;
}

export const QuestionRequestNotification: FC<
QuestionRequestNotificationProps
> = ({
  organizationId, createdAt, isNew, answerStatus, message
}) => {
  const {
    t
  } = useTranslation();

  const isApproved = answerStatus === AnswerStatus.ACCEPTED;

  const answer = isApproved
    ? t('answerStatus.approved')
    : t('answerStatus.declined');

  const buttonLink = `/${ROUTE.ORGANISATION}/${organizationId}/${
    isApproved ? ROUTE.EDIT_QUESTIONS : ROUTE.ADD_SET
  }`;

  const buttonText = isApproved
    ? t('buttons.goToEditQuestionSet')
    : t('buttons.goToCreateQuestionSet');

  return (
    <NotificationContainer $isNew={isNew}>
      <Style.Description>
        <Style.NotificationHeader>
          <Style.NotificationDate>
            {`${getTimeAgo(createdAt)} ${t('date.ago')}`}
          </Style.NotificationDate>
        </Style.NotificationHeader>

        <Style.NotificationText>
          <Trans
            i18nKey="question.arqAdminResponseToQuestionSet"
            components={{
              1: <Style.NotificationBoldText />,
            }}
            values={{
              answer: answer.toLowerCase(),
            }}
          />

          {isApproved || !message ? '.' : `: ${message}`}
        </Style.NotificationText>

        <Button
          variant="big-grey-bordered"
          className="w-max text-15-20 font-bold !px-3 !py-2 !h-9"
          to={buttonLink}
          component="link"
        >
          <p className="shrink-0">{buttonText}</p>
        </Button>
      </Style.Description>
    </NotificationContainer>
  );
};
