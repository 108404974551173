import React, {
  FC
} from 'react';
import {
  ActionCreatorWithPayload
} from '@reduxjs/toolkit';

import {
  Switcher
} from 'src/shared/components';
import {
  CommentsFilterReducer
} from 'src/redux/commentsFilters';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';

import * as Style from '../CommentsFilterContext.styles';

interface MyCommentsOnlySectionProps {
  selector: (state: CommentsFilterReducer) => boolean;
  label: string;
  onToggleDispatch: ActionCreatorWithPayload<boolean>;
}

export const ToggleVisibilityItem: FC<MyCommentsOnlySectionProps> = ({
  selector,
  label,
  onToggleDispatch,
}) => {
  const isActive = useTypedSelector(selector);

  const dispatch = useTypedDispatch();

  const onToggle = () => {
    dispatch(onToggleDispatch(!isActive));
  };

  return (
    <Style.LabelToggleVisibility>
      <Style.LabelDatesTitle>{label}</Style.LabelDatesTitle>

      <Switcher
        isActive={isActive}
        onChange={onToggle}
      />
    </Style.LabelToggleVisibility>
  );
};
