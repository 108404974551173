import React, {
  FC, PropsWithChildren
} from 'react';
import {
  Placement
} from 'react-laag';
import clsx from 'clsx';

import {
  EditButton, EditButtonProps
} from '../EditButton';
import {
  MenuContainer, MenuTitle
} from '../DropdownMenuContent';
import {
  PopoverMenu
} from '../PopoverMenu';
import {
  Tooltip
} from '../Tooltip';

interface EditPopoverMenuProps extends PropsWithChildren {
  tooltipMessage?: string;
  title?: string;
  placement?: Placement;
  width?: number;
  buttonProps?: Omit<EditButtonProps, 'onClick' | 'className' | 'iconColor'>;
}

export const EditPopoverMenu: FC<EditPopoverMenuProps> = ({
  tooltipMessage,
  placement,
  width,
  buttonProps,
  children,
  title,
}) => {
  return (
    <PopoverMenu
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <Tooltip
          message={tooltipMessage}
          disabled={!tooltipMessage}
        >
          <EditButton
            onClick={toggleMenu}
            className={clsx({
              'text-button-blue': isOpen,
              'text-dim-gray': !isOpen,
            })}
            iconColor="inherit"
            {...buttonProps}
            {...triggerProps}
          />
        </Tooltip>
      )}
      placement={placement}
      auto={false}
    >
      <MenuContainer $width={width}>
        {title && <MenuTitle>{title}</MenuTitle>}

        {children}
      </MenuContainer>
    </PopoverMenu>
  );
};
