import React from 'react';
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';

import {
  CalibrationResult
} from 'src/redux/openapi';
import {
  CenteredCellText, GrayText
} from 'src/shared/components';
import {
  CheckBoxTrueIcon, CheckBoxNegativeIcon
} from 'src/shared/icons';

const isCorrectAnswer = ({
  answer,
  rangeStart,
  rangeEnd,
}: {
  answer: number;
  rangeStart: number | null;
  rangeEnd: number | null;
}) => {
  if (rangeStart === null || rangeEnd === null) {
    return false;
  }

  return answer >= rangeStart && answer <= rangeEnd;
};

const resultsColumnsHelper = createColumnHelper<CalibrationResult>();

export const getResultsCols = () => [
  resultsColumnsHelper.accessor(
    'order',
    {
      header: () => <CenteredCellText>{t('common.number')}</CenteredCellText>,
      footer: (props) => props.column.id,
      size: 72,
      minSize: 72,
      cell: (info) => (
        <CenteredCellText className="font-bold">
          {`${info.getValue()}.`}
        </CenteredCellText>
      ),
    }
  ),
  resultsColumnsHelper.accessor(
    'calibrationQuestion.title',
    {
      header: t('question.questionName'),
      footer: (props) => props.column.id,
      size: 614,
      minSize: 320,
      cell: (info) => (
        <div className="font-medium text-gray7 pr-10">{info.getValue()}</div>
      ),
    }
  ),
  resultsColumnsHelper.accessor(
    'calibrationQuestion.answer',
    {
      header: t('question.answer'),
      footer: (props) => props.column.id,
      size: 150,
      minSize: 128,
      cell: (info) => <GrayText>{info.getValue()}</GrayText>,
    }
  ),
  resultsColumnsHelper.accessor(
    'rangeStart',
    {
      header: t('filters.questionRange'),
      footer: (props) => props.column.id,
      size: 150,
      minSize: 128,
      cell: ({
        row: {
          original: {
            rangeEnd, rangeStart
          },
        },
      }) => <GrayText>{`${rangeStart} - ${rangeEnd}`}</GrayText>,
    }
  ),
  resultsColumnsHelper.accessor(
    'status',
    {
      header: () => (
        <CenteredCellText>{t('calibration.yourAnswer')}</CenteredCellText>
      ),
      footer: (props) => props.column.id,
      size: 128,
      minSize: 128,
      cell: ({
        row: {
          original: {
            calibrationQuestion, rangeEnd, rangeStart
          },
        },
      }) => (isCorrectAnswer({
        answer: calibrationQuestion.answer,
        rangeEnd,
        rangeStart,
      }) ? (
        <CheckBoxTrueIcon className="text-forest-green w-6 h-6 mx-auto" />
        ) : (
          <CheckBoxNegativeIcon className="text-red-text-error w-6 h-6 mx-auto" />
        )),
    }
  ),
];
