import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MenuContainer = styled.div<{ $width: number }>`
  ${tw`flex flex-col gap-1 p-2`}

  ${({
    $width
  }) => `width: ${$width}px;`}
`;

export const MenuTitle = styled.div`
  ${tw`text-13 font-medium text-dark-gray px-2 py-1`}
`;

export const MenuItem = styled.button<{ $isActive?: boolean }>`
  ${tw`flex gap-2 text-17-26 font-medium p-2 transition`}

  ${({
    $isActive
  }) => $isActive && tw`bg-alice-blue text-button-blue`}
`;
