import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Group, UserGroupRole
} from 'src/redux/openapi';
import {
  Tooltip
} from 'src/shared/components';
import {
  KeyAdminIcon
} from 'src/shared/icons';

import {
  getGroupUserRole
} from '../../utils';

import * as Style from './GroupInfo.styles';

type Roles = Pick<Group, 'groupRole' | 'organizationRole' | 'userRole'>;

interface GroupInfoProps {
  group: Pick<Group, 'location' | 'name'> & Partial<Roles>;
}

export const GroupInfo: FC<GroupInfoProps> = ({
  group
}) => {
  const {
    location, name, groupRole, organizationRole, userRole
  } = group;

  const hasAllRoles = !!groupRole && !!organizationRole && !!userRole;

  const role = hasAllRoles
    && getGroupUserRole({
      groupRole,
      organizationRole,
      userRole,
    });

  const isAdmin = role === UserGroupRole.ADMIN;
  const isSuperAdmin = role === UserGroupRole.SUPERADMIN;

  const {
    t
  } = useTranslation();

  return (
    <Style.GroupInfo>
      <div>
        <Style.GroupLocation>{location}</Style.GroupLocation>

        <Style.GroupTile>{name}</Style.GroupTile>
      </div>

      {(isAdmin || isSuperAdmin) && (
        <Style.GroupIsAdminContainer>
          <Tooltip
            variant="black"
            opacity={0.8}
            message={(
              <p className="text-center max-w-128px">
                {t('group.youAreAdminInGroup')}
              </p>
            )}
          >
            <KeyAdminIcon />
          </Tooltip>
        </Style.GroupIsAdminContainer>
      )}
    </Style.GroupInfo>
  );
};
