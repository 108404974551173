import React, {
  FC, useEffect, useRef
} from 'react';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  Checkbox, CheckboxType, Slider
} from 'src/shared/components';
import {
  CircleGreyIcon, LineDashedIcon, RedLineIcon
} from 'src/shared/icons';
import {
  getAxisDateString
} from 'src/shared/utils/dateTime';
import {
  prepareGroupIdForGraph
} from 'src/shared/utils';
import {
  MAX_SCROLL_DISTANCE,
  MIN_SCROLL_DISTANCE,
  MY_FORECAST,
  SCROLL_STEP,
  TOTAL_PREDICTION,
} from 'src/entities/Graph';
import {
  useGetApiGroupsQuery
} from 'src/redux/openapi';

import {
  LineData
} from './types';

interface GraphLegendProps {
  payload?: LineData[];
  onChange: (dataKey: string) => void;
  state: Record<string, boolean>;
  endDate?: number;
  withScroll?: boolean;
  scrollDistance?: number;
  onScroll?: (distance: number) => void;
  isMulti?: boolean;
  setHeight?: (height: number) => void;
}

export const GraphLegend: FC<GraphLegendProps> = ({
  payload: lines,
  onChange: passedOnChange,
  state,
  endDate,
  withScroll,
  scrollDistance,
  onScroll,
  isMulti,
  setHeight,
}) => {
  const {
    t
  } = useTranslation();

  const toShowLines = () => {
    if (!isMulti) {
      return lines;
    }

    const filtered: string[] = [];
    const filteredLines: LineData[] = [];

    lines?.forEach((line) => {
      if (!filtered.includes(line.value)) {
        filtered.push(line.value);
        filteredLines.push(line);
      }
    });

    return filteredLines;
  };

  const linesForLegend = toShowLines();

  const {
    allUserGroups
  } = useGetApiGroupsQuery(
    {
      limit: 5000,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data
      }) => ({
        allUserGroups: data?.data.groups || [],
      }),
    },
  );

  const formatGroupName = (id: string) => {
    const correctId = prepareGroupIdForGraph(id);

    const group = allUserGroups.find(
      (groupInState) => groupInState.id === correctId,
    );

    return group?.name || id;
  };

  const renderCheckbox = ({
    color, dataKey, value
  }: LineData) => {
    const correctId = prepareGroupIdForGraph(dataKey);
    const param = isMulti ? value : correctId;
    const preparedName = formatGroupName(value);

    return (
      <label
        className="
          cursor-pointer
          flex items-center gap-x-2
        "
      >
        <Checkbox
          type={CheckboxType.SQUARE}
          isChecked={state[param]}
          color={color}
          onChange={() => {
            if (isMulti) {
              passedOnChange(value);
            } else {
              passedOnChange(correctId);
            }
          }}
          size={20}
        />

        {preparedName === MY_FORECAST ? t('question.dataKeyOwn') : preparedName}
      </label>
    );
  };

  const listRef = useRef<HTMLUListElement | null>(null);

  useEffect(
    () => {
      if (listRef.current && setHeight) {
        setHeight(listRef.current.clientHeight);
      }
    },
    [listRef.current?.clientHeight]
  );

  return (
    <>
      {withScroll && (
        <Slider
          variant="scrollbar"
          min={MIN_SCROLL_DISTANCE}
          max={MAX_SCROLL_DISTANCE}
          step={SCROLL_STEP}
          value={scrollDistance}
          onChange={onScroll}
          size="label-small"
          isMulti={isMulti}
        />
      )}

      <ul
        ref={listRef}
        className={clsx(
          'p-3 md:p-6 max-w-max md:mx-auto flex flex-col md:flex-row flex-wrap justify-center items-start md:items-center gap-x-6 gap-y-4 md:gap-y-1 text-sm',
          {
            'mt-10 md:mt-8': !isMulti,
          },
        )}
      >
        {linesForLegend?.map(({
          color, dataKey, value
        }) => {
          const isTotalVotes = dataKey === TOTAL_PREDICTION;

          return (
            <React.Fragment key={dataKey}>
              <li>
                {isTotalVotes && (
                  <div
                    className="
                    max-w-max
                    flex items-center gap-x-2.5
                  "
                  >
                    <LineDashedIcon />

                    {t('question.dataKeyTotal')}
                  </div>
                )}

                {!isTotalVotes
                  && renderCheckbox({
                    color,
                    dataKey,
                    value,
                  })}
              </li>

              {isTotalVotes && (
                <li>
                  <div
                    className="
                    max-w-max md:pl-6
                    flex items-center gap-x-2.5
                  "
                  >
                    <CircleGreyIcon />

                    {t('question.forecastsAmount')}
                  </div>
                </li>
              )}
            </React.Fragment>
          );
        })}

        {endDate && (
          <li
            key="endDate"
            className="pt-4"
          >
            <div
              className="
                max-w-max
                flex items-center gap-x-2.5
              "
            >
              <RedLineIcon />
              End date (

              {getAxisDateString({
                fromDate: endDate,
              })}
              )
            </div>
          </li>
        )}
      </ul>
    </>
  );
};
