import React, {
  FC, useState, useEffect, useMemo, useRef
} from 'react';
import {
  Link, useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetComments,
  useGetPollingComments,
  useSendComment,
} from 'src/shared/hooks';
import {
  SendCommentBlock
} from 'src/widgets/SendCommentBlock';
import {
  ROUTE,
  getSearchRegex,
  questionId,
  themeColors,
  commentSearch,
  groupId,
} from 'src/shared/utils';
import {
  SearchButton, Spinner
} from 'src/shared/components';
import {
  CommentsFilterContext, CommunitySearch
} from 'src/widgets';
import {
  Question
} from 'src/redux/openapi';

import {
  Title
} from '../Sections.styles';

import * as Style from './CommunityCommentsSection.styles';
import {
  CommentsList
} from './CommentsList';

interface CommunityCommentsSectionProps {
  activeQuestion: Question | null;
  isNoGroups?: boolean;
}

export const CommunityCommentsSection: FC<CommunityCommentsSectionProps> = ({
  activeQuestion,
  isNoGroups,
}) => {
  const {
    t
  } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isSearchActive, setIsSearchActive] = useState(false);

  const commentsRef = useRef<HTMLDivElement | null>(null);

  const searchQuery = searchParams.get(commentSearch) || '';
  const activeQuestionId = searchParams.get(questionId) || null;

  const toggleSearch = () => setIsSearchActive((prev) => !prev);

  const {
    addReplyHandler, addNewCommentHandler
  } = useSendComment();

  const groupIdParam = searchParams.get(groupId);

  const poolingCommentsData = useGetPollingComments({
    skip: !groupIdParam,
  });

  const commentsData = useGetComments({
    skip: !!groupIdParam,
  });

  const commentListProps = !groupIdParam ? commentsData : poolingCommentsData;

  const {
    data: comments,
    isLoading,
    loadNextPage,
    isLoadingMoreComments,
    refetch,
    total,
    hasOnboarded,
  } = commentListProps;

  const title = activeQuestion?.title || t('comment.allMyComments');
  const questionID = activeQuestion?.id;

  const matches = useMemo(
    () => {
      if (!searchQuery) {
        return 0;
      }

      return comments.reduce(
        (count, comment) => {
          const regex = getSearchRegex(searchQuery);

          const matchesCount = comment.text?.match(regex)?.length || 0;

          return count + matchesCount;
        },
        0
      );
    },
    [searchParams, comments]
  );

  useEffect(
    () => {
      setIsSearchActive(false);

      if (!activeQuestion && activeQuestionId) {
        searchParams.delete(questionId);
        setSearchParams(searchParams);
      }
    },
    [activeQuestion, activeQuestionId]
  );

  return (
    <Style.Section
      id="CommunityCommentsSection"
      $withSearch={isSearchActive}
      className="relative"
    >
      <Style.SectionHeader>
        <Style.HeaderTitleWrapper>
          <Title className="truncate">{title}</Title>

          {questionID && (
            <Link to={`/${ROUTE.QUESTION}/${questionID}`}>
              <Style.Question>
                <Style.QuestionTitle>
                  {t('community.goToQuestionDetailsPage')}
                </Style.QuestionTitle>
              </Style.Question>
            </Link>
          )}
        </Style.HeaderTitleWrapper>

        <Style.ButtonsContainer>
          <SearchButton
            onClick={toggleSearch}
            borderColor={
              isSearchActive ? themeColors['button-blue'] : themeColors.gray90
            }
            iconColor={
              isSearchActive
                ? themeColors['button-blue']
                : themeColors['dim-gray']
            }
          />

          <CommentsFilterContext isNoGroups={isNoGroups} />
        </Style.ButtonsContainer>
      </Style.SectionHeader>

      {isSearchActive && (
        <CommunitySearch
          matches={matches}
          onClose={toggleSearch}
          commentsRef={commentsRef}
          comments={comments}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <CommentsList
            commentsRef={commentsRef}
            comments={comments}
            isLoading={isLoading}
            loadNextPage={loadNextPage}
            isLoadingMoreComments={isLoadingMoreComments}
            refetch={refetch}
            total={total}
            hasQuestionComments={hasOnboarded}
          />

          <SendCommentBlock
            addReplyHandler={addReplyHandler}
            addNewCommentHandler={addNewCommentHandler}
            containerStyle={{
              padding: '16px',
            }}
          />
        </>
      )}
    </Style.Section>
  );
};
