import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const FormContainer = styled.form`
  ${tw`flex flex-col gap-6`}
`;

export const LabelText = styled.p`
  ${tw`text-grey-dark-40 text-15-20 font-medium mb-3`}
`;

export const CalendarIconContainer = styled.div`
  ${tw`pr-2 text-dim-gray`}
`;

export const ButtonContainer = styled.div`
  ${tw`flex gap-3 justify-end`}
`;
