import React, {
  FC, useEffect, useState
} from 'react';

import {
  useGetUserCurrentActivityQuery
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  TimePeriodSelect,
  TimePeriodSelectOption,
  getTimePeriodSelectOptions,
  getTimePeriodSelectValue,
} from 'src/shared/components';

import {
  CircularChartTile, CircularChartVariant
} from '../CircularChartTile';

interface ActivityTabContentProps {
  onSummaryChange: ({
    total
  }: { total: number }) => void;
  onModalToggle: (value: boolean) => void;
}

export const ActivityTabContent: FC<ActivityTabContentProps> = ({
  onSummaryChange,
  onModalToggle,
}) => {
  const language = useTypedSelector(languageSelectors.getLanguage);

  const timePeriodSelectOptions = getTimePeriodSelectOptions();

  const [timePeriod, setTimePeriod] = useState<TimePeriodSelectOption>(
    timePeriodSelectOptions[2],
  );

  useEffect(
    () => {
      const selectedTimePeriod = timePeriodSelectOptions.find(
        (period) => period.value === timePeriod.value,
      );

      if (selectedTimePeriod) {
        setTimePeriod(selectedTimePeriod);
      }
    },
    [language]
  );

  const {
    data: activityData
  } = useGetUserCurrentActivityQuery(
    {
      dateFrom: getTimePeriodSelectValue(timePeriod.value),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    active, scored, summary
  } = activityData?.data ?? {};

  useEffect(
    () => {
      if (summary) {
        onSummaryChange(summary);
      }
    },
    [summary]
  );

  return (
    <>
      <TimePeriodSelect
        timePeriod={timePeriod}
        onPeriodChange={setTimePeriod}
        onMenuClose={() => onModalToggle(false)}
        onMenuOpen={() => onModalToggle(true)}
      />

      <div className="flex flex-col md:flex-row gap-4 mb-4">
        <CircularChartTile
          variant={CircularChartVariant.Active}
          totalValue={active?.total || 0}
          userValue={active?.user || 0}
        />

        <CircularChartTile
          variant={CircularChartVariant.Scored}
          totalValue={scored?.total || 0}
          userValue={scored?.user || 0}
        />
      </div>
    </>
  );
};
