import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentWrapper = styled.div`
  ${tw`bg-white rounded-md h-full flex flex-col`}
`;

export const HeaderContainer = styled.div`
  ${tw`flex flex-col gap-7 pt-5 pb-6 px-3 border-b border-b-gray94 shadow-section-shadow`}
`;

export const Title = styled.div`
  ${tw`font-bold text-gray7 text-28-36`}
`;

export const EmptyMessage = styled.div`
  ${tw`bg-alice-blue text-dark-gray font-medium text-17-26 m-auto text-center py-6 px-8 max-w-600px`}
`;

export const FooterContainer = styled.div`
  ${tw`flex gap-4 border-t border-t-gray94 p-6 items-center`}
`;
